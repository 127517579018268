/// General Information
/// language.service : < Archivo para conexiones al server relacionadas a los lenguajes manejados en la plataforma >
///
/// < Notas específicas del archivo >
///
///
/// Author:
///   <Daniel de la Cruz Marcial>
///
/// Copyright(C) <Linkthinks>
///
///
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _URL_LABELS, _URL_LANGUAGES, _URL_USERS } from './../../config/config';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private isRtl : boolean = false; 
  constructor(private http: HttpClient) {}

  setRtl(value : boolean){
    this.isRtl = value;
  }
  getRtl(){
    return this.isRtl;
  }
  getAllLanguages(idDistributor, isPrivate, ownLanguages): Observable<any> {
    return this.http.get(_URL_LANGUAGES + '/getAllLanguages/'+idDistributor+'/'+isPrivate+'/'+ownLanguages);
  }
  deleteLanguage(languageId,check): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify({"languageId":languageId,"check":check});
    console.log(params)
    return this.http.post(_URL_LANGUAGES + '/deleteLanguage', params, {
      headers: httpHeaders,
    });
  }

  getLabelsByLanguageId(id) {
    return this.http.get(_URL_LABELS + '/getAllLabelsbyLanguage/' + id);
  }

  updateNameLanguage(jsonUser) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonUser);
    return this.http.put(_URL_LANGUAGES + '/updateNameLanguage', params, {
      headers: httpHeaders,
    });
  }

  fnCopyLanguage(jsonUser) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonUser);
    return this.http.post(_URL_LANGUAGES + '/copyLanguage', params, {
      headers: httpHeaders,
    });
  }

  /*
  deleteUserById(id): Observable<any>{
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(id);
    let url = `${_URL_USERS}/deleteUserById/${id}`
    return this.http.delete(url);
  }

  createUser(jsonUser): Observable<any>{
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let params = JSON.stringify(jsonUser);
    return this.http.post(_URL_USERS+'/createUser', params, {headers: httpHeaders});
  }

*/
getDescByLanguageId(idLanguage) {
  return this.http.get(_URL_LABELS + '/getDescsbyLanguage/'+ idLanguage);
}

  getScreenLabelsByLanguage(languageId: number): Observable<any> {
    return this.http.get(`${_URL_LABELS}/screenLabelsByLanguage/${languageId}`);
  }

  getValidationLabels(workshopID: number): Observable<any> {
    return this.http.get(`${_URL_LABELS}/validationLabels/${workshopID}`);
  }
  
  getLanguageSaved(workshopId, userId) {
    return this.http.get(_URL_LABELS + '/getLanguageSaved/'+ workshopId+'/'+userId);
  }

  fnInsUpdLanguageSelected(jsonlngSelected) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = JSON.stringify(jsonlngSelected);
    return this.http.post(_URL_LANGUAGES + '/saveLanguageSelected', params, {
      headers: httpHeaders,
    });
  }

}
