import { DialogBoardModeComponent } from './../dialog-board-mode/dialog-board-mode.component';
import { BoardService } from './../../services/board/board.service';
import { UsuarioService } from './../../services/usuario/usuario.service';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragEnd,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Platform, PlatformModule } from '@angular/cdk/platform';
import { Component, OnInit, OnDestroy, HostListener, resolveForwardRef, ViewChild, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { count, repeat, sample, switchMapTo, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SocketioService } from './../../services/socketio.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkshopService } from 'src/app/services/workshop/workshop.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewAuthUserBoardComponent } from '../viewAuthUserBoard/view-auth-user-board/view-auth-user-board.component'
import { MatListModule } from '@angular/material/list';
import { SimpleBalanceSheetComponent } from 'src/app/views/simple-balance-sheet/simple-balance-sheet.component';
import { SetupTeamComponent } from 'src/app/views/setup-team/setup-team.component';
import { DialogAllTeamsComponent } from 'src/app/views/dialog-all-teams/dialog-all-teams.component';
import { RulesGameComponent } from 'src/app/views/rules-game/rules-game.component';
import { RecordBalanceSheetComponent } from 'src/app/views/record-balance-sheet/record-balance-sheet.component';
import { IncomeStatementComponent } from 'src/app/views/income-statement/income-statement.component';
//import { parse } from 'node:path';
import { StandardBalanceSheetComponent } from 'src/app/views/standard-balance-sheet/standard-balance-sheet.component';
import { DialogOrdersComponent } from '../dialog-orders/dialog-orders.component';
import { RecordIncomeAndBalanceComponent } from 'src/app/views/record-income-and-balance/record-income-and-balance.component';
import { SocketOrderService } from 'src/app/services/sockets/socket-order/socket-order.service';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/services/order/order.service';
import { TeamService } from 'src/app/services/team/team.service';
import { ChangeboardComponent } from 'src/app/views/changeboard/changeboard.component'
import { PartitionObjectsComponent } from 'src/app/views/partition-objects/partition-objects.component';
import { DialogImprovementsComponent } from '../dialog-improvements/dialog-improvements.component';
import { SocketImprovementService } from 'src/app/services/sockets/socket-improvement/socket-improvement.service';
import { DialogMonthStatusComponent } from 'src/app/views/dialog-month-status/dialog-month-status.component';
import { DialogSetupImprovementsComponent } from '../dialog-setup-improvements/dialog-setup-improvements.component';
import { ImprovementService } from 'src/app/services/improvement/improvement.service';
import { ChatComponent } from 'src/app/views/chat/chat.component';
import { ConnectionService } from 'ng-connection-service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ChangeLanguageComponent } from 'src/app/views/change-language/change-language.component'
import { LanguageService } from 'src/app/services/language/language.service';
import { ReportsComponent } from 'src/app/views/reports/reports.component'
import { IncomestatementreportComponent } from 'src/app/views/incomestatementreport/incomestatementreport.component'
import { BalancesheetreportComponent } from 'src/app/views/balancesheetreport/balancesheetreport.component';
import { DialogBudgetExerciseComponent } from '../dialog-budget-exercise/dialog-budget-exercise.component';
import { DialogCashFlowExerciseComponent } from '../dialog-cash-flow-exercise/dialog-cash-flow-exercise.component';
import { PurchaseObjectsComponent } from '../purchase-objects/purchase-objects.component';
import { DialogHelpInfoComponent } from '../dialog-help-info/dialog-help-info.component';
import { LearningSlidesComponent } from '../learning-slides/learning-slides.component';
import { PiecesTeamsBoardComponent } from '../pieces-teams-board/pieces-teams-board.component';
import { BudgetreportComponent } from '../budgetreport/budgetreport.component';
import { DialogEditUsersComponent } from '../dialog-edit-users/dialog-edit-users.component';
import { IncomeStatementService } from 'src/app/services/IncomeStatement/IncomeStatement.service';
import { BalanceSheetService } from 'src/app/services/balance-sheet/balance.service';
import { DialogChecklistComponent } from '../dialog-checklist/dialog-checklist.component';
import { DialogLogsComponent } from '../dialog-logs/dialog-logs.component';
import { Alert } from 'selenium-webdriver';
import { type } from 'os';
import { ManualeditISBSComponent} from 'src/app/views/manualedit-isbs/manualedit-isbs.component'
import { ConfirmUserComponent } from '../confirm-user/confirm-user.component';
import { resolve } from 'dns';
import { DialogEditTeamsComponent } from '../dialog-edit-teams/dialog-edit-teams.component';
import { BalanceIncomeService } from 'src/app/services/sockets/balance-income/balance-income.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { stat } from 'fs';
import { _IP, _VERSION } from 'src/app/config/config';
declare var $: any;
// import { Console } from 'console';
export interface datosIncome{
  totSales?: number;
  costGoodSold: number;
  factotyOverheads: number;
  selling: number;
  interest:number;
  advertasting?:number;
}
export interface datosBalance{
  cash: number;
  receivables: number;
  finishGood: number;
  workProcess: number;
  rawMaterials: number;
  landBuildings: number;
  payables: number;
  loans: number;
  taxes: number;
  capitalStock: number;
  machine: number;
  retained: number;
}
@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
})
export class BoardComponent implements OnInit, OnDestroy {
  /*public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];
  public connectionStatusMessage: string;
  public connectionStatus: string;*/
  //funcion para controlar eventos cuando hay un scroll
  // @HostListener("window:scroll", ['$event'])
  // doSomethingOnWindowsScroll($event:any){
  //   let scrollOffset = $event.srcElement.children[0].scrollTop;
  //   console.log("window scroll: ", scrollOffset);

  // }
  /*@HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log("Processing beforeunload...");
    this.fnonBeforeUnload();
}*/
/*
@HostListener('window:beforeunload', ['$event'])
handleBeforeUnload(event) {
  this.boardService.setSessionforUser(this.userId, 0).subscribe((response:any) =>{
    if(response.intResponse == 200){
      console.log("CERRROO SESIONNNNNN ..........................")
    }else{
      console.log("NO PUDOCERRAR SESIONNNNNN  xxxxxxxxxxxxx")
    }
  })
  setTimeout(() => {
console.log("colsiinintervalllll")
  }, 1000);
}*/
// @HostListener('window:beforeunload')
//   ngOnDestroy()
//   {
//     
//     
//   }
ngOnDestroy(){
    //this.subscriptions.forEach(subscription => subscription.unsubscribe()); 
    this.destroy$.next(true); 
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
    console.log("Closing WS...");
    this.socketService.terminarConexion();
    
}
@HostListener('window:beforeunload', ['$event'])
unloadHandler(event: Event) {
    this.fnonBeforeUnload();
    return;
}

@HostListener('window:offline', ['$event'])
  onOffline(event: Event) {
    console.log('Se ha perdido la conexión a Internet');
    this.blnCanMoveitems = false;
    this.isConnected = false;
    Swal.mixin({
      html: this.getErrorLabel('1104'),
      showConfirmButton: true,
      confirmButtonText: 'Reload',
      showCancelButton: false,
      allowOutsideClick: false,
    }).fire().then(res => location.reload());
  }

  @HostListener('window:online', ['$event'])
  onOnline(event: Event) {
    console.log('Conexión restaurada');
    this.blnCanMoveitems = true;
    this.isConnected = true;
    Swal.mixin({
      html: 'Connection recovered',
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      showCancelButton: false,
      allowOutsideClick: false,
    }).fire().then(res => location.reload());
  }

  cantidadesIncome: datosIncome = { 
    costGoodSold: 0,
    factotyOverheads: 0,
    selling: 0,
    interest:0,
  };
  cantidadesBalance: datosBalance = {
    cash: 0,
    receivables:0,
    finishGood: 0,
    workProcess: 0,
    rawMaterials:0,
    landBuildings:0,
    payables: 0,
    loans: 0,
    taxes: 0,
    capitalStock:0,
    machine: 0,
    retained: 0,
  };
  improveStatus = -1;
  checkBoxes = [
    { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
    { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
    { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
    { id: 4, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
    { id: 5, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
    { id: 6, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
    { id: 7, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
    { id: 8, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
    { id: 9, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
    { id: 10, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
    { id: 11, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
    { id: 12, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
    { id: 13, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
    { id: 14, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
    { id: 15, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
    { id: 16, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
  ];
  arrTextLabels = [];
  improvementsOptionsSelected = [];
  improvementSelected = false;
  improvementImplemented = false;
  isProcessing = true;
  boardDisable = true;
  isHintInterest = 0;
  jsonBlnOpenModeActions = {
    'isDepreciateMachine1': false,
    'isDepreciateMachine2': false,
    'isDepreciation': false,
    'isBuyMaterials': false,
    'showMessageOwe12': false,
    'showMessageOwe18': false,
    'isBuyLoan': false,
    'showMessageInterest1': false,
    'isSoldBuild': false,
    'showMessageOwe20': false,
    'showMessageOwe10': false,
    'isPayBill': false,
    'isBuyBuild': false,
    'isBuySecondMachine': false,
    // para hacer mas grande una maquina
    'isEfficiency1': false,
    'isEfficiency2': false,
    // para mostrar maquina de customizing
    'isCustomizingShow': false,
    // para mostrar segunda maquina
    'isSecondMachine': false,
    'showBill20': false,
    'isPayBill20': false,
    'showBill10': false,
    'isPayBill10': false,
    'showBill1': false,
    'isPayBill1': false,
    'showBill6': false,
    'showBill2': false,
    'showBill2Aux1': false,
    'showBill2Aux2': false,
    'isRawMaterials1': false,
    'isRawMaterials2': false,
    'showMessageOwe4': false,
    'isActiveImprovement':false,
    'isLoansPaid':false,
    'isPayablesPaid':false
  }
  checkBoxesForFacilitator = [];
  checkBoxesForFacilitatorImprovement = [
    { id: 0, blnCheck: false, title: "Select Improvement Option", idBd: null, disabled: true },
    { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true },
    { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true },
    { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true },
    { id: 17, blnCheck: false, title: "Buy or Upgrade Equipment", idBd: null, disabled: true },
    { id: 18, blnCheck: false, title: "Pay Recruitment & Training", idBd: null, disabled: true },
    { id: 4, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true },
    { id: 5, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true },
    { id: 6, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true },
    { id: 7, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true },
    { id: 8, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true },
    { id: 9, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true },
    { id: 10, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true },
    { id: 11, blnCheck: false, title: "The Market", idBd: null, disabled: true },
    { id: 12, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true },
    { id: 13, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true },
    { id: 14, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true },
    { id: 15, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true },
    { id: 16, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true }
  ];
  checkBoxesForFacilitatorNoImprovement = [
    { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true },
    { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true },
    { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true },
    { id: 4, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true },
    { id: 5, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true },
    { id: 6, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true },
    { id: 7, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true },
    { id: 8, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true },
    { id: 9, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true },
    { id: 10, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true },
    { id: 11, blnCheck: false, title: "The Market", idBd: null, disabled: true },
    { id: 12, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true },
    { id: 13, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true },
    { id: 14, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true },
    { id: 15, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true },
    { id: 16, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true }
  ];
  VERSION: any = _VERSION;
  showBillAnimationStep16 = false;
  showBillAnimationStep23 = false;
  showDepreciationAnimationStep26 = false;
  countQuantitysDelivered = 0;
  TeamName :string = "Team Name";
  TeamNameArray: string[] = [];
  blnErrorContainer = true;
  isModeOpen = true;
  isOpenMarket = null;
  isOpenMarketDisabledBoard = false;
  intOpenMarket = 0;
  isTrashContainer = false;
  urlImgTrash = ' ../../../assets/boteblancoabierto.svg';
  currentMonth = 2;
  arrOrders = [];
  totalCashMAchine1 = 0;
  totalCashMAchine2 = 0;
  mejorasXMes = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();
  dialogRef: any;
  dialogRefAgrupar: MatDialogRef<PurchaseObjectsComponent>;
  dialogRefPartition: MatDialogRef<PartitionObjectsComponent>;
  userId = 0;
  abrioModal = false;
  expandsAdvertising = false;
  workshopId = 0;
  workshopCurrentMonthTeamAvanzado = 0;
  jsnUserSelected;
  owesAdvertising = 0;
  auxValTotalAdvertising = 0;
  advertising = 0;
  monthAuxMonthByMonth = 0;
  AdvertisingExtra = 0;
  projects = 1;
  IdStatusBid = 0;
  monthFacilitator = 0;
  showOwedAdvertising = false;
  showPaidAdvertising = false;
  isDialogMarketOpen = false;
  emailNotExists = false;
  roomId = "";
  roomWorkShopId = "";
  teamId;
  Cycles = 0;
  businessModel = '';
  userData;
  isClickBalanceSheet = false;
  isClickCustomers = false;
  blockAllTeams = false;
  itemDragged = null;
  indexItemDragged = -1;
  listAux;
  contadorSecreto = 0
  users = ['Usuario1', 'Usuario2', 'Usuario3', 'Usuario4', 'Usuario5'];
  contUser = 0;
  room = ['Room1', 'Room2', 'Room3', 'Room4', 'Room5'];
  contRoom = 0;
  strBackground = "#4b0fae";
  /* para maquina de estados */
  status = 0;
  // opcion de deshabilitacion de botones
  statusBeforeMonth = 0;
  //isChrome;

  blnFacilitador: boolean = false  /* bandera para saber si es facilitador */
  blnMove: boolean = false;
  blnAllowAnim: boolean = false;
  blnAllowAnimCapEx: boolean = false
  blnAnimRawMat: boolean = false;
  blnAnimRawMat2: boolean = false;
  blnAnimCoinPeople: boolean = false;
  blnMoveMonth2: boolean = false;
  blnMoveMonth3: boolean = false;
  blnMoveMonth4: boolean = false;
  blnMoveMonth5: boolean = false;
  blnMoveMonth6: boolean = false;
  move30: boolean = false;
  move60: boolean = false;
  move90: boolean = false;
  moveCash: boolean = false;
  blnBackgroundBlue: boolean = false;
  blnBackground2: boolean = false;
  blnBackground4: boolean = false;
  blnBackground5: boolean = false;
  blnBackground6: boolean = false;
  blnBackground7_8: boolean = false;
  blnBackground9: boolean = false;
  itemMove: number = 0;
  idLenguage: number = 1;  // ID del lenguaje (1=ingles)
  /*++++++++++++VARIABLES PARA CONTROL DE CAMBIO DE BOARD */
  //estas variable no se borra en cambio de Board,
  // variable global de todos los usuarios del board, observers members y facilitadores para usarla en el cambio de board
  lstGloblalUsersBoard = [];
  blnCanMoveitems: boolean = true;
  showCheckListFacilitator : boolean = false;
  blnCanMoveFacilitator : boolean = false;
  isAndromeda: boolean = false; //Indica si el ws es de Andromeda
  /*++++++++++++ FIN VARIABLES PARA CONTROL DE CAMBIO DE BOARD */
  lblUserwithControl = "";
  isConnected = true;

  boardMode: "DM" | "NM";

  isFacilitatorTeamAccount: boolean = false; //Indica si la cuenta del usuario pertenece a las cuentas del Facilitador para cada equipo ej: facilitator_teamN@io.com

  blnShowerrorPasoImplementImprov = false;
  textErrorImplementImprov = "";

  userWithoutsession = false;
  canSavebackup = false;
  initialDate;
  blnChangeOpenBoard = true;
  constructor(
    private socketService: SocketioService,
    private socketOrder: SocketOrderService,
    private incomeService: IncomeStatementService,
    private balanceService: BalanceSheetService,
    private injector: Injector,
    private boardService: BoardService,
    private activatedRoute: ActivatedRoute,
    private workshopService: WorkshopService,
    private _matDialog: MatDialog,
    private spinner: NgxSpinnerService,
    private orderService: OrderService,
    private teamService: TeamService,
    private socketImprovement: SocketImprovementService,
    private socketImprovementService: SocketImprovementService,
    private improvementService: ImprovementService,
    public platform: Platform,
    private connectionService: ConnectionService,
    public languageservice: LanguageService,
    private balanceIncomeService : BalanceIncomeService,
    private authService: AuthService,
    private teamsService: TeamService
  ) {
    /* this.connectionService.monitor().subscribe(isConnected => {
       this.isConnected = isConnected;
       if (this.isConnected) {
         this.InternetStatus = "ONLINE";
       
         this.fnTrowToastwithTimeMiliseconds('#errorConnection', 'Board Online Again!!', 3000);
       }
       else {
         this.InternetStatus = "OFFLINE";
         this.isOpenMarketDisabledBoard = true; 
         this.fnTrowToastwithTimeMiliseconds('#errorConnection', 'Internet Offline, Board is disabled!!', 6000);
       }
     })*/
  }

  //#region listas areas draggables
  lstLoans = [];
  lstPayables = [];
  cdkLstTrash = [];
  cdkLstTrashCash = [];
  cdkLstNoAreaDragg = [];
  currentImprovement = "";
  cdkLstTrashCapitalS = [];
  cdkLstBank = [];
  cdkLstCapEx = [];
  paidCapEx = [];
  paySuppliers = [];
  orders = [];
  cdkLstLabourP = [];
  cdkLstSuppliers = [];
  cdkLstMaterials = [];
  cdkLstLandBuild = [];
  cdkLstFinishedFI = [];
  cdkLstLoans90 = [];
  cdkLstLoans60 = [];
  cdkLstLoans30 = [];
  cdkLstLoans90Aux = [];
  cdkLstLoans60Aux = [];
  cdkLstLoans30Aux = [];
  cdkLstCash = [];
  cdkLstReceivables90 = [];
  cdkLstReceivables60 = [];
  cdkLstReceivables90Anim = [];
  cdkLstReceivables60Anim = [];
  cdkLstReceivables30Anim = [];
  cdkLstReceivablesCashAnim = [];
  lista2 = [];
  itemsToMove: number = 0;
  move2Items: boolean = false;
  move3Items: boolean = false;
  move1Items: boolean = false;
  cdkLstReceivables30 = [];
  totCdkLstReceivables90 = 0;
  totCdkLstReceivables60 = 0;
  totCdkLstReceivables30 = 0;
  cdkLstCapitalS = [];
  cdkLstRetainedE = [];
  cdkLstNetIncome = [];
  cdkLstTaxes = [];
  cdkLstIncomeBT = [];
  cdkLstInterest = [];
  cdkLstRent = [];
  cdkLstRecruitmentT = [];
  cdkLstAdminOver = [];
  cdkLstAdministration = [];
  cdkLstAdvertasing = [];
  cdkLstMarketingS = [];
  cdkLstChangeI = [];
  cdkLstDeprecation = [];
  cdkLstMachineOver = [];
  facilitatorOfBoard;
  cdkRles = [];
  cdkLstCostGS = [];
  cdkLstLocationMachine = [];
  cdkLstInsideMachineCapex = [];
  cdkLstInsideMachinePeople = [];
  cdkLstInsideMachineWorkInProgress = [];
  cdkLstInsideMachineCapex2 = [];
  cdkLstInsideMachinePeople2 = [];
  cdkLstInsideMachineWorkInProgress2 = [];
  cdkLstInsideMachineCapexCustomizing = [];
  cdkLstInsideMachineCapex3 = [];
  cdkLstInsideMachinePeople3 = [];
  cdkLstInsideMachineWorkInProgress3 = [];
  cdkLstPayables60 = [];
  cdkLstPayables30 = [];
  cdkLstPayables60Aux = [];
  cdkLstPayables30Aux = [];
  cdkLstOther = [];
  lstPositions = [];
  lstPositionBefore = [];
  //#endregion listas areas draggables
  blnGrowNocontrol = false;
  growNocontrolTaked = false;
  lstLanguagesBoard = [];
  blnAccessCUSTOMERISBS = true;
  blnAllowSeeResultTabs = true;
  teamIdOriginal = 0;
  lstTeamsGlobal: any;
  isPendingProject  = 0; 
  showOption = false;
  blnGoToMonth = false;
  lstTeamsBalance = []
  teamsCompleted = []  
  lastStartMonth
  forecastExc = 0
  isNoProjectsSocketServer = false;
  facilitatorEmails = new Set([
    "Fac1", "fac1", "Fac2", "fac2", "Fac3", "fac3", "Fac4", "fac4", "Fac5", "fac5", "Fac6", "fac6",
    "Fac1a", "fac1a", "Fac2a", "fac2a", "Fac3a", "fac3a", "Fac4a", "fac4a", "Fac5a", "fac5a", "Fac6a", "fac6a",
    "Fac1b", "fac1b", "Fac2b", "fac2b", "Fac3b", "fac3b", "Fac4b", "fac4b", "Fac5b", "fac5b", "Fac6b", "fac6b",
    "Fac1c", "fac1c", "Fac2c", "fac2c", "Fac3c", "fac3c", "Fac4c", "fac4c", "Fac5c", "fac5c", "Fac6c", "fac6c",
    "Fac10","Fac20","Fac30","fac10","fac20","fac30"
  ]);
  
  liabilitiesReceivablesBeforeUpdates: {
    id: number | null;
    liabilities30: number | null;
    liabilities60: number | null;
    liabilities90: number | null;
    payables30: number | null;
    payables60: number | null;
    receivables30: number | null;
    receivables60: number | null;
    receivables90: number | null;
  } = {
      id: null,
      liabilities30: null,
      liabilities60: null,
      liabilities90: null,
      payables30: null,
      payables60: null,
      receivables30: null,
      receivables60: null,
      receivables90: null,
    }
    // checkConnectionStatus(){
    //   if (navigator.onLine) {
    //     console.log('Conexión a internet disponible.');
    //     // Aquí puedes manejar la lógica de reconexión si es necesario.
    //   } else {
    //     console.log('Conexión a internet perdida.');
    //     this.isConnected = false;
    //     this.isOpenMarketDisabledBoard = true;
    //     this.fnTrowToastwithTimeMiliseconds('#errorConnection', this.getErrorLabel('1075'), 6000);
    //     Swal.mixin({
    //       html: this.getErrorLabel('1104'),
    //       showConfirmButton: true,
    //       confirmButtonText: 'Reload',
    //       showCancelButton: false,
    //       allowOutsideClick: false,
    //     }).fire().then(res => location.reload());
    //   }
    //   }
  ngOnInit() {
    // this.checkConnectionStatus();

    // // Escuchar eventos de conexión y desconexión a la red.
    // window.addEventListener('online', () => this.checkConnectionStatus());
    // window.addEventListener('offline', () => this.checkConnectionStatus());
    /* this.onlineEvent = fromEvent(window, 'online');
         this.offlineEvent = fromEvent(window, 'offline');
         this.subscriptions.push(this.onlineEvent.subscribe(event => {
             //this.connectionStatusMessage = 'Connected to internet! You are online';
             //this.connectionStatus = 'online';
             this.isConnected = true;
             this.fnTrowToastwithTimeMiliseconds('#errorConnection', 'Board Online Again!!', 3000);
         }));
         this.subscriptions.push(this.offlineEvent.subscribe(e => {
             //this.connectionStatusMessage = 'Connection lost! You are offline';
             //this.connectionStatus = 'offline';
             this.isConnected = false;
             this.isOpenMarketDisabledBoard = true; 
           this.fnTrowToastwithTimeMiliseconds('#errorConnection', 'Internet Offline, Board is disabled!!', 6000);
         }));
 */
    //console.log(this.platform.FIREFOX);
    // this.cdkLstCash.push({
    //   valor: 20,
    //   type: 1,
    //   id: null,
    //   draggable: false,
    // });
    //this.cdkLstAdvertasing = this.cdkLstAdvertasing[0]
    // console.log(this.cdkLstAdvertasing, 'OOOOOOOOOOOO')

    //obtenemos el usuario que intenta Jugar
    this.fnInitObserverImprovements();
    this.activatedRoute.params.subscribe(async (params) => {
      console.log('PARAMETROS:  ', params, this.status);
      // if (this.status >= 6){
      //   this.fnInitObserver()
      // }
      if (params.workshopID) {

        if (params.userMail) {
          //do anything here
          this.workshopId = params.workshopID
          this.arrTextLabels = (await this.languageservice.getValidationLabels(parseInt(this.workshopId.toString())).toPromise()).textLabels;
          const haveResponse = await this.existsWorkshop(params.userMail);
          if (haveResponse) {
            //poner codigo de beto aqui
            // this.usuarioservice.getUserById(this.userId).subscribe((responseUser: any) => {
            //   console.log("Response user", responseUser);
            var userSelect = this.jsnUserSelected[0];
            this.userData = userSelect;
            console.log('user',userSelect,this.userData)
            this.facilitatorOfBoard = this.lstGloblalUsersBoard[this.lstGloblalUsersBoard.length-1]
            if(this.userData.Email == this.facilitatorOfBoard.Email && this.userData.type == 3){
              const checkUser = await this.checkUser()
            }
            userSelect = this.userData;
            if (this.userData.IsFacilitator == 1 || this.facilitatorEmails.has(this.userData.Email)) {
              this.userData.logged = 0;
              console.log("DEJAR PASAR");
              let resultado: boolean = false;

              while (!resultado) {  //Ciclo para pedir el codigo del equipo - Card: Default Links for Facilitators
                const allowAccessWS = this.verificarAccesoUsuario(this.userData.Email);
                if (allowAccessWS){
                  resultado = true; 
                  continue;
                } 
                const codigo = await this.checkCode();
                console.log(codigo)
                if (typeof codigo === 'boolean') {
                  resultado = codigo;
                } else {
                  // Manejo de caso donde `checkCode()` no devuelve un booleano
                  console.error('Error: checkCode() no devolvió un booleano.');
                  // Romper el bucle o manejar de otra manera según sea necesario
                  break;
                }


              }

            }
                if(this.userData.logged == 0){
                  //registramos sesion
                    this.boardService.setSessionforUser(this.userData.UserID, 1,0).subscribe((response:any) =>{
                      if(response.intResponse == 200){
                        console.log("registro SESIONNNNNN ..........................")
                      }else{
                        console.log("NO pudo registrar SESIONNNNNN  ")
                      }
                    })

            this.blnFacilitador = (userSelect.type == 2 || userSelect.type == 7 || (userSelect.type == 6 && userSelect.IsFacilitator == 1) || (userSelect.type == 1 && userSelect.IsFacilitator == 1));
            //validamos si el usuario seleccionado es un observer debemos pedirle que elija algún board, ya que el no tiene
            if (userSelect.type == 4 || userSelect.type == 5) {
              Swal.fire({
                html: this.getErrorLabel('1058'),
                imageUrl: '../../../../assets/iconPrevention.svg',
                showCancelButton: false,
                allowEscapeKey: false,
                confirmButtonColor: '#6FB45C',
                cancelButtonColor: '#ED5050',
                confirmButtonText: 'OK!',
                reverseButtons: true,
              }).then((result) => {
                this.fnChangeBoardTeam();
              });

            }
            else {//sigue flujo normal de carga

              let relacionUserTeamInfo = this.lstGloblalUsersBoard.find((element) => { return element.Email.toString().toLowerCase() == params.userMail.toString().toLowerCase() });

              this.blnCanMoveitems = true;//en cambio de board nos servirá para restringirle mover cosas a members y observer que solo visualizan
              this.userId = userSelect.UserID;
              this.teamId = userSelect.TeamId == 1 ? relacionUserTeamInfo.WSUTeamId : userSelect.TeamId;
              this.boardService.getLiabilitiesReceivablesUpdates(this.workshopId, this.teamId).subscribe(res => {
                if (res.intResponse === 200) {
                  this.liabilitiesReceivablesBeforeUpdates = res.jsonData;
                }
              });
              this.userData.TeamId = this.teamId;
              // this.teamId = userSelect.TeamId;
              this.teamIdOriginal = userSelect.TeamId == 1 ? relacionUserTeamInfo.WSUTeamId : userSelect.TeamId;
              // this.teamIdOriginal = userSelect.TeamId;
              this.roomId = "room" + this.teamId;
              this.roomWorkShopId = "roomWorkShop" + this.workshopId;
              console.log("this.roomWorkShopId", this.roomWorkShopId);
              console.log("CYCLEEES", this.Cycles);
              console.log(this.roomId);
              this.fnInitSocket();
              this.socketService.setupSocketConnection(
                this.userId,
                this.roomId,
                this.roomWorkShopId
              );
              this.workshopService.getWorkshopById(this.workshopId).subscribe(response => {
                console.log("getWorkshopById", response);
                if (response['intResponse'] == 200) {
                  this.forecastExc = response['data'][0]['forecastExc'];
                  this.projects = response['data'][0]['Projects'];
                  this.lastStartMonth = response['data'][0]['lastStartMonth']
                  this.isPendingProject  = response['data'][0]['isPendingProject'];
                  console.log(this.forecastExc)
                  this.workshopCurrentMonthTeamAvanzado = response['data'][0]['currentMonth'];
                  this.improveStatus = response['data'][0]['ImproveOption'];
                  this.statusBeforeMonth = response['data'][0]['ImproveOption'] + 1;
                  var lngInitial = { LanguageId: response['data'][0]['LanguageId'], Language: response['data'][0]['Language'], langBase: 1, isRight: response['data'][0]['isRight']}
                  this.blnChangeOpenBoard = response['data'][0]['OpenTimeZone'] == 0 ? false : true;
                  this.lstLanguagesBoard.push(lngInitial);
                  if (response['data'][0]['LngOrganizationalPrivate'] != "") {
                    this.chargeotherLanguages(response['data'][0]['LngOrganizationalPrivate']);
                  }
                  this.boardMode = response['data'][0]['BoardMode'];

                  if (this.statusBeforeMonth == 0) {
                    this.isOpenMarketDisabledBoard = true;
                  }
                  if (this.statusBeforeMonth >= 3) {
                    this.isOpenMarketDisabledBoard = false;
                  }
                  //buscamos si hay algun valor de lenguaje guardado previamente por el usuario
                  this.languageservice.getLanguageSaved(this.workshopId,this.userId)
                  .subscribe(respLangSaved => {
                    console.log("resp languageSAVED:", respLangSaved);
                    if(respLangSaved["jsnAnswer"]["LanguageId"] != 0){
                      this.getTextLabelsByLanguage(respLangSaved["jsnAnswer"]["LanguageId"]);
                      this.idLenguage = respLangSaved["jsnAnswer"]["LanguageId"];
                      this.languageservice.setRtl(respLangSaved["jsnAnswer"]["isRight"] == 1 ? true : false);
                      console.log("ISRTL",this.languageservice.getRtl())
                    }else{
                      this.getTextLabelsByLanguage(lngInitial.LanguageId);
                      this.idLenguage = lngInitial.LanguageId;
                      this.languageservice.setRtl(lngInitial["isRight"] == 1 ? true : false);
                      console.log("ISRTL",this.languageservice.getRtl())
                    }
                    
                  })
                  


                }
              });
              this.orderService.getOpenMarketByWorkShopId(this.workshopId).subscribe(response => {
                console.log(response, 'isOpenMarket');
                if (response.intResponse == 200) {
                  this.isOpenMarketDisabledBoard = (response.openMarket as number) === 1 ? true : false;
                  this.intOpenMarket = response.openMarket as number;
                }
              });

              this.boardService.getInitBoard(this.userData.TeamId).subscribe(async responseBoard => {
                console.log("responseBoard", responseBoard);
                if(responseBoard.teamInf.team.lock == 1){
                  if(!this.blnFacilitador){
                    this.blockAllTeams = true;
                          Swal.fire({
                            html: this.getErrorLabel('3066'),
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey:false,
                            icon: 'warning'
                          })
                  }
                }
                this.fnInitObserver();
                if (responseBoard.teamInf.intResponse == 200 && responseBoard.board.intResponse == 200) {
                  this.TeamName = responseBoard.teamInf.team.Avatar;
                  console.log(this.TeamName,"aquiiii");
                  // if (!this.TeamName && this.blnFacilitador) {
                  //   this.TeamName = 'ACME';
                  // }
                  this.TeamNameArray = this.TeamName != null ? this.TeamName.split(" ") : [];
                  this.fnLoadBoardBackground(responseBoard.teamInf.team.Color);
                  const checklist = responseBoard.checkList.response as Array<any>
                  this.checkBoxes = [];
                  checklist.forEach((item, i) => {                    
                    this.checkBoxes.push({
                      id: item.idCheck,
                      blnCheck: item.valor,
                      title: item.title,
                      idBd: item.CheckListId,
                      disabled: true,
                      show:item.show
                    });
                  })
                  // para las actions del board;
                  const temArr = responseBoard.actionsBoard.response as Array<any>
                  temArr.forEach(item => {
                    this.jsonBlnOpenModeActions[item.Name] = item.Value as boolean;
                  });
                  console.log(this.jsonBlnOpenModeActions);
                  // fin para las actions del board;


                  const x = await this.fnAssignPiecesArea(responseBoard.board.board);
                  // this.jsonBlnOpenModeActions.isSoldBuild = this.cdkLstLandBuild.length == 0;
                  // this.cdkLstReceivables90Aux = [...this.cdkLstReceivables90];
                  // this.cdkLstReceivables60Aux = [...this.cdkLstReceivables60];
                  // this.cdkLstReceivables30Aux = [...this.cdkLstReceivables30];
                  this.totCdkLstReceivables30 = this.returnTotalLst(this.cdkLstReceivables30);
                  this.totCdkLstReceivables60 = this.returnTotalLst(this.cdkLstReceivables60);
                  this.totCdkLstReceivables90 = this.returnTotalLst(this.cdkLstReceivables90);
                  this.cdkLstLoans90Aux = [...this.cdkLstLoans90];
                  this.cdkLstLoans60Aux = [...this.cdkLstLoans60];
                  this.cdkLstLoans30Aux = [...this.cdkLstLoans30];
                  this.cdkLstPayables60Aux = [...this.cdkLstPayables60];
                  this.cdkLstPayables30Aux = [...this.cdkLstPayables30];
                  // this.cdkLstSuppliers.forEach(item => {
                  //   if(item.type == 3){
                  //     // this.jsonBlnOpenModeActions.showMessageOwe12 = true;
                  //     // this.socketService.setBlnControlModeOpen('showMessageOwe12', true, this.roomId, this.teamId);
                  //     // this.jsonBlnOpenModeActions.isBuyMaterials = true;
                  //     // this.socketService.setBlnControlModeOpen('isBuyMaterials', true, this.roomId, this.teamId);
                  //     return;
                  //   }
                  // })
                  this.status = responseBoard.teamInf.team.Status == null ? 0 : responseBoard.teamInf.team.Status;
                  this.currentMonth = responseBoard.teamInf.team.Month == null ? 1 : responseBoard.teamInf.team.Month;
                  console.log("currentMonth", this.currentMonth, this.isPendingProject); 
                  if(this.currentMonth <= this.isPendingProject){
                    console.log("aun no",this.isPendingProject)
                    this.isPendingProject == 0;
                  }
                  if(this.improveStatus == 2){
                    this.monthAuxMonthByMonth = this.currentMonth;
                  }else{
                    this.monthAuxMonthByMonth - 1
                  }
                  
                  console.log(this.checkBoxes)
                  if (this.improveStatus != 2 && this.currentMonth == this.workshopCurrentMonthTeamAvanzado) {
                    this.blnCanMoveitems = false;
                  }
                  if (this.currentMonth >= 4 && this.status == 2) {
                    this.lstLoans = this.cdkLstLoans30;
                  }
                  this.boardService.getAdvertisingBill(this.teamId, this.currentMonth).subscribe(response => {
                    console.log("Response advertising", response);
                    this.owesAdvertising = response.Advertising;
                    this.advertising = response.Advertising;
                    let tot = 0;
                    this.cdkLstAdvertasing.forEach(item => {
                      tot += item.valor;
                    })
                    this.owesAdvertising -= tot;
                    if (this.owesAdvertising > 0)
                      this.showOwedAdvertising = true;
                    else
                      this.showOwedAdvertising = false;
                  })
                  // this.checkBoxes.forEach((item, i) => {   
                  //   // console.log(item.title,item.disabled,this.status, this.intOpenMarket,this.cdkLstAdvertasing.length)
                  //   if(item.title == 'Pay Advertising' && (this.status >= 14 || this.blnFacilitador) || item.title == 'Prepare Bids' && this.status == 10 && this.intOpenMarket != 2){
                  //     console.log("Entra aqui")
                  //     item.disabled = false;
                  //   }               
                  // })
                  this.boardService.getImprovements(this.userData.TeamId).subscribe(async response => {
                    console.log("rseponse improvements", response);
                    if (response.intResponse == 200 && response.improvements != null && this.currentMonth >= 3) {
                      this.improvementsOptionsSelected = [];
                      let stringImpru = "";
                      let askImprovement = true;
                      let showNeedFacilitatorAproval = false;
                      let isQuestion = false;
                      const options = response.improvements as Array<any>;
                      options.forEach(item => {
                        this.improvementsOptionsSelected.push({
                          improvement: item.Option == 3 ? 'Customizing' :
                            item.Option == 4 ? 'Quality' :
                              item.Option == 5 ? 'Expansion' :
                                item.Option == 1 ? 'Raw Materials' :
                                  item.Option == 2 ? 'Efficiency' : 
                                  /*DCM improv */
                                  item.Option == 6 ? 'No Improvement' : '',
                          value: item.Status,
                          ImproveId: item.ImproveOptionId,
                          Approved: item.Approved,
                          option: item.Option,
                          question: item.Option == 3 ? 'Recalibrate this Machine for customizing materials? Cost is 10.  This cannot be undone.' :
                            item.Option == 4 ? 'Hire a new person for your company? Cost is 1 . This cannot be undone.' :
                              item.Option == 5 ? 'Install a second machine? CapEx is 20. Recruit & Train expense is 6.  Increased depreciation and Machine Overheads. This cannot be undone.' :
                                item.Option == 1 ? 'Recalibrate this Machine for cheaper Raw Materials? OpEx is 2 This cannot be undone.' :
                                  item.Option == 2 ? 'Increase efficiency of this machine?  CapEx is 10.  This cannot be undone.' : 
                                    item.Option == 6 ? 'You selected "No implement improvement" facilitator must approve this option in order you can continue. Please ask them or select an improvement now.' : ''
                        });
                        if(this.improvementsOptionsSelected.length >= 3 && this.currentMonth >= 4){
                          const indexToRemove = this.improvementsOptionsSelected.findIndex(option => option.option === 6);
                          if (indexToRemove !== -1) {
                              // Si se encuentra el índice, elimina el elemento con `splice`
                              this.improvementsOptionsSelected.splice(indexToRemove, 1);
                              console.log(this.improvementsOptionsSelected)
                          }
                        }
                        if(item['isQuestion'] == 1){
                          isQuestion = true;
                        }
                        if ((item.Status == 1) && item.Approved == 0 && item.Option !=6) {
                          // this.isOpenMarketDisabledBoard = true;
                          askImprovement = true;
                          console.log("aqui se metió")

                        } else if (item.Status == 2 && item.Approved == 1) {
                          askImprovement = false;
                          console.log("aqui se metió")

                          if(item['isQuestion'] == 1){
                            showNeedFacilitatorAproval = true;
                          }
                        } else if (item.Status == 2 && item.Approved == 0) {
                          askImprovement = false;
                          showNeedFacilitatorAproval = true;
                          stringImpru = item.Option == 3 ? 'Customizing' :
                          item.Option == 4 ? 'Quality' :
                            item.Option == 5 ? 'Expansion' :
                              item.Option == 1 ? 'Raw Materials' :
                                item.Option == 2 ? 'Efficiency' : 
                                /*DCM improv */
                                item.Option == 6 ? 'No Improvement' : ''
                          if(item['isQuestion'] == 0){
                            console.log("aqui se metió")
                            askImprovement = true;
                            showNeedFacilitatorAproval = false;
                          }

                        }
                        console.log(askImprovement);
                      })
                      //Ajuste en caso de que el primer proyecto sea status 2 y y arpobado 0 y isQuestion 0 y el segundo proyecto fue rechazado  
                      console.log(this.improvementsOptionsSelected)
                      if(this.improvementsOptionsSelected.length>=2){
                        if(this.improvementsOptionsSelected[0]['value'] == 2 && this.improvementsOptionsSelected[0]['Approved'] == 0  && this.improvementsOptionsSelected[1]['value'] == 2){
                          console.log("aqui se metió")
                          
                          askImprovement = true;
                        }
                        if(this.improvementsOptionsSelected[0]['value'] == 1 && this.improvementsOptionsSelected[0]['Approved'] == 0  && this.improvementsOptionsSelected[1]['value'] == 2){
                          console.log("aqui se metió")
                          
                          askImprovement = true;
                        }
                      }

                      //preguntamos si hay algun error que el sistema deba resolver este caso es para el erro5 estancados en pay materials
                      console.log("ENTRA A FUNCION")
                      this.fnFixErrorstatus5();
                      //preguntamos si el mercado esta abierto para abrir el modal y activar el socket al equipo, para asegurar que esta conectado
                      this.fnFixErrorSocketMarket();
                      
                      
                      //fin pregunta por error estatus  5
                      console.log("PREGUNTA" , this.status, askImprovement, this.blnFacilitador, isQuestion, this.isProjectsEnableByCurrentMonth)
                      if (this.status == 3) {
                        // if (!this.blnFacilitador) {
                        //   return;
                        // }
                        console.log("Entra aqui")
                        setTimeout(() => {
                        if (askImprovement  ) {
                          console.log("si toca")

                          // if(this.blnCanMoveFacilitator){
                          //   return;
                          // }
                          if(this.projects == 0){
                            console.log("no toca")

                            return;
                          }
                          if(isQuestion){
                            console.log("no toca")

                            return;
                          }
                          if(this.jsonBlnOpenModeActions.isActiveImprovement){
                            console.log("no toca")

                            return;

                          }
                          console.log(this.improvementsOptionsSelected)
                          if(this.improvementsOptionsSelected.length > 1){
                            if((this.improvementsOptionsSelected[0].option == 6 || this.improvementsOptionsSelected[1].option == 6) && !this.isProjectsEnableByCurrentMonth){
                            console.log("no toca")
                              
                            return;
                            }
                            //Caso bloqeuar la pregunta en mes 4, cuando ya se implementó en mes 3, si no se implementó entonces mostar
                            if((this.improvementsOptionsSelected[0].option != 0 && this.improvementsOptionsSelected[0].value == 3 && this.improvementsOptionsSelected[1].option == 0) && !this.isProjectsEnableByCurrentMonth && this.currentMonth >= 4){
                              console.log("no toca")
                                
                              return;
                              }
                          }else if(this.improvementsOptionsSelected.length == 1){
                            if(this.improvementsOptionsSelected[0].option == 6 && !this.isProjectsEnableByCurrentMonth){
                            console.log("no toca")
                              
                            return;
                            }
                          }
                          this.askImprovement(); 
                        }
                        }, 1000);
                        if (showNeedFacilitatorAproval) {
                          console.log(this.improvementsOptionsSelected)
                          console.log(this.getSingleTextLabel('8074'))
                          let originalString = this.getSingleTextLabel('8074')
                          let modifiedString = originalString.replace(/(your)(\s*)(project)/, `$1$2${stringImpru}$2$3`);
                          console.log(modifiedString)
                          if (this.currentMonth > 3) {
                            if (
                              ((this.improvementsOptionsSelected[0].value == 3) && this.improvementsOptionsSelected[0].option == 5 && this.improvementsOptionsSelected[0].Approved == 0) ||
                              ((this.improvementsOptionsSelected[1].value == 3) && this.improvementsOptionsSelected[1].option == 5 && this.improvementsOptionsSelected[1].Approved == 0)
                            ) {
                              let strMachine = ""
                              if (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isRawMaterials1) {
                                strMachine = "for Machine 2"

                              } else {
                                strMachine = "for Machine 1"
                              }
                              modifiedString = modifiedString.replace(/(project)(\s*)(and)/, `$1$2${strMachine}$2$3`);
                            }
                          }
                          Swal.mixin({
                            text: modifiedString,
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            showCancelButton: false,
                            allowEscapeKey: false
                          }).fire();
                        }
                      }
                    }
                    if(this.currentMonth == 2){//también abriremos el modal de mercado automaticamente apartir del mes 2
                      this.fnFixErrorSocketMarket();
                    }
                    if(this.currentMonth == 1 && this.boardMode == "DM"){
                      this.fnFixErrorSocketMarket();
                    }
                    const x = await this.getOrders();
                  });
                  console.log('Mes', this.currentMonth)

                  if (this.status >= 6 && this.currentMonth >= 2) {
                    if (this.cdkLstAdvertasing[0] != undefined) {
                      this.auxValTotalAdvertising = this.cdkLstAdvertasing[0].valor
                    } else {
                      this.auxValTotalAdvertising = 0
                    }

                  }

                  //depues de la carga de piezas en el board validamos si el user tiene el rol de CFO que es el asignado para poder controlar el BOARD al inicio

                  setTimeout(() => {

                    this.orderService.getValidationControlRol(this.userId, this.teamId).subscribe(response => {
                      console.log("respuesta de fn getValidationControlRol", response);
                      if (response.intResponse == 200) {
                        this.isFacilitatorTeamAccount = response.isFacilitatorAccount;
                        this.isOpenMarketDisabledBoard = response.IsCFO > 0 && this.statusBeforeMonth != 0 ? false : true;
                        this.blnGrowNocontrol = response.IsCFO > 0 && this.statusBeforeMonth != 0 ? false : true;
                        if (response.IsCFO > 0) {
                          this.fntakeControl();
                        }
                        console.log("blnGrowNocontrol true i am COO;;;;;;");

                      }
                      else {
                        this.isOpenMarketDisabledBoard = true;
                        this.blnGrowNocontrol = true;
                        console.log("blnGrowNocontrol true;;;;;;");
                      }
                      if (this.blnFacilitador || (response.memberCount === 2 && this.isFacilitatorTeamAccount==false)) {
                        this.isOpenMarketDisabledBoard = false;
                        this.blnGrowNocontrol = false;
                        console.log("blnGrowNocontrol false facilitator;;;;;;");
                      }
                      //Preguntar si facilitador esta en mes correcto para jugar
                      if(this.blnFacilitador && this.currentMonth >= 2){
                        this.boardService.getFacilitatorMonth(this.teamId).subscribe(response => {
                          console.log(response);
                          if(response['month'] != this.currentMonth){
                            this.monthFacilitator = response['month'];
                            // this.blnCanMoveFacilitator = true;
                            // console.log("NO PUEDE MOVER")
                            
                            if (this.improvementsOptionsSelected.length == 0){
                              if ( this.currentMonth == 3) {
                                this.improvementsOptionsSelected.push({
                                  improvement: '',
                                  value: 0,
                                  ImproveId: -1,
                                  option: 0,
                                  question: ''
                                });
                              }else if ( this.currentMonth == 4 || this.currentMonth ==5){
                                this.improvementsOptionsSelected.push({
                                  improvement: '',
                                  value: 0,
                                  ImproveId: -1,
                                  option: 0,
                                  question: ''
                                });
                                this.improvementsOptionsSelected.push({
                                  improvement: '',
                                  value: 0,
                                  ImproveId: -1,
                                  option: 0,
                                  question: ''
                                });
                              }
                              
                            }
                            else if (this.improvementsOptionsSelected.length == 1 && ( this.currentMonth == 4 || this.currentMonth ==5)){
                              this.improvementsOptionsSelected.push({
                                improvement: '',
                                value: 0,
                                ImproveId: -1,
                                option: 0,
                                question: ''
                              });
                              }

                            //
                            if(this.isProjectsEnableByCurrentMonth){
                              this.showCheckListFacilitator = true;
                              this.checkBoxesForFacilitator = this.checkBoxesForFacilitatorImprovement;
                            }else{
                              this.showCheckListFacilitator = true;
                              this.checkBoxesForFacilitator = this.checkBoxesForFacilitatorNoImprovement;
                              console.log(this.checkBoxesForFacilitator);

                            }
                          }else{
                            // console.log("PUEDE MOVER")
                            // this.blnCanMoveFacilitator = false;
                            this.showCheckListFacilitator = false;
                          }
                        })
                      }
                      /* if( response.IsCFO <= 0 ){
                         this.blnGrowNocontrol = true;
                         console.log("blnGrowNocontrol true;;;;;;");
                       }*/
                    });
                  }, 1000);
                  this.isModeOpen = responseBoard.teamInf.team.Mode == 1 ? true : false;
                  console.log(this.isModeOpen, "ismodeopen?");
                  setTimeout(() => {
                    
                    //Obtener a los equipos para saber si activamos "Go to X month"
                    this.teamService.getAllTeamsAndMembersById(this.workshopId).subscribe(resp => {
                      console.log("fnGetAllTeamsAndMembers", resp);
                      this.lstTeamsBalance = [];
                      
                      resp['teams'].forEach(element => {
                        this.lstTeamsBalance.push(element.team)
                      });
                      this.teamsCompleted = this.lstTeamsBalance.filter(x => x.Standardb_month >= this.currentMonth);
                      console.log(this.lstTeamsBalance,this.teamsCompleted);
                      if(this.teamsCompleted.length == this.lstTeamsBalance.length){
                        this.blnGoToMonth = true;

                      }else{
                        this.blnGoToMonth = false;
                      }
                    })
                  }, 1200);

                } else {
                  Swal.fire('Error!', responseBoard.strAnswer, 'error');
                }
              });

              //   });

          // observable para el socket de openWs
          //Solo está atento a cambios del fac con open ws y close ws
              this.socketService._openWs
              .pipe(takeUntil(this.destroy$))
              .subscribe((response) => {
                console.log(response);
                if (response) {
                  if(response.status === 'Change openTimeZone') {
                    console.log('CAMBIOOO DE OPENWS');
                    if(!this.blnFacilitador){
                      location.reload();
                    }
                  }else if(response.status == "finishBalanceServer" && response.data != undefined){
                    console.log("Equipo terminó mes");
                    this.lstTeamsBalance.forEach(element => {
                      if(element.TeamId == response.data['teamId'])
                        element['Standardb_month'] = response.data['month']
                    });
                    this.teamsCompleted = this.lstTeamsBalance.filter(x => x.Standardb_month >= this.currentMonth);
                    console.log(this.lstTeamsBalance,this.teamsCompleted);
                    if(this.teamsCompleted.length == this.lstTeamsBalance.length){
                      this.blnGoToMonth = true;

                    }else{
                      this.blnGoToMonth = false;
                    }

                  } else if (response.status == 'usersessionServer'){
                    console.log(this.userId, response.data.userId)
                    if(this.userId == response.data.userId && response.data.logged == 0){
                      console.log("Es el");
                      Swal.fire({
                        html: "Your session is disconnected. Please reload the app window.",
                        showCancelButton: false,
                        showConfirmButton:false,
                        confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
                        confirmButtonColor: '#5EA0CC',
                        allowOutsideClick: false,
                        allowEscapeKey:false,
                        icon: 'warning'
                      });
                    }
                  } else if (response.status == 'blockTeamBoardServer'){
                    console.log("bloquear board")
                    if(!this.blnFacilitador){
                      this.blnCanMoveitems = false;
                    }

                  } else if (response.status == 'updateFacilitatorMonth'){
                    if(this.blnFacilitador && this.currentMonth >= 2){
                      this.currentMonth = response.data.mes;
                      console.log("updateFacilitatorMonth update to month: ",this.currentMonth);
                    }
                    this.blnGoToMonth = false;
                  } 
                }
              });

              // observador para el socket que controla las monedas
              this.socketService._coin
                .pipe(takeUntil(this.destroy$))
                .subscribe((response) => {
                  if (response) {
                    /*environment.socketStatus = true;
                    this.isConnected = true;
                    this.fnTrowToastwithTimeMiliseconds('#errorConnection', 'Board Online Again!!', 3000);*/

                    console.log('RESPIESTAAAAAA', response);
                    if (response.status == "socketdisconected") {
                      console.log("socket disconected messagge from client!!!!!!!");
                    }
                    else if (
                      response.status == 'dropped' &&
                      response.data.data != undefined
                    ) {

                      this.fnDroppedPieceSocket(response);

                    }
                    else if (response.status == "pieceDisabled") {
                      this.disablePiece(response);
                    }
                    else if (response.status == "controlDisabled") {
                      this.disableControl(response);
                    }
                    else if (response.status == "editedTeams") {
                      /*if(!this.blnFacilitador){
                        location.reload();
                      }*/
                      location.reload();
                    }
                    else if (response.status == "editedTeamsByFac") {
                      if(!this.blnFacilitador){
                        location.reload();
                      }           
                    }
                    else if (response.status == "blockAllTeams") {
                      console.log("Bloquear", response)
                      if(!this.blnFacilitador){
                        if(response.data.status == 1){
                          this.blockAllTeams = true;
                          Swal.fire({
                            html: this.getErrorLabel('3066'),
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey:false,
                            icon: 'warning'
                          })
                        }else if (response.data.status == 0 && this.blockAllTeams == true){
                          location.reload();
                        }else if (response.data.status == 2){
                          location.reload();
                        }
                        
                      }
                    }
                    else if (response.status == "blockTeam") {
                      console.log("Bloquear team", response)
                      if(!this.blnFacilitador){
                        this.blockAllTeams = true;
                        Swal.fire({
                          html: this.getErrorLabel('3066'),
                          showConfirmButton: false,
                          allowOutsideClick: false,
                          allowEscapeKey:false,
                          icon: 'warning'
                        })
                      }
                    }
                    else if (response.status == "updatePendingProject"){
                      this.isPendingProject = response.data['pendingProject']
                      console.log("updatePendingProject", this.isPendingProject)
                      if(this.currentMonth <= this.isPendingProject){
                        console.log("aun no",this.isPendingProject)
                        this.isPendingProject == 0;
                      }
                    }
                    else if (response.status == "changeStatus") {
                      console.log("Response change status", response);
                      // setTimeout(() => {
                      //   this.checkBoxes.forEach((item, i) => {                     
                      //     if(item.title == 'Pay Advertising' && this.status >= 14 || item.title == 'Prepare Bids' && this.status == 10 && this.intOpenMarket != 2){
                      //       console.log("Entra aqui" ,item.title)
                      //       item.disabled = false;
                      //     }else{
                      //       item.disabled = true;
                      //     } 
                      //     if(item.title == 'Pay Advertising' && this.status >= 10 && this.blnFacilitador){
                      //       console.log("DESABILITA")
                      //       item.disabled = false;
                      //     }      
                      //   })
                      // }, 400);   
                      if (response.data.status > this.status || response.data.status == -1 || response.data.status == 0) {
                        this.status = response.data.status;
                      }
                      if(response.data.status == -1){
                        this.improveStatus = -1
                      }
                      this.currentMonth = response.data.month;
                      if (this.currentMonth >= 2) {
                        // this.checkBoxes[this.getPasoCkechList(this.status)].disabled = true;
                      }
                      if(this.currentMonth == 1 && this.showOwedAdvertising == true && this.status == 32 && this.boardMode == "NM"){ // bug adv bill del mes 1 en mes 2
                        this.showOwedAdvertising = false;
                      }
                      if (this.currentMonth >= 2 && this.status == 1) {
                        console.log("ENTRAAAAAAAAAA")
                        this.urlImgTrash = "../../../assets/boteblacocontachita.svg"
                        this.countQuantitysDelivered = 0;
                        this.jsonBlnOpenModeActions.isDepreciateMachine1 = false;
                        this.jsonBlnOpenModeActions.isDepreciateMachine2 = false;
                      } else if (this.currentMonth >= 2 && this.status <= 0) {
                        console.log(this.blnCanMoveitems);
                        this.intOpenMarket = 0;
                        if (this.blnCanMoveitems) {
                          this.blnCanMoveitems = this.intOpenMarket == 0;
                        }
                        // console.log(this.isProjectsEnableByCurrentMonth, this.status,this.currentMonth)
                        // if(!this.isProjectsEnableByCurrentMonth && this.status == 0){
                        //   console.log("Mes sin mejora")
                        //   this.blnCanMoveitems = false;
                        // }else{
                        //   if(this.isProjectsEnableByCurrentMonth && this.status == -1 ){
                        //     console.log("Mes con mejora")
                        //     this.blnCanMoveitems = false;
                        //   }
                        // }
                        this.isProjectsEnableByCurrentMonth
                        this.localUpdLoansAndReceivables();
                        this.countQuantitysDelivered = 0;
                        this.jsonBlnOpenModeActions.isDepreciateMachine2 = false;
                        this.improvementsOptionsSelected.forEach(item => {
                          item.Approved = 0;
                        });
                        
                      } else if (this.currentMonth == 2 && this.status == 3) {

                      }
                      console.log(this.status,this.owesAdvertising,this.showOwedAdvertising,this.showPaidAdvertising,this.cdkLstAdvertasing)
                      if(this.status == 31 && this.boardMode == "DM" && this.cdkLstAdvertasing.length!=0){
                        this.showOwedAdvertising = false;
                        this.showPaidAdvertising = true;
                        }
                      if(this.status == 30 && this.boardMode == "DM" && !this.isClickCustomers){
                        this.fnClickCustomers();
                      }
                      if(this.status != 36 && this.currentMonth == 1){
                        this.spinner.hide();
                      }
                      if(this.status < 3 && this.currentMonth >= 2){
                        console.log("Entro?")
                        this.spinner.hide();
                      }
                      // setTimeout(() => {
                      //   if(this.status == 14 && !this.getCheckCheckbox(11).blnCheck){
                      //     this.checkCheckbox(11,true);
                      //   }
                      //   setTimeout(() => {
                      //     if(this.status == 14 && !this.getCheckCheckbox(13).blnCheck){
                      //       this.checkCheckbox(13,true);
                      //     }
                      //   }, 500);
                      // }, 1500);
                    } else if(response.status == "sbsStepCompletedServer"){
                      console.log(response);
                      this.status = response.data.status;
                    } else if (response.status == "splitCoinServer") {
                      console.log("Response split coin", response);
                      if (response.data.idList == 'cdkLstCash') {
                        this.spinner.hide();
                        this.cdkLstCash = [];
                        this.cdkLstCash = response.data.newList;
                      } else if (response.data.idList == 'cdkLstInsideMachineCapex') {
                        this.cdkLstInsideMachineCapex = [];
                        this.cdkLstInsideMachineCapex = response.data.newList;
                        /*this.cdkLstInsideMachineCapex.forEach(item => {
                          if (item.valor == 1) {
                            this.jsonBlnOpenModeActions.isDepreciateMachine1 = true;
                            this.socketService.setBlnControlModeOpen('isDepreciateMachine1', true, this.roomId, this.teamId);
                          }
                        });*/
                      } else if (response.data.idList == 'cdkLstInsideMachineCapex2') {
                        this.cdkLstInsideMachineCapex2 = [];
                        this.cdkLstInsideMachineCapex2 = response.data.newList;
                        /*this.cdkLstInsideMachineCapex2.forEach(item => {
                          if (item.valor == 1) {
                            this.jsonBlnOpenModeActions.isDepreciateMachine2 = true;
                            this.socketService.setBlnControlModeOpen('isDepreciateMachine2', true, this.roomId, this.teamId);
                          }
                        });*/
                      } else if (response.data.idList == "payAcumanGrossProfit") {
                        this.cdkLstAdminOver.push({
                          // descripcion: 'bulto 20', 
                          type: 1,
                          valor: 1,
                          id: response.data.response.idPieces.cdkLstAdminOver.idPiece,
                          draggable: false,
                          draggingBy: ""
                        });
                        this.cdkLstAdministration.push({
                          // descripcion: 'bulto 20', 
                          type: 1,
                          valor: 1,
                          id: response.data.response.idPieces.cdkLstAdministration.idPiece,
                          draggable: false,
                          draggingBy: ""
                        });
                        this.cdkLstMarketingS.push({
                          // descripcion: 'bulto 20', 
                          type: 1,
                          valor: 1,
                          id: response.data.response.idPieces.cdkLstMarketingS.idPiece,
                          draggable: false,
                          draggingBy: ""
                        });
                        this.cdkLstChangeI.push({
                          // descripcion: 'bulto 20', 
                          type: 1,
                          valor: 1,
                          id: response.data.response.idPieces.cdkLstChangeI.idPiece,
                          draggable: false,
                          draggingBy: ""
                        });

                      } else if (response.data.idList == "cdkLstMaterials") {
                        this.cdkLstMaterials = [];
                        this.cdkLstMaterials = response.data.newList;
                      } else if (response.data.idList == "cdkLstSuppliers") {
                        this.cdkLstSuppliers = [];
                        this.cdkLstSuppliers = response.data.newList;
                        this.spinner.hide()
                      } else if (response.data.idList == "cdkLstFinishedFI") {
                        this.spinner.hide()
                        this.cdkLstFinishedFI = [];
                        response.data.newList.forEach(element => {
                          if (element.valor > 0) {
                            this.cdkLstFinishedFI.push(element);
                          }
                        });
                      } else if (response.data.idList == "cdkLstInsideMachineWorkInProgress") {
                        this.cdkLstInsideMachineWorkInProgress = [];
                        response.data.newList.forEach(element => {
                          if (element.valor > 0) {
                            this.cdkLstInsideMachineWorkInProgress.push(element);
                          }
                        });
                      } else if (response.data.idList == "cdkLstInsideMachineWorkInProgress2") {
                        this.cdkLstInsideMachineWorkInProgress2 = [];
                        response.data.newList.forEach(element => {
                          if (element.valor > 0) {
                            this.cdkLstInsideMachineWorkInProgress2.push(element);
                          }
                        });
                      } else if (response.data.idList == 'cdkLstCostGS') {
                        this.cdkLstCostGS = [];
                        response.data.newList.forEach(element => {
                          if (element.valor > 0) {
                            this.cdkLstCostGS.push(element);
                          }
                        });
                        this.spinner.hide();
                      } else if (response.data.idList == 'cdkLstRetainedE') {
                        console.log("QUITAR SPINNER PARA RE")
                        this.spinner.hide()
                        this.cdkLstRetainedE = [];
                        this.cdkLstRetainedE = response.data.newList;
                      } else if (response.data.idList == 'cdkLstNetIncome') {
                        this.cdkLstNetIncome = [];
                        this.cdkLstNetIncome = response.data.newList;
                      } else if (response.data.idList == 'cdkLstCapEx') {
                        this.cdkLstCapEx = [];
                        this.cdkLstCapEx = response.data.newList;
                        if (this.cdkLstCapEx.some(item => item.type == 11)) {
                          Swal.close({ isConfirmed: false, isDenied: false, isDismissed: false });
                        }
                      } else if (response.data.idList == 'cdkLstLabourP') {
                        this.cdkLstLabourP = [];
                        this.cdkLstLabourP = response.data.newList;
                      } else if (response.data.idList == 'cdkLstAdvertasing') {
                        console.log("ENTRO ADV SPLIT")
                        this.cdkLstAdvertasing = [];
                        this.cdkLstAdvertasing = response.data.newList;
                        let tot = 0;
                        this.cdkLstAdvertasing.forEach(item => {
                          tot += item.valor;
                        });
                        if (tot >= this.advertising) {
                          this.showOwedAdvertising = false;
                        }
                        console.log(tot, this.advertising, this.showOwedAdvertising)

                      }
                      /* EFA 93*/
                      else if (response.data.idList === 'cdkLstReceivables90') {
                        this.spinner.hide();
                        this.cdkLstReceivables90 = [];
                        this.cdkLstReceivables90 = response.data.newList;
                        // this.cdkLstReceivables90Aux = [...this.cdkLstReceivables90]
                        // this.totCdkLstReceivables90 = this.returnTotalLst(this.cdkLstReceivables90);
                      } else if (response.data.idList === 'cdkLstReceivables60') {
                        this.spinner.hide();
                        this.cdkLstReceivables60 = [];
                        this.cdkLstReceivables60 = response.data.newList;
                        // this.totCdkLstReceivables60 = this.returnTotalLst(this.cdkLstReceivables60);
                        // this.cdkLstReceivables60Aux = [...this.cdkLstReceivables60]
                      } else if (response.data.idList === 'cdkLstReceivables30') {
                        this.spinner.hide();
                        this.cdkLstReceivables30 = [];
                        this.cdkLstReceivables30 = response.data.newList;
                        // this.cdkLstReceivables30Aux = [...this.cdkLstReceivables30]
                        // this.totCdkLstReceivables30 = this.returnTotalLst(this.cdkLstReceivables30);
                      }
                      /* EFA 93 f*/
                    } else if (
                      response.status == 'dropped' &&
                      response.data.data != undefined
                    ) {
                      this.fnDroppedPieceSocket(response);
                    } else if (response.status == "setListServer") {
                      console.log("response setListServer", response);
                      // seteamos la lista como nos quedo en el servidor y en el cliente que la modifico
                      if (response.data.idList == "cdkLstMaterials") {
                        this.cdkLstMaterials = [];
                        this.cdkLstMaterials = response.data.newList;
                      } else if (response.data.idList == "cdkLstSuppliers") {
                        this.cdkLstSuppliers = [];
                        this.cdkLstSuppliers = response.data.newList;
                      }
                      // borramos el grupo que se movio
                      console.log(response.data.olIdList);
                      if (response.data.oldIdList == "cdkLstSuppliers") {
                        this.cdkLstSuppliers.forEach((item, i) => {
                          console.log("comparando: ", item.id, response.data.oldItem.id)
                          if (item.id == response.data.oldItem.id) {
                            this.cdkLstSuppliers.splice(i, 1);
                            return;
                          }
                        });
                      } else if (response.data.oldIdList == "cdkLstMaterials") {
                        this.cdkLstMaterials.forEach((item, i) => {
                          console.log("comparando: ", item.id, response.data.oldItem.id)
                          if (item.id == response.data.oldItem.id) {
                            this.cdkLstMaterials.splice(i, 1);
                            return;
                          }
                        });
                      }
                    } else if (response.status == "setListOrderDeliverServer") {
                      if (response.data.idList == "cdkLstReceivables60") {
                        // this.cdkLstReceivables60 = [];
                        this.cdkLstReceivables60 = this.cdkLstReceivables60.concat(response.data.newList);
                        this.totCdkLstReceivables60 = this.returnTotalLst(this.cdkLstReceivables60);
                        // this.cdkLstReceivables60Aux = [...this.cdkLstReceivables60];
                      } else if (response.data.idList == "cdkLstReceivables30") {
                        // this.cdkLstReceivables30 = [];
                        this.cdkLstReceivables30 = this.cdkLstReceivables30.concat(response.data.newList);
                        this.totCdkLstReceivables30 = this.returnTotalLst(this.cdkLstReceivables30);
                        // this.cdkLstReceivables30Aux = [...this.cdkLstReceivables30];
                      } else if (response.data.idList == "cdkLstReceivables90") {
                        // this.cdkLstReceivables90 = [];
                        this.cdkLstReceivables90 = this.cdkLstReceivables90.concat(response.data.newList);
                        this.totCdkLstReceivables90 = this.returnTotalLst(this.cdkLstReceivables90);
                        // this.cdkLstReceivables90Aux = [...this.cdkLstReceivables90];
                      } else if (response.data.idList == "cdkLstCash") {
                        // this.cdkLstCash = [];
                        this.cdkLstCash = this.cdkLstCash.concat(response.data.newList);
                        console.log(this.cdkLstCash);
                      }
                    } else if (response.status == "borrowFromBankServer") {
                      this.deleteCoinByAreaID('cdkLstBank', response.data.item.id)
                      this.cdkLstCash.push(
                        response.data.response.pieceCash
                      );
                      this.itemMove = response.data.response.pieceCash.id;
                      console.log(this.itemMove);
                      if (response.data.idList == "cdkLstLoans90") {
                        this.cdkLstLoans90.push(
                          response.data.response.pieceLoans
                        );
                        // this.cdkLstLoans90Aux = [...this.cdkLstLoans90];
                      } else if (response.data.idList == "cdkLstLoans60") {
                        this.cdkLstLoans60.push(
                          response.data.response.pieceLoans
                        );
                        // this.cdkLstLoans60Aux = [...this.cdkLstLoans60];
                      } else if (response.data.idList == "cdkLstLoans30") {
                        this.cdkLstLoans30.push(
                          response.data.response.pieceLoans
                        );
                        // this.cdkLstLoans30Aux = [...this.cdkLstLoans30];
                      }
                      const blns = response.data.idBlns as Array<any>;
                      const values = response.data.values as Array<boolean>;
                      blns.forEach((item, i) => {
                        this.jsonBlnOpenModeActions[item] = values[i];
                      })
                      // this.updStatusMachine();
                    } else if (response.status == "createCoinServer") {
                      this.addCoinByAreaID(response.data.idList, response.data.response.newCoin);
                    } else if (response.status == "deleteCoinServer") {
                      this.deleteCoinByAreaID(response.data.idList, response.data.item.id);
                    } else if (response.status == "deleteOrderServer") {
                      console.log(this.orders)
                      this.orders.forEach((item, i) => {
                        if (item.OrderId == response.data.item.OrderId) {
                          this.orders.splice(i, 1);
                        }
                        // if (item.BidId == response.data.item.BidId) {
                        //   this.orders[i]['blnDeleted'] = 1;
                        // }
                      });
                      console.log(this.orders);
                    } else if (response.status == "payLoanServer") {
                      switch (response.data.idList) {
                        case 'cdkLstLoans30':
                          this.fnUpdateLoan(this.cdkLstLoans30, response.data.item);
                          break;
                        case 'cdkLstLoans60':
                          this.fnUpdateLoan(this.cdkLstLoans60, response.data.item);
                          break;
                        case 'cdkLstLoans90':
                          this.fnUpdateLoan(this.cdkLstLoans90, response.data.item);
                          break;
                        case 'cdkLstPayables30':
                          this.fnUpdateLoan(this.cdkLstPayables30, response.data.item);
                          break;
                        case 'cdkLstPayables60':
                          this.fnUpdateLoan(this.cdkLstPayables60, response.data.item);
                          break;

                      }
                    } else if (response.status == "payGrossProfitServer") {
                      this.spinner.hide();
                      console.log("response pay gross profit ", response);
                      this.deleteCoinByAreaID('cdkLstCash', response.data.item.id);
                      const strAreasToPay = response.data.idListsToPay as Array<string>;
                      strAreasToPay.forEach((item, i) => {
                        this.addCoinByAreaID(item, {
                          id: response.data.response.info[item].idPiece,
                          type: 1,
                          valor: response.data.response.info[item].valor,
                          draggable: false
                        });
                      });
                      // checkear paso 8 del checklist
                      console.log('getCheckCheckBox1')
                      let x = this.getCheckCheckbox(10).blnCheck;
                      let y = this.fnisReadyPaso8();
                      // Pay expenses DONE
                      if (y && this.currentMonth >= 2 && !this.getCheckCheckbox(10).blnCheck && !this.blnGrowNocontrol) {
                        // this.checkBoxes[this.currentMonth>=3?9:7].blnCheck = true;
                        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
                        // this.checkCheckbox(7, true);
                        this.checkCheckbox(10, true);
                        this.status = 10;
                        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                        //guardamos backup cuando el check se marque para volver a guardar la cantidad correcta de cash
                        // this.fnSaveBackQuantities();
                        console.log(this.userId, response.data.userId )
                        if(this.userId == response.data.userId){
                          console.log("HACER BACKUP")
                          setTimeout(() => {
                            this.funcionBack("backOriginal");
                          }, 1000);
                        }                
                      } else if (this.fnisReadyPaso8() && this.currentMonth == 1) {
                        this.status = 19;
                        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                      }

                    } else if (response.status == "setBlnControlModeOpenServer") {
                      console.log("response bln control mode open ", response);
                      this.spinner.hide();
                      this.jsonBlnOpenModeActions[response.data.idBln] = response.data.value as boolean;
                      if (response.data.idBln == 'isRawMaterials1' || response.data.idBln == 'isRawMaterials2' || response.data.idBln == 'isEfficiency1'
                        || response.data.idBln == 'isEfficiency2' || (response.data.idBln == 'showBill2' && response.data.value == true)) {
                        Swal.close({ isConfirmed: false, isDenied: false, isDismissed: false });
                      }
                      if (response.data.idBln == 'isRawMaterials1') {
                        this.jsonBlnOpenModeActions.showBill2Aux1 = true;
                        this.socketService.setBlnControlModeOpen('showBill2Aux1', true, this.roomId, this.teamId);
                      } else if (response.data.idBln == 'isRawMaterials2') {
                        this.jsonBlnOpenModeActions.showBill2Aux2 = true;
                        this.socketService.setBlnControlModeOpen('showBill2Aux2', true, this.roomId, this.teamId);
                      }

                    } else if (response.status == "updateCoinServer") {
                      console.log("response update coin server ", response);
                      this.deleteCoinByAreaID(response.data.idList, response.data.item.id);
                      this.addCoinByAreaID(response.data.idList, response.data.item);

                    } else if (response.status == "updateCheckListServer") {
                      console.log("response update Check List Server server ", response);
                      const check = JSON.parse(response.data.checkList);
                      this.checkBoxes = check;
                      //TheMarket PrepareBids DONE
                      if(!this.isDialogMarketOpen && this.getCheckCheckbox(11).blnCheck && !this.getCheckCheckbox(13).blnCheck && !this.blnFacilitador && (this.status == 10 || this.status == 11)){
                        location.reload();
                      }
                      if(this.status<=9 && this.status >= 1 && this.getCheckCheckbox(11).blnCheck && this.getCheckCheckbox(13).blnCheck){
                        console.log("BORRAAAAR")
                        this.spinner.show();
                        this.checkCheckbox(11, false);
                        setTimeout(() => {
                          this.checkCheckbox(13 , false);
                          this.spinner.hide();
                        }, 500);
                      }
                      if(this.status == 3){
                        setTimeout(() => {
                          this.spinner.hide();
                        }, 500);
                      }
                    } else if (response.status == "deliverOrderServer") {
                      //this.move60 = true;
                      console.log("response deliverOrderServer ", response);
                      this.spinner.hide();
                      // this.checkBoxes = JSON.parse(response.data.checkList);
                      const piecesSold = response.data.piecesSold as Array<number>;
                      piecesSold.forEach(id => {
                        const coin = this.cdkLstCostGS.find((item) => item.id == id);
                        coin.paid = true;
                      });
                      this.orders.forEach(item => {
                        if (item.OrderId == response.data.jsonOrder.OrderId) {
                          item.Delivered = true;
                          item.showOrder = false;
                          this.countQuantitysDelivered += item.Quantity;
                        }
                      })

                    } else if (response.status == "askImprovementServer") {
                      console.log("response askImprovementServer ", response);
                      this.spinner.hide();
                      this.askImprovement();
                    } else if (response.status == "implementExpansionWithRawMaterialServer") {
                      console.log("response implementExpansionWithRawMaterialServer ", response);
                      this.spinner.hide();
                      this.implementExpansionWithRawMaterial(response.data.machine);
                    } else if (response.status == "implementExpansionWithEfficiencyServer") {
                      console.log("response implementExpansionWithEfficiencyServer ", response);
                      this.spinner.hide();
                      this.implementExpansionWithEfficiency(response.data.machine);
                    } else if (response.status == 'createLoanCoin'){
                      console.log("createLoanCoin", response);
                      let newLoan = response.data.newCoin;
                      console.log(newLoan);
                      if(newLoan['tipo'] == 9){
                        console.log(this.cdkLstBank);
                        this.cdkLstBank.push({
                          valor: 20,
                          id: newLoan['id'],
                          draggable: false,
                          type: 9,
                        });
                        console.log(this.cdkLstBank);

                      }
                    }
                  } else {
                    /*console.log("NO RESPONDE SOCKETTT");
                    this.isConnected = false;
                    this.isOpenMarketDisabledBoard = true; 
                     this.fnTrowToastwithTimeMiliseconds('#errorConnection', 'Internet Offline, Board is disabled!!', 6000);*/
                  }
                }).unsubscribe;

              // observable para el socket de configuracion
              this.socketService._config
                .pipe(takeUntil(this.destroy$))
                .subscribe((response) => {
                  console.log(response);
                  if (response) {
                    if (response.status == "changeBoardModeServer") {
                      this.isModeOpen = response.data.isModeOpen == 0 ? false : true;
                      console.log("response", response);
                    } else if (response.status == "setUpTeamByIDServer") {
                      console.log("response", response);
                      this.TeamName = response.data['teamAvatar'];
                      console.log(this.TeamName,this.TeamNameArray)
                      this.TeamNameArray = this.TeamName.split(" ");
                      this.strBackground = response.data.strBackground;
                      this.blnBackgroundBlue = response.data._data.blnBackgroundBlue;
                      this.blnBackground2 = response.data._data.blnBackground2;
                      this.blnBackground4 = response.data._data.blnBackground4;
                      this.blnBackground5 = response.data._data.blnBackground5;
                      this.blnBackground6 = response.data._data.blnBackground6;
                      this.blnBackground7_8 = response.data._data.blnBackground7_8;
                      this.blnBackground9 = response.data._data.blnBackground9;
                    } else if (response.status === 'assignDefaultTeamNames') {
                      const { name, color, strBackground, blnBackground } = response.data;
                      if (name) {
                        if (name.includes(" ")) {
                          this.TeamNameArray = name.split(" ");
                        } else {
                          this.TeamNameArray = [];
                          this.TeamNameArray.push(name);
                        }
                      }
                      if (color) {
                        this.strBackground = strBackground;
                        this.blnBackgroundBlue = false;
                        this.blnBackground2 = false;
                        this.blnBackground4 = false;
                        this.blnBackground5 = false;
                        this.blnBackground6 = false;
                        this.blnBackground7_8 = false;
                        this.blnBackground9 = false;
                        switch (blnBackground) {
                          case 'blnBackgroundBlue':
                            this.blnBackgroundBlue = true;
                            break;
                          case 'blnBackground2':
                            this.blnBackground2 = true;
                            break;
                          case 'blnBackground4':
                            this.blnBackground4 = true;
                            break;
                          case 'blnBackground5':
                            this.blnBackground5 = true;
                            break;
                          case 'blnBackground6':
                            this.blnBackground6 = true;
                            break;
                          case 'blnBackground7_8':
                            this.blnBackground6 = true;
                            break;
                          case 'blnBackground9':
                            this.blnBackground6 = true;
                            break;
                          default:
                            break;
                        
                        }
                        this.fnLoadBoardBackground(color)

                      }
                    } else if (response.status === 'liabilitiesReceivablesUpdates') {
                      if (response.data) {
                        this.liabilitiesReceivablesBeforeUpdates = response.data;
                      }
                    } else if(response.status === 'reloadDemo') {
                      if(response.data.success && response.data.teamId == this.teamId){
                        location.reload();
                      }
                    } else if(response.status === 'reloadFinishDemo') {
                      if(response.data.success && response.data.teamId == this.teamId){
                        location.reload();
                      }
                    } else if(response.status === 'reloadFinishNormal') {
                      if(response.data.success && response.data.teamId == this.teamId){
                        location.reload();
                      }
                    } 
                  }
                });
              //Obtenes estatus de bill
              setTimeout(() => {
                this.boardService.getBillStatus(this.teamId).subscribe(response => {
                  if (response.intResponse == 200) {
                    this.IdStatusBid = response.StatusBill.idBillStatus ? response.StatusBill.idBillStatus : null;
                    if (this.currentMonth >= 2 && this.cdkLstAdvertasing.length > 0) {
                      this.auxValTotalAdvertising = response.StatusBill.value
                      this.AdvertisingExtra = this.cdkLstAdvertasing[0].valor - this.auxValTotalAdvertising
                      // this.cdkLstAdvertasing[0].draggingBy = 'Advertising: ' + this.auxValTotalAdvertising + ' Brand Awareness: ' + this.AdvertisingExtra
                      this.cdkLstAdvertasing[0].draggingBy = 'Advertising: ' + this.auxValTotalAdvertising
                      if (response.StatusBill.Status == 0) {
                        // this.checkBoxes[this.currentMonth>=3?11:9].blnCheck = false;
                        this.checkCheckbox(12, false);
                        this.showOwedAdvertising = true;
                      }
                      console.log(response.StatusBill.value, 'Respuesta Status', response.StatusBill.Status)
                    }
                  }
                });
                //Se obtiene el total de advertising gastado
                this.fnGetAllOrders(this.workshopId)
              }, 200);

            }
            //funcion ciclica para verificar conexion socket
            setTimeout(() => {
              this.fnCheckSocket();
            }, 5000);
                }
                else{
                  this.userWithoutsession = true;
                  //codigo de que no tiene acceso porque ya hay sesion abierta
                  Swal.fire({
                    html: "There is an open session with that user. Please indicate this to the facilitator.",
                    showCancelButton: false,
                    showConfirmButton:false,
                    confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
                    confirmButtonColor: '#5EA0CC',
                    allowOutsideClick: false,
                    allowEscapeKey:false,
                    icon: 'warning'
                  });

                }


          }
           else {
            return;
          }

        } else {
          //La url no trae el mail o general
          /*this.workshopId = params.workshopID
          this.existsWorkshop("");*/
          Swal.fire({
            html: `<div>The  url was not valid. Please contact your facilitator to request the correct URL</div>`,//27.- The  url was not valid. Please contact your facilitator to request the correct URL
            imageUrl: '../../../../assets/iconPrevention.svg',
            showCancelButton: false,
            allowEscapeKey:false,
            confirmButtonColor: '#5EA0CC',
            cancelButtonColor: '#CCCCCC',
            confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
          })

        }
      } else {//no se agrega workshop en url
        Swal.fire({
          html: `<div>28.- The board was not found due to an error in the url. Please contact your facilitator to request the correct URL</div>`,
          imageUrl: '../../../../assets/iconPrevention.svg',
          showCancelButton: false,
          allowEscapeKey:false,
          confirmButtonColor: '#5EA0CC',
          cancelButtonColor: '#CCCCCC',
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
        })
      }
    });


    //funciones para evitar f12 y boton derecho para inspeccionar
    /*if(environment.isProd)
    {console.log("antes lisener....");
        document.addEventListener('contextmenu', function(e) {
          e.preventDefault();
        });
       
      console.log("despues lisener....");
  
    }*/

  }



  getOrders() {
    return new Promise((resolve, reject) => {
      this.boardService.getOrdersByTeamId(this.teamId, (this.status == 0 || this.status == -1) ? this.currentMonth - 1 : this.currentMonth).subscribe(response => {

        console.log(response, "response orders");
        if (response.intResponse == 200 && response.orders) {
          this.orders = response.orders as Array<any>;
          this.orders.forEach(item => {
            if (item.Delivered == 1) {
              item.showOrder = false;
              this.countQuantitysDelivered += item.Quantity;
            }
          });
          for (let i = 0; i < this.countQuantitysDelivered; i++) {
            this.cdkLstCostGS[i].paid = true;
          }
          if (!this.blnFacilitador) {
            if (this.currentMonth >= 3 && this.improvementsOptionsSelected.length > 0) {
              if (this.improvementsOptionsSelected.length == 1) {
                this.improvementsOptionsSelected.push({
                  improvement: '',
                  value: 0,
                  ImproveId: -1,
                  option: 0,
                  question: ''
                })
              }
              if ((this.improvementsOptionsSelected[0].improvement == "Quality" && this.improvementsOptionsSelected[0].value == 3
                || this.improvementsOptionsSelected[1].improvement == "Quality" && this.improvementsOptionsSelected[1].value == 3)) {
                this.orders.forEach(item => {
                  item.Bid = Math.round(item.Bid * 1.20);
                });
              }
            }
          }
        }
        if (this.blnFacilitador && this.orders.length == 0 && this.currentMonth == 1) {
          this.orders.push(
            { Terms: 60, OrderNum: 101, Bid: 40, Quantity: 3, Delivered: false },
          )
        }
        //Cuando se recarga la página los valores se pierden, por lo que aquí en getOrders son asignados nuevamente
        if (this.blnFacilitador && this.orders.length == 1 && this.currentMonth == 1) {
          console.log(this.orders)
          if (this.orders[0]) {
            if(this.status <= 33){
              this.orders[0].Delivered = false;
            this.orders[0].showOrder = true; 
            this.orders[0].blnDeleted = 0;  
            }else{
              this.orders[0].Delivered = true;
            this.orders[0].showOrder = false; 
            this.orders[0].blnDeleted = 0;    
          }
            }
            
        }
        resolve(true);
      });
    });
  }

  fnCheckSocket() {

    setInterval(() => {
      //console.log("entre checar status");
      if (!environment.socketStatus) {
        if (this.isConnected == true) {
          console.log("socket ya no respondio..");
          this.isConnected = false;
          this.isOpenMarketDisabledBoard = true;
          this.fnTrowToastwithTimeMiliseconds('#errorConnection', this.getErrorLabel('1075'), 6000);
          Swal.mixin({
            html: this.getErrorLabel('1104'),
            showConfirmButton: true,
            confirmButtonText: 'Reload',
            showCancelButton: false,
            allowOutsideClick: false,
          }).fire().then(res => location.reload());
        }
      } else {
        if (this.isConnected == false && this.blnCanMoveitems == true) {
          this.isConnected = true;
          this.fnTrowToastwithTimeMiliseconds('#errorConnection', this.getErrorLabel('1076'), 3000);
        }
      }
    }, 750)


  }

  fnInitSocket() {
    this.socketImprovement.setupSocketConnection();
    // observador para el socket que controla las monedas
    this.socketImprovement._improvement
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          console.log('RESPONSE SOCKET SERVER BOARD', response);
          if (response.status == 'improveStatusServer' || response.status == 'improveStatus' && response.data != undefined) {
            //this.fnSetBid(response.data);
            console.log('improveStatusServer', response);
            if(this.blnFacilitador && response.data.improveStatus == 2){
              this.intOpenMarket = 0;
            }
            if(response.data.improveStatus == 2){
              this.lastStartMonth = this.currentMonth;
              console.log(this.lastStartMonth)
            }
            this.improveStatus = response.data['improveStatus'];
            this.statusBeforeMonth = response.data['improveStatus'] + 1;
            if (this.statusBeforeMonth == 1) {
              this.isOpenMarketDisabledBoard = true;
            }
            if (this.statusBeforeMonth >= 3) {
              setTimeout(() => {
                this.compruebaCheckboxes();
                
              }, 1500);
              //this.isOpenMarketDisabledBoard = false;
              //validacion para que puedan mover el board
              this.blnCanMoveitems = true;
              //validacion qasignar coo dcm
              this.AssingControl();
            }
          } else if (response.status == 'improveStatus' && response.data != undefined && this.blnFacilitador) {
            if (this.status <= 0) {
              this.status = 0;
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
              setTimeout(() => {
                this.checkBoxes[0].blnCheck = true;
                this.fnScrollCheckList(this.checkBoxes[0]);
                this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
              }, 1000);
              this.improvementSelected = true;
            }
          }//meter control de que debe hacer el equipo para cuando el fac acepta o rechaza la opcion aceptDeclineImprovementServer
          else if (response.status == 'aceptDeclineImprovementServer' && response.data != undefined && this.blnFacilitador) {
            console.log("entro a control aceptDeclineImprovementServer PRIMER SOCKET IMPROVEMENT *********");
            if (response.data.improveStatus == 1 && response.data.teamId == this.teamId) {
              console.log("improve==1 tons fac rechazo opcion de team de no implementar debemos volver a preguntar")
              //linea dani
            }
            else if (response.data.improveStatus == 2 && response.data.teamId == this.teamId) {
              location.reload();
            }
          }
          else if (response.status == 'updateAvatarServer' && response.data != undefined && !this.blnFacilitador) {
            console.log(response);
            response.data.arrTeams.forEach(team => {
              if (this.teamId == team['TeamId']) {
                console.log("Mi team", team);
                this.TeamName = team['Avatar'];
                console.log(this.TeamName, "aquiiii");
                this.TeamNameArray = this.TeamName != null ? this.TeamName.split(" ") : [];
              }
            });
          } else if (response.status == 'noProjectsSocketServer' && response.data != undefined && !this.blnFacilitador) {
            console.log(response);
            if(response.data.status == "0" && !this.isNoProjectsSocketServer){
              this.isPendingProject = this.currentMonth;
              this.isNoProjectsSocketServer = true
              this.status = 0
              this.checkBoxes = [];
              this.projects = 0;
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
              // this.boardService.getInitBoard(this.userData.TeamId).subscribe(async responseBoard => {
              //   console.log("responseBoard", responseBoard);
              //   const checklist = responseBoard.checkList.response as Array<any>
              //   checklist.forEach((item, i) => {
              //     this.checkBoxes.push({
              //       id: item.idCheck,
              //       blnCheck: item.valor,
              //       title: item.title,
              //       idBd: item.CheckListId,
              //       disabled: true,
              //       show:item.show
              //     });
              //   })
              //   this.checkBoxes.forEach((item, i) => {
              //     if (item.title == 'Pay Advertising' && (this.status >= 14 || this.blnFacilitador) || item.title == 'Prepare Bids' && this.status == 10 && this.intOpenMarket != 2) {
              //       console.log("Entra aqui")
              //       item.disabled = false;
              //     }
              //   })

              // })
              // this.checkBoxes = [
              //   { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true },
              //   { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true },
              //   { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true },
              //   { id: 4, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true },
              //   { id: 5, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true },
              //   { id: 6, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true },
              //   { id: 7, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true },
              //   { id: 8, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true },
              //   { id: 9, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true },
              //   { id: 10, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true },
              //   { id: 11, blnCheck: false, title: "The Market", idBd: null, disabled: true },
              //   { id: 12, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true },
              //   { id: 13, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true },
              //   { id: 14, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true },
              //   { id: 15, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true },
              //   { id: 16, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true }
              // ];
              // this.checkBoxes.forEach(item => {
              //   item.blnCheck = false;
              //   item.disabled = true;
              // });
              // this.boardService.createUpdCheckList(this.teamId, this.checkBoxes).subscribe(response => {
              //   console.log('response update check list mes 2', response);
              //   this.checkBoxes = response.checkList as Array<any>;
              //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
              // });
              this.improvementService.getImprovementByTeamId(this.teamId).subscribe(response=>{
                console.log(response)
                let improveOptionId
                response['improvement']['options'].forEach(option => {
                  if(option['Month'] === this.currentMonth){
                    improveOptionId = option['ImproveOptionId'];
                  }
                });
                
                this.socketImprovementService.updateImproveOption(this.teamId,("roomWorkShop"+this.workshopId),6, improveOptionId, 1);
                setTimeout(() => {
                  if(!this.blnGrowNocontrol && (this.status == 0 || this.status == -1)) { //Quitar bloqueo al iniciar el mes al que tiene el control
                    this.isOpenMarketDisabledBoard = false;
                  }
                }, 1500);


                console.log(this.blnGrowNocontrol, this.isOpenMarketDisabledBoard, this.status)
                setTimeout(() => {
                  location.reload();
                 
                }, 1700);

                
              })

            }else if (response.data.status == "1" && !this.isNoProjectsSocketServer){
              this.isNoProjectsSocketServer = true;
              this.projects = 1;
              console.log("Entra aqui!!");
              this.status = -1
              this.checkBoxes = [];
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
              // this.boardService.getInitBoard(this.userData.TeamId).subscribe(async responseBoard => {
              //   console.log("responseBoard", responseBoard);
              //   this.improveStatus = 2;
              //   this.blnCanMoveitems = true;;
              //   const checklist = responseBoard.checkList.response as Array<any>
              //   checklist.forEach((item, i) => {
              //     this.checkBoxes.push({
              //       id: item.idCheck,
              //       blnCheck: item.valor,
              //       title: item.title,
              //       idBd: item.CheckListId,
              //       disabled: true,
              //       show:item.show
              //     });
              //   })
              //   this.checkBoxes.forEach((item, i) => {
              //     if (item.title == 'Pay Advertising' && (this.status >= 14 || this.blnFacilitador) || item.title == 'Prepare Bids' && this.status == 10 && this.intOpenMarket != 2) {
              //       console.log("Entra aqui")
              //       item.disabled = false;
              //     }
              //   })

              // })
              setTimeout(() => {
                location.reload();
               
              }, 1700);
            }else if (response.data.status == "2"){
              console.log("Aqui!!")
              this.projects = 1;
            }
            setTimeout(() => {
              this.isNoProjectsSocketServer = false;

            }, 500);
          }
          
        }
      })
  }

  fnGetAllOrders(workShopId) {
    return new Promise(resolve => {
      this.orderService.getOrdersByWorkShopId(workShopId).subscribe(resp => {
        console.log("fnGetAllOrders", resp);
        this.arrOrders = resp['orders'].filter(el => {
          if (el.Month === this.currentMonth)//view board
          {
            return el;
          }
          else { resolve('no'); }
        });
        resolve('si');

        // for (var i = 0; i < this.arrOrders.length; i++) {
        //   for (var j = 0; j < this.arrOrders[i].Bids.length; j++)
        //     if (this.arrOrders[i].Bids[j].Bid != 0 && this.arrOrders[i].Bids[j].TeamId == this.teamId) {
        //       this.owesAdvertising = this.owesAdvertising + 1
        //     }
        // }
        // this.owesAdvertising = this.owesAdvertising - this.auxValTotalAdvertising

      })
    })
  }

  /*@HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log("Closing WS...");

    this.socketService.terminarConexion();

  }*/
  
  async fnSelectOption(option) {
    if (option == 1) {
      this.fnModalTeam(this.teamId);
    } else if (option == 2) {
      this.fnModalTeams(this.workshopId)
    } else if (option == 3) {
      //ir a rules
    } else if (option == 4) {
      this.fnModalRecordShet();
    } else if (option == 5) {
      this.fnModalBoardMode();
    } else if (option == 6) {
      this.isTrashContainer = !this.isTrashContainer;
    }
    else if (option == 7) {
      this.fnModalRecordStatements();
    }
    else if (option == 8) {
      if (this.statusBeforeMonth == 2) {
        return;
      }
      this.fnChangeBoardTeam();
    } else if (option == 9) {
      if (this.jsnUserSelected[0].type == 4 || this.jsnUserSelected[0].type == 5) {
        Swal.fire({
          html: this.getErrorLabel('1062'),
          imageUrl: '../../../../assets/iconPrevention.svg',
          showCancelButton: false,
          allowEscapeKey:false,
          confirmButtonColor: '#6FB45C',
          cancelButtonColor: '#ED5050',
          confirmButtonText: 'OK!',
          reverseButtons: true,
        }).then((result) => {
          //nada por hacer cuando el observer intenta regresar a su board
        });
      } else {
        this.fnReloadMyBoard();
      }

    } else if (option == 10) {
      //this.fnStatusMonthImprove(1);
      this.fnModalSetUpImprovements();
    } else if (option == 11) {
      //this.fnStatusMonthImprove(2);
      //this.fnAlertSuccess("Start Month");
      this.fnModalMonthStatus();
    }
    else if (option == 12) {
      this.fnChangeLanguage();
    } else if (option == 13 || option == 14) {
      this.fnModalExcercises(option);
    } else if (option == 20) {
      this.fnModalStacksBoardTeams();
    } else if (option == 21) {
      this.fnCreateBackupBD();
    } else if (option == 22) {
      this.fnEditUsers();
    } else if (option == 15) {
      this._matDialog.open(BudgetreportComponent, {
        // width: '700px',
        // height: '',
        disableClose: false,
      hasBackdrop: false,
        data: {
          workshopId: this.workshopId,
          month: this.currentMonth,
          cycles: this.Cycles,
          forecastExc: this.forecastExc,
          arrTextLabels: this.arrTextLabels

        }
      });
    } else if (option == 23) {
      if(this.improveStatus == -1){
        this.fnTrowToastwithTimeMiliseconds("#errorGeneral", "Facilitator must Start Month to use this action", 3000);
        return
      }
      this.spinner.show();
      const x = await this.goToMarket();
    } else if (option == 24) {
      this.spinner.show();
      const x = await this.goToNextMonth();
    } else if (option == 25) {
      this.fnModalTeamsChecklist();
    } else if (option == 26) {
      this.fnModalLogs();
    }else if (option == 27) {
      this.fnModalEditTeamISBS(this.workshopId);
    }else if (option == 30) {
      this.fnModalEditTeams(this.workshopId);
    }else if (option == 31) {
      this.fnOpenWorkshop(this.workshopId);
    }else if (option == 32) {
      this.fnCloseWorkshop(this.workshopId);
    }else if (option == 33) {
      Swal.fire({
          title: 'Are you sure?',
          text: "Please ensure no teams are playing at the moment, as this action could cause data loss.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed!',
          cancelButtonText: 'No, cancel'
      }).then((result) => {
          if (result.isConfirmed) {
              this.socketService.blockAllTeams(this.workshopId, 2);
              Swal.fire(
                  'Done!',
                  'All teams have been reloaded successfully.',
                  'success'
              );
          } else {
              Swal.fire(
                  'Cancelled',
                  'The action has been cancelled.',
                  'error'
              );
          }
      });
    }

  }

  fnEditUsers() {
    //volvemos a preguntar por la info de los usuarios para refrescarl los checks de sesiones
    var d = this.initialDate,
            dformat = [d.getFullYear(), d.getMonth() + 1,
            d.getDate(),
            ].join('-') + ' ' +
              [d.getHours(),//ponemos +1 ay que el requerimiento pide dar acceso incluso una hora antes al board
              d.getMinutes(),
              d.getSeconds()].join(':');
    //this.workshopService.getExistsWorkshopBoard(this.workshopId, dformat).subscribe(
    this.workshopService.getExistsWorkshopBoard(this.workshopId).subscribe(
      (response) => {
        console.log('response data workshop exists ANTES CARGAR MODAL EDIT:', JSON.stringify( response['data']));
        //guardamos variable global de todos los usuarios del board, observers members y facilitadores para usarla en el cambio de board

        this._matDialog.open(DialogEditUsersComponent, {
          width: '1600px',
          height: '700px',
          maxWidth: '1400px',
          data: {
            workshopId: this.workshopId,
            lstUsers: response["data"],
            arrTextLabels: this.arrTextLabels,

          }
        });
      })
    
  }

  fnCreateBackupBD() {
    this.spinner.show();
    this.workshopService.createBackupBD(this.workshopId, this.currentMonth).subscribe((response) => {
      this.spinner.hide();
      console.log(response);
    });
  }

  fnModalStacksBoardTeams() {
    this._matDialog.open(PiecesTeamsBoardComponent, {
      width: '1200px',
      height: '700px',
      data: {
        workshopId: this.workshopId
      }
    });
  }

  fnModalExcercises(option) {
    if (option == 13) {
      // budget exercise
      this._matDialog.open(DialogBudgetExerciseComponent, {
        width: '850px',
        height: '556px',
        data: {
          // month: option == 13 ? 4 : option == 14 ? 5 : 6,
          month: this.currentMonth,
          workshopId: this.workshopId,
          teamId: this.teamId,
          status: this.status,
          arrTextLabels: this.arrTextLabels,
          forecastExc :this.forecastExc,
          cycles : this.Cycles,
          businessModel : this.businessModel

        }
      });
    } else {
      // cash flow exercise
      this._matDialog.open(DialogCashFlowExerciseComponent, {
        width: '622px',
        height: '635px',
        data: {
          // month: option == 16 ? 4 : option == 17 ? 5 : 6,
          month: this.currentMonth,
          teamId: this.teamId,
          blnFacilitador: this.blnFacilitador,
          workshopId: this.workshopId,
          arrTextLabels: this.arrTextLabels,
          forecastExc :this.forecastExc,
          cycles : this.Cycles,
          businessModel : this.businessModel
        }
      })
    }
  }

  fnModalMonthStatus() {
    this.improvementService.getPendingProjectByWorkShopId(this.workshopId).subscribe(resp =>{
      console.log(resp)
      if(resp.intResponse == 200){
        this.isPendingProject = resp['data']
        console.log(this.isPendingProject);
        this.dialogRef = this._matDialog.open(DialogMonthStatusComponent, {
          width: '800px',
          height: '500px',
          panelClass: 'event-form-dialog',
          disableClose:true,
          data: {
            workshopId: this.workshopId,
            cycles: this.Cycles,
            bool: true,
            month: this.currentMonth,
            arrTextLabels: this.arrTextLabels,
            previousMonth: this.lastStartMonth,
            isPendingProject: this.isPendingProject,
            showBtnImpOpt: this.isProjectsEnableByCurrentMonth,
            WStype:"MFG",
            userName : this.userData['FirstName'],
            teamId : this.teamId
          },
        });
    
        this.dialogRef.afterClosed().subscribe((resp) => {
          console.log(resp)
          console.log("RETURNED DIALOG")
          if(resp){
            if(resp['status'] == 2) {
              this.monthAuxMonthByMonth = this.currentMonth;
      
            }
          }else{
    
          }
          
    
        });
      }
    })

  }

  fnModalSetUpImprovements() {
    this.dialogRef = this._matDialog.open(DialogSetupImprovementsComponent, {
      disableClose: true,
      hasBackdrop: false,
      width: '1400px',
      height: '545px',
      panelClass: ['event-form-dialog','record-balance'],
      data: {
        workshopId: this.workshopId,
        month: this.currentMonth,
        arrTextLabels: this.arrTextLabels,
        Cycles : this.Cycles,
        businessModel: this.businessModel
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG")
    });
  }

  fnModalRecordShet() {
    localStorage.setItem('IsFacilitator', JSON.stringify(this.blnFacilitador));

    // window.open("https://app.income-outcome.com/play/board/"+this.workshopId+"/view/simplebalancesheet")

    this.dialogRef = this._matDialog.open(RecordBalanceSheetComponent, {
      disableClose: true,
      width: '380px',
      height: '300px',
      panelClass: ['event-form-dialog','record-balance'],      
      hasBackdrop: false,
      data: {
        workshopId: this.workshopId,
        arrTextLabels: this.arrTextLabels,
        businessModel: this.businessModel
      }
    });
  }

  fnModalBoardMode() {
    this.dialogRef = this._matDialog.open(DialogBoardModeComponent, {
      disableClose: true,
      width: '1400px',
      height: '400px',
      panelClass: 'event-form-dialog',
      data: {
        workshopId: this.workshopId,
        arrTextLabels: this.arrTextLabels,
      }
    });
  }

  fnModalRules() {
    if (this.statusBeforeMonth == 1) {
      return;
    }
    this.dialogRef = this._matDialog.open(RulesGameComponent, {
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        languageId: this.idLenguage,
        arrTextLabels: this.arrTextLabels,
        boardType: this.businessModel,
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log('Cerrar modal' + this.owesAdvertising)
      console.log("RETURNED DIALOG")

      // if (this.owesAdvertising > 0) {
      //   this.fnTrowToast("", "You owe " + this.owesAdvertising)
      // } else {
      //   this.fnTrowToast("", "You paid " + this.auxValTotalAdvertising + " in advertising")
      // }
      // this.fnTrowToast("", "Refound")

    });
  }

  fnModalIncomeStatement() {
    console.log(this.improvementsOptionsSelected)
    if (!this.blnAccessCUSTOMERISBS) {
      return;
    }
    if (this.statusBeforeMonth == 2 && this.currentMonth >= 3) {
      return;
    }
    // Pay Expenses DONE
    if(!this.getCheckCheckbox(10).blnCheck && this.status>=14  && this.currentMonth >= 2){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1059'));
      return
    }
    /*DCM quitamos validacion de que primero pague advertising if (this.showOwedAdvertising && this.currentMonth >= 2) {
      this.fnTrowToastwithTimeMiliseconds("#errorGeneral", "You must pay for advertising first", 3000);
      return;
    }*/
    // Make Delivery & Recieve Payment
    let totSales = 0;
    this.orders.forEach(item => {
      totSales += item.Bid;
    })
    let blnCanEdit = true;
    if (this.currentMonth == 1) {
      blnCanEdit = this.status == 34;
    } else if (this.currentMonth >= 2) {
      // Take Product from Machine Make Delivery & Recieve Payment Complete the Income Statement DONE
      blnCanEdit = this.currentMonth >= 2 && this.status < 13 || this.currentMonth >= 2 && !this.getCheckCheckbox(15).blnCheck || (this.getCheckCheckbox(15).blnCheck && !this.getCheckCheckbox(14).blnCheck) || this.getCheckCheckbox(16).blnCheck ? false : true;
    } 
    // otorgar edicion a quien tenga el control
    blnCanEdit = blnCanEdit && !this.isOpenMarketDisabledBoard;
    console.log(blnCanEdit, this.isModeOpen)
    //Mensaje de error en caso de que no se haya hecho delivery.
    /*if((this.currentMonth >= 2 && this.status === 14)  && !(this.currentMonth >= 2 && !this.getCheckCheckbox(12).blnCheck ? false : true)){
      this.fnTrowToast('#errorGeneral', this.getSingleTextLabel('1043'));
      return;
    }*/
    this.compruebaBalance()
    let width = "";
    let height = "";
    this.balanceIncomeService.getSize(1, this.userId).subscribe(res => {
      if (res.intResponse == "200") {
        console.log(res);
        this.userData['sizeIS'] = res.data.sizeIS;
        // this.userData['sizeIS'] = "632.992px,526.991px"
        if (this.userData['sizeIS'] == null) {
          width = '915px';
          height = '600px';
        } else {
          let sizes = this.userData['sizeIS'].split(',')
          console.log(sizes)
          width = sizes[0];
          height = sizes[1];
        }
        console.log("ES FACILITADORR",this.blnFacilitador);
        // Complete the Income Statement DONE
        this.dialogRef = this._matDialog.open(IncomeStatementComponent, {
          disableClose: false,
          width: width,
          height: height,
          panelClass: ['resize-checklist'],//'event-form-dialog',
          data: {
            languageId: this.idLenguage,
            teamId: this.teamId,
            currentMonth: this.currentMonth,
            cycles: this.Cycles,
            workshopId: this.workshopId,
            totSales: totSales,
            canEdit: blnCanEdit,
            control: this.isOpenMarketDisabledBoard,
            arrTextLabels: this.arrTextLabels,
            isfacilitador: this.blnFacilitador,
            isInCurrentStatus: this.blnFacilitador ? true : !this.getCheckCheckbox(16).blnCheck,
            userId: this.userId
          },
        });
        this.dialogRef.afterClosed().subscribe((resp: { netIncome: number, createNetIncome: boolean }) => {
          if (!resp || !resp.createNetIncome) {
            return;
          }
          console.log("RETURNED DIALOG");
          console.log(resp)
          if (this.status == 34 && this.boardMode == "NM") {
            this.status = 35;
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            this.socketService.createCoin('cdkLstNetIncome', {
              valor: 13,
              id: null,
              draggable: false,
              type: 2,
            }, this.roomId, this.teamId);
          } else if (this.status == 34 && this.boardMode == "DM") {
            this.status = 35;
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            const netIncome = resp.netIncome
            const isNegative = netIncome < 0;
            if (netIncome > 20 || netIncome < -20) {
              let package20 = 0;
              let packageLess20 = 0;
              package20 = Math.floor(netIncome / (isNegative ? -20 : 20));
              packageLess20 = netIncome % 20;
              for (let i = 0; i < package20; i++) {
                this.cdkLstNetIncome.push({
                  valor: isNegative ? -20 : 20,
                  id: null,
                  draggable: false,
                  type: 2,
                });
              }
              if (packageLess20 != 0) {
                this.cdkLstNetIncome.push({
                  valor: packageLess20,
                  id: null,
                  draggable: false,
                  type: 2,
                });
              }
              this.socketService.splitCoin(this.cdkLstNetIncome, 'cdkLstNetIncome', this.roomId, this.teamId);
            } else if (netIncome != 0) {
              this.socketService.createCoin('cdkLstNetIncome', {
                valor: resp.netIncome,
                id: null,
                draggable: false,
                type: 2,
              }, this.roomId, this.teamId);
            } else {
              this.status = 37;
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            }
          } else if (!this.isModeOpen && this.getCheckCheckbox(15).blnCheck && !this.getCheckCheckbox(16).blnCheck && resp.netIncome != 0 && this.status >= 14 && this.currentMonth >= 2) {
            // Make Delivery & Payment Complete the Income Statement DONE
            this.status = 15;
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            const netIncome = resp.netIncome
            const isNegative = netIncome < 0;
            if (netIncome > 20 || netIncome < -20) {
              let package20 = 0;
              let packageLess20 = 0;
              package20 = Math.floor(netIncome / (isNegative ? -20 : 20));
              packageLess20 = netIncome % 20;
              for (let i = 0; i < package20; i++) {
                this.cdkLstNetIncome.push({
                  valor: isNegative ? -20 : 20,
                  id: null,
                  draggable: false,
                  type: 2,
                });
              }
              if (packageLess20 != 0) {
                this.cdkLstNetIncome.push({
                  valor: packageLess20,
                  id: null,
                  draggable: false,
                  type: 2,
                });
              }
              this.socketService.splitCoin(this.cdkLstNetIncome, 'cdkLstNetIncome', this.roomId, this.teamId);
            } else {
              this.socketService.createCoin('cdkLstNetIncome', {
                valor: resp.netIncome,
                id: null,
                draggable: false,
                type: 2,
              }, this.roomId, this.teamId);
            }
            //DONE
          } else if (this.isModeOpen && this.getCheckCheckbox(15).blnCheck && !this.getCheckCheckbox(16).blnCheck && resp.netIncome != 0 && this.cdkLstNetIncome.length == 0 && this.status >= 3 && this.currentMonth >= 2) {
            console.log(resp);
            const netIncome = resp.netIncome;
            const isNegative = netIncome < 0;
            if (netIncome > 20 || netIncome < -20) {
              let package20 = 0;
              let packageLess20 = 0;
              package20 = Math.abs(Math.floor(netIncome / (isNegative ? -20 : 20)));
              packageLess20 = netIncome % 20;
              for (let i = 0; i < package20; i++) {
                this.cdkLstNetIncome.push({
                  valor: isNegative ? -20 : 20,
                  id: null,
                  draggable: false,
                  type: 2,
                });
              }
              if (packageLess20 != 0) {
                this.cdkLstNetIncome.push({
                  valor: packageLess20,
                  id: null,
                  draggable: false,
                  type: 2,
                });
              }
              this.socketService.splitCoin(this.cdkLstNetIncome, 'cdkLstNetIncome', this.roomId, this.teamId);
            } else {
              this.socketService.createCoin('cdkLstNetIncome', {
                valor: resp.netIncome,
                id: null,
                draggable: false,
                type: 2,
              }, this.roomId, this.teamId);
            }
          }
          // checkear el paso de complete incomestatment
          if (this.currentMonth >= 2) {
            // this.checkBoxes[this.currentMonth>=3?15:13].blnCheck = true;
            // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
            // this.checkCheckbox(13, true);
            this.checkCheckbox(16, true);
            if (resp.netIncome == 0) {
              setTimeout(() => {
                // this.checkCheckbox(14, true);
                this.checkCheckbox(17, true);
              }, 1500);
            }
          }  
        });
      }
    })
    

 
  }

  async fnModalEditTeamISBS(_workshopId) {
    //obetenemos teams
    this.lstTeamsGlobal = await this.fnGetAllTeams();
   
        this.dialogRef = this._matDialog.open(ManualeditISBSComponent, {
          width: '1400px',
          height: '700px',
          panelClass: 'event-form-dialog',
          data: {
            workshopId : _workshopId,
            arrTeams: this.lstTeamsGlobal,
            languageId : this.idLenguage,
            arrTextLabels: this.arrTextLabels,
          },
        }
        );
        this.dialogRef.afterClosed().subscribe((resp) => {
          console.log("RETURNED DIALOG", resp)
          if (resp) {
            if (resp.teamName.includes(" ")) {
              this.TeamNameArray = resp.teamName.split(" ");
            } else {
              this.TeamNameArray = [];
              this.TeamNameArray.push(resp.teamName);
            }
            this.TeamName = resp.teamName;
            //this.TeamNameArray = this.TeamName.split(" ");
            this.strBackground = resp.strBackground;
            this.blnBackgroundBlue = resp.blnBackgroundBlue;
            this.blnBackground2 = resp.blnBackground2;
            this.blnBackground4 = resp.blnBackground4;
            this.blnBackground5 = resp.blnBackground5;
            this.blnBackground6 = resp.blnBackground6;
            this.blnBackground7_8 = resp.blnBackground7_8;
            this.blnBackground9 = resp.blnBackground9;
          }
        });
    
  }



  
  async fnModalTeam(teamId) {
    //obetenemos teams
    this.lstTeamsGlobal = await this.fnGetAllTeams();
    // var today = new Date();
    // var d = today,
    //         dformat = [d.getFullYear(), d.getMonth() + 1,
    //         d.getDate(),
    //         ].join('-') + ' ' +
    //           [d.getHours(),//ponemos +1 ay que el requerimiento pide dar acceso incluso una hora antes al board
    //           d.getMinutes(),
    //           d.getSeconds()].join(':');
    // console.log(today)
    // console.log(dformat)
    this.workshopService.getExistsTeamMeambersBoard(this.workshopId).subscribe(
      (response) => {
        this.lstGloblalUsersBoard = response["data"];
        console.log(response["data"])
        let canDeleteOrAdd = false;
        if (this.currentMonth == 1 && this.improveStatus == -1) {
          canDeleteOrAdd = true;
        } 
        this.dialogRef = this._matDialog.open(SetupTeamComponent, {
          width: '1400px',
          height: '700px',
          panelClass: 'event-form-dialog',
          data: {
            workShopId: this.workshopId,
            Cycles : this.Cycles,
            room: this.room,
            canDeleteOrAdd: canDeleteOrAdd,
            teamId: teamId, //93
            backgroundActual: this.strBackground,
            nameActual: this.TeamName,
            canEdit: this.blnCanMoveitems,
            blnFacilitador: this.blnFacilitador,
            blnBackgroundBlue: this.blnBackgroundBlue,
            blnBackground2: this.blnBackground2,
            blnBackground4: this.blnBackground4,
            blnBackground5: this.blnBackground5,
            blnBackground6: this.blnBackground6,
            blnBackground7_8: this.blnBackground7_8,
            blnBackground9: this.blnBackground9,
            arrTextLabels: this.arrTextLabels,
            arrTeams: this.lstTeamsGlobal,
            lstUsers: this.lstGloblalUsersBoard,
            boardMode:"NM"
          },
        });
        this.dialogRef.afterClosed().subscribe((resp) => {
          console.log("RETURNED DIALOG edit IS BS", resp)
          if (resp) {
            console.log(this.room,this.roomId,this.roomWorkShopId,this.TeamNameArray);
            this.socketService.changeTeamName(this.roomWorkShopId,resp['teamName'], resp['teamId'])
            this.TeamName = resp['teamName'];
            this.TeamNameArray = this.TeamName != null ? this.TeamName.split(" ") : [];

            /*if (resp.teamName.includes(" ")) {
              this.TeamNameArray = resp.teamName.split(" ");
            } else {
              this.TeamNameArray = [];
              this.TeamNameArray.push(resp.teamName);
            }
            this.TeamName = resp.teamName;
            //this.TeamNameArray = this.TeamName.split(" ");
            this.strBackground = resp.strBackground;
            this.blnBackgroundBlue = resp.blnBackgroundBlue;
            this.blnBackground2 = resp.blnBackground2;
            this.blnBackground4 = resp.blnBackground4;
            this.blnBackground5 = resp.blnBackground5;
            this.blnBackground6 = resp.blnBackground6;
            this.blnBackground7_8 = resp.blnBackground7_8;
            this.blnBackground9 = resp.blnBackground9;*/
          }
        });
      })
    
   
  }
  fnModalRecordStatements() {
    // localStorage.setItem('IsFacilitator', JSON.stringify(this.blnFacilitador));

    // window.open("https://app.income-outcome.com/play/board/"+this.workshopId+"/"+this.Cycles+"/view/RecordIncomeAndBalance")
    this.dialogRef = this._matDialog.open(RecordIncomeAndBalanceComponent, {
      disableClose: true,
      width: '410px',
      height: '450px',
      panelClass: ['event-form-dialog','record-income-balance'],
      hasBackdrop: false,
      data: {
        workshopId: this.workshopId,
        cycles: this.Cycles,
        arrTextLabels: this.arrTextLabels,
      }
    });
  }
  fnModalEditTeams(workShopId) {
    var today = new Date();
    var d = today,
            dformat = [d.getFullYear(), d.getMonth() + 1,
            d.getDate(),
            ].join('-') + ' ' +
              [d.getHours(),//ponemos +1 ay que el requerimiento pide dar acceso incluso una hora antes al board
              d.getMinutes(),
              d.getSeconds()].join(':');
    //this.workshopService.getExistsWorkshopBoard(this.workshopId, dformat).subscribe(
    this.workshopService.getExistsWorkshopBoard(this.workshopId).subscribe(
      (response) => {
        this.lstGloblalUsersBoard = response["data"];
        let canDeleteOrAdd = false;
        if (this.currentMonth == 1 && this.improveStatus == -1) {
          canDeleteOrAdd = true;
        }

        this.dialogRef = this._matDialog.open(DialogEditTeamsComponent, {
          disableClose: false,
          width: '1500px',
          height: '780px',
          panelClass: 'event-form-dialog',
          data: {
            workShopId: workShopId, //48
            arrTextLabels: this.arrTextLabels,
            lstUsers: this.lstGloblalUsersBoard,
            Cycles : this.Cycles,
            room: this.room,
            canDeleteOrAdd: canDeleteOrAdd,
            boardMode:this.boardMode
          },
        });
      });
  }
  fnModalTeams(workShopId) {
    this.dialogRef = this._matDialog.open(DialogAllTeamsComponent, {
      disableClose: false,
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        workShopId: workShopId, //48
        arrTextLabels: this.arrTextLabels,
        Cycles : this.Cycles,
        businessModel : this.businessModel
      },
    });
  }
  checkUser(){
    return new Promise(resolve => {
      this.facilitatorOfBoard = this.lstGloblalUsersBoard[this.lstGloblalUsersBoard.length-1]
            if(this.userData.Email == this.facilitatorOfBoard.Email && this.userData.type == 3){
              console.log("IGUALES!!!!");
              this.dialogRef = this._matDialog.open(ConfirmUserComponent, {
                disableClose: true,
                width: '700px',
                height: '400px',
                panelClass: 'event-form-dialog',
                data: {
                  facilitatorEmail: this.facilitatorOfBoard.Email,
                },
              });
              this.dialogRef.afterClosed().subscribe((resp) => {
                console.log("RETURNED DIALOG change board", resp);
                if(resp.userType == 'User'){
                  resolve(true);
                }else{
                  resp.userType == 'Facilitator'
                  this.userData = this.facilitatorOfBoard;
                }
                resolve(true);
              })
            }
    })
  }
  
  checkCode() {
    return new Promise(resolve => {
      Swal.fire({
        title: 'Enter Team Code:',
        input: 'text',
        inputPlaceholder: 'Enter Team Code',
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed && result.value !== '') {
          const value = result.value; // Asignar el valor ingresado al 'input' a la variable 'value'
          console.log(value);
          console.log(this.userData);
  
          // Buscamos el código según el teamID
          this.workshopService.searchWSCode(this.workshopId, value).subscribe((response) => {
            console.log(response);
  
            if (response['intResponse'] == 200) {
              // Código correcto
              //Guardamos la marca de tiempo en localStorage
              const clave = `ultimoAcceso_${this.userData.Email}_${this.workshopId}`;
              localStorage.setItem(clave, Date.now().toString());
              resolve(true);
            } else {
              // Si no, buscamos la contraseña
              this.authService.login(this.userData.Email, value).subscribe((loginResponse) => {
                console.log('Api login INICIADA OK:', loginResponse);
                if (loginResponse['intResponse'] == '200') {
                  // Contraseña correcta
                  //Guardamos la marca de tiempo en localStorage
                  const clave = `ultimoAcceso_${this.userData.Email}_${this.workshopId}`;
                  localStorage.setItem(clave, Date.now().toString());
                  resolve(true);
                } else {
                  // Si no, mostramos mensaje de error por 1 seg
                  Swal.fire({
                    text: '',
                    icon: 'error',
                    html: "Wrong code or password",
                  });
                  setTimeout(() => {
                    resolve(false);
                  }, 1000);
                }
              });
            }
          });
        } else {
          resolve(false);
        }
      });
    });
  }
  
  
  existsWorkshop(userMail) {
    return new Promise(resolve => {
      console.log("userMail::", userMail);


      let blnFacilitadorAux = false;

          this.workshopService.getFacilitatorsById(this.workshopId).subscribe((response) => {
            if(response){
              console.log('Facilitadorrr: ',response);
              if(response['data'][0]['CompanyName'] == 'Andromeda'){
                this.isAndromeda = true;
                console.log('Es facilitador de Andromeda');
              }else{
                console.log('No es facilitador de Andromeda');
              }
              response['data'].forEach(element => {
                if(element.Email == userMail || this.facilitatorEmails.has(userMail)){
                  blnFacilitadorAux = true;
                  console.log(element.Email, 'es fac');
                }
              });


              this.workshopService.getExistsWorkshopBoard(this.workshopId, blnFacilitadorAux).subscribe(
                (response) => {
                  console.log('response data workshop exists response[data]?:', response['data']);
                  //guardamos variable global de todos los usuarios del board, observers members y facilitadores para usarla en el cambio de board
                  this.lstGloblalUsersBoard = response["data"];
                  var respArr = response["data"];
                  if (respArr.length > 0) {
                    var tmpjson = respArr[0];
                    this.Cycles = tmpjson.Cycles;
                    this.businessModel = tmpjson.BusinessModel;
                    console.log(this.businessModel !== 'Mfg EBIT', 'Es o no es', this.businessModel);
                    
                    if (tmpjson.invalid == 1) {
                      Swal.fire({
                        text: '',
                        icon: 'error',
                        html: this.getErrorLabel('1064'),
                      });
                      resolve(false);
                    } else if (tmpjson.invalid == 2) {
                      Swal.fire({
                        text: '',
                        icon: 'error',
                        // html: <label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">32.- Board inactive. Please contact your Administrator.</label>,
                        html: this.getErrorLabel('1065'),
                      });
                      resolve(false);
                    }
                    else if (tmpjson.invalid == 3) {
                      this.showOption = true;
                      let roomId = "room" + 0;
                      let roomWorkShopId = "roomWorkShop" + this.workshopId;
                      this.socketService.setupSocketConnection(0, 0, roomWorkShopId)
                      this.socketService._openWs
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((response) => {
                          console.log(response);
                          if (response) {
                            if (response.status === 'Change openTimeZone') {
                              console.log('CAMBIOOO DE OPENWS');
                              if (!this.blnFacilitador) {
                                location.reload();
                              }
                            }
                          }
                        });
                      Swal.fire({
                        text: '',
                        icon: 'error',
                        // html: <label  style="font-size: 20px;font-family: Montserrat;font-weight: 500;color: #244C92 !important;">33.- The board is out of the delivery date.</label>,
                        html: this.getErrorLabel('1066'),
                      });
                      resolve(false);
                    }
                    else {
                      if (this.businessModel !== 'Mfg EBIT') {
                        Swal.fire({
                          text: 'Check your access link, this board should be a manufacturing game mode',
                          icon: 'error',
                          //html: this.getErrorLabel('1064'),
                        });
                        resolve(false);
                        return;
                     }
    
                      if (userMail.toString().toLowerCase() == "general") {
                        this.dialogRef = this._matDialog.open(ViewAuthUserBoardComponent, {
                          disableClose: true,
                          width: '700px',
                          height: '400px',
                          panelClass: 'event-form-dialog',
                          data: {
                            usersList: response['data']
                          },
                        });
    
                        this.dialogRef.afterClosed().subscribe((resp) => {
                          console.log("RETURNED DIALOG", resp);
                          this.jsnUserSelected = resp.jsnUserSelected;
                          resolve(true);
                          /*  Email: "danmem2@gmail.com"
                      FirstName: "danmem2"
                      IsFacilitator: null
                      LastName: "ape"
                      TeamId: 96
                      UserID: 220
                      invalid: 0
                      type: 3*/
                        });
                      }
                      else {
                        //significa que existe un mail dentro de la url de ingreso
                        /*  Email: "danmem2@gmail.com"
                          FirstName: "danmem2"
                          IsFacilitator: null
                          LastName: "ape"
                          TeamId: 96
                          UserID: 220
                          invalid: 0
                          type: 3*/
                        var exists = 0;
                        response['data'].forEach(element => {
                          if (element.Email.toString().toLowerCase() == userMail.toString().toLowerCase()) {
                            exists = 1;
                          }
                        })
                        if (this.businessModel !== 'Mfg EBIT') {
                          Swal.fire({
                            text: 'This board should be a manufacturing game mode',
                            icon: 'error',
                            allowEscapeKey: false, 
                            //html: this.getErrorLabel('1064'),
                          });
                          resolve(false);
                          return;
                        }
                        if (exists == 1) {
                          this.jsnUserSelected = response['data'].filter(el => {
                            if (el.Email.toString().toLowerCase() == userMail.toString().toLowerCase())//view board
                              return el;
                          });

                            Swal.fire({
                              text: '',
                              imageUrl: '../../../../assets/iconSuccessfully.svg',
                              imageAlt: 'icon successfully',
                              confirmButtonColor: '#5EA0CC',
                              html: this.getErrorLabel('1067'),
                            });
                            resolve(true);
                        } else {
                          // console.log("EMAIL NO VALIDO")
                          // this.emailNotExists = true;
                          Swal.fire({
                            text: '',
                            icon: 'error',
                            html: this.getErrorLabel('1068'),
                          });
                          resolve(false);
                        }
    
                      }
    
                    }
    
                  }//ifrespuesta length>0
                  //this.workshopdata = response;
                });
            }else{
              console.log('No hubo respuesta del servidor');
            }

          });

    })//fin promise
  }

  verificarAccesoUsuario(email) {
    this.clearLocalStorage();
    const clave = `ultimoAcceso_${email}_${this.workshopId}`;
    const ultimoAcceso = localStorage.getItem(clave);

    if (ultimoAcceso) {
        const ahora = Date.now();
        const cincoHoras = 5 * 60 * 60 * 1000;

        if (ahora - parseInt(ultimoAcceso) < cincoHoras) {
            console.log("Aún no es necesario reingresar el código para", email);
            return true;
        } else {
            console.log("Es necesario reingresar el código para", email);
            return false;
        }
    } else {
        console.log("No se encontró registro del último acceso para", email, ". Es necesario ingresar el código.");
        return false;
    }
}

clearLocalStorage() {
  const dosDias = 2 * 24 * 60 * 60 * 1000; // 2 días en milisegundos
  const ahora = Date.now();

  for (let i = 0; i < localStorage.length; i++) {
      const clave = localStorage.key(i);
      if (clave.startsWith('ultimoAcceso_')) {
          const valor = localStorage.getItem(clave);
          const tiempoGuardado = parseInt(valor);
          if (ahora - tiempoGuardado > dosDias) {
              localStorage.removeItem(clave);
              console.log(`Se ha eliminado la clave: ${clave} por antiguedad.`);
              i--; // Ajustar el índice pq eliminamos item
          }
      }
  }
}



  disableControl(response) {
    console.log(this.dialogRefAgrupar)
    // console.log(this.dialogRef['componentInstance'])
    console.log("socket fndisableControl CONTROL disabled", response.data.userId);
    if (response.data.userId != this.userId) {
      if(this.dialogRefAgrupar){
        this.dialogRefAgrupar.close();
        this.dialogRefAgrupar = undefined;
      }
      if(this.dialogRefPartition){
        this.dialogRefPartition.close();
        this.dialogRefPartition = undefined;
      }
      this.isOpenMarketDisabledBoard = true;
      const msg = `<div><span style="color:${environment.isProd ? 'lightgray' : 'gray'}; font-size: 12px">1102: </span>${response.data.name + ' ' + this.getSingleTextLabel('1078')}</div>`;
      this.fnTrowToastwithTimeMiliseconds('#errorConnection', msg, 3000);
      console.log("blnGrowNocontrol true;;;;;;");
      this.blnGrowNocontrol = true;
    } else {
      this.isOpenMarketDisabledBoard = false;
      if (!Swal.isVisible() || this.status != 3)
        this.fnTrowToastwithTimeMiliseconds('#errorConnection', this.getErrorLabel('1077'), 3000);
      console.log("blnGrowNocontrol false;;;;;;");
      this.blnGrowNocontrol = false;
    }
    this.lblUserwithControl = response.data.name;
    /*{
        container,
        index,
        room,
        userId,
        userName,
        statusPiece: status,
        pieceData
      } */
      
  }

  disablePiece(response) {
    console.log("Status piece disabled", response.data.statusPiece);
    const strAreaDrag = response.data.container;
    switch (strAreaDrag) {
      case 'cdkLstTrashCash':
        console.log("es cdklstTrash")
        this.cdkLstTrashCash.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            item.draggingBy = response.data.statusPiece ? response.data.userName + " is using it" : "";
          }
        });
        break;
      case 'cdkLstTrashCapitalS':
        console.log("es cdklstTrash")
        this.cdkLstTrashCapitalS.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            item.draggingBy = response.data.statusPiece ? response.data.userName + " is using it" : "";
          }
        });
        break;
      case 'cdkLstBank':
        this.cdkLstBank.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstCapEx':
        this.cdkLstCapEx.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstLabourP':
        this.cdkLstLabourP.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstSuppliers':
        this.cdkLstSuppliers.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstMaterials':
        this.cdkLstMaterials.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstInsideMachineCapex':
        this.cdkLstInsideMachineCapex.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstInsideMachinePeople':
        this.cdkLstInsideMachinePeople.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstInsideMachineWorkInProgress':
        this.cdkLstInsideMachineWorkInProgress.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstLandBuild':
        this.cdkLstLandBuild.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstFinishedFI':
        this.cdkLstFinishedFI.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstLoans90':
        this.cdkLstLoans90.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstLoans60':
        this.cdkLstLoans60.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstLoans30':
        this.cdkLstLoans30.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstReceivables90':
        this.cdkLstReceivables90.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstReceivables60':
        this.cdkLstReceivables60.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstReceivables30':
        this.cdkLstReceivables30.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstCapitalS':
        this.cdkLstCapitalS.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstRetainedE':
        this.cdkLstRetainedE.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstMachineOver':
        this.cdkLstMachineOver.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstDeprecation':
        this.cdkLstDeprecation.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstChangeI':
        this.cdkLstChangeI.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstMarketingS':
        this.cdkLstMarketingS.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstAdvertasing':
        this.cdkLstAdvertasing.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstAdministration':
        this.cdkLstAdministration.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstAdminOver':
        this.cdkLstAdminOver.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstRecruitmentT':
        this.cdkLstRecruitmentT.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstRent':
        this.cdkLstRent.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstInterest':
        this.cdkLstInterest.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstCash':
        this.cdkLstCash.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstCostGS':
        this.cdkLstCostGS.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstOther':
        this.cdkLstOther.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstIncomeBT':
        this.cdkLstIncomeBT.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstNetIncome':
        this.cdkLstNetIncome.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstTaxes':
        this.cdkLstTaxes.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstPayables60':
        this.cdkLstPayables60.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstPayables30':
        this.cdkLstPayables30.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
      case 'cdkLstInsideMachineCapexCustomizing':
        this.cdkLstInsideMachineCapexCustomizing.forEach(item => {
          if (item.id == response.data.pieceData.id) {
            item.draggable = response.data.statusPiece;
            if (response.data.statusPiece)
              item.draggingBy = response.data.userName + " is using it";
            else
              item.draggingBy = "";
            return;
          }
        });
        break;
    }
  }

  //#region funciones dropped

  deleteCoinByAreaID(idPreviousContainer: string, id) {
    switch (idPreviousContainer) {
      case 'cdkLstTrashCash':
        this.cdkLstTrashCash.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstTrashCash.splice(i, 1);
          }
        });
        break;
      case 'cdkLstTrashCapitalS':
        this.cdkLstTrashCapitalS.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstTrashCapitalS.splice(i, 1);
          }
        });
        break;
      case 'cdkLstBank':
        this.cdkLstBank.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstBank.splice(i, 1);
          }
        });
        break;
      case 'cdkLstCapEx':
        this.cdkLstCapEx.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstCapEx.splice(i, 1);
          }
        });
        break;
      case 'cdkLstLabourP':
        this.cdkLstLabourP.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstLabourP.splice(i, 1);
          }
        });
        break;
      case 'cdkLstSuppliers':
        this.cdkLstSuppliers.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstSuppliers.splice(i, 1);
          }
        });
        break;
      case 'cdkLstMaterials':
        this.cdkLstMaterials.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstMaterials.splice(i, 1);
          }
        });
        break;
      case 'cdkLstInsideMachineCapex':
        this.cdkLstInsideMachineCapex.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstInsideMachineCapex.splice(i, 1);
          }
        });
        break;
      case 'cdkLstInsideMachinePeople':
        this.cdkLstInsideMachinePeople.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstInsideMachinePeople.splice(i, 1);
          }
        });
        break;
      case 'cdkLstInsideMachineWorkInProgress':
        this.cdkLstInsideMachineWorkInProgress.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstInsideMachineWorkInProgress.splice(i, 1);
          }
        });
        break;
      case 'cdkLstInsideMachineCapex2':
        this.cdkLstInsideMachineCapex2.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstInsideMachineCapex2.splice(i, 1);
          }
        });
        break;
      case 'cdkLstInsideMachinePeople2':
        this.cdkLstInsideMachinePeople2.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstInsideMachinePeople2.splice(i, 1);
          }
        });
        break;
      case 'cdkLstInsideMachineWorkInProgress2':
        this.cdkLstInsideMachineWorkInProgress2.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstInsideMachineWorkInProgress2.splice(i, 1);
          }
        });
        break;
      case 'cdkLstLandBuild':
        this.cdkLstLandBuild.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstLandBuild.splice(i, 1);
          }
        });
        break;
      case 'cdkLstFinishedFI':
        this.cdkLstFinishedFI.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstFinishedFI.splice(i, 1);
          }
        });
        break;
      case 'cdkLstLoans90':
        this.cdkLstLoans90.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstLoans90.splice(i, 1);
            this.cdkLstLoans90Aux.splice(i, 1);
          }
        });
        break;
      case 'cdkLstLoans60':
        this.cdkLstLoans60.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstLoans60.splice(i, 1);
            this.cdkLstLoans60Aux.splice(i, 1);
          }
        });
        break;
      case 'cdkLstLoans30':
        this.cdkLstLoans30.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstLoans30.splice(i, 1);
            this.cdkLstLoans30Aux.splice(i, 1);
          }
        });
        break;
      case 'cdkLstReceivables90':
        this.cdkLstReceivables90.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstReceivables90.splice(i, 1);
            this.totCdkLstReceivables90 -= item.valor;
            // this.cdkLstReceivables90Aux.splice(i, 1);
          }
        });
        break;
      case 'cdkLstReceivables60':
        this.cdkLstReceivables60.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstReceivables60.splice(i, 1);
            this.totCdkLstReceivables60 -= item.valor;
            // this.cdkLstReceivables60Aux.splice(i, 1);
          }
        });
        break;
      case 'cdkLstReceivables30':
        this.cdkLstReceivables30.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstReceivables30.splice(i, 1);
            this.totCdkLstReceivables30 -= item.valor;
            // this.cdkLstReceivables30Aux.splice(i, 1);
          }
        });
        break;
      case 'cdkLstCapitalS':
        this.cdkLstCapitalS.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstCapitalS.splice(i, 1);
          }
        });
        break;
      case 'cdkLstRetainedE':
        this.cdkLstRetainedE.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstRetainedE.splice(i, 1);
          }
        });
        break;
      case 'cdkLstMachineOver':
        this.cdkLstMachineOver.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstMachineOver.splice(i, 1);
          }
        });
        break;
      case 'cdkLstDeprecation':
        this.cdkLstDeprecation.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstDeprecation.splice(i, 1);
          }
        });
        break;
      case 'cdkLstChangeI':
        this.cdkLstChangeI.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstChangeI.splice(i, 1);
          }
        });
        break;
      case 'cdkLstMarketingS':
        this.cdkLstMarketingS.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstMarketingS.splice(i, 1);
          }
        });
        break;
      case 'cdkLstAdvertasing':
        this.cdkLstAdvertasing.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstAdvertasing.splice(i, 1);
          }
        });
        break;
      case 'cdkLstAdministration':
        this.cdkLstAdministration.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstAdministration.splice(i, 1);
          }
        });
        break;
      case 'cdkLstAdminOver':
        this.cdkLstAdminOver.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstAdminOver.splice(i, 1);
          }
        });
        break;
      case 'cdkLstRecruitmentT':
        this.cdkLstRecruitmentT.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstRecruitmentT.splice(i, 1);
          }
        });
        break;
      case 'cdkLstRent':
        this.cdkLstRent.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstRent.splice(i, 1);
          }
        });
        break;
      case 'cdkLstInterest':
        this.cdkLstInterest.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstInterest.splice(i, 1);
          }
        });
        break;
      case 'cdkLstCash':
        this.cdkLstCash.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstCash.splice(i, 1);
          }
        });
        break;
      case 'cdkLstCostGS':
        this.cdkLstCostGS.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstCostGS.splice(i, 1);
          }
        });
        break;
      case 'cdkLstOther':
        this.cdkLstOther.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstOther.splice(i, 1);
          }
        });
        break;
      case 'cdkLstIncomeBT':
        this.cdkLstIncomeBT.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstIncomeBT.splice(i, 1);
          }
        });
        break;
      case 'cdkLstNetIncome':
        this.cdkLstNetIncome.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstNetIncome.splice(i, 1);
          }
        });
        break;
      case 'cdkLstTaxes':
        this.cdkLstTaxes.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstTaxes.splice(i, 1);
          }
        });
        break;
      case 'cdkLstPayables60':
        this.cdkLstPayables60.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstPayables60.splice(i, 1);
          }
        });
        break;
      case 'cdkLstPayables30':
        this.cdkLstPayables30.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstPayables30.splice(i, 1);
            this.cdkLstPayables30Aux.splice(i, 1);
          }
        });
        break;
      case 'cdkLstInsideMachineCapexCustomizing':
        this.cdkLstInsideMachineCapexCustomizing.forEach((item, i) => {
          if (item.id == id) {
            this.cdkLstPayables30.splice(i, 1);
          }
        });
        break;
    }
  }

  addCoinByAreaID(idContainer: string, jsonCoin) {
    switch (idContainer) {
      case 'cdkLstTrashCash':
        this.cdkLstTrashCash.push(jsonCoin);
        break;
      case 'cdkLstTrashCapitalS':
        this.cdkLstTrashCapitalS.push(jsonCoin);
        break;
      case 'cdkLstBank':
        this.cdkLstBank.push(jsonCoin);
        break;
      case 'cdkLstCapEx':
        this.cdkLstCapEx.push(jsonCoin);
        break;
      case 'cdkLstLabourP':
        this.cdkLstLabourP.push(jsonCoin);
        break;
      case 'cdkLstSuppliers':
        this.cdkLstSuppliers.push(jsonCoin);
        break;
      case 'cdkLstMaterials':
        this.cdkLstMaterials.push(jsonCoin);
        break;
      case 'cdkLstInsideMachineCapex':
        this.cdkLstInsideMachineCapex.push(jsonCoin);
        break;
      case 'cdkLstInsideMachinePeople':
        this.cdkLstInsideMachinePeople.push(jsonCoin);
        break;
      case 'cdkLstInsideMachineWorkInProgress':
        this.cdkLstInsideMachineWorkInProgress.push(jsonCoin);
        break;
      case 'cdkLstInsideMachineCapex2':
        this.cdkLstInsideMachineCapex2.push(jsonCoin);
        break;
      case 'cdkLstInsideMachinePeople2':
        this.cdkLstInsideMachinePeople2.push(jsonCoin);
        break;
      case 'cdkLstInsideMachineWorkInProgress2':
        this.cdkLstInsideMachineWorkInProgress2.push(jsonCoin);
        break;
      case 'cdkLstLandBuild':
        this.cdkLstLandBuild.push(jsonCoin);
        break;
      case 'cdkLstFinishedFI':
        this.cdkLstFinishedFI.push(jsonCoin);
        break;
      case 'cdkLstLoans90':
        this.cdkLstLoans90.push(jsonCoin);
        break;
      case 'cdkLstLoans60':
        this.cdkLstLoans60.push(jsonCoin);
        break;
      case 'cdkLstLoans30':
        this.cdkLstLoans30.push(jsonCoin);
        break;
      case 'cdkLstReceivables90':
        this.cdkLstReceivables90.push(jsonCoin);
        break;
      case 'cdkLstReceivables60':
        this.cdkLstReceivables60.push(jsonCoin);
        break;
      case 'cdkLstReceivables30':
        this.cdkLstReceivables30.push(jsonCoin);
        break;
      case 'cdkLstCapitalS':
        this.cdkLstCapitalS.push(jsonCoin);
        break;
      case 'cdkLstRetainedE':
        this.cdkLstRetainedE.push(jsonCoin);
        break;
      case 'cdkLstMachineOver':
        this.cdkLstMachineOver.push(jsonCoin);
        break;
      case 'cdkLstDeprecation':
        this.cdkLstDeprecation.push(jsonCoin);
        break;
      case 'cdkLstChangeI':
        this.cdkLstChangeI.push(jsonCoin);
        break;
      case 'cdkLstMarketingS':
        this.cdkLstMarketingS.push(jsonCoin);
        break;
      case 'cdkLstAdvertasing':
        this.cdkLstAdvertasing.push(jsonCoin);
        break;
      case 'cdkLstAdministration':
        this.cdkLstAdministration.push(jsonCoin);
        break;
      case 'cdkLstAdminOver':
        this.cdkLstAdminOver.push(jsonCoin);
        break;
      case 'cdkLstRecruitmentT':
        this.cdkLstRecruitmentT.push(jsonCoin);
        break;
      case 'cdkLstRent':
        this.cdkLstRent.push(jsonCoin);
        break;
      case 'cdkLstInterest':
        this.cdkLstInterest.push(jsonCoin);
        break;
      case 'cdkLstCash':
        this.cdkLstCash.push(jsonCoin);
        break;
      case 'cdkLstCostGS':
        this.cdkLstCostGS.push(jsonCoin);
        break;
      case 'cdkLstOther':
        this.cdkLstOther.push(jsonCoin);
        break;
      case 'cdkLstIncomeBT':
        this.cdkLstIncomeBT.push(jsonCoin);
        break;
      case 'cdkLstNetIncome':
        this.cdkLstNetIncome.push(jsonCoin);
        break;
      case 'cdkLstTaxes':
        this.cdkLstTaxes.push(jsonCoin);
        break;
      case 'cdkLstPayables60':
        this.cdkLstPayables60.push(jsonCoin);
        break;
      case 'cdkLstPayables30':
        this.cdkLstPayables30.push(jsonCoin);
        break;
      case 'cdkLstInsideMachineCapexCustomizing':
        this.cdkLstInsideMachineCapexCustomizing.push(jsonCoin);
        break;
    }
  }

  fnDroppedPieceSocket(response) {
    console.log("responseEventDropped", response);
    if (response.data.userId == this.userId) {
      return;
    }
    const index = response.data.index;
    const jsonCoin = response.data.data;
    console.log('moneda nuevaaaaaaaaaaaa', jsonCoin);

    // this.socketService.disablePiece(response.data.previousContainer,index,this.roomId,this.userId,this.userData.FirstName, false);
    // primero quitamos la moneda del array donde se encontraba anteriormente
    const idPreviousContainer = response.data.previousContainer;
    this.deleteCoinByAreaID(idPreviousContainer, response.data.data.id);



    // despues agregamos la moneda al contenedor destino donde se solto
    const strAreaDrag = response.data.container;
    jsonCoin.draggable = false;
    jsonCoin.draggingBy = "";
    this.addCoinByAreaID(strAreaDrag, jsonCoin);
  }

  fnStatusPiece(cointainerId: string, status, jsonDragged) {
    jsonDragged.draggable = status;
    jsonDragged.draggingBy = status ? jsonDragged.draggingBy : "";
    this.socketService.disablePiece(
      cointainerId,
      this.indexItemDragged,
      this.roomId,
      this.userId,
      this.userData.FirstName,
      status,
      jsonDragged
    )
  }

  fnSetNewList(event: CdkDragDrop<any>, jsonDragged) {
    console.log(event.container.data[event.currentIndex]);
    if (event.container.data[event.currentIndex])
      event.container.data[event.currentIndex].draggingBy = "";
    this.socketService.setList(
      event.container.data,
      event.container.id,
      event.previousContainer.id,
      jsonDragged,
      this.roomId,
      this.teamId
    );
    console.log("nueva lista ", event.container.id, jsonDragged);
    // this.socketService.disablePiece(event.container.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
    this.updStatusMachine();
  }

  async fnDroppedPieceFromBank(event: CdkDragDrop<any>, jsonDragged, status, idBln, value) {
    return new Promise(resolve => {
      this.socketService.borrowFromBank(
        event.previousContainer.id,
        event.container.id,
        jsonDragged,
        this.roomId,
        this.teamId,
        this.currentMonth,
        idBln,
        value
      );

      // setTimeout(async () => {
      resolve('listo')
      // }, 1000);
      console.log("Vamos a cambiar estado de pieza con hide", jsonDragged);
      // this.socketService.disablePiece(event.container.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
      // this.updStatusMachine();
    });
  }

  fnDroppedPiece(event: CdkDragDrop<any>, jsonDragged) {
    console.log(event.container.data[event.currentIndex]);
    if (event.container.data[event.currentIndex])
      event.container.data[event.currentIndex].draggingBy = "";
    this.socketService.dropACoin(
      event.previousContainer.id,
      event.container.id,
      jsonDragged,
      this.indexItemDragged,
      this.roomId,
      this.userId,
      this.userData.FirstName,
      this.teamId,
      this.status
    );
    console.log("Vamos a cambiar estado de pieza con hide", jsonDragged);
    this.socketService.disablePiece(event.container.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
    if (this.currentMonth >= 2 && (event.container.id == 'cdkLstInsideMachineCapex2' || event.container.id == 'cdkLstInterest' || event.container.id == 'cdkLstCash' || event.container.id == 'cdkLstRecruitmentT' || (event.container.id == 'cdkLstLabourP' && jsonDragged.type == 6))) {
      return;
    }
    this.updStatusMachine();
  }
  //////////////trash
  fnDroppedOnTrash(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.id == "cdkLstOrders" && this.status == 0) {
      // delete order
      event.previousContainer.data.splice(this.indexItemDragged, 1);
      this.updStatusMachine();
      this.socketService.deleteOrder(jsonDragged, this.roomId, this.teamId);
      this.orderService.setDeletedOrder(jsonDragged.BidId).subscribe(response => {
        console.log(response, 'setDeletedOrder');
        if (response.intResponse == 200) {
          
        }
      });
      return;
    }
    if (jsonDragged.type == 6 || jsonDragged.type == 12) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if(!this.improvementSelected && this.status == 0 && this.isProjectsEnableByCurrentMonth && this.currentMonth >= 3){
      console.log(this.improvementsOptionsSelected)
      if(this.improvementsOptionsSelected.length == 0){
        console.log("Aquis")
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('4030', '3001'))
        return;
      }      
      if(this.improvementsOptionsSelected[0].option == 0){
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('4030', '3001'))
        return;
      }
    }
    if (
      (event.previousContainer.id == "cdkLstAdvertasing" || event.previousContainer.id == "cdkLstCostGS" ||
        event.previousContainer.id == "cdkLstMachineOver" || event.previousContainer.id == "cdkLstDeprecation" ||
        event.previousContainer.id == "cdkLstChangeI" || event.previousContainer.id == "cdkLstMarketingS" ||
        event.previousContainer.id == "cdkLstAdminOver" || event.previousContainer.id == "cdkLstAdministration" ||
        event.previousContainer.id == 'cdkLstInterest' || event.previousContainer.id == "cdkLstInterest" ||
        event.previousContainer.id == 'cdkLstRecruitmentT' || event.previousContainer.id == 'cdkLstRent') && this.status == 0
    ) {
      // delete piece
      console.log("vamos a eliminar la moneda", jsonDragged);
      event.previousContainer.data.splice(this.indexItemDragged, 1);
      this.updStatusMachine();
      this.socketService.deleteCoin(event.previousContainer.id, jsonDragged, this.roomId, this.teamId);
      // this.errorControlContainer(0, event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }


    this.indexItemDragged = -1;

  }

  fnDroppedOnTrashCash(event: CdkDragDrop<any>) {
    if(this.currentMonth >= 2){
      return;
    }    
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    // console.log("eventend", eventend);
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    }
    else {
      // regresamos el elemento a su anterior posicion, ya que nodebe poder moverse
      setTimeout(() => {
        this.errorControl(event, jsonDragged);
        this.fnRegresaposicionContainer(jsonDragged.id);
      }, 200);

      /* this.lstPositionBefore.forEach((element, index) => {
         if(element.id = jsonDragged.id){
           console.log("this.lstPositions[index]:",this.lstPositions[index]);
           console.log("element de before:", element);
           this.lstPositions[index] = element;
           var elem = document.getElementById(jsonDragged.id);
           let strStyles= "left:"+element.left+"px !important;top:"+element.top+"px !important; position: fixed !important;"
           console.log("strStyles a aplicar::::::",strStyles);
           elem.style.cssText = strStyles;
         }
       });
 
       console.log("this.lstPositionsfinal:-- ",this.lstPositions);*/
      /* transferArrayItem(
         event.previousContainer.data,
         event.container.data,
         this.indexItemDragged,
         event.currentIndex
       );
       this.fnDroppedPiece(event, jsonDragged);
       // this.errorControlContainer(0, event, jsonDragged);*/
    }
    //this.indexItemDragged = -1; 

  }

  fnDroppedOnNoAreaDragg(event: CdkDragDrop<any>) {
    console.log("ENTRAMOS A ONnODRAGGAREA:");
    if(this.currentMonth >= 2){
      return;
    }
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    console.log(jsonDragged, jsonDragged.id);
    this.errorControl(event, jsonDragged);
    this.fnRegresaposicionContainer(jsonDragged.id);
    /*if (event.previousContainer.data[this.indexItemDragged].type == 6 ||  this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    }
    else {
      // regresamos el elemento a su anterior posicion, ya que nodebe poder moverse
      */
    setTimeout(() => {
      this.fnRegresaposicionContainer(jsonDragged.id);
    }, 200);
    /*
  }*/

  }

  fnDroppedOnTrashCapitalS(event: CdkDragDrop<any>) {
    if(this.currentMonth >= 2){
      return;
    }
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    }
    else {
      this.errorControlContainer(0, event, jsonDragged);
    }
    this.indexItemDragged = -1;

  }

  fnDroppedOnBank(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    }/*No se puede regresar de cash o loans a bank
     else if (jsonDragged.type == 1 || jsonDragged.type == 3) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    }*/ else {
      this.errorControlContainer(1, event, jsonDragged);
    }

  }

  fnDroppedOnCapEx(event: CdkDragDrop<any>) {
    
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    //DONE?
    let checkbox = this.getCheckCheckbox(16);
    if (checkbox.blnCheck && this.currentMonth >= 2) {
      this.fnTrowToastwithTimeMiliseconds("#errorGeneral", "You can't do this", 3000);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    jsonDragged.draggable = false;
    jsonDragged.draggingBy = "";
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // mode open vender el edificio
      (this.isModeOpen && event.previousContainer.id == "cdkLstLandBuild" && jsonDragged.type == 4 && this.status >= 3 &&
        this.currentMonth >= 2
      )
    ) {
      if (this.jsonBlnOpenModeActions.isBuyBuild) {
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1054'))
        this.fnStatusPiece(event.container.id, false, jsonDragged);
        return;
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.jsonBlnOpenModeActions.isSoldBuild = true;
      this.socketService.setBlnControlModeOpen('isSoldBuild', true, this.roomId, this.teamId);
      // this.checkBoxes[this.currentMonth>=3?9:7].blnCheck = false;
      // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      this.checkCheckbox(7, false);
      this.fnDroppedPiece(event, jsonDragged);
      this.socketService.createCoin('cdkLstCapEx', {
        valor: 20,
        id: null,
        draggable: false,
        type: 1,
      }, this.roomId, this.teamId);
      // Mandar mensaje facilitador, edificio vendido
      this.notifyFacilitator(2);

    } else if (
      // mode riguroso vender el edificio
      (!this.isModeOpen && event.previousContainer.id == "cdkLstLandBuild" && jsonDragged.type == 4 && this.status >= 3 &&
        this.currentMonth >= 2)
    ) {
      if (this.jsonBlnOpenModeActions.isBuyBuild) {
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1054'))
        this.fnStatusPiece(event.container.id, false, jsonDragged);
        return;
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      /*DCM CHECAR CON ANGEL SI ES NECARIO
      this.jsonBlnOpenModeActions.isSoldBuild = true;
      this.socketService.setBlnControlModeOpen('isSoldBuild', true, this.roomId, this.teamId);*/
      // this.checkBoxes[this.currentMonth>=3?9:7].blnCheck = false;
      // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      // this.checkCheckbox(7, false);
      this.checkCheckbox(10, false);
    

      // -------------------- actualiza pieza, lo mismo que droppedpiece pero sin actualizar la maquina
      console.log("Correctooooooo!!");
      this.jsonBlnOpenModeActions.isSoldBuild = true;
      console.log("Vendio edificioooooooo")
      this.socketService.setBlnControlModeOpen('isSoldBuild', true, this.roomId, this.teamId);
      // this.checkifNeedbackupCoin();
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      this.socketService.dropACoin(
        event.previousContainer.id,
        event.container.id,
        jsonDragged,
        this.indexItemDragged,
        this.roomId,
        this.userId,
        this.userData.FirstName,
        this.teamId,
        this.status
      );

      this.socketService.disablePiece(event.container.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
      this.socketService.deleteCoin('cdkLstCapEx', jsonDragged, this.roomId, this.teamId);

      this.blnAllowAnimCapEx = true;
      // --------------------------------------------
      setTimeout(() => {
        this.socketService.createCoin('cdkLstCash', {
          valor: 20,
          id: null,
          draggable: false,
          type: 1,
        }, this.roomId, this.teamId);
        this.blnAllowAnimCapEx = false;

      }, 5000);

     
      // Mandar mensaje facilitador, edificio vendido
      this.notifyFacilitator(2);

    } else {
      this.errorControlContainer(2, event, jsonDragged);
    }
  }

  paidLandBuild(event) {
    console.log("paidLandBuild", event);
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      (this.status == 4 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && jsonDragged.valor == 20 ||
        this.status == 9 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && jsonDragged.valor == 20) && this.currentMonth == 1
    ) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      // this.paidCapEx = [];
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      console.log("Droppeeeed on paid land");
      this.fnDroppedPiece(event, jsonDragged); 
    } else if (event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && this.status >= 3 && jsonDragged.valor == 10 && this.currentMonth >= 3) {
      // implementando improvement efficiency
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged)
      this.jsonBlnOpenModeActions.isPayBill = false;
      this.socketService.setBlnControlModeOpen('isPayBill', false, this.roomId, this.teamId);
      this.spinner.show('my-custom-spinner')
      setTimeout(() => {
        this.socketService.setBlnControlModeOpen('isActiveImprovement', false, this.roomId, this.teamId);
        this.jsonBlnOpenModeActions.isActiveImprovement = false;
        this.socketService.setBlnControlModeOpen('showMessageOwe10', false, this.roomId, this.teamId);
        this.jsonBlnOpenModeActions.showMessageOwe10 = false;
        setTimeout(async () => {
          // this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = true;
          // this.fnScrollCheckList(this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4]);
          // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          this.checkCheckbox(4,true);
          this.spinner.hide('my-custom-spinner')
          if (this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isEfficiency2) {
            this.socketService.implementExpansionWithEfficiency(this.roomId, this.teamId, 2)
            this.spinner.show();
            // const x = await this.implementExpansionWithEfficiency(2);
          } else if (
            (((this.improvementsOptionsSelected[0].value == 1 && this.improvementsOptionsSelected[0].option != 6) || this.improvementsOptionsSelected[0].value == 2)) ||
            (((this.improvementsOptionsSelected[1].value == 1 && this.improvementsOptionsSelected[1].option != 6) || this.improvementsOptionsSelected[1].value == 2))
          ) {
            if(this.improvementsOptionsSelected[1].Approved == 1 || this.improvementsOptionsSelected[0].Approved == 1){
              return;
            }
            this.socketService.askImprovement(this.roomId, this.teamId);
            this.spinner.show();
          }
        }, 500);
      }, 1000);
    } else if (event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && this.status >= 3 && this.currentMonth >= 2 && ((this.jsonBlnOpenModeActions.isBuyBuild && this.cdkLstRent.length > 0) || this.jsonBlnOpenModeActions.showMessageOwe20) && jsonDragged.valor == 20) {
      // pagar el land and build
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      console.log("Droppeeeed on paid land");
      this.fnDroppedPiece(event, jsonDragged)
      // this.jsonBlnOpenModeActions.isBuyBuild = false;
      // this.socketService.setBlnControlModeOpen('isBuyBuild', false, this.roomId, this.teamId);
      setTimeout(() => {
        this.socketService.setBlnControlModeOpen('showMessageOwe20', false, this.roomId, this.teamId);
        this.jsonBlnOpenModeActions.showMessageOwe20 = false;
        if (this.jsonBlnOpenModeActions.isSecondMachine) {
          // this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = true;
          // this.fnScrollCheckList(this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4]);
          // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          this.checkCheckbox(4,true);
          this.spinner.hide('my-custom-spinner')
        }
        let cap: any = {};
        this.cdkLstCapEx.forEach(item => {
          if (item.type == 3) {
            cap = item;
            return;
          }
        });
        this.socketService.deleteCoin('cdkLstCapEx', cap, this.roomId, this.teamId);
        // if (this.jsonBlnOpenModeActions.isSoldBuild) {
        //   setTimeout(() => {
        //     this.socketService.setBlnControlModeOpen('isSoldBuild', false, this.roomId, this.teamId);
        //   }, 500);
        // }
      }, 2000);
    } else {
      this.errorControl(event, jsonDragged);
    }
    // this.fnDroppedPiece(event, jsonDragged);
  }

  paidCustomizing(event) {
    console.log("paidCustomizing", event);
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && this.status >= 3 && jsonDragged.valor == 10) {
      // modo open, pagar el land and build
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged)
      this.jsonBlnOpenModeActions.isPayBill10 = false;
      this.socketService.setBlnControlModeOpen('isActiveImprovement', false, this.roomId, this.teamId);
      this.jsonBlnOpenModeActions.isActiveImprovement = false;
      this.socketService.setBlnControlModeOpen('isPayBill10', false, this.roomId, this.teamId);
      this.spinner.show('my-custom-spinner')
      setTimeout(() => {
        this.socketService.setBlnControlModeOpen('showBill10', false, this.roomId, this.teamId);
        this.jsonBlnOpenModeActions.showBill10 = false;
        setTimeout(async () => {
          // this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = true;
          // this.fnScrollCheckList(this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4]);
          // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          this.spinner.hide('my-custom-spinner')
          this.checkCheckbox(4, true);
          if (
            (((this.improvementsOptionsSelected[0].value == 1 && this.improvementsOptionsSelected[0].option != 6) || this.improvementsOptionsSelected[0].value == 2)) ||
            (((this.improvementsOptionsSelected[1].value == 1 && this.improvementsOptionsSelected[1].option != 6) || this.improvementsOptionsSelected[1].value == 2))
          ) {
            if(this.improvementsOptionsSelected[1].Approved == 1 || this.improvementsOptionsSelected[0].Approved == 1){
              return;
            }
            
            this.socketService.askImprovement(this.roomId, this.teamId);
            this.spinner.show();
          }
        }, 500);
      }, 750);
    } else {
      this.errorControl(event, jsonDragged);
    }
    // this.fnDroppedPiece(event, jsonDragged);
  }

  paidSuppliers(event) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      (this.status == 15) && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && jsonDragged.valor == 12
    ) {
      // this.paySuppliers = [];
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      console.log("Droppeeeed on paid suppliers");
      this.fnDroppedPiece(event, jsonDragged)
    } else if (event.previousContainer.id == "cdkLstCash" &&
      jsonDragged.type == 1 && (jsonDragged.valor == 12 || jsonDragged.valor == 18 && this.jsonBlnOpenModeActions.isEfficiency1) &&
      this.isModeOpen && this.status >= 3) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      console.log("Droppeeeed on paid suppliers");
      let borrar = { id: -1 };
      this.cdkLstSuppliers.forEach(item => {
        if (item.type == 3) {
          borrar = item;
        }
      })
      this.socketService.deleteCoin('cdkLstSuppliers', borrar, this.roomId, this.teamId);
      this.deleteCoinByAreaID('cdkLstSuppliers', borrar.id);
      // this.jsonBlnOpenModeActions.isBuyMaterials = false;
      // this.socketService.setBlnControlModeOpen('isBuyMaterials', false, this.roomId, this.teamId);
      // setTimeout(() => {
      //   this.socketService.setBlnControlModeOpen('showMessageOwe12', false, this.roomId, this.teamId);
      //   this.jsonBlnOpenModeActions.showMessageOwe12 = false;
      // }, 3000);
      // this.fnDroppedPiece(event, jsonDragged);
      // // checkear paso de comprar materiales
      //   this.checkCheckbox(3, true);
      setTimeout(() => {
        this.fnDroppedPiece(event, jsonDragged);
        setTimeout(async () => {
          if(this.jsonBlnOpenModeActions.isSecondMachine){
            if(this.cdkLstSuppliers.length==0){
              // this.checkCheckbox(3, true);
              this.checkCheckbox(6, true);
            }
          }else{
            this.checkCheckbox(6, true);

          }         
          const x = await this.payRowMaterials();
        }, 500);
      }, 500);
    } else if (
      event.previousContainer.id == "cdkLstCash" &&
      jsonDragged.type == 1 &&
      (jsonDragged.valor == 12 || jsonDragged.valor == 18 && this.jsonBlnOpenModeActions.isEfficiency1) &&
      !this.isModeOpen &&
      this.status == 4) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      console.log("Droppeeeed on paid suppliers");
      this.fnDroppedPiece(event, jsonDragged);
      let borrar;
      this.cdkLstSuppliers.forEach(item => {
        if (item.type == 3) {
          borrar = item;
        }
      })
      this.deleteCoinByAreaID('cdkLstSuppliers', borrar.id);
      // checkear paso de comprar materiales
      // this.checkBoxes[this.currentMonth >= 3?5:3].blnCheck = true;
      // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      if(this.jsonBlnOpenModeActions.isSecondMachine){
        if(this.cdkLstSuppliers.length==0){
          this.checkCheckbox(6, true);

        }
      }else{
        this.checkCheckbox(6, true);

      }
    } else if (
      // improvement row material applied
      event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 &&
      jsonDragged.valor == 4 && !this.isModeOpen && this.status == 4
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      console.log("Droppeeeed on paid suppliers");
      this.fnDroppedPiece(event, jsonDragged);
      // this.socketService.deleteCoin('cdkLstSuppliers', this.cdkLstSuppliers[0],this.roomId, this.teamId);
      // this.deleteCoinByAreaID('cdkLstSuppliers', jsonDragged.id);
      let borrar;
      this.cdkLstSuppliers.forEach(item => {
        if (item.type == 3) {
          borrar = item;
        }
      })
      this.deleteCoinByAreaID('cdkLstSuppliers', borrar.id);
      // checkear paso de comprar materiales
      // this.checkBoxes[this.currentMonth >= 3?5:3].blnCheck = true;
      // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      if(this.jsonBlnOpenModeActions.isSecondMachine){
        if(this.cdkLstSuppliers.length==0){
          this.checkCheckbox(6, true);

        }
      }else{
        this.checkCheckbox(6, true);

      }
    } else if (// improvement row material applied 
      event.previousContainer.id == "cdkLstCash" &&
      jsonDragged.type == 1 &&
      this.jsonBlnOpenModeActions.isRawMaterials1 &&
      !this.jsonBlnOpenModeActions.isEfficiency1 &&
      jsonDragged.valor == 4) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      let borrar;
      this.cdkLstSuppliers.forEach(item => {
        if (item.type == 3) {
          borrar = item;
        }
      })
      this.socketService.deleteCoin('cdkLstSuppliers', borrar, this.roomId, this.teamId);
      this.deleteCoinByAreaID('cdkLstSuppliers', borrar.id);

      setTimeout(() => {
        this.fnDroppedPiece(event, jsonDragged);
        setTimeout(async () => {
          this.checkCheckbox(6, true);
          const x = await this.payRowMaterials();
        }, 500);
      }, 500);
      // checkear paso de comprar materiales

    } else if (// improvement row material & efficiency applied
      event.previousContainer.id == "cdkLstCash" &&
      jsonDragged.type == 1 &&
      this.jsonBlnOpenModeActions.isRawMaterials1 &&
      this.jsonBlnOpenModeActions.isEfficiency1 &&
      jsonDragged.valor == 6) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      let borrar;
      this.cdkLstSuppliers.forEach(item => {
        if (item.type == 3) {
          borrar = item;
        }
      })
      this.socketService.deleteCoin('cdkLstSuppliers', borrar, this.roomId, this.teamId);
      this.deleteCoinByAreaID('cdkLstSuppliers', borrar.id);

      setTimeout(() => {
        this.fnDroppedPiece(event, jsonDragged);
        setTimeout(() => {
          this.checkCheckbox(6, true);

          setTimeout(async () => {
            const x = await this.payRowMaterials();
          }, 500);
        }, 500);
      }, 500);
      // checkear paso de comprar materiales

    } else {
      this.errorControl(event, jsonDragged);
    }
    // this.fnDroppedPiece(event, jsonDragged);
  }

  payLiabilitie(event: CdkDragDrop<any>, loan, idList: string) {
    console.log('pay liabilitie', event);
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.id == 'cdkLstCash' && jsonDragged.type == 1 && jsonDragged.valor == loan.valor) {
      this.cdkLstCash.splice(this.indexItemDragged, 1);
      this.socketService.deleteCoin('cdkLstCash', jsonDragged, this.roomId, this.teamId);
      if (idList == 'lstLoan') {
        this.spinner.show('my-custom-spinner')
        this.cdkLstLoans30Aux.forEach((item, i) => {
          if (item.id == loan.id) {
            console.log('eliminar Item',item.id);
            setTimeout(async () => {
              const x = await this.fnIsReadyPaso3(loan, "cdkLstLoans30");
              if (x) {
                this.cdkLstLoans30.splice(i, 1);
                this.cdkLstLoans30Aux.splice(i, 1);
                this.socketService.deleteCoin('cdkLstLoans30', loan, this.roomId, this.teamId);
                console.log('Elementos loans 30:',this.jsonBlnOpenModeActions.isLoansPaid);
                if (this.cdkLstLoans30Aux.length === 0) {
                  this.jsonBlnOpenModeActions.isLoansPaid = true;
                  console.log('entro loan!!!!!',this.jsonBlnOpenModeActions.isLoansPaid);
                  this.socketService.setBlnControlModeOpen('isLoansPaid', true, this.roomId, this.teamId);
                }
                if (this.cdkLstLoans30.length === 0) {
                  this.boardService.clearLiabilitiesReceivablesUpdatesArea(this.workshopId, this.teamId, 'liabilities30').subscribe(rupd => {
                    if (rupd.intResponse === 200) {
                      this.liabilitiesReceivablesBeforeUpdates = rupd.jsonData;
                    }
                  });
                }
              setTimeout(() => {
                  this.socketService.createCoin('cdkLstBank', {
                    valor: 20,
                    id: null,
                    draggable: false,
                    type: 9,
                  }, this.roomId, this.teamId);
                  this.spinner.hide('my-custom-spinner')

                }, 500);
              }
            }, 500);
            return;
          }
        })
      } else if (idList == 'lstPayables') {
        this.spinner.show('my-custom-spinner')
        this.cdkLstPayables30Aux.forEach((item, i) => {
          if (item.id == loan.id) {
            console.log('eliminar');
            setTimeout(() => {
              this.cdkLstPayables30.splice(i, 1);
              this.cdkLstPayables30Aux.splice(i, 1);
              this.socketService.deleteCoin('cdkLstPayables30', loan, this.roomId, this.teamId);
              console.log('Elementos Payables 30:',this.jsonBlnOpenModeActions.isPayablesPaid);
              if (this.cdkLstPayables30Aux.length === 0) {
                this.jsonBlnOpenModeActions.isPayablesPaid = true;
                console.log('entro payable!!!!!',this.jsonBlnOpenModeActions.isPayablesPaid);
                this.socketService.setBlnControlModeOpen('isPayablesPaid', true, this.roomId, this.teamId);
              }
              if (this.cdkLstPayables30.length === 0) {
                this.boardService.clearLiabilitiesReceivablesUpdatesArea(this.workshopId, this.teamId, 'payables30').subscribe(rupd => {
                  if (rupd.intResponse === 200) {
                    this.liabilitiesReceivablesBeforeUpdates = rupd.jsonData;
                  }
                });
              }
              this.fnIsReadyPaso3({ valor: 0 }, "cdkLstPayables30", true);
              setTimeout(() => {
              this.spinner.hide('my-custom-spinner')
                
              }, 500);
            }, 500);
            return;
          }
        })
      }
    } else if (event.previousContainer.id == 'cdkLstCash' && jsonDragged.type == 1 && jsonDragged.valor != loan.valor) {
      if (jsonDragged.valor > loan.valor) {
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'));
      } else {
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1096')); // not enough
      }
    }
  }


  fnDroppedOnLabourP(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer === event.container) {
      console.log("Dropped on laboor pool el mismo");
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (jsonDragged.type == 6) {
      if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
        this.errorControl(event, jsonDragged);
        return;
      }
      console.log("dropped on trash, from list", event.previousContainer.id)
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else {
      this.errorControlContainer(3, event, jsonDragged);
    }

  }

  fnDroppedOnSuppliers(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    }
    //  else if (jsonDragged.type == 7) {
    //   console.log("dropped on trash, from list", event.previousContainer.id)
    //   transferArrayItem(
    //     event.previousContainer.data,
    //     event.container.data,
    //     this.indexItemDragged,
    //     event.currentIndex
    //   );
    //   this.fnDroppedPiece(event, jsonDragged);
    // } 
    else {
      this.errorControlContainer(4, event, jsonDragged);
    }
  }

  async fnDroppedOnMaterials(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    jsonDragged.draggable = false;
    jsonDragged.draggingBy = '';
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    // if(this.jsonBlnOpenModeActions.isActiveImprovement == true){
    //     this.fnTrowToast("#errorGeneral", "63.- Implement your improvement!");
    //     return;
    // }
    if (!this.isModeOpen) {
      let thereAreStackCover = false;

      if (this.cdkLstCapEx.length > 0) {
        this.cdkLstCapEx.forEach(item => {
          console.log(item.valor == 20);
          console.log((this.jsonBlnOpenModeActions.isSecondMachine || this.thereAreStack(this.cdkLstCapEx, 5)));
          console.log(!(this.jsonBlnOpenModeActions.isSoldBuild));
          console.log(1 && false);
          console.log((item.valor == 20 && ((this.jsonBlnOpenModeActions.isSecondMachine || this.thereAreStack(this.cdkLstCapEx, 5)) && !(this.jsonBlnOpenModeActions.isSoldBuild))));
          if (item.valor == 10) {
            thereAreStackCover = true; return;
          }
        })
      }
      if (this.jsonBlnOpenModeActions.showBill1 || this.jsonBlnOpenModeActions.showBill2 || this.thereAreStack(this.cdkLstCapEx, 11) ||
        thereAreStackCover || this.jsonBlnOpenModeActions.showBill10) {
        return;
      }
    }
    let totMaterials = 0;
    this.cdkLstMaterials.forEach(x => {
      totMaterials += x['valor'];
      console.log(x);
    });
    console.log(this.cdkLstMaterials,jsonDragged,totMaterials)

    if((this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isEfficiency1 && !this.jsonBlnOpenModeActions.isRawMaterials1)
        || (this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.isRawMaterials2 && this.jsonBlnOpenModeActions.isRawMaterials1)
        || (this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isEfficiency2)){
      if(totMaterials - jsonDragged.valor < 0 && totMaterials > 0 && this.status == 3 && this.currentMonth > 2){
        console.log("No se puede, tienes "+totMaterials+" y quieres mover " +jsonDragged.valor)
        return;
      }
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 14 mover de suppliers a row materials
      this.status == 14 && event.previousContainer.id == "cdkLstSuppliers" && jsonDragged.type == 7
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if ((this.isModeOpen && this.currentMonth >= 2 && (!this.jsonBlnOpenModeActions.showMessageOwe18 && !this.jsonBlnOpenModeActions.showMessageOwe12 && !this.jsonBlnOpenModeActions.showMessageOwe4) && event.previousContainer.id == "cdkLstSuppliers" && (jsonDragged.type == 7 || jsonDragged.type == 14) && this.status >= 3)) {
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      let totalMaterials = 0;
      this.cdkLstMaterials.forEach(item => {
        totalMaterials += item.valor;
      });
      console.log("COMPRAAAAAR")
      const x = await this.buyRowMaterials(totalMaterials, jsonDragged);
    } else if (!this.isModeOpen &&
      event.previousContainer.id == "cdkLstSuppliers" &&
      (jsonDragged.type == 7 || jsonDragged.type == 14) && (this.status == 3 || this.status == 4 || this.status == 6) && this.currentMonth >= 2 &&
      (!this.jsonBlnOpenModeActions.showMessageOwe18 && !this.jsonBlnOpenModeActions.showMessageOwe12 && !this.jsonBlnOpenModeActions.showMessageOwe4)) {
      if (this.currentMonth >= 3 && (!this.getCheckCheckbox(3).blnCheck || (!this.getCheckCheckbox(4).blnCheck && this.getCheckCheckbox(4).show == 1) || (!this.getCheckCheckbox(5).blnCheck && this.getCheckCheckbox(5).show == 1))) {
        //liabilities DONE
        this.errorControl(event, jsonDragged);
        console.log("AQui?")
        //this.fnTrowToast("#errorGeneral", "63.- Implement your improvement!");
        return;
      }
      // this.spinner.show();
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }
  }

  fnDroppedOnBuild(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // comprar de vuelta el edificio
      event.previousContainer.id == "cdkLstCapEx" && jsonDragged.type == 4 && this.status >= 3 && this.currentMonth >= 2 && false
    ) {

      this.socketService.setBlnControlModeOpen('isBuyBuild', true, this.roomId, this.teamId);
      setTimeout(() => {
        this.socketService.setBlnControlModeOpen('showMessageOwe20', true, this.roomId, this.teamId);
        console.log("dropped on trash, from list", event.previousContainer.id)
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        this.fnDroppedPiece(event, jsonDragged);
      }, 750);
    } else if (
      // paso 4 mover de capex a land and build
      this.status == 3 && event.previousContainer.id == "cdkLstCapEx" && jsonDragged.type == 4 && this.cdkLstLandBuild.length == 0 && this.currentMonth == 1
    ) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }


  }

  async fnDroppedOnFinishedGI(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    this.spinner.show()
    const x = await this.fnGetAllOrders(this.workshopId);
    if(x){
      console.log(this.returnTotalLst(this.cdkLstAdvertasing), this.orders, this.arrOrders);
      let countOrders= 0;
      this.arrOrders.forEach(order =>{
        order.Bids.forEach(bid => {
          if(bid.TeamId == this.teamId && bid.Bid>0){
            countOrders++;
          }
        });
      })
      this.spinner.hide();
      console.log(countOrders)
      if(this.returnTotalLst(this.cdkLstAdvertasing) != countOrders && !this.blnFacilitador){
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1011'));
        this.fnStatusPiece(event.container.id, false, jsonDragged);
        return;
      }
    }else{
      console.log("error")
    }
    
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      console.log("AQUI")
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    // Pay advertising The Market  DONE
    if(this.status>=14 && !this.getCheckCheckbox(13).blnCheck && this.getCheckCheckbox(12).blnCheck){
      console.log("Soy este caso")
      this.workshopService.getWorkshopById(this.workshopId).subscribe(response=>{
        console.log(response)
        console.log(response['data'][0].OpenMarket)
        if(response['data'][0].OpenMarket == 2){
          this.checkCheckbox(10,true);
        }
      })
    }
    if(this.showOwedAdvertising){
      console.log("AQUI")
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1011'));
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (this.currentMonth == 2 && this.status == 0) {
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      if ((this.currentMonth === 2 && (this.status >= -1 && this.status <= 4) || !this.isModeOpen)) {
        this.errorControl(event, jsonDragged);
      } else {
        this.errorControl(event, jsonDragged);
      }
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 31 mover los royals a FGI
      (this.status == 31 && event.previousContainer.id == "cdkLstInsideMachineWorkInProgress" && jsonDragged.type == 8 && this.currentMonth == 1 && (this.intOpenMarket == 2 && !this.blnFacilitador || this.blnFacilitador))
    ) {

      jsonDragged.draggable = false;
      jsonDragged.draggingBy = '';
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      console.log("Droppeeeed on finished gI");
      this.fnDroppedPiece(event, jsonDragged)
    } else if ((event.previousContainer.id == "cdkLstInsideMachineWorkInProgress" || event.previousContainer.id == "cdkLstInsideMachineWorkInProgress2") && (jsonDragged.type == 8 || jsonDragged.type == 16 || jsonDragged.type == 17 || jsonDragged.type == 18) && this.currentMonth >= 2 && this.status >= 14) {//Solicitud de bloquear subida a FGI hasta despues del mercado
      this.funcionBack("caseAdvertasting");
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = '';
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      if(this.jsonBlnOpenModeActions.isSecondMachine){
        if(this.cdkLstInsideMachineWorkInProgress.length == 0 && this.cdkLstInsideMachineWorkInProgress2.length == 0){
          // this.checkCheckbox(11, true);
          this.checkCheckbox(14, true);
          //volver a checar si hay ordenes, si no hay ordenes ganadas marcar el checklist de deliver
           this.fnFixCheckAutoDeliverWhenNoOrdersWon();
        }else{
          return;
        }
      }
      if (this.cdkLstInsideMachineWorkInProgress.length == 0) {
        console.log("Es este")
        console.log(this.cdkLstInsideMachineWorkInProgress)
        console.log(this.cdkLstInsideMachineWorkInProgress2)
        console.log(this.cdkLstInsideMachineWorkInProgress3)
        // this.checkBoxes[this.currentMonth>=3?13:11].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        // this.checkCheckbox(11, true);
        this.checkCheckbox(14, true);

        //volver a checar si hay ordenes, si no hay ordenes ganadas marcar el checklist de deliver
         this.fnFixCheckAutoDeliverWhenNoOrdersWon();
         //checar si hubo algun problema con el advertising y cash, y autocorregirlo
        //  this.fnFixAdvertisingCashQuantities();

      }

    } else if (event.previousContainer.id == 'cdkLstCostGS') {
      //this.fnTrowToast("#errorGeneral", "64.- only royals are allowed from the work in progress of the machine");
      this.errorControl(event, jsonDragged);
    } else {
      console.log("AQUI", this.status)
      this.errorControl(event, jsonDragged);
    }
  }

  //linea de movimientos y animacion
  async fnDroppedOnLoans90(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    let checkbox = this.getCheckCheckbox(16);
    // 'Complete the Income Statement' DONE 
    if (checkbox.blnCheck && this.currentMonth >= 2) {
      this.fnTrowToastwithTimeMiliseconds("#errorGeneral", "You can't do this", 3000);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    this.blnAllowAnim = true;
    console.log(jsonDragged);
    //this.itemMove = jsonDragged.id;
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 18 mover de bank a loans o cash
      this.status == 18 && event.previousContainer.id == "cdkLstBank" && jsonDragged.type == 9 && this.cdkLstLoans90.length == 0
    ) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      event.previousContainer.data.splice(this.indexItemDragged, 1);

      this.fnDroppedPieceFromBank(event, jsonDragged, 19, [], []);
      console.log("ADELANTAMOS A PASO 21");
      this.status = 21;
      this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
    } else if (event.previousContainer.id == "cdkLstLoans60" || event.previousContainer.id == "cdkLstLoans30") {
      // Mostrar error
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1025'));
      console.log("Se equivocoooooo");
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else if (event.previousContainer.id == "cdkLstBank" && jsonDragged.type == 9 && this.isModeOpen && this.status >= 2 && this.currentMonth >= 2) {
      // modo open, pidiendo prestado al banco
      /*VAMOS A GUARDAR BACKUP DE 19 POR TOMAR PRESTAMO DESPUES DE PAY EXPENSES Y ANTES DE PASO WIP A FGI */
      //this.checkifNeedbackupCoin(); Nota Checar cuales sería las condiciones para guardar el backup de la moneda ya que en modo open no es posible hacer el fix de advertising y cash
      event.previousContainer.data.splice(this.indexItemDragged, 1);

      const x = await this.fnDroppedPieceFromBank(event, jsonDragged, this.status, ['isBuyLoan', 'showMessageInterest1'], [true, true]);
      this.jsonBlnOpenModeActions.isBuyLoan = true;
      // this.socketService.setBlnControlModeOpen('isBuyLoan', true, this.roomId, this.teamId);
      this.jsonBlnOpenModeActions.showMessageInterest1 = true;
      // this.socketService.setBlnControlModeOpen('showMessageInterest1', true, this.roomId, this.teamId);
      //this.blnMoveMonth2 = true;
      if (this.currentMonth == 2) {
        this.blnMoveMonth2 = true;
      }
      if (this.currentMonth == 3) {
        this.blnMoveMonth3 = true;
      }
      if (this.currentMonth == 4) { this.blnMoveMonth4 = true; }
      if (this.currentMonth == 5) { this.blnMoveMonth5 = true; }
      if (this.currentMonth == 6) { this.blnMoveMonth6 = true; }
      // Pay Expenses DONE
      if (this.getCheckCheckbox(10).blnCheck) {
        // this.checkBoxes[this.currentMonth>=3?9:7].blnCheck = false;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        // this.checkCheckbox(7, false);
        this.checkCheckbox(10, false);
      }
      // setTimeout(() => {
      // }, 500);
    } else if (event.previousContainer.id == "cdkLstBank" && jsonDragged.type == 9 && !this.isModeOpen && this.status >= 2 && this.currentMonth >= 2) {
      // modo riguros, pidiendo prestado al banco
      /*VAMOS A GUARDAR BACKUP DE 19 POR TOMAR PRESTAMO DESPUES DE PAY EXPENSES Y ANTES DE PASO WIP A FGI */
      // this.checkifNeedbackupCoin();
      event.previousContainer.data.splice(this.indexItemDragged, 1);

      const hola = await this.fnDroppedPieceFromBank(event, jsonDragged, this.status, ['isBuyLoan', 'showMessageInterest1'], [true, true]);
      this.jsonBlnOpenModeActions.isBuyLoan = true;
      // this.socketService.setBlnControlModeOpen('isBuyLoan', true, this.roomId, this.teamId);
      this.jsonBlnOpenModeActions.showMessageInterest1 = true;
      // this.socketService.setBlnControlModeOpen('showMessageInterest1', true, this.roomId, this.teamId);
      //this.blnMoveMonth2 = true;
      if (this.currentMonth == 2) {
        this.blnMoveMonth2 = true;
      }
      if (this.currentMonth == 3) {
        this.blnMoveMonth3 = true;
      }
      if (this.currentMonth == 4) { this.blnMoveMonth4 = true; }
      if (this.currentMonth == 5) { this.blnMoveMonth5 = true; }
      if (this.currentMonth == 6) { this.blnMoveMonth6 = true; }
      // Pay Expenses DONE
      if (this.getCheckCheckbox(10).blnCheck) {
        // this.checkBoxes[this.currentMonth>=3?9:7].blnCheck = false;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        // this.checkCheckbox(7, false);
        this.checkCheckbox(10, false);
      }
      // setTimeout(() => {
      // }, 500);
    } else {
      console.log("Se equivocoooooo elseeeeee");
      this.errorControl(event, jsonDragged);
    }
    //this.itemMove = jsonDragged.id;
    //console.log(this.itemMove);
    //console.log(jsonDragged);
    console.log("HERE ENTRO A MOVERLE AL STAND");
    if (this.currentMonth == 2) {
      console.log("HERE ENTRO A MOVERLE AL STAND MES 2");
      //  this.blnMoveMonth2 = true;
    }
  }

  async fnDroppedOnLoans60(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if(jsonDragged.strDescription && jsonDragged.strDescription.includes(`Month: ${this.currentMonth}`)){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('2080'));
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // para paso 2 inicio de mes 2
      this.status == 2 && event.previousContainer.id == "cdkLstLoans90" && jsonDragged.type == 3
    ) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      const x = await this.fnIsReadyPaso3(jsonDragged, "cdkLstLoans90");
      if (x) {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        if (this.cdkLstLoans60.reduce((acc, el) => acc + el.valor, 0) === this.liabilitiesReceivablesBeforeUpdates.liabilities90) {
          this.boardService.clearLiabilitiesReceivablesUpdatesArea(this.workshopId, this.teamId, 'liabilities90').subscribe(rupd => {
            if (rupd.intResponse === 200) {
              this.liabilitiesReceivablesBeforeUpdates = rupd.jsonData;
            }
          });
        }
      this.fnDroppedPiece(event, jsonDragged);
      }
    } else if (event.previousContainer.id == "cdkLstLoans90" || event.previousContainer.id == "cdkLstLoans30") {
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1023'))
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }

  }

  async fnDroppedOnLoans30(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if(jsonDragged.strDescription && jsonDragged.strDescription.includes(`Month: ${this.currentMonth}`)){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('2080'));
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // para paso 1 inicio de mes 2
      this.status == 2 && event.previousContainer.id == "cdkLstLoans60" && jsonDragged.type == 3
    ) {
      const x = await this.fnIsReadyPaso3(jsonDragged, "cdkLstLoans60");
      if (x) {
        //El siguiente if activa las bandera de update liabilities en caso de que no se tengan deudas en el mes
        if (this.cdkLstLoans30Aux.length === 0) {
          this.jsonBlnOpenModeActions.isLoansPaid = true;
          console.log('There is no loan',this.jsonBlnOpenModeActions.isLoansPaid);
          this.socketService.setBlnControlModeOpen('isLoansPaid', true, this.roomId, this.teamId);
        }
        console.log("dropped on trash, from list", event.previousContainer.id)
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        if (this.cdkLstLoans60.length === 0) {
          this.boardService.clearLiabilitiesReceivablesUpdatesArea(this.workshopId, this.teamId, 'liabilities60').subscribe(rupd => {
            if (rupd.intResponse === 200) {
              this.liabilitiesReceivablesBeforeUpdates = rupd.jsonData;
            }
          });
        }
        this.fnDroppedPiece(event, jsonDragged);
      }
    } else if (this.status == 2 && event.previousContainer.id == "cdkLstLoans90") {
      this.fnTrowToast("#errorPaso34", this.getErrorLabel('1024'))
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else if (event.previousContainer.id == "cdkLstLoans60" || event.previousContainer.id == "cdkLstLoans90") {
      this.fnTrowToast("#errorPaso34", this.getErrorLabel('1023'))
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }
  }

  async fnDroppedOnCash(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    console.log(jsonDragged);
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    //this.blnAllowAnim = true;
    console.log("dropped on cash", jsonDragged.type, jsonDragged.id);
    console.log("dropped on cash", event.previousContainer.id);
    console.log("dropped on cash", event.previousContainer.data[this.indexItemDragged].id);
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 1 mover de trash a cash
      (this.status == 0 && event.previousContainer.id == "cdkLstTrashCash" && jsonDragged.type == 1)
    ) {
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = '';
      console.log("Correctooooooo!!");
      transferArrayItem(
        this.cdkLstTrashCash,
        this.cdkLstCash,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      setTimeout(() => {
        this.fnasignaposicionContainerCorrecto(jsonDragged.id);
      }, 200);

    } else if (
      // mes 2 mover el dinero que aparece cuando vendes el edificio
      (event.previousContainer.id == "cdkLstCapEx" && jsonDragged.type == 1 && this.currentMonth >= 2)
    ) {
      /*VAMOS A GUARDAR BACKUP DE 19 POR GANAR CASH DE VENTA EDIFICIO DESPUES DE PAY EXPENSES Y ANTES DE PASO WIP A FGI */
      // this.checkifNeedbackupCoin();

      jsonDragged.draggable = false;
      jsonDragged.draggingBy = '';
      
      transferArrayItem(
        this.cdkLstCapEx,
        this.cdkLstCash,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      // setTimeout(() => {
      //   this.fnasignaposicionContainerCorrecto(jsonDragged.id);
      //     }, 200);

    } else if (
      // recibir dinero de receivables de 30 days
      (event.previousContainer.id == "cdkLstReceivables30" && jsonDragged.type == 1 && this.currentMonth >= 2 && this.status == 1)
    ) {
      let mesagges = [
        this.getErrorLabel('1055'),
        this.getErrorLabel('1056'),
        this.getErrorLabel('1057'),
      ]
      this.fnTrowToast('#errorGeneral', mesagges[Math.floor(Math.random() * 3)]);
      console.log("dropped on trash, from list", event.previousContainer.id)
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = '';
      // this.cdkLstReceivables30Aux.splice(this.indexItemDragged, 1);
      const x = await this.fnIsReadyPaso2(jsonDragged, 'cdkLstCash');
      if (x) {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        if (this.cdkLstReceivables30.length === 0) {
          this.boardService.clearLiabilitiesReceivablesUpdatesArea(this.workshopId, this.teamId, 'receivables30').subscribe(rupd => {
            if (rupd.intResponse === 200) {
              this.liabilitiesReceivablesBeforeUpdates = rupd.jsonData;
            }
          });
        }
        this.fnDroppedPiece(event, jsonDragged);
      }
    } else if (
      // para alerta de erro cuando mueve de receivables a cash
      this.status == 1 && (event.previousContainer.id == "cdkLstReceivables60" || event.previousContainer.id == "cdkLstReceivables90")
    ) {
      this.errorControl(event, jsonDragged);
      // this.cdkLstReceivables60
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else if (
      // para error cuando mueve de loans a cash antes de actualizar
      this.status <= 2 && (
        event.previousContainer.id == "cdkLstLoans90" || event.previousContainer.id == "cdkLstLoans60" ||
        event.previousContainer.id == "cdkLstLoans30" || event.previousContainer.id == "cdkLstPayables30" ||
        event.previousContainer.id == "cdkLstPayables60")
    ) {
      this.fnTrowToast("#errorPaso34", this.getErrorLabel('4006'));
      // this.cdkLstReceivables60
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else if (
      ((event.previousContainer.id == "cdkLstLoans30" || event.previousContainer.id == "cdkLstLoans60" ||
        event.previousContainer.id == "cdkLstLoans90" || event.previousContainer.id == "cdkLstPayables30" ||
        event.previousContainer.id == "cdkLstPayables60")
      )) {
      await this.fnTrowSwalPayLoans(jsonDragged, event.previousContainer.id);
    } else if (
      event.previousContainer.id == "cdkLstAdvertasing" && jsonDragged.type == 1 && this.cdkLstAdvertasing.length > 1 && this.status >= 3
    ) {
      this.auxValTotalAdvertising = this.auxValTotalAdvertising - jsonDragged.valor
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.expandsAdvertising = false;
      this.fnDroppedPiece(event, jsonDragged);
      this.cdkLstAdvertasing = [{
        valor: this.cdkLstAdvertasing[0].valor,
        type: this.cdkLstAdvertasing[0].type,
        id: this.cdkLstAdvertasing[0].id,
        draggable: this.cdkLstAdvertasing[0].draggable,
        // draggingBy: "Advertising: " + this.auxValTotalAdvertising + ' Brand Awareness: ' + this.AdvertisingExtra
        draggingBy: "Advertising: " + this.auxValTotalAdvertising
      }]
      this.socketService.splitCoin(this.cdkLstAdvertasing, 'cdkLstAdvertasing', this.roomId, this.teamId);
      this.socketService.splitCoin(this.cdkLstCash, 'cdkLstCash', this.roomId, this.teamId);
      this.boardService.updateBillStatus(this.IdStatusBid, this.teamId, 1, this.auxValTotalAdvertising).subscribe(response => {
        console.log(response)
      });
    } else {
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      this.errorControl(event, jsonDragged);
    }
    this.indexItemDragged = -1;
  }

  fnDroppedOnReceivables90(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    console.log(jsonDragged);

    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (this.status == 50) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    }

  }

  async fnDroppedOnReceivables60(event: CdkDragDrop<any>) {
    this.spinner.show();
    console.log(this.totCdkLstReceivables30,this.totCdkLstReceivables60,this.totCdkLstReceivables90)
    console.log(this.cdkLstReceivables30,this.cdkLstReceivables60,this.cdkLstReceivables90)
    console.log(this.cdkLstReceivables30Anim,this.cdkLstReceivables60Anim,this.cdkLstReceivables90Anim)
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      this.spinner.hide();

      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      this.spinner.hide();

    } else if (
      // para paso 1 inicio de mes 3
      this.status == 1 && event.previousContainer.id == "cdkLstReceivables90" && jsonDragged.type == 1
    ) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = '';
      const x = await this.fnIsReadyPaso2(jsonDragged, 'cdkLstReceivables60');
      if (x) {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        if (this.cdkLstReceivables90.length === 0) {
          this.boardService.clearLiabilitiesReceivablesUpdatesArea(this.workshopId, this.teamId, 'receivables90').subscribe(rupd => {
            if (rupd.intResponse === 200) {
              this.liabilitiesReceivablesBeforeUpdates = rupd.jsonData;
            }
          });
        }
        this.fnDroppedPiece(event, jsonDragged);
      }else{
        this.spinner.hide()
      }
    } else {
      this.spinner.hide();

      this.errorControl(event, jsonDragged);
    }
    this.indexItemDragged = -1;

    // this.fnDroppedPiece(event, jsonDragged);
  }

  async fnDroppedOnReceivables30(event: CdkDragDrop<any>) {
    this.spinner.show();
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      this.spinner.hide();
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      this.spinner.hide();

    } else if (
      // para paso 1 inicio de mes 2
      this.status == 1 && event.previousContainer.id == "cdkLstReceivables60" && jsonDragged.type == 1
    ) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = '';
      const x = await this.fnIsReadyPaso2(jsonDragged, 'cdkLstReceivables30');
      if (x) {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        if (this.cdkLstReceivables60.length === 0) {
          this.boardService.clearLiabilitiesReceivablesUpdatesArea(this.workshopId, this.teamId, 'receivables60').subscribe(rupd => {
            if (rupd.intResponse === 200) {
              this.liabilitiesReceivablesBeforeUpdates = rupd.jsonData;
            }
          });
        }
        this.fnDroppedPiece(event, jsonDragged);
      }else{
        this.spinner.hide()

      }
    } else {
      this.spinner.hide();
      this.errorControl(event, jsonDragged);
    }
  }
  //////fnDroppedOnCapitalS
  fnDroppedOnCapitalS(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // para paso 2
      this.status == 1 && event.previousContainer.id == "cdkLstTrashCapitalS" && jsonDragged.type == 2
    ) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }

  }
  ///validate correct status
  async fnDroppedOnRetainedE(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    // console.log(event.previousContainer);
    if(event.previousContainer.data[this.indexItemDragged].type != 2 || event.previousContainer.id != "cdkLstNetIncome"){ //Era posible mover monedas cash a RT y desbalansear el BS - eso es incorrecto
      let target = "#errorGeneral";
      let text = this.getErrorLabel('1001');
      this.fnTrowToast(target, text);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    }
    else if (
      // para paso 35, mover de net income a retained earnings
      this.status == 35 && event.previousContainer.id == "cdkLstNetIncome" && jsonDragged.valor >= 13 && this.boardMode == "NM"
    ) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // para paso 35, mover de net income a retained earnings
      this.status == 35 && event.previousContainer.id == "cdkLstNetIncome" && this.boardMode == "DM"
    ) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (this.currentMonth >= 2 && ((this.isModeOpen && this.status >= 3) || (!this.isModeOpen && this.status >= 15)) && this.getCheckCheckbox(16).blnCheck) {
      // modo open
      this.spinner.show('my-custom-spinner')
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      setTimeout(() => {
        this.spinner.hide('my-custom-spinner')
      }, 800);

      if (this.cdkLstNetIncome.length == 0) {
        // checkear paso de adjust equity
      this.checkCheckbox(17, true);
      setTimeout(() => {
      let totRetained = 0;
      let package20 = 0;
      let packageLess20 = 0;
      this.cdkLstRetainedE.forEach(item => {
        totRetained += item.valor;
      });
      const isNegative = totRetained < 0;
      package20 = Math.abs(Math.floor(totRetained / (isNegative ? -20 : 20)));
      packageLess20 = totRetained % 20;

      this.cdkLstRetainedE = [];
      for (let i = 0; i < package20; i++) {
        this.cdkLstRetainedE.push({
          type: 2,
          valor: isNegative ? -20 : 20,
          id: null,
          draggable: false,
          draggingBy: ""
        });
      }
      if (packageLess20 > 0 || packageLess20 < 0) {
        this.cdkLstRetainedE.push({
          type: 2,
          valor: packageLess20,
          id: null,
          draggable: false,
          draggingBy: ""
        })
      }
      this.socketService.splitCoin(this.cdkLstRetainedE, 'cdkLstRetainedE', this.roomId, this.teamId);

      }, 300);
      
      }
    } else {
      this.errorControl(event, jsonDragged);
    }

  }

  fnDroppedOnNetIncome(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (this.currentMonth >= 2) {
      console.log("dropped on trash, from list", event.previousContainer.id)
      // transferArrayItem(
      //   event.previousContainer.data,
      //   event.container.data,
      //   this.indexItemDragged,
      //   event.currentIndex
      // );
      // this.fnDroppedPiece(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      this.errorControl(event, jsonDragged);
    } else {
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    }

  }

  fnDroppedOnTaxes(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    }
  }

  fnDroppedOnIncomeBT(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    }

  }

  fnDroppedOnInterest(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    const initialContainerId = event.previousContainer.id;
    if (event.previousContainer.data[this.indexItemDragged].type == 6) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 19 pagar 1 de interes
      (this.status == 19 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && jsonDragged.valor == 1)
    ) {
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && jsonDragged.valor == 1 && this.jsonBlnOpenModeActions.isBuyLoan && this.currentMonth >= 2) {
      // modo open, pagando 1 de interes
      this.isHintInterest = 0;
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      this.jsonBlnOpenModeActions.isBuyLoan = false;
      this.socketService.setBlnControlModeOpen('isBuyLoan', false, this.roomId, this.teamId);
      setTimeout(() => {
        this.socketService.setBlnControlModeOpen('showMessageInterest1', false, this.roomId, this.teamId);
        this.jsonBlnOpenModeActions.showMessageInterest1 = false;
      }, 500);
      // Pay Expenses - Pagué 1 en interest DONE
      if (this.fnisReadyPaso8() && !this.getCheckCheckbox(10).blnCheck) {
        // this.checkBoxes[this.currentMonth>=3?9:7].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        // this.checkCheckbox(7, true);
        this.checkCheckbox(10, true);
        console.log("HACER BACKUP")
        this.funcionBack("casePrestamo");

        //guardamos backup cuando el check se marque para volver a guardar la cantidad correcta de cash
        // this.fnSaveBackQuantities();
      }
    } else {
      if (initialContainerId === 'cdkLstCash') {
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1005'));
      } else {
        this.errorControl(event, jsonDragged);
      }
    }
  }

  fnDroppedOnRent(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    jsonDragged.draggable = false;
    jsonDragged.draggingBy = "";
    console.log(this.cdkLstRent);
    if(this.cdkLstRent.length > 0){
      return;
    }
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.isBuyLoan && !this.isModeOpen) {
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // modo open, pagar renta
      (this.isModeOpen && this.status >= 3 && this.jsonBlnOpenModeActions.isSoldBuild && event.previousContainer.id == 'cdkLstCash' && jsonDragged.valor == 1 && jsonDragged.type == 1)
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      // checkear el paso 8 pay expenses DONE
      if (this.fnisReadyPaso8() && !this.getCheckCheckbox(10).blnCheck) {
        this.fnDroppedPiece(event, jsonDragged);
        // this.checkCheckbox(7, true);
        this.checkCheckbox(10, true);
        //guardamos backup cuando el check se marque para volver a guardar la cantidad correcta de cash
        // this.fnSaveBackQuantities();
        console.log("HACER BACKUP")
        this.funcionBack("caseEdificio");

        // this.status = 10;
        // this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
      } else {
        if (event.container.data[event.currentIndex])
          event.container.data[event.currentIndex].draggingBy = "";
        this.socketService.dropACoin(
          event.previousContainer.id,
          event.container.id,
          jsonDragged,
          this.indexItemDragged,
          this.roomId,
          this.userId,
          this.userData.FirstName,
          this.teamId,
          this.status
        );
        this.socketService.disablePiece(event.container.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
        if (this.currentMonth >= 2 && (event.container.id == 'cdkLstInsideMachineCapex2' || event.container.id == 'cdkLstInterest' || event.container.id == 'cdkLstCash' || event.container.id == 'cdkLstRecruitmentT' || (event.container.id == 'cdkLstLabourP' && jsonDragged.type == 6))) {
          return;
        }
      }
    } else if (
      // modo riguroso, pagar renta
      (!this.isModeOpen && this.status >= 3 && this.jsonBlnOpenModeActions.isSoldBuild && event.previousContainer.id == 'cdkLstCash' && jsonDragged.valor == 1 && jsonDragged.type == 1)
    ) { 
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      // checkear el paso 8 pay expenses DONE
      if (this.fnisReadyPaso8() && !this.getCheckCheckbox(10).blnCheck) {
        this.fnDroppedPiece(event, jsonDragged);
        // this.checkCheckbox(7, true);
        this.checkCheckbox(10, true);
        console.log("HACER BACK UP")
        this.funcionBack("caseEdificio");

        //guardamos backup cuando el check se marque para volver a guardar la cantidad correcta de cash
        // this.fnSaveBackQuantities();
        // this.status = 10;
        // this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
      } else {
        if (event.container.data[event.currentIndex])
          event.container.data[event.currentIndex].draggingBy = "";
        this.socketService.dropACoin(
          event.previousContainer.id,
          event.container.id,
          jsonDragged,
          this.indexItemDragged,
          this.roomId,
          this.userId,
          this.userData.FirstName,
          this.teamId,
          this.status
        );
        this.socketService.disablePiece(event.container.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
        if (this.currentMonth >= 2 && (event.container.id == 'cdkLstInsideMachineCapex2' || event.container.id == 'cdkLstInterest' || event.container.id == 'cdkLstCash' || event.container.id == 'cdkLstRecruitmentT' || (event.container.id == 'cdkLstLabourP' && jsonDragged.type == 6))) {
          return;
        }
      }
    } else {
      this.errorControl(event, jsonDragged);
    }

  }

  fnDroppedOnRecuitmentT(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 3 && this.jsonBlnOpenModeActions['showBill1'] && jsonDragged.type == 1 && jsonDragged.valor == 1) {
      console.log("Aqui")
      if (!(this.thereAreStack(this.cdkLstChangeI, 12) || this.thereAreStack(this.cdkLstAdministration, 12) || this.thereAreStack(this.cdkLstMarketingS, 12))) {
      console.log("Aqui")
       
        this.errorControl(event, jsonDragged);
        this.fnStatusPiece(event.container.id, false, jsonDragged);
        return;
      }
      console.log("ES QUALIYYYYYYYYYY")
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      console.log("dropped on trash, from list", event.previousContainer.id)
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      this.socketService.setBlnControlModeOpen('isActiveImprovement', false, this.roomId, this.teamId);
        this.jsonBlnOpenModeActions.isActiveImprovement = false;
      this.jsonBlnOpenModeActions['showBill1'] = false;
      this.socketService.setBlnControlModeOpen('showBill1', false, this.roomId, this.teamId);
      this.spinner.show('my-custom-spinner')
      //Deprecado nuevos checkboxes
      // setTimeout(() => {
      //   if(this.checkBoxes[4].title == "Buy or Upgrade Equipment"){
      //     this.checkBoxes[5].blnCheck = true;
      //     this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      //   }else{
      //     this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = true;
      //     this.fnScrollCheckList(this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4]);      
      //     this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      //   }
      //   this.spinner.hide('my-custom-spinner')
      // }, 1000);
      setTimeout(() => {
        this.checkCheckbox(5,true);
      this.spinner.hide('my-custom-spinner')    
      }, 500);
      
    } else if (event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 3 && this.jsonBlnOpenModeActions.showBill6 && jsonDragged.type == 1 && jsonDragged.valor == 6) {
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      this.spinner.show('my-custom-spinner')
      setTimeout(() => {
        this.jsonBlnOpenModeActions.isActiveImprovement = false;
        this.socketService.setBlnControlModeOpen('isActiveImprovement', false, this.roomId, this.teamId);
        this.jsonBlnOpenModeActions['showBill6'] = false;
        this.socketService.setBlnControlModeOpen('showBill6', false, this.roomId, this.teamId);
        setTimeout(async () => {
          // this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 4 : 5].blnCheck = true;
          // this.fnScrollCheckList(this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 4 : 5]);
          // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          // this.spinner.hide('my-custom-spinner')
            this.checkCheckbox(5,true);
            this.spinner.hide('my-custom-spinner')    
          if (
            this.jsonBlnOpenModeActions.isRawMaterials1 ||
            ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 1) ||
            ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 1)) {
            // const x = await this.implementExpansionWithRawMaterial(!this.jsonBlnOpenModeActions.isRawMaterials1?1:2);
            this.socketService.implementExpansionWithRawMaterial(this.roomId, this.teamId, !this.jsonBlnOpenModeActions.isRawMaterials1 ? 1 : 2);
            this.spinner.show();
          } else if (
            this.jsonBlnOpenModeActions.isEfficiency1 ||
            ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 2) ||
            ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 2)) {
            // const x = await this.implementExpansionWithEfficiency(!this.jsonBlnOpenModeActions.isEfficiency1?1:2);
            this.spinner.show();
            this.socketService.implementExpansionWithEfficiency(this.roomId, this.teamId, !this.jsonBlnOpenModeActions.isEfficiency1 ? 1 : 2)
          } else if (
            (((this.improvementsOptionsSelected[0].value == 1 && this.improvementsOptionsSelected[0].option != 6) || this.improvementsOptionsSelected[0].value == 2)) ||
            (((this.improvementsOptionsSelected[1].value == 1 && this.improvementsOptionsSelected[1].option != 6) || this.improvementsOptionsSelected[1].value == 2))
          ) {
            if(this.improvementsOptionsSelected[1].Approved == 1 || this.improvementsOptionsSelected[0].Approved == 1){
              return;
            }
            this.socketService.askImprovement(this.roomId, this.teamId);
            this.spinner.show();
          }
        }, 500);
      }, 500);
    } else {
      console.log("Aqui")

      this.errorControl(event, jsonDragged);
    }

  }

  async fnDroppedOnAdminOver(event: CdkDragDrop<any>) {
    if(this.blnGrowNocontrol){
      return;
    }
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    jsonDragged.draggable = false;
    jsonDragged.draggingBy = "";
    if (this.jsonBlnOpenModeActions.showMessageInterest1) {
      console.log("AQUI")
      this.isHintInterest++;
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1005'));
      return;
    }
    if(this.status == 9 && this.cdkLstRent.length == 0 && this.jsonBlnOpenModeActions.isSoldBuild){
      console.log("Pay rent first")
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1059'));

      return
    }
    //Depreciation DONE
    if((this.status == 9 || this.status == 8) && !this.getCheckCheckbox(9).blnCheck){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('4015','2022'));
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 28 pagarle a la persona de admin overhead
      (this.status == 28 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1)
    ) {
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        console.log("indefinidoooooos")
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        event.previousContainer.data.splice(this.indexItemDragged, 1)
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId, this.userId);
      }
    } else if (this.isModeOpen && jsonDragged.type == 1 && this.status >= 3 && event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 2) {
      // modo abierto, vamos a pagar a las personas
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId,this.userId);
      }


    } else if (!this.isModeOpen && jsonDragged.type == 1 && this.status >= 8 && event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 2) {
      // modo riguroso, vamos a pagar a las personas
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId,this.userId);
      }
    } else {
      this.errorControl(event, jsonDragged);
    }

  }

  async fnDroppedOnAdministration(event: CdkDragDrop<any>) {
    if(this.blnGrowNocontrol){
      return;
    }
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    jsonDragged.draggable = false;
    jsonDragged.draggingBy = "";
    if (this.jsonBlnOpenModeActions.showMessageInterest1) {
      console.log("AQUI")
      this.isHintInterest++;
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1005'));
      return;
    }
    if(this.status == 9 && this.cdkLstRent.length == 0 && this.jsonBlnOpenModeActions.isSoldBuild){
      console.log("Pay rent first")
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1059'));
      return
    }
    //Depreciation DONE
    if((this.status == 9 || this.status == 8) && !this.getCheckCheckbox(9).blnCheck){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('4015','2022'));
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 13 mover una persona de labour pool a administration
      (this.status == 13 && event.previousContainer.id == "cdkLstLabourP" && jsonDragged.type == 6 && jsonDragged.valor == 1 && this.cdkLstAdministration.length == 0)
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // paso 28 pagarle a la persona de administracion
      (this.status == 28 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1)
    ) {
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        console.log("indefinidoooooos")
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        event.previousContainer.data.splice(this.indexItemDragged, 1)
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId, this.userId);
      }
    } else if (this.isModeOpen && this.status >= 3 && jsonDragged.type == 1 && event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 2) {
      // modo abierto, vamos a pagar a las personas
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId, this.userId);
      }


    } else if (!this.isModeOpen && jsonDragged.type == 1 && this.status >= 8 && event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 2) {
      // modo riguroso, vamos a pagar a las personas
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId, this.userId);
      }
    } else if (
      // mover a monito de qualitya a change
      ((this.status == 3 && !this.isModeOpen || this.isModeOpen) && event.previousContainer.id == "cdkLstLabourP" && jsonDragged.type == 12 && jsonDragged.valor == 1 && this.currentMonth >= 3)
    ) {

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }

  }

  fnDroppedOnAdvertising(event: CdkDragDrop<any>) {
    // console.log(this.isOpenMarket, 'Estatus de mercado')
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    // Pay Advertising
    console.log(this.advertising,!this.blnFacilitador,this.owesAdvertising,this.advertising, this.showOwedAdvertising);
    if(this.status <= 9 ){
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      this.errorControl(event, jsonDragged);
      return; 
    }
    if (this.advertising == 0 && !this.blnFacilitador) {
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      this.errorControl(event, jsonDragged);
      return;
    }
    const totAdvertising = this.returnTotalLst(this.cdkLstAdvertasing);
    if (event.previousContainer === event.container) {
      console.log('If')
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 30 pagar advertising
      (this.status == 30 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && (jsonDragged.valor == 2 && this.boardMode == "NM" || this.boardMode == "DM" && (jsonDragged.valor == this.owesAdvertising || this.blnFacilitador)) && ((this.advertising >= 1) || this.blnFacilitador))
      // (this.status == 30 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && (jsonDragged.valor == 2 && this.boardMode == "NM" || this.boardMode == "DM" && (jsonDragged.valor == this.owesAdvertising || this.blnFacilitador)) && ((this.advertising >= 1 && this.intOpenMarket == 2) || this.blnFacilitador))
    ) {
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      this.showOwedAdvertising = false;
      this.showPaidAdvertising = true;
    } else if (
      (this.isModeOpen || (!this.isModeOpen && this.status >= 8)) &&
      this.status >= 3 && event.previousContainer.id == 'cdkLstCash' && jsonDragged.type == 1
      && (!this.getCheckCheckbox(12).blnCheck && !(totAdvertising >= this.advertising)) && this.currentMonth >= 2) {
      // Pay Advertising DONE
      if (this.showOwedAdvertising) {
        if (jsonDragged.valor != this.owesAdvertising) {
          if (jsonDragged.valor > this.owesAdvertising) {
            this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'));
          } else {
            this.fnTrowToast('#errorGeneral', this.getErrorLabel('1096')); // not enough
          }
          this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
        } else {
          jsonDragged.draggable = false;
          jsonDragged.draggingBy = "";
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            this.indexItemDragged,
            event.currentIndex
          );
          // this.fnDroppedPiece(event, jsonDragged);
          this.socketService.deleteCoin('cdkLstCash', jsonDragged, this.roomId, this.teamId);
          let total = 0
          this.cdkLstAdvertasing.forEach(function (item, index, object) {
            if (item.type === 1) {
              total = total + item.valor
            }
          });
          this.auxValTotalAdvertising = total - this.AdvertisingExtra
          this.cdkLstAdvertasing = [{
            valor: this.auxValTotalAdvertising + this.AdvertisingExtra,
            type: 1,
            id: null,
            draggable: false,
            // draggingBy: "Advertising: " + this.auxValTotalAdvertising + ' Brand Awareness: ' + this.AdvertisingExtra
            draggingBy: "Advertising: " + this.auxValTotalAdvertising
          }];
          setTimeout(() => {
            this.socketService.splitCoin(this.cdkLstAdvertasing, 'cdkLstAdvertasing', this.roomId, this.teamId);
          }, 500);
          this.showOwedAdvertising = false
          this.showPaidAdvertising = true
          // this.checkBoxes[this.currentMonth>=3?11:9].blnCheck = true;
          // this.checkCheckbox(9, true);
          this.checkCheckbox(12, true);
          setTimeout(() => {
            if(!this.getCheckCheckbox(11).blnCheck){
              this.checkCheckbox(11,true);
            }
          }, 500);
          setTimeout(() => {
            if(this.status>=14 && !this.getCheckCheckbox(13).blnCheck){
              this.checkCheckbox(13,true);
  
            }
          }, 1000);
          

          this.boardService.updateBillStatus(this.IdStatusBid, this.teamId, 1, this.auxValTotalAdvertising).subscribe(response => {
            console.log(response)
          });
          setTimeout(() => {
            this.showPaidAdvertising = false
          }, 3000);
        }
      } else {//owesAdvertising
        this.isOpenMarket = true;
        jsonDragged.draggable = false;
        jsonDragged.draggingBy = "";
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        this.socketService.deleteCoin('cdkLstCash', jsonDragged, this.roomId, this.teamId);
        // this.fnDroppedPiece(event, jsonDragged);
        let total = 0
        this.cdkLstAdvertasing.forEach(function (item, index, object) {
          if (item.type === 1) {
            total = total + item.valor
          }
        });
        this.auxValTotalAdvertising = this.auxValTotalAdvertising + jsonDragged.valor
        this.cdkLstAdvertasing = [
          {
            valor: total,
            type: 1,
            id: null,
            draggable: false,
            // draggingBy: "Advertising: " + this.auxValTotalAdvertising + ' Brand Awareness: ' + this.AdvertisingExtra
            draggingBy: "Advertising: " + this.auxValTotalAdvertising
          }];
        this.boardService.updateBillStatus(this.IdStatusBid, this.teamId, 1, this.auxValTotalAdvertising).subscribe(response => {
          console.log(response)
        });
        setTimeout(() => {
          this.socketService.splitCoin(this.cdkLstAdvertasing, 'cdkLstAdvertasing', this.roomId, this.teamId);
        }, 500);
      }
      // this.socketService.splitCoin(this.cdkLstAdvertasing, 'cdkLstAdvertasing', this.roomId, this.teamId);
    } else if (
      (this.isModeOpen || (!this.isModeOpen && this.status >= 8)) &&
      this.status >= 3 && event.previousContainer.id == 'cdkLstCash' &&
      jsonDragged.type == 1 && (this.getCheckCheckbox(12).blnCheck || totAdvertising >= this.advertising) && this.isOpenMarket != false && this.currentMonth >= 2) {
      //brand awarness DONE
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      this.errorControl(event, jsonDragged);
      return;
      // // Swal.fire({
      // //   title: 'Confirm',
      // //   html: '<p>79.- Do you mean to build Brand Awareness? <br/> (this action cannot be undone)</p>',
      // //   showCancelButton: true,
      // //   confirmButtonColor: '#5EA0CC',
      // //   cancelButtonColor: '#CCCCCC',
      // //   confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
      // //   cancelButtonText: 'Cancel',
      // // }).then((value) => {
      // //   if (value.isConfirmed) {
      //     jsonDragged.draggable = false;
      //     transferArrayItem(
      //       event.previousContainer.data,
      //       event.container.data,
      //       this.indexItemDragged,
      //       event.currentIndex
      //     );
      //     this.socketService.deleteCoin('cdkLstCash', jsonDragged, this.roomId, this.teamId);
      //     // this.fnDroppedPiece(event, jsonDragged);
      //     this.AdvertisingExtra = this.AdvertisingExtra + jsonDragged.valor
      //     let total = 0
      //     let list = []
      //     list = this.cdkLstAdvertasing
      //     this.cdkLstAdvertasing.forEach(function (item, index, object) {
      //       if (item.type === 1) {
      //         total = total + item.valor
      //       }
      //     });
      //     this.cdkLstAdvertasing = [
      //       {
      //         valor: total,
      //         type: 1,
      //         id: null,
      //         draggable: false,
      //         // draggingBy: "Advertising: " + this.auxValTotalAdvertising + ' Brand Awareness: ' + this.AdvertisingExtra
      //         draggingBy: "Advertising: " + this.auxValTotalAdvertising
      //       }];
      //     this.boardService.updateBillStatus(this.IdStatusBid, this.teamId, 1, this.auxValTotalAdvertising).subscribe(response => {
      //       console.log(response)
      //     });
      //     this.socketService.splitCoin(this.cdkLstAdvertasing, 'cdkLstAdvertasing', this.roomId, this.teamId);
      //   //}
      // // });
    } else {
      console.log('PPPPPPP', this.isModeOpen, event.previousContainer, jsonDragged.type)
      this.errorControl(event, jsonDragged);
    }


  }

  async fnDroppedOnMarketing(event: CdkDragDrop<any>) {
    if(this.blnGrowNocontrol){
      return;
    }
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    jsonDragged.draggable = false;
    jsonDragged.draggingBy = "";
    if (this.jsonBlnOpenModeActions.showMessageInterest1) {
      console.log("AQUI")
      this.isHintInterest++;
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1005'));
      return;
    }
    if(this.status == 9 && this.cdkLstRent.length == 0 && this.jsonBlnOpenModeActions.isSoldBuild){
      console.log("Pay rent first")
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1059'));

      return
    }
    // Take depreciation DONE
    if((this.status == 9 || this.status == 8) && !this.getCheckCheckbox(9).blnCheck){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('4015','2022'));
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 13 mover una persona de labour pool a marketing
      (this.status == 13 && event.previousContainer.id == "cdkLstLabourP" && jsonDragged.type == 6 && jsonDragged.valor == 1 && this.cdkLstMarketingS.length == 0)
    ) {

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // paso 28 pagarle a la persona de administracion
      (this.status == 28 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1)
    ) {
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        console.log("indefinidoooooos")
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        event.previousContainer.data.splice(this.indexItemDragged, 1)
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId, this.userId);
      }
    } else if (this.isModeOpen && this.status >= 3 && jsonDragged.type == 1 && event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 2) {
      // modo abierto, vamos a pagar a las personas
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId, this.userId);
      }


    } else if (!this.isModeOpen && jsonDragged.type == 1 && this.status >= 8 && event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 2) {
      // modo riguroso, vamos a pagar a las personas
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId, this.userId);
      }
    } else if (
      // mover a monito de qualitya a change
      ((this.status == 3 && !this.isModeOpen || this.isModeOpen) && event.previousContainer.id == "cdkLstLabourP" && jsonDragged.type == 12 && jsonDragged.valor == 1 && this.currentMonth >= 3)
    ) {

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }

  }

  async fnDroppedOnChangeI(event: CdkDragDrop<any>) {
    if(this.blnGrowNocontrol){
      return;
    }
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    jsonDragged.draggable = false;
    jsonDragged.draggingBy = "";
    console.log("en change inovation", jsonDragged);
    if (this.jsonBlnOpenModeActions.showMessageInterest1) {
      console.log("AQUI")
      this.isHintInterest++;
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1005'));
      return;
    }
    if(this.status == 9 && this.cdkLstRent.length == 0 && this.jsonBlnOpenModeActions.isSoldBuild){
      console.log("Pay rent first")
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1059'));

      return
    }
    //DONE
    if((this.status == 9 || this.status == 8) && !this.getCheckCheckbox(9).blnCheck){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('4015','2022'));
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 13 mover una persona de labour pool a change Inovation
      (this.status == 13 && event.previousContainer.id == "cdkLstLabourP" && jsonDragged.type == 6 && jsonDragged.valor == 1 && this.cdkLstChangeI.length == 0)
    ) {

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // paso 28 pagarle a la persona de administracion
      (this.status == 28 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1)
    ) {
      // event.container.id = "payAcumanGrossProfit";
      // this.fnDroppedPiece(event, jsonDragged);
      // this.socketService.splitCoin([], 'payAcumanGrossProfit', this.roomId, this.teamId);
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        console.log("indefinidoooooos")
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        event.previousContainer.data.splice(this.indexItemDragged, 1)
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId, this.userId);
      }
    } else if (this.isModeOpen && this.status >= 3 && jsonDragged.type == 1 && event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 2) {
      // modo abierto, vamos a pagar a las personas
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        console.log("indefinidoooooos")
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId, this.userId);
      }
    } else if (!this.isModeOpen && jsonDragged.type == 1 && (this.status >= 8) && event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 2) {
      // modo riguroso, vamos a pagar a las personas
      let inf = await this.fnGetListToPaySGA(jsonDragged.valor);
      console.log("vamos a pagar a:", inf[0], inf[1]);
      if (!inf[0] || !inf[1]) {
        console.log("indefinidoooooos")
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'))
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
      } else {
        this.socketService.payGrossProfit(inf[0], inf[1], jsonDragged, this.roomId, this.teamId, this.userId);
      }
    } else if (
      // mover a monito de qualitya a change
      ((this.status == 3 && !this.isModeOpen || this.isModeOpen) && event.previousContainer.id == "cdkLstLabourP" && jsonDragged.type == 12 && jsonDragged.valor == 1 && this.currentMonth >= 3)
    ) {

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }
  }

  fnDroppedOnDepreciation(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if(jsonDragged.valor > 1){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1007'));
      return;
    }
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      console.log("here")
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if(this.currentMonth >= 3 && this.jsonBlnOpenModeActions.isSecondMachine && this.cdkLstDeprecation.length >= 2){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1007'));
      return
    }
    if(this.currentMonth >= 2 && !this.jsonBlnOpenModeActions.isSecondMachine && this.cdkLstDeprecation.length >= 1){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1007'));
      return
    }
    if(this.currentMonth >= 3 && this.jsonBlnOpenModeActions.isSecondMachine && this.status >=8){ //card523
      if(!this.getCheckCheckbox(8).blnCheck){
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1033'));
        return;
      }
    }
    console.log(this.getCheckCheckbox(6).blnCheck)
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 25 pagar depreciacion de la maquina
      (this.status == 25 && event.previousContainer.id == "cdkLstInsideMachineCapex" && jsonDragged.type == 4 && jsonDragged.valor == 1) ||
      (this.isModeOpen && jsonDragged.type == 4 && jsonDragged.valor == 1 && this.status >= 3)
    ) {
      if (event.previousContainer.id == "cdkLstInsideMachineCapex") {
        this.jsonBlnOpenModeActions.isDepreciateMachine1 = true;
        this.socketService.setBlnControlModeOpen('isDepreciateMachine1', true, this.roomId, this.teamId);
      } else if (event.previousContainer.id == "cdkLstInsideMachineCapex2") {
        this.jsonBlnOpenModeActions.isDepreciateMachine2 = true;
        this.socketService.setBlnControlModeOpen('isDepreciateMachine2', true, this.roomId, this.teamId);
      }
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      if (this.currentMonth >= 2) {
        // checkear el paso 7 del checklist
        // this.checkBoxes[this.currentMonth>=3?8:6].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        if (this.currentMonth >= 3 && this.jsonBlnOpenModeActions.isSecondMachine) {
          let totDep = 0;
          this.cdkLstDeprecation.forEach(item => {
            totDep += item.valor;
          });
          if (totDep >= 2) {
            this.checkCheckbox(6, true);
          }
        } else {
          this.checkCheckbox(6, true);
        }
      }

      this.fnDroppedPiece(event, jsonDragged);
    } else if (!this.isModeOpen && (this.status >= 8) && jsonDragged.type == 4 && jsonDragged.valor == 1) {
      if (event.previousContainer.id == "cdkLstInsideMachineCapex") {
        this.jsonBlnOpenModeActions.isDepreciateMachine1 = true;
        this.socketService.setBlnControlModeOpen('isDepreciateMachine1', true, this.roomId, this.teamId);
      } else if (event.previousContainer.id == "cdkLstInsideMachineCapex2") {
        this.jsonBlnOpenModeActions.isDepreciateMachine2 = true;
        this.socketService.setBlnControlModeOpen('isDepreciateMachine2', true, this.roomId, this.teamId);
      }
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      if (this.currentMonth >= 2) {
        // checkear el paso 7 del checklist
        // this.checkBoxes[this.currentMonth>=3?8:6].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        if (this.jsonBlnOpenModeActions.isSecondMachine) {
          if (this.cdkLstDeprecation.length >= 2) {
            // this.checkCheckbox(5, true);
            this.checkCheckbox(9, true);
          }
        } else {
          // this.checkCheckbox(5, true);
          this.checkCheckbox(9, true);
        }
      }

      this.fnDroppedPiece(event, jsonDragged);
    } else {
      console.log("here")
      this.errorControl(event, jsonDragged);
    }

  }

  fnDroppedOnMachineOver(event: CdkDragDrop<any>) {
    console.log(this.status,this.cdkLstRent,this.jsonBlnOpenModeActions.isSoldBuild)
    if (this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen) {
      console.log("AQUI")
      this.isHintInterest++;
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1005'));
      return;
    }
    if(this.status == 9 && this.cdkLstRent.length == 0 && this.jsonBlnOpenModeActions.isSoldBuild){
      console.log("Pay rent first")
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1059'));

      return
    }
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    console.log("fndroppedmachenover: event.previousContainer.id=", event.previousContainer.id)
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {//dan7
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    // Take depreciation DONE
    if((this.status == 9 || this.status == 8) && !this.getCheckCheckbox(9).blnCheck){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('4015','2022'));
      return;
    }
    console.log(this.improvementsOptionsSelected)
    if (this.currentMonth >= 4 && this.isProjectsEnableByCurrentMonth) {
      // if ((this.improvementsOptionsSelected[0].option == 1 && this.improvementsOptionsSelected[1].option == 5
      //   && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.isRawMaterials1 && !this.jsonBlnOpenModeActions.isRawMaterials2)) {// caso raw + expa
      //   this.fnTrowToast("#errorGeneral", this.getErrorLabel('1001'));
      //   return;
      // }
    }
    let totCashMachineOver = 0;
    this.cdkLstMachineOver.forEach(item => {
      totCashMachineOver += item.valor;
    });
    totCashMachineOver += jsonDragged.valor;
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (event.previousContainer.id == "cdkLstCash" && this.currentMonth >= 3 && this.jsonBlnOpenModeActions.isRawMaterials1 && jsonDragged.type == 1 && jsonDragged.valor == 2) {
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      if (
        totCashMachineOver > 6 && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 4 && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 4 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 2 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 3 && !this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 1 && !this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2
      ) {
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1008'));
        this.fnStatusPiece(event.container.id, false, jsonDragged);
        return;
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      setTimeout(async () => {
        if (this.jsonBlnOpenModeActions.showBill2) {
          this.socketService.setBlnControlModeOpen('isActiveImprovement', false, this.roomId, this.teamId);
        this.jsonBlnOpenModeActions.isActiveImprovement = false;
          this.jsonBlnOpenModeActions.showBill2 = false;
          this.socketService.setBlnControlModeOpen('showBill2', false, this.roomId, this.teamId);
          // si no solo tenemos 1 improvement, agregamos otro con info vacia para que no genere error
          if (this.improvementsOptionsSelected.length == 1) {
            this.improvementsOptionsSelected.push({
              improvement: '',
              value: 0,
              ImproveId: -1,
              option: 0,
              question: ''
            });
          }
          if (this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isRawMaterials2) {

            // const x = await this.implementExpansionWithRawMaterial(2);
            this.spinner.show();
            this.socketService.implementExpansionWithRawMaterial(this.roomId, this.teamId, 2);
          } else if (
            (((this.improvementsOptionsSelected[0].value == 1 && this.improvementsOptionsSelected[0].option != 6) || this.improvementsOptionsSelected[0].value == 2)) ||
            (((this.improvementsOptionsSelected[1].value == 1 && this.improvementsOptionsSelected[1].option != 6) || this.improvementsOptionsSelected[1].value == 2))
          ) {
            if(this.improvementsOptionsSelected[1].Approved == 1 || this.improvementsOptionsSelected[0].Approved == 1){
            }else{
              this.socketService.askImprovement(this.roomId, this.teamId);
              this.spinner.show();
            }
            
          }
        }
        setTimeout(() => {
          //Deprecado nuevos checkbox
          // if (!this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck) {
          //   // this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = true;
          //   // this.fnScrollCheckList(this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4]);
          //   // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          // }
          //Deprecado nuevos checkbox
          this.checkCheckbox(4,true);
          //DONE
          if (this.fnisReadyPaso8() && !this.getCheckCheckbox(10).blnCheck && this.isModeOpen) {
            setTimeout(() => {
              // this.checkCheckbox(7, true);
              this.checkCheckbox(10, true);
              //guardamos backup cuando el check se marque para volver a guardar la cantidad correcta de cash
              console.log("HACER BACKUP")
              this.funcionBack("backOriginal");

              // this.fnSaveBackQuantities();
            }, 1000);
          }
        }, 500);
      }, 500);
    } else if (
      // paso 27 pagar machine over head
      (this.status == 27 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && jsonDragged.valor == 1 && this.currentMonth == 1) ||
      (this.isModeOpen && jsonDragged.type == 1 && (jsonDragged.valor == 1 || jsonDragged.valor == 2 && this.jsonBlnOpenModeActions.isSecondMachine) && (this.status >= 3 && this.currentMonth >= 2))

    ) {
      if (
        totCashMachineOver > 6 && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 4 && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 4 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 2 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 3 && !this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 1 && !this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2
      ) {
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1008'));
        this.fnStatusPiece(event.container.id, false, jsonDragged);
        return;
      }
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      // checkear el paso 8 del checklist DONE
      if (this.fnisReadyPaso8() && !this.getCheckCheckbox(10).blnCheck) {
        // this.checkBoxes[this.currentMonth>=3?9:7].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        // this.checkCheckbox(7, true);
        this.checkCheckbox(10, true);
        this.status = 10;
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        //guardamos backup cuando el check se marque para volver a guardar la cantidad correcta de cash
        // this.fnSaveBackQuantities();
        console.log("HACER BACKUP")
        this.funcionBack("backOriginal");

      }

      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // modo riguroso paso 9 pay expenses
      (event.previousContainer.id == "cdkLstCash" && !this.isModeOpen && jsonDragged.type == 1 && (jsonDragged.valor == 1 || jsonDragged.valor == 2 && this.jsonBlnOpenModeActions.isSecondMachine) && (this.status >= 8 && this.currentMonth >= 2))

    ) {
      if (
        totCashMachineOver > 6 && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 4 && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 4 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 2 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 3 && !this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 1 && !this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2
      ) {
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1008'));
        this.fnStatusPiece(event.container.id, false, jsonDragged);
        return;
      }
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      // Pay expenses
      // checkear el paso 8 del checklist DONE
      if (this.fnisReadyPaso8() && !this.getCheckCheckbox(10).blnCheck) {
        // this.checkBoxes[this.currentMonth>=3?9:7].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        // this.checkCheckbox(7, true);
        this.checkCheckbox(10, true);
        this.status = 10;
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        console.log("HACER BACKUP")
        this.funcionBack("backOriginal");

        //guardamos backup cuando el check se marque para volver a guardar la cantidad correcta de cash
        // this.fnSaveBackQuantities();
      }

      this.fnDroppedPiece(event, jsonDragged);
    } else {
      if (
        totCashMachineOver > 6 && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 4 && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 4 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 2 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 3 && !this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
        totCashMachineOver > 1 && !this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2
      ) {
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1008'));
        this.fnStatusPiece(event.container.id, false, jsonDragged);
        return;
      } else {
        this.errorControl(event, jsonDragged);
      }
    }

  }

  fnDroppedOnCostGS(event: CdkDragDrop<any>) { 
    let totOrders = 0;
    let totCSG = 0;
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.container.id === event.previousContainer.id) {
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    this.orders.forEach(item => {
      totOrders += item.Quantity;
    });
    this.cdkLstCostGS.forEach(item => {
      totCSG += item.valor;
    });


    jsonDragged.draggable = false;
    jsonDragged.draggingBy = "";
    totCSG += jsonDragged.valor;
    let precioRoyal = 3;
    // si improvement raw materials o efficiency se modifica el precio de los royals
    if (this.currentMonth >= 3) {
      // if(this.jsonBlnOpenModeActions.isRawMaterials1){
      //   precioRoyal = 4;
      // }else if(this.jsonBlnOpenModeActions.isEfficiency1){
      //   precioRoyal = 5;
      // }
      if (jsonDragged.type == 16) {
        precioRoyal = 4;
      } else if (jsonDragged.type == 17) {
        precioRoyal = 5;
      } else if (jsonDragged.type == 18) {
        precioRoyal = 3;
      }
    }

    let itemsDragged = jsonDragged.valor / precioRoyal;

    /*if (totOrders * precioRoyal < totCSG && this.currentMonth >= 2) {
      this.fnTrowToast("#errorGeneral", "You don’t need that extra!");
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }*/
    if (this.cdkLstCostGS.length + itemsDragged > totOrders && (this.currentMonth >= 2 || this.boardMode == "DM")) {
      this.fnTrowToast('#errorGeneral', this.getErrorLabel('1012'));
      //this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }

    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    let tot = 0;
    this.cdkLstCostGS.forEach(item => {
      tot += item.valor;
    });
    tot += parseInt(jsonDragged.valor);
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 32 mover royals a cost of goods solds
      this.status == 32 && this.boardMode == "NM" && event.previousContainer.id == "cdkLstFinishedFI" && jsonDragged.type == 8 && tot <= 9 && jsonDragged.valor <= 9
    ) {
      /* this.status == 32 && event.previousContainer.id == "cdkLstFinishedFI" && (jsonDragged.type == 8 || jsonDragged.type == 16  || jsonDragged.type == 17) && tot <= 9 && jsonDragged.valor <= 9
            ) {*/
      jsonDragged.paid = false;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // paso 32 mover royals a cost of goods solds
      this.status == 32 && this.boardMode == "DM" && event.previousContainer.id == "cdkLstFinishedFI" && jsonDragged.type == 8
    ) {
      /* this.status == 32 && event.previousContainer.id == "cdkLstFinishedFI" && (jsonDragged.type == 8 || jsonDragged.type == 16  || jsonDragged.type == 17) && tot <= 9 && jsonDragged.valor <= 9
            ) {*/
      jsonDragged.paid = false;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // modo open, mover royals de fgi a cost of gs
      this.isModeOpen && (event.previousContainer.id == "cdkLstFinishedFI" || event.previousContainer.id == 'cdkLstInsideMachineWorkInProgress' || event.previousContainer.id == 'cdkLstInsideMachineWorkInProgress2') && (jsonDragged.type == 8 || jsonDragged.type == 16 || jsonDragged.type == 17 || jsonDragged.type == 18) && this.status >= 3
    ) {
      // if(jsonDragged.valor >= 12){
      //   this.fnTrowToast("#errorGeneral", "You don’t need that extra!");
      //   return;
      // }

      let tot = 0;
      this.cdkLstCostGS.forEach(item => {
        tot += item.valor;
      });
      tot += jsonDragged.valor;

      let valor = 0;
      let tipo = 0
      tipo = jsonDragged.type;
      if (tipo == 16) {//tipo 16 = nuevo royal 1 verde 3 plata (imp. Raw material)
        valor = 4;
      } else if (tipo == 17) {//tipo 17 = nuevo royal 3 azul y 2 plata (imp. Efficiency)
        valor = 5;
      } else if (tipo == 8) {//royal normal 3/3
        valor = 3
      } else if (tipo == 18) {
        valor = 3;
      }
      // if(this.jsonBlnOpenModeActions.isRawMaterials1){
      //  /* valor = 4;
      //   tipo = 16;*/


      // }else if(this.jsonBlnOpenModeActions.isEfficiency1){
      //   valor = 5;
      //   tipo = 17;
      // } else {
      //   valor = 3;
      //   tipo = 8;
      // }

      let units = Math.floor(jsonDragged.valor / valor);

      for (let i = 0; i < units; i++) {
        this.cdkLstCostGS.push({
          type: tipo,
          valor: valor,
          id: null,
          draggable: false,
          draggingBy: "",
          paid: false
        });
      }
      this.deleteCoinByAreaID(event.previousContainer.id, jsonDragged.id);
      if (event.previousContainer.id == 'cdkLstInsideMachineWorkInProgress') {
        if (this.cdkLstInsideMachineWorkInProgress.length == 0) {
          // this.checkBoxes[this.currentMonth>=3?13:11].blnCheck = true;
          // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          this.checkCheckbox(11, true);
        }
      }
      setTimeout(() => {
        this.socketService.deleteCoin(event.previousContainer.id, jsonDragged, this.roomId, this.teamId);
      }, 500);
      this.socketService.splitCoin(this.cdkLstCostGS, 'cdkLstCostGS', this.roomId, this.teamId);
    } else if (
      // modo riguroso, mover royals de fgi a cost of gs
      !this.isModeOpen && (event.previousContainer.id == "cdkLstFinishedFI") && (jsonDragged.type == 8 || jsonDragged.type == 16 || jsonDragged.type == 17 || jsonDragged.type == 18) && (this.status == 13 || this.status == 14)
    ) {
      // if(jsonDragged.valor >= 12){
      //   this.fnTrowToast("#errorGeneral", "You don’t need that extra!");
      //   return;
      // }

      let tot = 0;
      this.cdkLstCostGS.forEach(item => {
        tot += item.valor;
      });
      tot += jsonDragged.valor;

      let valor = 0;
      let tipo = 0
      tipo = jsonDragged.type;
      if (tipo == 16) {//tipo 16 = nuevo royal 1 verde 3 plata (imp. Raw material)
        valor = 4;
      } else if (tipo == 17) {//tipo 17 = nuevo royal 3 azul y 2 plata (imp. Efficiency)
        valor = 5;
      } else if (tipo == 8) {//royal normal 3/3
        valor = 3
      } else if (tipo == 18) {
        valor = 3;
      }

      let units = Math.floor(jsonDragged.valor / valor);

      for (let i = 0; i < units; i++) {
        this.cdkLstCostGS.push({
          type: tipo,
          valor: valor,
          id: null,
          draggable: false,
          draggingBy: "",
          paid: false
        });
      }
      this.deleteCoinByAreaID('cdkLstFinishedFI', jsonDragged.id);
      setTimeout(() => {
        this.socketService.deleteCoin('cdkLstFinishedFI', jsonDragged, this.roomId, this.teamId);
        setTimeout(() => {
          this.status = 14;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }, 500);
      }, 500);
      this.spinner.show();
      this.socketService.splitCoin(this.cdkLstCostGS, 'cdkLstCostGS', this.roomId, this.teamId);
    } else {
      if ((tot > 9 || jsonDragged.valor > 9) && this.currentMonth == 1) {
        Swal.mixin({
          target: '#errorGeneral',
          customClass: {
            container: 'position-absolute'
          },
          toast: true,
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 3000,
          position: 'bottom-right',
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        }).fire({
          html: this.getErrorLabel('1012')
        });
        event.previousContainer.data[this.indexItemDragged].draggable = false;
        event.previousContainer.data[this.indexItemDragged].draggingBy = "";
        this.socketService.disablePiece(event.previousContainer.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
      } else {
        this.errorControl(event, jsonDragged);
      }
    }
  }

  fnDroppedOnLocationMachine(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
    } else if (
      // paso 8 mover de machinary a location machinary
      this.status == 7 && event.previousContainer.id == "cdkLstCapEx" && jsonDragged.type == 5
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }

  }

  fnDroppedOnInsideMachineCapex(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if(this.currentMonth >= 4 && this.isProjectsEnableByCurrentMonth){
      console.log(this.improvementsOptionsSelected, this.mejorasXMes);
      console.log(this.cdkLstInsideMachineCapex);
      if((this.improvementsOptionsSelected[1].option == 3 && this.improvementsOptionsSelected[1].value == 3)  //caso effy + custom
      || (this.mejorasXMes >= 2 && this.improvementsOptionsSelected[1].option == 2 && this.improvementsOptionsSelected[1].value == 3 && this.improvementsOptionsSelected[0].option == 3 && this.improvementsOptionsSelected[0].value == 3)// caso custom + effy mismo mes
        || ((this.improvementsOptionsSelected[0].option == 5 && this.improvementsOptionsSelected[1].option == 2 && this.returnTotalLst(this.cdkLstInsideMachineCapex) > 20) || (this.improvementsOptionsSelected[1].option == 5 && this.improvementsOptionsSelected[0].option == 2 && this.returnTotalLst(this.cdkLstInsideMachineCapex) > 20) )){  //caso expa + Effy
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1001'));
        return;

      }
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 9 pagar machinary, capex a inside machinary
      this.status == 8 && event.previousContainer.id == "cdkLstCapEx" && jsonDragged.type == 4
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // implementando improvement efficiency
      event.previousContainer.id == "cdkLstCapEx" && jsonDragged.type == 4 && jsonDragged.valor == 10 && this.jsonBlnOpenModeActions.isEfficiency1
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      setTimeout(() => {
        this.jsonBlnOpenModeActions.showMessageOwe10 = true;
        this.socketService.setBlnControlModeOpen('showMessageOwe10', true, this.roomId, this.teamId);
        setTimeout(() => {
          this.jsonBlnOpenModeActions.isPayBill = true;
          this.socketService.setBlnControlModeOpen('isPayBill', true, this.roomId, this.teamId);
        }, 500);
      }, 500);
    } else {
      this.errorControl(event, jsonDragged);
    }
  }

  fnDroppedOnInsideMachineCapex2(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      this.status >= 3 && event.previousContainer.id == "cdkLstCapEx" && jsonDragged.type == 4 && jsonDragged.valor == 20 && this.cdkLstInsideMachineCapex2.length === 0
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      this.spinner.show('my-custom-spinner')
      setTimeout(() => {
        this.socketService.createCoin('cdkLstCapEx', {
          valor: 20,
          id: null,
          draggable: false,
          type: 3,
        }, this.roomId, this.teamId);
        setTimeout(() => {
          this.jsonBlnOpenModeActions.showMessageOwe20 = true;
          this.socketService.setBlnControlModeOpen('showMessageOwe20', true, this.roomId, this.teamId);
          setTimeout(() => {
          this.spinner.hide('my-custom-spinner')
    
            this.jsonBlnOpenModeActions.isBuySecondMachine = true;
            this.socketService.setBlnControlModeOpen('isBuySecondMachine', true, this.roomId, this.teamId);
          }, 500);
        }, 500);
      }, 500);
    } else if (
      // implementando improvement efficiency
      event.previousContainer.id == "cdkLstCapEx" && jsonDragged.type == 4 && jsonDragged.valor == 10 && this.jsonBlnOpenModeActions.isEfficiency2
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      setTimeout(() => {
        this.jsonBlnOpenModeActions.showMessageOwe10 = true;
        this.socketService.setBlnControlModeOpen('showMessageOwe10', true, this.roomId, this.teamId);
        setTimeout(() => {
          this.jsonBlnOpenModeActions.isPayBill = true;
          this.socketService.setBlnControlModeOpen('isPayBill', true, this.roomId, this.teamId);
        }, 500);
      }, 500);
    } else {
      this.errorControl(event, jsonDragged);
    }
  }

  fnDroppedOnInsideMachineCapexCustomizing(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    console.log("ES QUI", jsonDragged);

    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if(this.currentMonth >= 4 && this.isProjectsEnableByCurrentMonth){
      console.log(this.improvementsOptionsSelected,this.mejorasXMes);
      if(this.cdkLstInsideMachineCapexCustomizing.length>0){
        this.fnTrowToast("#errorGeneral", this.getErrorLabel('1001'));
        return;
      }
      if((this.improvementsOptionsSelected[1].option == 2 && this.improvementsOptionsSelected[1].value == 3 && this.mejorasXMes <= 1 ) ){ //caso custo + effy
          this.fnTrowToast("#errorGeneral", this.getErrorLabel('1001'));
        return;

      }
      
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 9 pagar machinary, capex a inside machinary
      ((this.status == 3 && !this.isModeOpen) || this.isModeOpen) && event.previousContainer.id == "cdkLstCapEx" && jsonDragged.type == 4 && jsonDragged.valor == 10
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      this.jsonBlnOpenModeActions['showBill10'] = true;
      this.jsonBlnOpenModeActions['isPayBill10'] = true;
      this.socketService.setBlnControlModeOpen('showBill10', true, this.roomId, this.teamId);
      setTimeout(() => {
        this.socketService.setBlnControlModeOpen('isPayBill10', true, this.roomId, this.teamId);

      }, 1000);
    } else {
      this.errorControl(event, jsonDragged);
    }
  }

  fnDroppedOnInsideMachinePeople(event: CdkDragDrop<any>) {
    console.log(this.cdkLstInsideMachineWorkInProgress,this.cdkLstInsideMachineWorkInProgress2,this.cdkLstInsideMachineWorkInProgress3)
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    let alreadyPaid = this.thereAreStack(this.cdkLstInsideMachineWorkInProgress, 8);
    let totMaterials = this.returnTotalLst(this.cdkLstInsideMachineWorkInProgress);
    let isCompleteMachine = (totMaterials == 4 && this.jsonBlnOpenModeActions.isRawMaterials1) ? true :
      (totMaterials == 6 && this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isRawMaterials1) ? true :
        (totMaterials == 18 && this.jsonBlnOpenModeActions.isEfficiency1) ? true :
          (totMaterials == 12 && !this.jsonBlnOpenModeActions.isRawMaterials1 && !this.jsonBlnOpenModeActions.isEfficiency1) ? true : false;
    if (this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    console.log(this.cdkLstInsideMachineWorkInProgress,this.cdkLstInsideMachineWorkInProgress2);
    if(this.jsonBlnOpenModeActions.isSecondMachine && (this.cdkLstInsideMachineWorkInProgress.length == 0 || this.cdkLstInsideMachineWorkInProgress2.length == 0) && jsonDragged.type==1){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('2081'));
      console.log("PAGAR NO VALIDO");
      return;
    }
    console.log("Peronas?", jsonDragged);
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 12 pasar 12 personas de labour pool a inside machine
      (this.status == 12 && event.previousContainer.id == "cdkLstLabourP" && jsonDragged.type == 6 && jsonDragged.valor == 12 && this.currentMonth == 1)
    ) {
      jsonDragged.draggable = false;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // paso 22 pagar a las 12 personas en la maquina
      (this.status == 22 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && jsonDragged.valor == 12 && this.currentMonth == 1)
    ) {
      this.blnAnimRawMat = true;
      setTimeout(() => {
        this.blnAnimRawMat = false;
      }, 8000);
      this.blnAnimCoinPeople = true;
      setTimeout(() => {
        this.blnAnimCoinPeople = false;
      }, 3000);
      event.previousContainer.data.splice(this.indexItemDragged, 1)
      event.container.id = "payCapex";
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // error no hay elementos en la machinary
      (event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && this.cdkLstInsideMachineWorkInProgress.length == 0)
    ) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else if (
      // error no esta llena de elementos la machinary
      (event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && !isCompleteMachine)
    ) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else if (
      // pagar a las 12 personas en la maquina
      (this.currentMonth >= 2 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && jsonDragged.valor == 12 && !alreadyPaid)
    ) {
      this.blnAnimRawMat = true;
      setTimeout(() => {
        this.blnAnimRawMat = false;
      }, 8000);
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      let totToPay = 0;
      // si implemento raw materials
      // this.cdkLstInsideMachineWorkInProgress.forEach(item => {
      //   totToPay += item.valor;
      // });
      if (jsonDragged.valor > 12) {
        // this.fnTrowSwalPayLoans;
        console.log(jsonDragged);
        jsonDragged.valor = jsonDragged.valor - totToPay;
        this.socketService.updateCoin('cdkLstCash', jsonDragged, this.roomId, this.teamId);
        this.fnTrowBonusLabourAlert();
        this.fnConvertMaterialsToRoyals(this.cdkLstInsideMachineWorkInProgress, 'cdkLstInsideMachineWorkInProgress');
        // checkear el paso de pay labour
        // this.checkBoxes[this.currentMonth >= 3?7:5].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        if(this.jsonBlnOpenModeActions.isSecondMachine){
          console.log("HEY")
          if(this.cdkLstInsideMachineWorkInProgress2[0].type!=7 ){
            // this.checkCheckbox(5, true);
            this.checkCheckbox(8, true);
          }
        }else{
          if(!this.jsonBlnOpenModeActions.isSecondMachine){
            // this.checkCheckbox(5, true);
            this.checkCheckbox(8, true);
          }
        }        
        this.updStatusMachine();
      } else if (jsonDragged.valor == 12) {
        console.log("Entra!!")
        this.spinner.show('my-custom-spinner')
        event.previousContainer.data.splice(this.indexItemDragged, 1);
        event.container.id = "payCapex";
        this.fnDroppedPiece(event, jsonDragged);
        setTimeout(() => {
          this.fnConvertMaterialsToRoyals(this.cdkLstInsideMachineWorkInProgress, 'cdkLstInsideMachineWorkInProgress');
          setTimeout(() => {
            // checkear el paso de pay labour
            if(this.jsonBlnOpenModeActions.isSecondMachine){
              if(this.cdkLstInsideMachineWorkInProgress2[0].type!=7 && this.cdkLstInsideMachineWorkInProgress2[0].type!=14 ){
                // this.checkCheckbox(5, true);
                this.checkCheckbox(8, true);
              }
            }else{
              if(!this.jsonBlnOpenModeActions.isSecondMachine){
                // this.checkCheckbox(5, true);
                this.checkCheckbox(8, true);
              }
            }  
            this.updStatusMachine();
            setTimeout(() => {
              this.spinner.hide('my-custom-spinner')
            }, 300);
          }, 400);
        }, 200);
      } else {
        Swal.fire({
          html: this.getErrorLabel('1069'),
          showCancelButton: false,
          allowEscapeKey:false,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
        });
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);

      }

    } else if (!(this.isModeOpen && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && this.status >= 3)) {
      this.errorControl(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }
  }

  fnDroppedOnInsideMachinePeople2(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    let alreadyPaid = this.thereAreStack(this.cdkLstInsideMachineWorkInProgress2, 8);
    let totMaterials = this.returnTotalLst(this.cdkLstInsideMachineWorkInProgress2);
    let isCompleteMachine = (totMaterials == 4 && this.jsonBlnOpenModeActions.isRawMaterials2) ? true :
      (totMaterials == 6 && this.jsonBlnOpenModeActions.isEfficiency2 && this.jsonBlnOpenModeActions.isRawMaterials2) ? true :
        (totMaterials == 18 && this.jsonBlnOpenModeActions.isEfficiency2) ? true :
          (totMaterials == 12 && !this.jsonBlnOpenModeActions.isRawMaterials2 && !this.jsonBlnOpenModeActions.isEfficiency2) ? true : false;
    if (this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if(this.status == 3 && (this.cdkLstCapEx.length > 0 || this.jsonBlnOpenModeActions.isPayBill20)){ // Restringir los pasos de expacion, primero hacer cap ex y luego labor
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1001'));
      return
    }
    console.log(this.cdkLstInsideMachineWorkInProgress,this.cdkLstInsideMachineWorkInProgress2,jsonDragged,isCompleteMachine);
    if(this.jsonBlnOpenModeActions.isSecondMachine && (this.cdkLstInsideMachineWorkInProgress.length == 0 || this.cdkLstInsideMachineWorkInProgress2.length == 0) && jsonDragged.type==1){
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('2081'));
      console.log("PAGAR NO VALIDO");
      return;
    }
    console.log("Peronas?", jsonDragged);
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 12 pasar 12 personas de labour pool a inside machine
      (this.status >= 3 && event.previousContainer.id == "cdkLstLabourP" && jsonDragged.type == 6 && jsonDragged.valor == 12)
    ) {
      jsonDragged.draggable = false;
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
      this.jsonBlnOpenModeActions.showBill6 = true;
      setTimeout(() => {
        this.socketService.setBlnControlModeOpen('showBill6', true, this.roomId, this.teamId);
      }, 500);
    } else if (
      // open mode, error no hay elementos en la machinary
      (event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && this.cdkLstInsideMachineWorkInProgress2.length == 0)
    ) {
      this.errorControl(event, jsonDragged);
      //this.fnTrowToast("#errorGeneral", "66.- You need to load your machine first!");
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else if (
      // error no esta llena de elementos la machinary
      (event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && !isCompleteMachine)
    ) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else if (
      // mode open pagar a las 12 personas en la maquina
      (this.currentMonth >= 2 && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && jsonDragged.valor == 12 && !alreadyPaid)
    ) {
      this.blnAnimRawMat2 = true;
      setTimeout(() => {
        this.blnAnimRawMat2 = false;
      }, 8000);
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      let totToPay = 0;
      // si implemento raw materials
      // this.cdkLstInsideMachineWorkInProgress.forEach(item => {
      //   totToPay += item.valor;
      // });
      if (jsonDragged.valor > 12) {
        // this.fnTrowSwalPayLoans;
        console.log(jsonDragged);
        jsonDragged.valor = jsonDragged.valor - totToPay;
        this.socketService.updateCoin('cdkLstCash', jsonDragged, this.roomId, this.teamId);
        this.fnTrowBonusLabourAlert();
        this.fnConvertMaterialsToRoyals(this.cdkLstInsideMachineWorkInProgress2, 'cdkLstInsideMachineWorkInProgress2');
        // checkear el paso de pay labour
        // this.checkBoxes[this.currentMonth >= 3?7:5].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        if(this.jsonBlnOpenModeActions.isSecondMachine){
          if(this.cdkLstInsideMachineWorkInProgress[0].type!=7 && this.cdkLstInsideMachineWorkInProgress[0].type!=14 ){
            // this.checkCheckbox(5, true);
              this.checkCheckbox(8, true);          }
        }else{
          if(!this.jsonBlnOpenModeActions.isSecondMachine){
            // this.checkCheckbox(5, true);
            this.checkCheckbox(8, true);  
          }
        }  
        this.updStatusMachine();
      } else if (jsonDragged.valor == 12) {
        console.log("ENTRA!!")
        this.spinner.show('my-custom-spinner')

        event.previousContainer.data.splice(this.indexItemDragged, 1);
        event.container.id = "payCapex";
        this.fnDroppedPiece(event, jsonDragged);
        setTimeout(() => {
          this.fnConvertMaterialsToRoyals(this.cdkLstInsideMachineWorkInProgress2, 'cdkLstInsideMachineWorkInProgress2');
          setTimeout(() => {
            // checkear el paso de pay labour
            if(this.jsonBlnOpenModeActions.isSecondMachine){
              if(this.cdkLstInsideMachineWorkInProgress[0].type!=7 && this.cdkLstInsideMachineWorkInProgress[0].type!=14 ){
                // this.checkCheckbox(5, true);
              this.checkCheckbox(8, true);  
              }
            }else{
              if(!this.jsonBlnOpenModeActions.isSecondMachine){
                // this.checkCheckbox(5, true);
              this.checkCheckbox(8, true);  
              }
            }  
            this.updStatusMachine();
            setTimeout(() => {
              this.spinner.hide('my-custom-spinner')
            }, 300);
          }, 400);
        }, 200);
      } else {
        Swal.fire({
          html: this.getErrorLabel('1069'),
          showCancelButton: false,
          allowEscapeKey:false,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
        });
        this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);

      }

    } else if (!(this.isModeOpen && event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1 && this.status >= 3)) {
      this.errorControl(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }
  }

  fnDroppedOnInsideMachineWorkInProgress(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];

    jsonDragged.draggable = false;
    jsonDragged.draggingBy = '';
    let totMaterials = 0;
    let thereAre4Units = false;
    let thereAre6Units = false;
    this.cdkLstInsideMachineWorkInProgress.forEach(item => {
      totMaterials += item.valor;
    });
    totMaterials += jsonDragged.valor;
    if (totMaterials > 12) {
      thereAre4Units = true;
    }
    if (totMaterials > 18) {
      thereAre6Units = true;
    }
    if(this.jsonBlnOpenModeActions.isRawMaterials1){
      if (totMaterials > 4) {
        thereAre4Units = true;
      }
    }
    console.log(totMaterials)
    if(jsonDragged.valor == 12 && jsonDragged.type == 7 && this.jsonBlnOpenModeActions.isEfficiency1 && !this.jsonBlnOpenModeActions.isEfficiency2){
      let target = "#errorGeneral";
      let text = this.getErrorLabel('1001');
      console.log("despues de mostrar mensaje de que debe comprar los materiales pendinets para maquina 2")
      this.fnTrowToast(target, text);
      return
    }
    if(jsonDragged.valor == 12 && jsonDragged.type == 7 && this.jsonBlnOpenModeActions.isRawMaterials1 && !this.jsonBlnOpenModeActions.isRawMaterials2){
      let target = "#errorGeneral";
      let text = this.getErrorLabel('1001');
      console.log("despues de mostrar mensaje de que debe comprar los materiales pendinets para maquina 2")
      this.fnTrowToast(target, text);
      return
    }
    if ((event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id) || this.jsonBlnOpenModeActions.isBuyMaterials || this.cdkLstSuppliers.length != 0) && jsonDragged.type != 11) {
      //dcmNO permitiremos avanzar sin comprar los materiales de la segunda maquina cuando hay expantion
      if(this.cdkLstSuppliers.length != 0  && this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isSecondMachine){
        console.log("regla de excepcion solo permitir comprar un material");
        let target = "#errorGeneral";
        let text = this.getErrorLabel('1027');
        console.log("despues de mostrar mensaje de que debe comprar los materiales pendinets para maquina 2")
        this.fnTrowToast(target, text);
        return;

      }else{
        this.errorControl(event, jsonDragged);
        this.fnStatusPiece(event.container.id, false, jsonDragged);
        return;
      }
    
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 21 mover row materials a work in progress
      this.status == 21 && event.previousContainer.id == "cdkLstMaterials" && jsonDragged.type == 7 && !thereAre4Units
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // open mode mover los row materials a inside machine work in progress
      event.previousContainer.id == "cdkLstMaterials" && ((jsonDragged.type == 7 && !this.jsonBlnOpenModeActions.isRawMaterials1) || (jsonDragged.type == 14 && this.jsonBlnOpenModeActions.isRawMaterials1)) && this.isModeOpen && this.currentMonth >= 2 && this.status >= 3
    ) {
      if ((!thereAre4Units || (!thereAre6Units && this.jsonBlnOpenModeActions.isEfficiency1)) && this.currentMonth >= 2) {
        console.log("AQUI")
        jsonDragged.draggable = false;
        jsonDragged.draggingBy = "";
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        this.fnDroppedPiece(event, jsonDragged);
        // checkear el paso de load raw materials to machine
        // this.checkBoxes[this.currentMonth >= 3?6:4].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        let isCompleteMachine = this.isCompleteMachine(this.cdkLstInsideMachineWorkInProgress, "machine1");
        if (isCompleteMachine)
          // this.checkCheckbox(4, true);
          this.checkCheckbox(7, true);
      }

    } else if (
      // rigurous mode mover los row materials a inside machine work in progress
      event.previousContainer.id == "cdkLstMaterials" && ((jsonDragged.type == 7 && !this.jsonBlnOpenModeActions.isRawMaterials1) || (jsonDragged.type == 14 && this.jsonBlnOpenModeActions.isRawMaterials1)) && this.currentMonth >= 2
    ) {
      // if ((!thereAre4Units || !thereAre6Units) && this.currentMonth >= 2) {
      if ((!thereAre4Units || (!thereAre6Units && this.jsonBlnOpenModeActions.isEfficiency1)) && this.currentMonth >= 2) {
        console.log("AQUI")

        jsonDragged.draggable = false;
        jsonDragged.draggingBy = "";
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        this.fnDroppedPiece(event, jsonDragged);
        // checkear el paso de load raw materials to machine
        // this.checkBoxes[this.currentMonth >= 3?6:4].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        let isCompleteMachine = (totMaterials == 4 && this.jsonBlnOpenModeActions.isRawMaterials1) ? true :
          (totMaterials == 6 && this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isRawMaterials1) ? true :
            (totMaterials == 18 && this.jsonBlnOpenModeActions.isEfficiency1) ? true :
              (totMaterials == 12 && !this.jsonBlnOpenModeActions.isRawMaterials1 && !this.jsonBlnOpenModeActions.isEfficiency1) ? true : false;
        if(this.jsonBlnOpenModeActions.isSecondMachine){
            if(this.cdkLstMaterials.length == 0 && isCompleteMachine){
              // this.checkCheckbox(4, true);
              this.checkCheckbox(7, true);

            }
          }else{
            if (isCompleteMachine){
              // this.checkCheckbox(4, true);
                this.checkCheckbox(7, true);

            }
          }
      }

    } else if (
      // para improvement efficiency
      event.previousContainer.id == 'cdkLstCapEx' && (jsonDragged.type == 11) && this.currentMonth >= 3 && !this.jsonBlnOpenModeActions.isEfficiency1
    ) {
      console.log("AQUI")
      this.spinner.show('my-custom-spinner')

      this.jsonBlnOpenModeActions['isEfficiency1'] = true;
      this.socketService.setBlnControlModeOpen('isEfficiency1', true, this.roomId, this.teamId);
      this.deleteCoinByAreaID('cdkLstCapEx', jsonDragged.id);
      setTimeout(() => {
        this.socketService.deleteCoin('cdkLstCapEx', jsonDragged, this.roomId, this.teamId);
        setTimeout(() => {
          this.socketService.createCoin('cdkLstCapEx', {
            valor: 10,
            id: null,
            draggable: false,
            type: 4,
          }, this.roomId, this.teamId);
          setTimeout(() => {
            let newArray = [];
            if (this.jsonBlnOpenModeActions.isRawMaterials1) {
              newArray = this.createArrayCoins(1, 2, 14);
            } else {
              newArray = this.createArrayCoins(3, 2, 7);
            }
            this.socketService.splitCoin(this.cdkLstSuppliers.concat(newArray), 'cdkLstSuppliers', this.roomId, this.teamId);
            this.spinner.hide('my-custom-spinner')
          }, 500);
        }, 500);
      }, 500);
    } else if (this.currentMonth >= 2) {
      this.errorControl(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    }
  }

  fnDroppedOnInsideMachineWorkInProgress2(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    jsonDragged.draggable = false;
    jsonDragged.draggingBy = '';
    let totMaterials = 0;
    let thereAre4Units = false;
    let thereAre6Units = false;
    this.cdkLstInsideMachineWorkInProgress2.forEach(item => {
      totMaterials += item.valor;
    });
    totMaterials += jsonDragged.valor;
    if (totMaterials > 12) {
      thereAre4Units = true;
    }
    if (totMaterials > 18) {
      thereAre6Units = true;
    }
    if(this.jsonBlnOpenModeActions.isRawMaterials2){
      if (totMaterials > 4) {
        thereAre4Units = true;
      }
    }
    if(jsonDragged.valor == 18 && jsonDragged.type == 7 && this.jsonBlnOpenModeActions.isEfficiency1 && !this.jsonBlnOpenModeActions.isEfficiency2){
      let target = "#errorGeneral";
      let text = this.getErrorLabel('1001');
      console.log("despues de mostrar mensaje de que debe comprar los materiales pendinets para maquina 2")
      this.fnTrowToast(target, text);
      return
    }
    if(jsonDragged.valor == 4 && jsonDragged.type == 14 && this.jsonBlnOpenModeActions.isRawMaterials1 && !this.jsonBlnOpenModeActions.isRawMaterials2){
      let target = "#errorGeneral";
      let text = this.getErrorLabel('1001');
      console.log("despues de mostrar mensaje de que debe comprar los materiales pendinets para maquina 2")
      this.fnTrowToast(target, text);
      return
    }
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id) || this.jsonBlnOpenModeActions.isBuyMaterials || (this.cdkLstSuppliers.length != 0 && jsonDragged.type != 11)) {
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // paso 21 mover row materials a work in progress
      this.status == 21 && event.previousContainer.id == "cdkLstMaterials" && jsonDragged.type == 7 && !thereAre4Units
    ) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnDroppedPiece(event, jsonDragged);
    } else if (
      // open mode mover los row materials a inside machine work in progress
      event.previousContainer.id == "cdkLstMaterials" && ((jsonDragged.type == 7 && !this.jsonBlnOpenModeActions.isRawMaterials2) || (jsonDragged.type == 14 && this.jsonBlnOpenModeActions.isRawMaterials2)) && this.isModeOpen && this.currentMonth >= 2 && this.status >= 3
    ) {
      if ((!thereAre4Units || !thereAre6Units && this.jsonBlnOpenModeActions.isEfficiency2) && this.currentMonth >= 2) {
        jsonDragged.draggable = false;
        jsonDragged.draggingBy = "";
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        this.fnDroppedPiece(event, jsonDragged);
        // checkear el paso de load raw materials to machine
        // this.checkBoxes[this.currentMonth >= 3?6:4].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        let isCompleteMachine = (totMaterials == 4 && this.jsonBlnOpenModeActions.isRawMaterials2) ? true :
          (totMaterials == 6 && this.jsonBlnOpenModeActions.isEfficiency2 && this.jsonBlnOpenModeActions.isRawMaterials2) ? true :
            (totMaterials == 18 && this.jsonBlnOpenModeActions.isEfficiency2) ? true :
              (totMaterials == 12 && !this.jsonBlnOpenModeActions.isRawMaterials2 && !this.jsonBlnOpenModeActions.isEfficiency2) ? true : false;
        if (isCompleteMachine)
          // this.checkCheckbox(4, true);
          this.checkCheckbox(7, true);  
        }

    } else if (
      // rigurous mode mover los row materials a inside machine work in progress
      event.previousContainer.id == "cdkLstMaterials" && ((jsonDragged.type == 7 && !this.jsonBlnOpenModeActions.isRawMaterials2) || (jsonDragged.type == 14 && this.jsonBlnOpenModeActions.isRawMaterials2)) && this.currentMonth >= 2
    ) {
      // if ((!thereAre4Units || !thereAre6Units) && this.currentMonth >= 2) {
      if ((!thereAre4Units || (!thereAre6Units && this.jsonBlnOpenModeActions.isEfficiency1)) && this.currentMonth >= 2) {
        jsonDragged.draggable = false;
        jsonDragged.draggingBy = "";
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        this.fnDroppedPiece(event, jsonDragged);
        // checkear el paso de load raw materials to machine
        // this.checkBoxes[this.currentMonth >= 3?6:4].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        let isCompleteMachine = (totMaterials == 4 && this.jsonBlnOpenModeActions.isRawMaterials2) ? true :
          (totMaterials == 6 && this.jsonBlnOpenModeActions.isEfficiency2 && this.jsonBlnOpenModeActions.isRawMaterials2) ? true :
            (totMaterials == 18 && this.jsonBlnOpenModeActions.isEfficiency2) ? true :
              (totMaterials == 12 && !this.jsonBlnOpenModeActions.isRawMaterials2 && !this.jsonBlnOpenModeActions.isEfficiency2) ? true : false;
        if(this.jsonBlnOpenModeActions.isSecondMachine){
            if(this.cdkLstMaterials.length == 0 && isCompleteMachine){
              // this.checkCheckbox(4, true);
              this.checkCheckbox(7, true);
            }
        }else{
            if (isCompleteMachine){
              // this.checkCheckbox(4, true);
              this.checkCheckbox(7, true);
            }
        }
      }

    } else if (
      // para improvement efficiency
      event.previousContainer.id == 'cdkLstCapEx' && (jsonDragged.type == 11) && this.currentMonth >= 3 && !this.jsonBlnOpenModeActions.isEfficiency2
    ) {
      if (!this.jsonBlnOpenModeActions.isEfficiency1) {
        this.errorControl(event, jsonDragged);
        //this.fnTrowToast("#errorGeneral", "85.- Implement improvement on your first machine");
        return;
      }
      this.spinner.show()
      this.jsonBlnOpenModeActions['isEfficiency2'] = true;
      this.socketService.setBlnControlModeOpen('isEfficiency2', true, this.roomId, this.teamId);
      this.deleteCoinByAreaID('cdkLstCapEx', jsonDragged.id);
      setTimeout(() => {
        this.socketService.deleteCoin('cdkLstCapEx', jsonDragged, this.roomId, this.teamId);
        setTimeout(() => {
          this.socketService.createCoin('cdkLstCapEx', {
            valor: 10,
            id: null,
            draggable: false,
            type: 4,
          }, this.roomId, this.teamId);
          setTimeout(() => {
            let newArray = [];
            if (this.jsonBlnOpenModeActions.isRawMaterials1) {
              newArray = this.createArrayCoins(1, 2, 14);
            } else {
              newArray = this.createArrayCoins(3, 2, 7);
            }
            this.socketService.splitCoin(this.cdkLstSuppliers.concat(newArray), 'cdkLstSuppliers', this.roomId, this.teamId);
          }, 500);
        }, 500);
      }, 500);
    } else if (this.currentMonth >= 2) {
      //this.fnTrowToast("#errorGeneral", "67.- Load Raw Materials from the Inventory Area!");
      this.errorControl(event, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    }
  }

  // fnDroppedOnInsideMachineWorkInProgress2(event: CdkDragDrop<any>) {
  //   const jsonDragged = event.previousContainer.data[this.indexItemDragged];
  //   jsonDragged.draggable = false;
  //   jsonDragged.draggingBy = '';
  //   let totMaterials = 0;
  //   let thereAre4Units = false;
  //   let thereAre6Units = false;
  //   this.cdkLstInsideMachineWorkInProgress2.forEach(item => {
  //     totMaterials += item.valor;
  //   });
  //   totMaterials += jsonDragged.valor;
  //   if (totMaterials > 12) {
  //     thereAre4Units = true;
  //   }
  //   if (totMaterials > 18) {
  //     thereAre6Units = true;
  //   }
  //   if (event.previousContainer.data[this.indexItemDragged].type == 6 ||  this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
  //     this.fnStatusPiece(event.container.id, false, jsonDragged);
  //     return;
  //   }
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(
  //       event.container.data,
  //       this.indexItemDragged,
  //       event.currentIndex
  //     );
  //     this.fnStatusPiece(event.container.id, false, jsonDragged);
  //   } else if (
  //     // paso 21 mover row materials a work in progress
  //     this.status == 21 && event.previousContainer.id == "cdkLstMaterials" && jsonDragged.type == 7 && !thereAre4Units
  //   ) {
  //     transferArrayItem(
  //       event.previousContainer.data,
  //       event.container.data,
  //       this.indexItemDragged,
  //       event.currentIndex
  //     );
  //     this.fnDroppedPiece(event, jsonDragged);
  //   } else if (
  //     // open mode mover los row materials a inside machine work in progress
  //     event.previousContainer.id == "cdkLstMaterials" && (jsonDragged.type == 7 || jsonDragged.type == 14)  && this.isModeOpen && this.currentMonth >= 2 && this.status >= 3
  //   ) {
  //     if ((!thereAre4Units || !thereAre6Units) && this.currentMonth >= 2) {
  //       jsonDragged.draggable = false;
  //       jsonDragged.draggingBy = "";
  //       transferArrayItem(
  //         event.previousContainer.data,
  //         event.container.data,
  //         this.indexItemDragged,
  //         event.currentIndex
  //       );
  //       this.fnDroppedPiece(event, jsonDragged);
  //       // checkear el paso de load raw materials to machine
  //       // this.checkBoxes[this.currentMonth >= 3?6:4].blnCheck = true;
  //       // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
  //       this.checkCheckbox(4, true);
  //     }

  //   } else if (
  //     // rigurous mode mover los row materials a inside machine work in progress
  //     event.previousContainer.id == "cdkLstMaterials" && (jsonDragged.type == 7 || jsonDragged.type == 14)  && this.currentMonth >= 2
  //   ) {
  //     // if ((!thereAre4Units || !thereAre6Units) && this.currentMonth >= 2) {
  //       if ((!thereAre4Units) && this.currentMonth >= 2) {
  //       jsonDragged.draggable = false;
  //       jsonDragged.draggingBy = "";
  //       transferArrayItem(
  //         event.previousContainer.data,
  //         event.container.data,
  //         this.indexItemDragged,
  //         event.currentIndex
  //       );
  //       this.fnDroppedPiece(event, jsonDragged);
  //       // checkear el paso de load raw materials to machine
  //       // this.checkBoxes[this.currentMonth >= 3?6:4].blnCheck = true;
  //       // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
  //       this.checkCheckbox(4, true);
  //     }

  //   } else if (this.currentMonth >= 2) {
  //     this.fnTrowToast("#errorGeneral", "Load Raw Materials from the Inventory Area!");
  //   } else {
  //     this.errorControl(event, jsonDragged);
  //     this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
  //   }
  // }

  fnDroppedOnPayables60(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // modo open y riguroso, pagar despues a suppliers
      event.previousContainer.id == "cdkLstSuppliers" && jsonDragged.type == 3
    ) {
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      // this.jsonBlnOpenModeActions.isBuyMaterials = false;
      // this.socketService.setBlnControlModeOpen('isBuyMaterials', false, this.roomId, this.teamId);
      // setTimeout(() => {
      //   this.jsonBlnOpenModeActions.showMessageOwe12 = false;
      //   this.socketService.setBlnControlModeOpen('showMessageOwe12', false, this.roomId, this.teamId);
      // }, 3000);
      // this.fnDroppedPiece(event, jsonDragged);
      // // checkear paso de comprar materiales
      // // this.checkBoxes[this.currentMonth >= 3?5:3].blnCheck = true;
      // // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      //   this.checkCheckbox(3, true);
      this.spinner.show('my-custom-spinner')
      setTimeout(() => {
        this.fnDroppedPiece(event, jsonDragged);
        setTimeout(() => {
          // let auxSupliers = this.cdkLstSuppliers.filter(x => x.type = 7 )
          // Buy Raw Materials
          if(this.jsonBlnOpenModeActions.isSecondMachine){
            console.log(this.cdkLstSuppliers)

            if(this.cdkLstSuppliers.length==0){
              console.log("ENTRA A CHEQUEAR")
              this.checkCheckbox(6, true);
            }
          }else{
            console.log("ENTRA A CHEQUEAR")
            // Buy Raw Materials
            // this.checkCheckbox(3, true);
            this.checkCheckbox(6, true);
          }
          setTimeout(async () => {
            const x = await this.payRowMaterials();
            if(x){
              console.log("apagamos")
              setTimeout(() => {
            this.spinner.hide('my-custom-spinner')
                
              }, 500);
                    
            }
          }, 200);
        }, 200);
      }, 200);
      this.cdkLstPayables60Aux = [...this.cdkLstPayables60Aux];
    } else if (
      event.previousContainer.id == 'cdkLstCapEx' && jsonDragged.type == 3 && jsonDragged.valor == 10
    ) {
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.jsonBlnOpenModeActions.isPayBill = false;
      this.socketService.setBlnControlModeOpen('isPayBill', false, this.roomId, this.teamId);
      setTimeout(() => {
        this.jsonBlnOpenModeActions.showMessageOwe10 = false;
        this.socketService.setBlnControlModeOpen('showMessageOwe10', false, this.roomId, this.teamId);
        setTimeout(() => {
          //Deprecado nuevos checkboxes
          // this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = true;
          // this.fnScrollCheckList(this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4]);
          // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          //Deprecado nuevos checkboxes
          this.checkCheckbox(4,true);

        }, 200);
      }, 200);
      this.fnDroppedPiece(event, jsonDragged);
      this.cdkLstPayables60Aux = [...this.cdkLstPayables60Aux];
    } else if (
      event.previousContainer.id == 'cdkLstCapEx' && jsonDragged.type == 3
    ) {
      jsonDragged.draggable = false;
      jsonDragged.draggingBy = "";
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      // this.jsonBlnOpenModeActions.isBuyBuild = false;
      // this.socketService.setBlnControlModeOpen('isBuyBuild', false, this.roomId, this.teamId);
      setTimeout(() => {
        this.jsonBlnOpenModeActions.showMessageOwe20 = false;
        this.socketService.setBlnControlModeOpen('showMessageOwe20', false, this.roomId, this.teamId);
        if (this.jsonBlnOpenModeActions.isSecondMachine) {
          setTimeout(() => {
          //Deprecado nuevos checkboxes
            // this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = true;
            // this.fnScrollCheckList(this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4]);
            // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          //Deprecado nuevos checkboxes
          this.checkCheckbox(4,true);

          }, 200);
        }
      }, 200);
      this.fnDroppedPiece(event, jsonDragged);
      this.cdkLstPayables60Aux = [...this.cdkLstPayables60Aux];
    } else {
      this.errorControl(event, jsonDragged);
    }

  }

  async fnDroppedOnPayables30(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else if (
      // para paso 2 inicio de mes 3
      this.status == 2 && event.previousContainer.id == "cdkLstPayables60" && jsonDragged.type == 3
    ) {
      const x = await this.fnIsReadyPaso3(jsonDragged, "cdkLstPayables60");
      if (x) {
        //El siguiente if active las bandera de update liabilities en caso de que no se tengan deudas en el mes
        if (this.cdkLstPayables30Aux.length === 0) {
          this.jsonBlnOpenModeActions.isPayablesPaid = true;
          console.log('There is no payables',this.jsonBlnOpenModeActions.isPayablesPaid);
          this.socketService.setBlnControlModeOpen('isPayablesPaid', true, this.roomId, this.teamId);
        }
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          this.indexItemDragged,
          event.currentIndex
        );
        if (this.cdkLstPayables60.length === 0) {
          this.boardService.clearLiabilitiesReceivablesUpdatesArea(this.workshopId, this.teamId, 'payables60').subscribe(rupd => {
            if (rupd.intResponse === 200) {
              this.liabilitiesReceivablesBeforeUpdates = rupd.jsonData;
            }
          });
        }
        if (event.container.data[event.currentIndex])
          event.container.data[event.currentIndex].draggingBy = "";
        this.socketService.dropACoin(event.previousContainer.id, event.container.id, jsonDragged, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, this.teamId, this.status);
        this.socketService.disablePiece(event.container.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
      }
    } else if (event.previousContainer.id == "cdkLstPayables60") {
      this.fnTrowToast("#errorPaso34", this.getErrorLabel('1023'));
      this.fnStatusPiece(event.previousContainer.id, false, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }

  }

  fnDroppedOnOther(event: CdkDragDrop<any>) {
    const jsonDragged = event.previousContainer.data[this.indexItemDragged];
    if (event.previousContainer.data[this.indexItemDragged].type == 6 || this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id)) {
      this.errorControl(event, jsonDragged);
      this.fnStatusPiece(event.container.id, false, jsonDragged);
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        this.indexItemDragged,
        event.currentIndex
      );
      this.fnStatusPiece(event.container.id, false, jsonDragged);
    } else {
      this.errorControl(event, jsonDragged);
    }

  }


  //#endregion funciones dropped
  fnLoadBoardBackground(colorTeam) {
    /* { id: 1, selected: false, color: '#54a4bc', disabled: false },
       { id: 2, selected: false, color: '#accc94', disabled: false },
       { id: 3, selected: false, color: '#8ccce4', disabled: false },
       { id: 4, selected: false, color: '#7f2378', disabled: false },
       { id: 5, selected: false, color: '#083584', disabled: false },
       { id: 6, selected: false, color: '#4b0fae', disabled: false },
       { id: 7, selected: false, color: '#af753a', disabled: false },
       { id: 8, selected: false, color: '#84eeeb', disabled: false },
       { id: 9, selected: false, color: '#ffe998', disabled: false }*/
    switch (colorTeam) {
      case null: {
        this.strBackground = "#4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this.blnBackgroundBlue = true;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#7faeff": {
        this.strBackground = "#7faeff";
        //this.strBackground = "./../../../assets/MIDTHEME3.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#50b1a8": {
        this.strBackground = "#50b1a8";
        //this.strBackground = "./../../../assets/MIDTHEME2.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;//true;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#af753a": {
        this.strBackground = "#af753a";
        //this.strBackground = "./../../../assets/MIDTHEME1.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#7f2378": {
        this.strBackground = "#7f2378";
        //this.strBackground = "./../../../assets/DARKTHEME3.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = true;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#083584": {
        this.strBackground = "#083584";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = true;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#4b0fae": {
        this.strBackground = "4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME1.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = true;
        this.blnBackground7_8 = false;
        this.blnBackground9 = false;
        break;
      }
      case "#bec3ff": {
        this.strBackground = "#bec3ff";
        //this.strBackground = "./../../../assets/LIGHTTHEME3.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = true;
        this.blnBackground9 = false;
        break;
      }
      case "#84eeeb": {
        this.strBackground = "#84eeeb";
        //this.strBackground = "./../../../assets/LIGHTTHEME2.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = true;
        this.blnBackground9 = false;
        break;
      }
      case "#ffe998": {
        this.strBackground = "#ffe998";
        //this.strBackground = "./../../../assets/LIGHTTHEME1.jpg";
        this.blnBackgroundBlue = false;
        this.blnBackground2 = false;
        this.blnBackground4 = false;
        this.blnBackground5 = false;
        this.blnBackground6 = false;
        this.blnBackground7_8 = false;
        this.blnBackground9 = true;
        break;
      }
      default: {
        this.strBackground = "#4b0fae";
        //this.strBackground = "./../../../assets/DARKTHEME2.jpg";
        this.blnBackgroundBlue = true;
        break;
      }
    }
  }

  fnAssignPiecesArea(board: Array<any>) {
    return new Promise(resolve => {
      board.forEach(item => {
        if (item.Valor == 0)
          return;
        switch (item.AreaDrag) {
          case 1:
            this.cdkLstTrashCash.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 2:
            this.cdkLstBank.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 3:
            this.cdkLstCapEx.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 4:
            this.cdkLstLabourP.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 5:
            this.cdkLstSuppliers.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 6:
            this.cdkLstMaterials.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 7:
            this.cdkLstInsideMachineCapex.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 8:
            this.cdkLstInsideMachinePeople.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 9:
            this.cdkLstInsideMachineWorkInProgress.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 10:
            this.cdkLstLandBuild.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 11:
            this.cdkLstFinishedFI.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 12:
            this.cdkLstLoans90.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 13:
            this.cdkLstLoans60.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 14:
            this.cdkLstLoans30.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 15:
            this.cdkLstReceivables90.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 16:
            this.cdkLstReceivables60.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 17:
            this.cdkLstReceivables30.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: "",
                strDescription: item.strDescription
              }
            );
            break;
          case 18:
            this.cdkLstCapitalS.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 19:
            this.cdkLstRetainedE.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 20:
            this.cdkLstMachineOver.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 21:
            this.cdkLstDeprecation.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 22:
            this.cdkLstChangeI.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 23:
            this.cdkLstMarketingS.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 24:
            this.cdkLstAdvertasing.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 25:
            this.cdkLstAdministration.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 26:
            this.cdkLstAdminOver.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 27:
            this.cdkLstRecruitmentT.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 28:
            this.cdkLstRent.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 29:
            this.cdkLstInterest.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 30:
            this.cdkLstCash.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                strDescription: item.strDescription
              }
            );
            break;
          case 31:
            this.cdkLstCostGS.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                paid: false
              }
            );
            break;
          case 32:
            this.cdkLstOther.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 34:
            this.cdkLstIncomeBT.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 35:
            this.cdkLstNetIncome.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 36:
            this.cdkLstTaxes.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 37:
            this.cdkLstPayables60.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 38:
            this.cdkLstPayables30.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 39:
            this.cdkRles.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 40:
            this.cdkLstTrashCapitalS.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 41:
            this.cdkLstInsideMachineCapexCustomizing.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false
              }
            );
            break;
          case 47:
            this.cdkLstInsideMachineCapex2.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 48:
            this.cdkLstInsideMachinePeople2.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 49:
            this.cdkLstInsideMachineWorkInProgress2.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 57:
            this.cdkLstInsideMachineCapex3.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 58:
            this.cdkLstInsideMachinePeople3.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
          case 59:
            this.cdkLstInsideMachineWorkInProgress3.push(
              {
                // descripcion: 'bulto 20', 
                type: item.tipo,
                valor: item.Valor,
                id: item.StatusBoardId,
                draggable: false,
                draggingBy: ""
              }
            );
            break;
        }
      })
      resolve('listo');
    })

  }

  async agruparRoyals(item, idList) {
    if (
      ((this.jsonBlnOpenModeActions.isRawMaterials1 || this.jsonBlnOpenModeActions.isRawMaterials2) && this.jsonBlnOpenModeActions.showBill2) ||
      ((this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2 || this.cdkLstCapEx.some(item => item.type == 11) || this.cdkLstCapEx.some(item => item.type == 4 && item.valor == 10)) && this.jsonBlnOpenModeActions.showMessageOwe10)
    ) {
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1001'));
      return;
    }
    if (this.isOpenMarketDisabledBoard) {
      return;
    }
    let lista = [];
    let tipo = 0;
    let value = 0;
    let mensaje = this.getSingleTextLabel('142');
    let isMachine = false;
    let strGroup, isConfirmed;
    if (idList == 'cdkLstFinishedFI') {
      lista = this.cdkLstFinishedFI;
    } else if (idList == 'cdkLstInsideMachineWorkInProgress') {
      lista = this.cdkLstInsideMachineWorkInProgress;
      mensaje = this.jsonBlnOpenModeActions.isEfficiency1 ? `${this.getSingleTextLabel('143')} 6` : `${this.getSingleTextLabel('143')} 4`;
      strGroup = this.jsonBlnOpenModeActions.isEfficiency1 ? "6" : "4";
      isMachine = true;
    } else if (idList == 'cdkLstInsideMachineWorkInProgress2') {
      lista = this.cdkLstInsideMachineWorkInProgress2;
      mensaje = this.jsonBlnOpenModeActions.isEfficiency2 ? `${this.getSingleTextLabel('143')} 6` : `${this.getSingleTextLabel('143')} 4`;
      strGroup = this.jsonBlnOpenModeActions.isEfficiency1 ? "6" : "4";
      isMachine = true;
    }
    // if(this.jsonBlnOpenModeActions.isRawMaterials1 && this.currentMonth>=3){
    //   tipo = 16; value = 4;
    // } else if(this.jsonBlnOpenModeActions.isEfficiency1 && this.currentMonth>=3){
    //   tipo = 17  ; value = 5;
    // } else {
    //   tipo = 8; value = 3;
    // }
    tipo = item.type;
    if (tipo == 16) {
      value = 4;
    } else if (tipo == 17) {
      value = 5;
    } else if (tipo == 8 || tipo == 7) {
      value = 3
    } else if (tipo == 18) {
      value = 3
    }

    console.log('datos entrantes:', item);
    let intIndexArchivo = -1;
    lista.map((value, index) => {
      if (value.id == item.id) {
        intIndexArchivo = index;
      }
    });
    this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, true, item);
    if (isMachine) {
      // const { isConfirmed: respIsConfirmed } = await Swal.fire({
      //   title: 'Confirm',
      //   width: '18em',
      //   text: mensaje,
      //   inputValue: '',
      //   showCancelButton: true,
      //   confirmButtonColor: '#5EA0CC',
      //   cancelButtonColor: '#CCCCCC',
      //   confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
      //   cancelButtonText: 'Cancel',
      //   inputValidator: (value) => {
      //     if (!value || (parseInt(value) > 20 || parseInt(value) < 1)) {
      //       return '37.- Please select a correct number!';
      //     }
      //   }
      // });
      // isConfirmed = respIsConfirmed;
      const tmpTotal = lista.reduce((acc, item) => acc + parseInt(item.valor), 0);
      if (tmpTotal < value * (+strGroup)) {
        return;
      }
      console.log("ENTRA AL ALERT");
      this.dialogRefAgrupar = this._matDialog.open(PurchaseObjectsComponent, {
        position: { top: '460px', left: '930px' },
        width: '160px',
        height: '75px',
        panelClass: 'my-dialog',
        disableClose: false,
        data: {
          isConfirmed: isConfirmed,
          message: mensaje,
          input: 'no',
          arrTextLabels: this.arrTextLabels,
        }
      });
      this.dialogRefAgrupar.afterClosed().subscribe((resp) => {
        if(resp==undefined){
          this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
          console.log("habilitando pieza");
        }
  
        isConfirmed = resp.isConfirmed;
        if (isConfirmed) {
          console.log(strGroup, "tomaaar");
          const group = strGroup;
          let total = 0;
          let listOtherTypes = lista.filter(item => item.type != tipo);
          lista = lista.filter(item => item.type == tipo);
          lista.forEach(item => {
            total += parseInt(item.valor);
          })
          if (total >= value * group) {
            console.log("Si hay los suficientes");
            lista = [];
            total = total - value * group;
            lista.push({
              valor: value * group,
              type: tipo,
              id: null,
              draggable: false,
            });
            const packege3 = Math.floor(total / value);
            const countGroup4 = Math.floor(packege3 / 4);
            const countPackege3 = packege3 % 4;
            console.log("grupos de 4: ", countGroup4);
            console.log("paquetes individuales: ", countPackege3);
            if (!Number.isFinite(countGroup4) || Number.isNaN(countPackege3)) {
              return;
            }
            for (let i = 0; i < countGroup4; i++) {
              lista.push({
                valor: value * 4,
                type: tipo,
                id: null,
                draggable: false,
              })
            }
            for (let i = 0; i < countPackege3; i++) {
              lista.push({
                valor: value,
                type: tipo,
                id: null,
                draggable: false,
              })
            }
            lista = lista.concat(listOtherTypes);
            this.socketService.splitCoin(lista, idList, this.roomId, this.teamId);
          } else {
            console.log("No hay lo suficientes");
            console.log("No hay lo suficientes");
            Swal.fire({
              html: this.getErrorLabel('1069'),
              icon: 'error'
            });
          }

        } else {
          this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
          console.log("habilitando pieza");
        }
      });
    } else {
      this.dialogRefAgrupar = this._matDialog.open(PurchaseObjectsComponent, {
        position: { top: '10px', left: '500px' },
        width: '180px',
        height: '122px',
        panelClass: 'my-dialog',
        disableClose: false,
        data: {
          isConfirmed: isConfirmed,
          message: mensaje,
          strGroup: strGroup,
          input: 'yes',
          arrTextLabels: this.arrTextLabels,
        }
      });

      this.dialogRefAgrupar.afterClosed().subscribe((resp) => {
        if(resp==undefined){
          this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
          console.log("habilitando pieza");
        }
        isConfirmed = resp.isConfirmed;
        strGroup = resp.strGroup;
        if (isConfirmed && strGroup != '') {
          console.log(strGroup, "tomaaar");
          const group = parseInt(strGroup);
          let total = 0;
          let auxLista = [];
          lista.forEach(item => {
            if (item.type == tipo) {
              total += parseInt(item.valor);
            } else {
              auxLista.push(item);
            }
          })
          if (total >= value * group) {
            console.log("Si hay los suficientes");
            lista = [];
            total = total - value * group;
            lista.push({
              valor: value * group,
              type: tipo,
              id: null,
              draggable: false,
            });
            const packege3 = Math.floor(total / value);
            const countGroup20 = Math.floor(packege3 / 20);
            const lessPackege = packege3 % 20;
            console.log("grupos de 20: ", countGroup20);
            console.log("paquetes individual: ", lessPackege);
            for (let i = 0; i < countGroup20; i++) {
              lista.push({
                valor: value * 20,
                type: tipo,
                id: null,
                draggable: false,
              })
            }
            lista.push({
              valor: lessPackege * value,
              type: tipo,
              id: null,
              draggable: false,
            });
            lista = lista.concat(auxLista);
            this.spinner.show()
            this.socketService.splitCoin(lista, idList, this.roomId, this.teamId);
          } else {
            console.log("No hay lo suficientes");
            if(idList == 'cdkLstFinishedFI'){
              Swal.fire({
                html: this.getErrorLabel('1029.1'),
                icon: 'error'
              });
            }else{
              Swal.fire({
                html: this.getErrorLabel('1069'),
                icon: 'error'
              });
            }
           
          }

        } else {
          this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
          console.log("habilitando pieza");
        }
      });

      // const { value: respStrGroup, isConfirmed: respIsConfirmed } = await Swal.fire({
      //   title: 'Confirm',
      //   width: '18em',
      //   input: 'number',
      //   text: mensaje,
      //   inputValue: '',
      //   showCancelButton: true,
      //   confirmButtonColor: '#5EA0CC',
      //   cancelButtonColor: '#CCCCCC',
      //   confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
      //   cancelButtonText: 'Cancel',
      //   inputValidator: (value) => {
      //     if (!value || (parseInt(value) > 20 || parseInt(value) < 1)) {
      //       return 'Please select a correct number!';
      //     }
      //   }
      // });
      // strGroup = respStrGroup; isConfirmed = respIsConfirmed;
    }
    // if (isConfirmed && strGroup != '') {
    //   console.log(strGroup, "tomaaar");
    //   const group = parseInt(strGroup);
    //   let total = 0;
    //   let auxLista = [];
    //   lista.forEach(item => {
    //     if(item.type == tipo){
    //       total += parseInt(item.valor);
    //     } else {
    //       auxLista.push(item);
    //     }
    //   })
    //   if (total >= value * group) {
    //     console.log("Si hay los suficientes");
    //     lista = [];
    //     total = total - value * group;
    //     lista.push({
    //       valor: value * group,
    //       type: tipo,
    //       id: null,
    //       draggable: false,
    //     });
    //     const packege3 = Math.floor(total / value);
    //     const countGroup20 = Math.floor(packege3 / 20);
    //     const lessPackege = packege3 % 20;
    //     console.log("grupos de 20: ", countGroup20);
    //     console.log("paquetes individual: ", lessPackege);
    //     for (let i = 0; i < countGroup20; i++) {
    //       lista.push({
    //         valor: value * 20,
    //         type: tipo,
    //         id: null,
    //         draggable: false,
    //       })
    //     }
    //     lista.push({
    //       valor: lessPackege * value,
    //       type: tipo,
    //       id: null,
    //       draggable: false,
    //     });
    //     lista = lista.concat(auxLista);
    //     this.socketService.splitCoin(lista, idList, this.roomId, this.teamId);
    //   } else {
    //     console.log("No hay lo suficientes");
    //     Swal.fire({
    //       title: "36.- You do not have that much cash!",
    //       icon: 'error'
    //     });
    //   }

    // } else {
    //   this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
    //   console.log("habilitando pieza");
    // }
  }


  async agruparMaterials(item, idList) {
    console.log(this.improveStatus,this.status, this.improvementsOptionsSelected, this.jsonBlnOpenModeActions, this.checkBoxes);
    let auxImprovement=false;
    this.improvementsOptionsSelected.forEach(obj =>{
      if(obj['option']==2 || obj['option']==6){ //Equipo con mejora de Efficiency 
        console.log("Option 2");
        auxImprovement = true;
    }});
    let auxCycles = this.isProjectsEnableByCurrentMonth; 
    if((auxImprovement && this.status < 3 && auxCycles)||(this.status == -1)){
      console.log("Agrupar Materiales no valido")
      return;
    }
    if(this.cdkLstMaterials.length!=0 && this.jsonBlnOpenModeActions.isSecondMachine && this.status == 3){
      console.log("Agrupar Materiales no valido")
      return;
    }
    let auxChecklist = false;
    if((!this.getCheckCheckbox(4).blnCheck && this.getCheckCheckbox(4).show == 1 || !this.getCheckCheckbox(5).blnCheck && this.getCheckCheckbox(5).show == 1)){
      auxChecklist = true;
    }
    // this.checkBoxes.forEach(obj =>{ 
    //   if(obj.id == 4 && obj.blnCheck == false && (obj.title == 'Pay Recruitment & Training' || obj.title == 'Buy or Upgrade Equipment')){ //Comprobar que ya haya completado la mejora
    //     auxChecklist = true;
    //     console.log(obj); 
    //   } 
    // });
    if(auxChecklist){
      console.log("Agrupar Materiales no valido")
      return;
    }
    if ((item.type != 7 && item.type != 14) || this.isOpenMarketDisabledBoard) {
      return;
    }
    let top = '0px', left = '0px';
    if (
      ((this.jsonBlnOpenModeActions.isRawMaterials1 || this.jsonBlnOpenModeActions.isRawMaterials2) && this.jsonBlnOpenModeActions.showBill2) ||
      ((this.cdkLstCapEx.some(item => item.type == 11) || this.cdkLstCapEx.some(item => item.type == 4 && item.valor == 10)))
    ) {
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1001'));
      return;
    }
    let lista = [];
    let tipo = 0;
    let value = 0;
    let materials = 0;
    this.cdkLstMaterials.forEach(item => {
      materials += item.valor;
    })
    let mensaje = this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isEfficiency2 && idList == 'cdkLstSuppliers' ? `${this.getSingleTextLabel('144')} 6` :
      this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isEfficiency2 && idList == 'cdkLstMaterials' ? `${this.getSingleTextLabel('143')} 6` :
        this.jsonBlnOpenModeActions.isEfficiency1 && !this.jsonBlnOpenModeActions.isSecondMachine && idList == 'cdkLstSuppliers' ? `${this.getSingleTextLabel('144')} 6` :
          this.jsonBlnOpenModeActions.isEfficiency1 && !this.jsonBlnOpenModeActions.isSecondMachine && idList == 'cdkLstMaterials' ? `${this.getSingleTextLabel('143')} 6` :
            (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2) && materials == 0 && idList == 'cdkLstSuppliers' ? `${this.getSingleTextLabel('144')} 6` :
              (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2) && materials == 0 && idList == 'cdkLstMaterials' ? `${this.getSingleTextLabel('143')} 6` :
                (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2) && materials != 0 && idList == 'cdkLstSuppliers' ? `${this.getSingleTextLabel('144')} 6` :
                  (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2) && materials != 0 && idList == 'cdkLstMaterials' ? `${this.getSingleTextLabel('143')} 6` :
                    idList == 'cdkLstMaterials' ? "Move 4" : "Purchase 4";
    let option;
    if (mensaje == 'Purchase 6' || mensaje == 'Move 6' || mensaje == `${this.getSingleTextLabel('143')} 6` || mensaje == `${this.getSingleTextLabel('144')} 6`) {
      option = 6;
    } else {
      option = 4;
    }
    if (idList == 'cdkLstSuppliers') {
      lista = this.cdkLstSuppliers;
      top = '391px';
      left = '915px';
    } else if (idList == 'cdkLstMaterials') {
      lista = this.cdkLstMaterials;
      top = '570px';
      left = '820px';
    }
    if (item.type == 14 && this.currentMonth >= 3) {
      tipo = 14; value = 1;
    } else {
      tipo = 7; value = 3;
    }
    const tmpTotal = lista.reduce((acc, item) => acc + parseInt(item.valor), 0);
    if (tmpTotal < option * value) {
      return;
    }
    let intIndexArchivo = -1;
    lista.map((value, index) => {
      if (value.id == item.id) {
        intIndexArchivo = index;
      }
    });
    this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, true, item);
    let isConfirmed;
    this.dialogRefAgrupar = this._matDialog.open(PurchaseObjectsComponent, {
      disableClose: false,
      width: '160px',
      height: '75px',
      panelClass: 'my-dialog',
      position: { top: top, left: left },
      data: {
        isConfirmed: isConfirmed,
        message: mensaje,
        input: 'no',
        arrTextLabels: this.arrTextLabels,
      }
    });

    let strGroup = option;
    this.dialogRefAgrupar.afterClosed().subscribe((resp) => {
      if(resp==undefined){
        this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
        console.log("habilitando pieza");
      }
      isConfirmed = resp.isConfirmed;
      if (isConfirmed) {
        const group = strGroup;
        let total = 0;
        let listOtherTypes = lista.filter(item => item.type != tipo);
        lista = lista.filter(item => item.type == tipo);
        lista.forEach(item => {
          total += parseInt(item.valor);
        })
        if (total >= value * group) {
          lista = [];
          total = total - value * group;
          lista.push({
            valor: value * group,
            type: tipo,
            id: null,
            draggable: false,
          });
          let remainingGroup = 4;
          if (total / (value * group) >= 1) {
            remainingGroup = group;
          }
          const packege3 = Math.floor(total / value);
          const countGroup4 = Math.floor(packege3 / remainingGroup);
          const countPackege3 = packege3 % remainingGroup;
          for (let i = 0; i < countGroup4; i++) {
            lista.push({
              valor: value * remainingGroup,
              type: tipo,
              id: null,
              draggable: false,
            })
          }
          for (let i = 0; i < countPackege3; i++) {
            lista.push({
              valor: value,
              type: tipo,
              id: null,
              draggable: false,
            })
          }
          lista = lista.concat(listOtherTypes);
          this.socketService.splitCoin(lista, idList, this.roomId, this.teamId);
        } else {
          Swal.fire({
            html: this.getErrorLabel('1069'),
            icon: 'error'
          });
        }
      } else {
        this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
      }
    });
  }

  fnDepreciateMachine(item, idList) {
    console.log("entra");
    let lista = [];
    let tipo = 0;
    let mensaje = "";
    if (this.isOpenMarketDisabledBoard) {
      return;
    }
    if (item.draggable) {
      return;
    }
    if (
      idList == 'cdkLstInsideMachineCapex' && !this.jsonBlnOpenModeActions.isDepreciateMachine1 &&
      ((this.status == 25 && this.currentMonth == 1) || (this.currentMonth >= 2))
    ) {
      lista = this.cdkLstInsideMachineCapex;
      tipo = 4;
      mensaje = "How Many?";
    } else if (idList == 'cdkLstInsideMachineCapex2' && !this.jsonBlnOpenModeActions.isDepreciateMachine2 &&
      (this.currentMonth >= 2)) {
      lista = this.cdkLstInsideMachineCapex2;
      tipo = 4;
      mensaje = "How Many?";
    } else {
      return;
    }
    /*if(lista.length > 1 || this.cdkLstDeprecation.length >= 1){
      return;
    }*/
    this.jsonBlnOpenModeActions.isDepreciation = true;
    console.log('datos entrantes:', item);
    let intIndexArchivo = -1;
    lista.map((value, index) => {
      if (value.id == item.id) {
        intIndexArchivo = index;
      }
    });
    this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, true, item);

    /*const { value: strPartTake, isConfirmed: isConfirmed } = await Swal.fire({
      //title: 'Confirm',
      input: 'number',
      text: mensaje,
      inputValue: '',
      showCancelButton: true,
      confirmButtonText: '&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#5EA0CC',
      cancelButtonColor: '#CCCCCC',
      width: 300,
      inputValidator: (value) => {
        if (parseInt(value) != 1 && idList == 'cdkLstInsideMachineCapex') {
          return 'Deprecation is 1 per month!';
        } else if (parseInt(value) < 4 && idList == 'cdkLstCash' && this.status == 28) {
          return 'You have to pay EVERYONE! Admin is 4';
        } else if (parseInt(value) > 4 && idList == 'cdkLstCash' && this.status == 28) {
          return 'Does your CFO know you are about to overpay your expenses?';
        }
      }
    });*/


    let isConfirmed;
    let strPartTake = '';

    console.log("ENTRA AL ALERT")
    this.dialogRefPartition = this._matDialog.open(PartitionObjectsComponent, {
      disableClose: false,
      width: '91px',
      height: '72px',
      panelClass: 'my-dialog',
      position: { top: '311px', left: '867px' },
      data: {
        isConfirmed: isConfirmed,
        strPartTake: strPartTake,
        arrTextLabels: this.arrTextLabels
      }
    });

    this.dialogRefPartition.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG MONEDAS", resp)
      if(resp==undefined){
        this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
        console.log("habilitando pieza");
      }

      isConfirmed = resp.isConfirmed;
      strPartTake = resp.strPartTake;
      //this.blnBackgroundBlue = resp.blnBackgroundBlue;




      if (isConfirmed && strPartTake != '' && strPartTake !== '0' &&  parseInt(strPartTake) <= 20) {
        console.log(strPartTake, "tomaaar");
        const partTake = parseInt(strPartTake);
        let total = 0;
        lista.forEach(item => {
          total += parseInt(item.valor);
        });
        if (total >= partTake) {
          console.log("Si hay los suficientes");
          lista = [];
          total = total - partTake;
          lista.push({
            valor: partTake,
            type: tipo,
            id: null,
            draggable: false,
          });
          const countGroup20 = Math.floor(total / 20);
          const lessPackege = total % 20;
          console.log("grupos de 20: ", countGroup20);
          console.log("paquetes individual: ", lessPackege);
          for (let i = 0; i < countGroup20; i++) {
            lista.push({
              valor: 20,
              type: tipo,
              id: null,
              draggable: false,
            })
          }
          if (lessPackege > 0) {
            lista.push({
              valor: lessPackege,
              type: tipo,
              id: null,
              draggable: false,
            });
          }
          this.socketService.splitCoin(lista, idList, this.roomId, this.teamId);
          console.log("Lista a mandar", lista);
        } else {
          console.log("No hay lo suficientes");
          Swal.fire({
            html: this.getErrorLabel('1069'),
            icon: 'error'
          });
        }
      } else {
        this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
        console.log("habilitando pieza");
      }
    });
  }

  async fnverdatos(item, idList) {
    if (this.isOpenMarketDisabledBoard) {
      return;
    }
    if (this.status == 19) {
      this.blnMove = true;
    }
    console.log(this.blnMove);

    if (this.currentMonth == 2) {
      console.log("HERE ENTRO A MOVERLE AL STAND MES 2 pedir dinero");
      this.blnMoveMonth2 = false;
    }

    if (this.currentMonth == 3) {
      console.log("HERE ENTRO A MOVERLE AL STAND MES 3 pedir dinero");
      this.blnMoveMonth3 = false;
    }
    if (this.currentMonth == 4) { this.blnMoveMonth4 = false; }
    if (this.currentMonth == 5) { this.blnMoveMonth5 = false; }
    if (this.currentMonth == 6) { this.blnMoveMonth6 = false; }

    if (item.draggable) {
      return;
    }


    let lista = [];
    let tipo = 0;
    let mensaje = "";
    if (idList == 'cdkLstCash') {
      lista = this.cdkLstCash;
      tipo = 1;

      mensaje = "How Many ?";
    } else if (idList == 'cdkLstInsideMachineCapex' && this.status == 25) {
      lista = this.cdkLstInsideMachineCapex;
      tipo = 4;
      mensaje = "How much depretiation are you taking?";
    } else if (idList == 'cdkLstInsideMachineCapex2') {
      lista = this.cdkLstInsideMachineCapex2;
      tipo = 4;
      mensaje = "How much depretiation are you taking?";
    }
    /* EFA 93 */
    else if (idList === 'cdkLstReceivables90') {
      lista = this.cdkLstReceivables90;
      tipo = 1;
    } else if (idList === 'cdkLstReceivables60') {
      lista = this.cdkLstReceivables60;
      tipo = 1;
    } else if (idList === 'cdkLstReceivables30') {
      lista = this.cdkLstReceivables30;
      tipo = 1;
    }
    /* EFA 93 f*/
    else {
      return;
    }
    console.log('datos entrantes:', item);
    let intIndexArchivo = -1;
    lista.map((value, index) => {
      if (value.id == item.id) {
        intIndexArchivo = index;
      }
    });
    this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, true, item);


    let isConfirmed;
    let strPartTake = '';

    console.log("ENTRA AL ALERT")
    this.dialogRefPartition = this._matDialog.open(PartitionObjectsComponent, {
      disableClose: false,
      width: '91px',
      height: '72px',
      panelClass: 'my-dialog',
      position: { top: '286px', left: '514px' },
      data: {
        isConfirmed: isConfirmed,
        strPartTake: strPartTake,
        arrTextLabels: this.arrTextLabels
      }
    });

    this.dialogRefPartition.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG MONEDAS", resp)
      if(resp==undefined){
        this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
        console.log("habilitando pieza");
      }

      isConfirmed = resp.isConfirmed;
      strPartTake = resp.strPartTake;
      //this.blnBackgroundBlue = resp.blnBackgroundBlue;


      console.log("aqui entra despues de cerrar", isConfirmed, strPartTake)

      if (isConfirmed && strPartTake != '') {
        console.log(strPartTake, "tomaaar");
        const partTake = parseInt(strPartTake);
        if (partTake <= 0) {
          Swal.fire({
            html: this.getErrorLabel('1070'),
            icon: 'error'
          });
          return;
        } else if (partTake > 20) {
          Swal.fire({
            html: this.getErrorLabel('1071'),
            icon: 'error'
          });
          return;
        }
        let total = 0;
        lista.forEach(item => {
          total += parseInt(item.valor);
        });
        if (total >= partTake) {
          console.log("Si hay los suficientes");
          lista = [];
          total = total - partTake;
          lista.push({
            valor: partTake,
            type: tipo,
            id: null,
            draggable: false,
          });
          const countGroup20 = Math.floor(total / 20);
          const lessPackege = total % 20;
          console.log("grupos de 20: ", countGroup20);
          console.log("paquetes individual: ", lessPackege);
          for (let i = 0; i < countGroup20; i++) {
            lista.push({
              valor: 20,
              type: tipo,
              id: null,
              draggable: false,
            })
          }
          if (lessPackege > 0) {
            lista.push({
              valor: lessPackege,
              type: tipo,
              id: null,
              draggable: false,
            });
          }
          this.socketService.splitCoin(lista, idList, this.roomId, this.teamId);
          this.spinner.show();
          console.log("Lista a mandar", lista);
        } else {
          console.log("No hay lo suficientes");
          Swal.fire({
            html: this.getErrorLabel('1069'),
            icon: 'error'
          });
        }
      } else {
        this.socketService.disablePiece(idList, intIndexArchivo, this.roomId, this.userId, this.userData.FirstName, false, item);
        console.log("habilitando pieza");
      }
    });
  }

  eventDraggin12People(event, item, index) {
    this.indexItemDragged = index;
    if (item.type == 6 && (event.source.dropContainer.id == 'cdkLstInsideMachineWorkInProgress' || event.source.dropContainer.id == 'cdkLstInsideMachineWorkInProgress2')) {
      Swal.mixin({
        target: '#errorGeneral',
        customClass: {
          container: 'position-absolute'
        },
        toast: true,
        timerProgressBar: true,
        showConfirmButton: false,
        timer: 3000,
        position: 'center-right',
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).fire({
        html: this.getErrorLabel('1001')
      });
    } else {
      this.itemDragged = item;
      this.socketService.dragACoin(
        event.source.dropContainer.id,
        item,
        index,
        this.roomId,
        this.userData.FirstName,
        this.userId
      );
      this.socketService.disablePiece(
        event.source.dropContainer.id,
        index,
        this.roomId,
        this.userId,
        this.userData.FirstName,
        true,
        item
      )
    }

  }

  eventDragginCoin(event, item, index) {
    this.itemDragged = item;
    this.indexItemDragged = index;
    const initialContainerId = event.source.dropContainer.id
    if (this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen && initialContainerId !== 'cdkLstCash') {
      console.log("AQUI")
      this.isHintInterest++;
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1005'));
      return;
    }
    if (this.shouldTakeBuildPayment(initialContainerId)) {
      this.fnTrowToast("#errorGeneral", this.getErrorLabel('1103'));
      return;
    }
    if (this.status == 19) {
      this.blnMove = true;
      console.log(this.status);
    }

    if (this.currentMonth == 2) {
      console.log("HERE ENTRO A MOVERLE AL STAND MES 2");
      this.blnMoveMonth2 = false;
    }

    if (this.currentMonth == 3) {
      console.log("HERE ENTRO A MOVERLE AL STAND MES 3");
      this.blnMoveMonth3 = false;
    }

    if (this.currentMonth == 4) { this.blnMoveMonth4 = false; }
    if (this.currentMonth == 5) { this.blnMoveMonth5 = false; }
    if (this.currentMonth == 6) { this.blnMoveMonth6 = false; }



    console.log("event dragging coin", item.id);
    console.log(index, "INDEEEEEEEEEEEEEEEEX");
    // this.socketService.dragACoin(
    //   event.source.dropContainer.id,
    //   item,
    //   index,
    //   this.roomId,
    //   this.userData.FirstName,
    //   this.userId
    // );
    this.socketService.disablePiece(
      event.source.dropContainer.id,
      index,
      this.roomId,
      this.userId,
      this.userData.FirstName,
      true,
      item
    )
  }

  /*ngOnDestroy() {
    //this.subscriptions.forEach(subscription => subscription.unsubscribe());

    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
    console.log("Closing WS...");
    this.socketService.terminarConexion();
  }*/
/*
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
      // Your logic on beforeunload
      this.fnonBeforeUnload()
      return;
  }*/
  
  async fnonBeforeUnload(){
    
    await this.boardService.setSessionforUser(this.userId, 0,0).subscribe((response:any) =>{
      if(response.intResponse == 200){
        console.log("CERRROO SESIONNNNNN ..........................")
      }else{
        console.log("NO PUDOCERRAR SESIONNNNNN  xxxxxxxxxxxxx")
      }
    })
  }

  fnClickStandardBalanceSheet() {
    // console.log("HOLA");
    if (this.statusBeforeMonth == 2 && this.currentMonth >= 3) {
      return;
    }
    this.balanceService.getCantidadesBoard(this.teamId).subscribe((response) => {
      console.log(response);
      let retainedFisico = response.data.filter(x => x.AreaDrag == 19);
      console.log(retainedFisico);
      this.incomeService.getCantidadesLlenado(this.teamId).subscribe(async (response) => {
        console.log(response)
        if(response && retainedFisico.length != 0){
        let arrayIS = response.data.filter(x => x.netIncome != null);
        console.log(arrayIS)
        let sumaIS = 0;
        arrayIS.forEach(element => {
          sumaIS += element['netIncome']
        });
        console.log(sumaIS,retainedFisico[0]['cantidad']);
        if(retainedFisico[0]['cantidad'] != sumaIS  && this.getCheckCheckbox(17).blnCheck && !this.getCheckCheckbox(18).blnCheck && this.currentMonth >= 2){
          console.log("entra")
            const isNegative = sumaIS < 0;
            var package20 = Math.abs(Math.floor(sumaIS / (isNegative ? -20 : 20)));
            var packageLess20 = sumaIS % 20;
            this.cdkLstRetainedE = [];
            for (let i = 0; i < package20; i++) {
              this.cdkLstRetainedE.push({
                type: 2,
                valor: isNegative ? -20 : 20,
                id: null,
                draggable: false,
                draggingBy: ""
              });
            }
            if (packageLess20 > 0 || packageLess20 < 0) {
              this.cdkLstRetainedE.push({
                type: 2,
                valor: packageLess20,
                id: null,
                draggable: false,
                draggingBy: ""
              })
            }

            this.socketService.splitCoin(this.cdkLstRetainedE, 'cdkLstRetainedE', this.roomId, this.teamId);

          // Swal.fire({
          //   text: "We detect an error in your Retained Earnings ",
          //   icon: 'error'
          // });


        }
          // console.log(response);
          // let cantidadesIncome : datosIncome = { 
          //   totSales: response.back.totSales,
          //   costGoodSold: response.back.costGoodSold,
          //   factotyOverheads: response.back.factotyOverheads,
          //   selling: response.back.selling,
          //   interest:response.back.interest,
          //   advertasting:response.back.advertasting,
          // };
          // let cantidadesBalance :datosBalance = {
          //   cash: response.back.cash,
          //   receivables:response.back.receivables,
          //   finishGood: response.back.finishGood,
          //   workProcess: response.back.workProcess,
          //   rawMaterials:response.back.rawMaterials,
          //   landBuildings:response.back.landBuildings,
          //   payables: response.back.payables,
          //   loans: response.back.loans,
          //   taxes: response.back.taxes,
          //   capitalStock:response.back.capitalStock,
          //   machine: response.back.machine,
          //   retained: response.back.retained,
          // };
          // let retainedE = cantidadesIncome.totSales - cantidadesIncome.costGoodSold - cantidadesIncome.factotyOverheads - cantidadesIncome.selling -cantidadesIncome.interest -cantidadesIncome.advertasting;
          // let retainedBack = retainedE+cantidadesBalance.retained
          // console.log(retainedBack,retainedFisico[0]?.cantidad )
          // Adjust Retained Earnings Complete the Balance Sheet DONE
          // if(retainedFisico[0]?.cantidad != retainedBack && this.getCheckCheckbox(17).blnCheck && !this.getCheckCheckbox(18).blnCheck){
          //   const isNegative = retainedBack < 0;
          //   var package20 = Math.abs(Math.floor(retainedBack / (isNegative ? -20 : 20)));
          //   var packageLess20 = retainedBack % 20;
          //   this.cdkLstRetainedE = [];
          //   for (let i = 0; i < package20; i++) {
          //     this.cdkLstRetainedE.push({
          //       type: 2,
          //       valor: isNegative ? -20 : 20,
          //       id: null,
          //       draggable: false,
          //       draggingBy: ""
          //     });
          //   }
          //   if (packageLess20 > 0 || packageLess20 < 0) {
          //     this.cdkLstRetainedE.push({
          //       type: 2,
          //       valor: packageLess20,
          //       id: null,
          //       draggable: false,
          //       draggingBy: ""
          //     })
          //   }

          //   this.socketService.splitCoin(this.cdkLstRetainedE, 'cdkLstRetainedE', this.roomId, this.teamId);
          // }
          //Mensaje de error en caso de que no se haya completado el IS antes.
          /*if ((this.status === 15 && this.currentMonth >= 2) && !(!this.getCheckCheckbox(14).blnCheck && this.currentMonth >= 2) ? false : true) {
            this.fnTrowToast('#errorGeneral', this.getSingleTextLabel('1044'));
            return;
          }*/
          // otorgar edicion a quien tenga el control
          // Adjust Retained Earnings DONE
          let blnCanEdit = (this.currentMonth >= 2 && this.status < 13) || (!this.getCheckCheckbox(17).blnCheck && this.currentMonth >= 2) ? false : true;
          blnCanEdit = blnCanEdit && !this.isOpenMarketDisabledBoard;
          // Proceso para llenado de  Standard balance sheet aqui
          let width = "";
          let height = "";
          this.balanceIncomeService.getSize(2, this.userId).subscribe(res => {
            if (res.intResponse == "200") {
              console.log(res);
              this.userData['sizeBS'] = res.data.sizeIS;
              // this.userData['sizeIS'] = "632.992px,526.991px"
              if (this.userData['sizeBS'] == null) {
                width = '777px';
                height = '860px';
              } else {
                let sizes = this.userData['sizeBS'].split(',')
                console.log(sizes)
                width = sizes[0];
                height = sizes[1];
              }
              // Complete the Balance Sheet DONE
              this.dialogRef = this._matDialog.open(StandardBalanceSheetComponent, {
                disableClose: false,
                width: width,
                height: height,
                panelClass: ['resize-checklist'],//'event-form-dialog',
                data: {
                  userId: this.userId,
                  languageId: this.idLenguage,
                  teamId: this.teamId,
                  currentMonth: this.currentMonth,
                  cycles: this.Cycles,
                  workshopId: this.workshopId,
                  canEdit: blnCanEdit,
                  isInCurrentStatus: !this.getCheckCheckbox(18).blnCheck,
                  arrTextLabels: this.arrTextLabels,
                },
              });
              this.dialogRef.afterClosed().subscribe((resp) => {
                console.log("RETURNED DIALOG", resp);
                this.actualizarMes(resp);
              });
            }
          });

        }else{
          console.log("Entra else")
          //Mensaje de error en caso de que no se haya completado el IS antes.
          /*if ((this.status === 15 && this.currentMonth >= 2) && !(!this.getCheckCheckbox(14).blnCheck && this.currentMonth >= 2) ? false : true) {
            this.fnTrowToast('#errorGeneral', this.getSingleTextLabel('1044'));
            return;
          }*/
          // otorgar edicion a quien tenga el control 
          //RT DONE
          let blnCanEdit = (this.currentMonth >= 2 && this.status < 13) || (!this.getCheckCheckbox(17).blnCheck && this.currentMonth >= 2) ? false : true;
          blnCanEdit = blnCanEdit && !this.isOpenMarketDisabledBoard;
          // Proceso para llenado de  Standard balance sheet aqui
          let width = "";
          let height = "";
          this.balanceIncomeService.getSize(2, this.userId).subscribe(res => {
            if (res.intResponse == "200") {
              console.log(res);
              this.userData['sizeBS'] = res.data.sizeBS;
              // this.userData['sizeIS'] = "632.992px,526.991px"
              if (this.userData['sizeBS'] == null) {
                width = '777px';
                height = '860px';
              } else {
                let sizes = this.userData['sizeBS'].split(',')
                console.log(sizes)
                width = sizes[0];
                height = sizes[1];
              }
              console.log(this.checkBoxes)
              // BS DONE
              this.dialogRef = this._matDialog.open(StandardBalanceSheetComponent, {
                disableClose: false,
                width: width,
                height: height,
                panelClass: ['resize-checklist'],//'event-form-dialog',
                data: {
                  userId: this.userId,
                  languageId: this.idLenguage,
                  teamId: this.teamId,
                  currentMonth: this.currentMonth,
                  cycles: this.Cycles,
                  workshopId: this.workshopId,
                  canEdit: blnCanEdit,
                  isInCurrentStatus: this.blnFacilitador ? true : !this.getCheckCheckbox(18).blnCheck,
                  arrTextLabels: this.arrTextLabels,
                },
              });
              this.dialogRef.afterClosed().subscribe((resp) => {
                console.log("RETURNED DIALOG", resp);
                this.actualizarMes(resp);
              });
            }
          });



         
        }
      })
    })


  }

  async updateIsDepreciateMachineFlags() {
    try {
      if (this.jsonBlnOpenModeActions.isDepreciateMachine1) {
        this.jsonBlnOpenModeActions.isDepreciateMachine1 = false;
        await this.socketService.setBlnControlModeOpen('isDepreciateMachine1', false, this.roomId, this.teamId);
      }
      await new Promise(resolve => setTimeout(resolve, 1000));  // Asegura un retraso entre llamadas
  
      this.jsonBlnOpenModeActions.isDepreciateMachine2 = false;
      await this.socketService.setBlnControlModeOpen('isDepreciateMachine2', false, this.roomId, this.teamId);
    } catch (error) {
      console.error('Error en actualizarControlMode:', error);
    }
  }
  

  actualizarMes(resp): Promise<any> {
    return new Promise((resolve, reject) => {

      if (resp.status == 'cancel') {
        resolve(false);
        return;
      }
      this.updateIsDepreciateMachineFlags();
      if (this.status == 37) {
        this.status = 0;
        this.countQuantitysDelivered = 0;
        this.currentMonth = 2;
        //this.intOpenMarket = 0;
        if(this.blnFacilitador){
          this.boardService.updateFacilitatorMonth(this.teamId,this.currentMonth).subscribe(response => {
            console.log(response);
          }) 
        }
        this.boardService.updateMonth(this.teamId, this.currentMonth, this.status).subscribe(async responseUpdMonth => {
          this.advertising = 0;
          this.blnCanMoveitems = false;
          // this.fnUpdateOpenMarketByWorkshopId(this.workshopId, 0);
          this.fnUpdateImprovementStatusByWorkshopId();
          console.log("response update month", responseUpdMonth);
          this.checkBoxes.forEach(item => {
            item.blnCheck = false;
          });

          this.socketService.splitCoin(this.createArrayCoins(3, 4, 7), 'cdkLstSuppliers', this.roomId, this.teamId);

          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          this.checkBoxes = [
            { id: 0, blnCheck: false, title: "Select Improvement Option", idBd: null, disabled: true, show: 0 },
            { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
            { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
            { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
            { id: 4, blnCheck: false, title: "Buy or Upgrade Equipment", idBd: null, disabled: true, show: 0 },
            { id: 5, blnCheck: false, title: "Pay Recruitment & Training", idBd: null, disabled: true, show: 0 },
            { id: 6, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
            { id: 7, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
            { id: 8, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
            { id: 9, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
            { id: 10, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
            { id: 11, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
            { id: 12, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
            { id: 13, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
            { id: 14, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
            { id: 15, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
            { id: 16, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
            { id: 17, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
            { id: 18, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
          ];
          this.improvementSelected = false;
          this.checkBoxes.forEach(item => {
            item.blnCheck = false;
          });
          this.urlImgTrash = ' ../../../assets/boteblancoabierto.svg';
          this.boardService.createUpdCheckList(this.teamId, this.checkBoxes).subscribe(response => {
            console.log('response update check list mes 2', response);
            this.checkBoxes = response.checkList as Array<any>;
            this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
            resolve(true);
          });
          const supplyAlFinalDeMes = this.calcularRoyalsEnSupply();
          const maxSupplies = await this.calcularMaxSupply();
          this.boardService.storeHistoryMaxSupply(this.workshopId, this.currentMonth - 1, this.teamId, maxSupplies, supplyAlFinalDeMes).subscribe(suppliesRes => {
            console.log(suppliesRes)
          });
          let numRoyals = this.totRoyals();
          this.boardService.updateHistoryRoyals(this.workshopId, numRoyals, this.teamId, this.currentMonth - 1).subscribe(hres => {
            console.log(hres);
          });
          this.socketService.blockTeamBoard(this.roomId, this.teamId);

        });

      } else if (this.currentMonth >= 2 && this.status >= 10) {
        // checkear ultimo paso del checklist
        // this.checkBoxes[this.currentMonth>=3?17:15].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        this.checkCheckbox(18, true);
        this.fnUpdateImprovementStatusByWorkshopId();
        this.currentMonth += 1;
        this.status = (
          ((this.currentMonth == 3 || this.currentMonth == 4) && (this.Cycles == 4 || this.Cycles == 5)) ||
          ((this.currentMonth == 3 || this.currentMonth == 5) && this.Cycles == 6)) ? -1 : 0;
        setTimeout(() => {
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          this.socketService.blockTeamBoard(this.roomId, this.teamId);
        }, 500);
        setTimeout(() => {
          // para los materiales nuevos
          const tipo = this.currentMonth >= 3 && this.jsonBlnOpenModeActions.isRawMaterials1 ? 14 : 7;
          const valor = this.currentMonth >= 3 && this.jsonBlnOpenModeActions.isRawMaterials1 ? 1 : 3;
          const cantidad = this.currentMonth >= 3 && this.jsonBlnOpenModeActions.isSecondMachine ? 8 : this.currentMonth >= 3 && this.jsonBlnOpenModeActions.isEfficiency1 ? 6 : 4;
          let materials = [];
          if (this.jsonBlnOpenModeActions.isSecondMachine) {
            if (this.jsonBlnOpenModeActions.isRawMaterials1) {
              materials = this.createArrayCoins(valor, 4, 14)
            } else if (this.jsonBlnOpenModeActions.isEfficiency1) {
              materials = this.createArrayCoins(valor, 6, 7)
            } else {
              materials = this.createArrayCoins(valor, 4, 7)
            }
            if (this.jsonBlnOpenModeActions.isRawMaterials2) {
              materials = materials.concat(this.createArrayCoins(valor, 4, 14));
            } else if (this.jsonBlnOpenModeActions.isEfficiency2) {
              materials = materials.concat(this.createArrayCoins(valor, 6, 7));
            } else {
              materials = materials.concat(this.createArrayCoins(3, 4, 7));
            }
          } else {
            materials = this.createArrayCoins(valor, cantidad, tipo);
          }

          this.socketService.splitCoin(materials, 'cdkLstSuppliers', this.roomId, this.teamId);
        }, 1000);
        if(this.blnFacilitador){
          this.boardService.updateFacilitatorMonth(this.teamId,this.currentMonth).subscribe(response => {
            console.log(response);
          }) 
        }
        this.boardService.updateMonth(this.teamId, this.currentMonth, this.status).subscribe(async response => {
          this.countQuantitysDelivered = 0;
          this.advertising = 0;
          if (this.jsonBlnOpenModeActions.showBill2Aux1) {
            this.jsonBlnOpenModeActions.showBill2Aux1 = false;
            this.socketService.setBlnControlModeOpen('showBill2Aux1', false, this.roomId, this.teamId);
          }
          setTimeout(() => {
            if (this.jsonBlnOpenModeActions.showBill2Aux2) {
              this.jsonBlnOpenModeActions.showBill2Aux2 = false;
              this.socketService.setBlnControlModeOpen('showBill2Aux2', false, this.roomId, this.teamId);
            }
            resolve(true);
          }, 1000);
          // this.updateIsDepreciateMachineFlags();
          if(this.jsonBlnOpenModeActions.isPayablesPaid == true){
            setTimeout(() => {
              this.jsonBlnOpenModeActions.isPayablesPaid = false;
              this.socketService.setBlnControlModeOpen('isPayablesPaid', false, this.roomId, this.teamId);
            }, 100);
          }
          if(this.jsonBlnOpenModeActions.isLoansPaid == true){
            setTimeout(() => {
              this.jsonBlnOpenModeActions.isLoansPaid = false;  
              this.socketService.setBlnControlModeOpen('isLoansPaid', false, this.roomId, this.teamId);
            }, 100);
          }
          //this.intOpenMarket = 0;
          this.blnCanMoveitems = false;
          // this.fnUpdateOpenMarketByWorkshopId(this.workshopId, 0);
          this.localUpdLoansAndReceivables();
          // this.orders = [];
          console.log(response);
          const supplyAlFinalDeMes = this.calcularRoyalsEnSupply();
          const maxSupplies = await this.calcularMaxSupply();
          this.boardService.storeHistoryMaxSupply(this.workshopId, this.currentMonth - 1, this.teamId, maxSupplies, supplyAlFinalDeMes).subscribe(suppliesRes => {
            console.log(suppliesRes)
          });
          let numRoyals = this.totRoyals();
          this.boardService.updateHistoryRoyals(this.workshopId, numRoyals, this.teamId, this.currentMonth - 1).subscribe(hres => {
            console.log(hres);
          });
        });
        if (this.currentMonth >= 3) {
          this.mejorasXMes = 0;
          this.boardService.updateBudgetLockStatus(this.workshopId, this.teamId, 0).subscribe(response => {
            console.log(response);
          });
        if (((this.currentMonth == 4 || this.currentMonth == 3) && (this.Cycles == 4 || this.Cycles == 5)) || ((this.currentMonth == 5 || this.currentMonth == 3) && this.Cycles == 6)) {
            console.log("ESTE")

            this.improvementsOptionsSelected.forEach(item => {
              item.Approved = 0;
            });
            this.fnUpdateImprovementStatusByWorkshopId();
            this.checkBoxes = [
              { id: 0, blnCheck: false, title: "Select Improvement Option", idBd: null, disabled: true, show: 1 },
            { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
            { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
            { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
            { id: 4, blnCheck: false, title: "Buy or Upgrade Equipment", idBd: null, disabled: true, show: 0 },
            { id: 5, blnCheck: false, title: "Pay Recruitment & Training", idBd: null, disabled: true, show: 0 },
            { id: 6, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
            { id: 7, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
            { id: 8, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
            { id: 9, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
            { id: 10, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
            { id: 11, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
            { id: 12, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
            { id: 13, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
            { id: 14, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
            { id: 15, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
            { id: 16, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
            { id: 17, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
            { id: 18, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
            ];
            this.improvementSelected = false;
          } else if ((this.currentMonth == 5 && this.Cycles == 5) || (this.currentMonth == 6 && this.Cycles == 6) || (this.currentMonth == 4 && this.Cycles == 6)) {
            console.log("ESTE")
            
            this.improvementsOptionsSelected.forEach(item => {
              item.Approved = 0;
            });
            this.fnUpdateImprovementStatusByWorkshopId();
            this.checkBoxes = [
              
              { id: 0, blnCheck: false, title: "Select Improvement Option", idBd: null, disabled: true, show: 0 },
            { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
            { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
            { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
            { id: 4, blnCheck: false, title: "Buy or Upgrade Equipment", idBd: null, disabled: true, show: 0 },
            { id: 5, blnCheck: false, title: "Pay Recruitment & Training", idBd: null, disabled: true, show: 0 },
            { id: 6, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
            { id: 7, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
            { id: 8, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
            { id: 9, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
            { id: 10, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
            { id: 11, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
            { id: 12, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
            { id: 13, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
            { id: 14, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
            { id: 15, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
            { id: 16, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
            { id: 17, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
            { id: 18, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
            ];
            this.improvementSelected = false;
          }
          this.checkBoxes.forEach(item => {
            item.blnCheck = false;
            item.disabled = true;
          });
          this.urlImgTrash = ' ../../../assets/boteblancoabierto.svg';
          this.boardService.createUpdCheckList(this.teamId, this.checkBoxes).subscribe(response => {
            console.log('response update check list mes 2', response);
            this.checkBoxes = response.checkList as Array<any>;
            this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          });
        }
        // this.isOpenMarketDisabledBoard = true;
        // this.checkBoxes[this.currentMonth>=3?10:8].disabled = true;
        // this.checkBoxes[this.currentMonth>=3?11:9].disabled = true;
      
      }
    });
  }

  fnUpdateImprovementStatusByWorkshopId() {
    this.improvementService.updateImprovementStatusByWorkshopId(this.workshopId, -1, this.currentMonth)
      .subscribe(response => {
        console.log("fnUpdateImprovementStatusByWorkshopId", response);
        this.improveStatus = -1;
      })
  }

  fnClickBalanceSheet() {
    if (!this.blnAccessCUSTOMERISBS) {
      return;
    }
    let blnCanEdit = !this.isOpenMarketDisabledBoard;
    this.dialogRef = this._matDialog.open(SimpleBalanceSheetComponent, {
      disableClose: false,
      width: '490px',
      height: '580px',
      panelClass: 'event-form-dialog',
      data: {
        teamId: this.teamId,
        workShopId: this.workshopId,
        statusBoard: this.status,
        arrTextLabels: this.arrTextLabels,
        canEdit: blnCanEdit,
        userId: this.userId,
        month: this.currentMonth
      }
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      // console.log("RETURNED DIALOG simplebalancesheet", resp)
      // this.spinner.show('my-custom-spinner')
      // setTimeout(() => {
      // this.spinner.hide('my-custom-spinner')
      // }, 1000);

      if (resp.status == 1) {
        // Proceso para llenado de balance sheet aqui
        this.isClickBalanceSheet = true;

        
      }
    });


  }

  async fnClickCustomers(tipoBoton? : string) {
    //if (this.statusBeforeMonth == 1 || this.statusBeforeMonth == 2 || !this.blnAccessCUSTOMERISBS) {
    //  return;
    //}
    if ((this.statusBeforeMonth == 1 || this.statusBeforeMonth == 2 || !this.blnAccessCUSTOMERISBS)
      && this.blnFacilitador == false) {
      return;
    }
    if(tipoBoton != 'menu'){
      tipoBoton = "customers"
    }
    console.log(this.improvementsOptionsSelected)
    this.abrioModal == true
    // Proceso para hacer las bids aqui
    // si no hemos cumplido los primeros 8 pasos no debemos de permitir hacerle bids
    let permitir = true;
    let auxI
    let latestCheckBoxCompletedId = 0;
    if (this.currentMonth >= 2) {
      // for (let i = 0; i < 8; i++) {
      //   // Dependiendo de i, evalúa 
      //   // i = 0 -> Clear Income Statement 
      //   // i = 1 -> Update Receivables
      //   // i = 2 ->  Update Liabilities
      //   // i = 3 -> Buy Raw Materials
      //   // i = 4 -> Load Raw Materials to Machine
      //   // i = 5 -> Pay Labor
      //   // i = 6 -> Take depreciation
      //   // i = 7 -> Pay Expenses
      //   if (!this.getCheckCheckbox(i).blnCheck) {
      //     permitir = false;
      //     auxI = i;
      //     break;
      //   }
      // }
      if (this.intOpenMarket === 1 || this.intOpenMarket === 2)
        permitir = false;
      // Pay Expenses DONE
      if (!this.getCheckCheckbox(10).blnCheck && this.currentMonth >= 2) {
        this.fnTrowToast('#errorGeneral', this.getErrorLabel('1039'))
        // return;
      }
      if (
        this.jsonBlnOpenModeActions.showMessageOwe20 || this.jsonBlnOpenModeActions.isBuyLoan ||
        this.jsonBlnOpenModeActions.showMessageInterest1 || this.jsonBlnOpenModeActions.showMessageOwe12 ||
        this.jsonBlnOpenModeActions.showBill1 || this.jsonBlnOpenModeActions.showBill10 || this.jsonBlnOpenModeActions.showBill6
      )
        permitir = false;
      let checkBoxesShow  = this.checkBoxes.filter(check => check.show == 1)
      console.log(checkBoxesShow)
      for (let i = 0; i < checkBoxesShow.length; i++) {
        const check = this.getCheckCheckbox(checkBoxesShow[i].id)
        // Dependiendo de i, evalúa 
        // i = 0 -> Clear Income Statement 
        // i = 1 -> Update Receivables
        // i = 2 ->  Update Liabilities
        // i = 3 -> Buy Raw Materials
        // i = 4 -> Load Raw Materials to Machine
        // i = 5 -> Pay Labor
        // i = 6 -> Take depreciation
        // i = 7 -> Pay Expenses
        // i = 8 -> Prepare bids
        if (check.blnCheck) {
          latestCheckBoxCompletedId = check.id
        } else {
          break;
        }
      }
    }
    console.log(latestCheckBoxCompletedId)

    let height: string = '0px';
    const blnValidTeamsSetUp = await this.fnGetValidateTeamsSetUp();
    const lstTeams: any = await this.fnGetAllTeams();
    if (lstTeams.length >= 5) {
      height = '750px';
    } else {
      height = '700px';
    }

    if (this.currentMonth == 1) {
      permitir = permitir && (this.status == 29 || this.status == 30) && this.intOpenMarket === 0;
    }
    if (blnValidTeamsSetUp) {
      //DCM meter aqui la cantidad de cash
      var CurrentCash = 0;
      this.cdkLstCash.forEach(item => {
        if (item.valor > 0) {
          CurrentCash = CurrentCash + item.valor;
        }
      });
      var CurrentAdv = 0;
      this.cdkLstAdvertasing.forEach(item => {
        if (item.valor > 0) {
          CurrentAdv = CurrentAdv + item.valor;
        }
      });
      if(this.status >= 10){
        this.isDialogMarketOpen = true;
      }
      console.log(this.isDialogMarketOpen);
      console.log("enviamos cantidad actual de cash ", CurrentCash);
      console.log(this.blnGrowNocontrol);
      this.dialogRef = this._matDialog.open(DialogOrdersComponent, {
        disableClose: false,
        width: '1400px',
        height: height,
        maxWidth:'92vw',
        panelClass: 'custom-orders',
        data: {
          workShopId: this.workshopId,
          statusBoard: this.status,
          latestCheckBoxCompletedId: latestCheckBoxCompletedId,
          blnIsFacilitator: this.blnFacilitador,
          teamId: this.teamId,
          view: 1,
          blnAvalibleBid: permitir,
          month: this.currentMonth,
          cycles: this.Cycles,
          arrTextLabels: this.arrTextLabels,
          boardMode: this.boardMode,
          Current_Cash: CurrentCash,
          Current_Adv: CurrentAdv,
          userId: this.userData.UserID,
          tipoBoton : tipoBoton,
          hasControl:!this.blnGrowNocontrol,
          userName : this.userData['FirstName'],
        },
      });

      this.dialogRef.afterClosed().subscribe((resp) => {
        this.status == 29 ? this.status = this.status + 1 : this.status = this.status;
        if (this.status == 30) {
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
      });
      this.isClickCustomers = true;
    } else {
      this.fnAlertInfo("Please wait until all companies are named");
    }
  }

  fnGetAllTeams() {
    return new Promise(resolve => {
      this.teamService.getAllTeamsAndMembersById(this.workshopId)
        .subscribe(response => {
          resolve(response['teams']);
        }, error => {
          resolve(false);
        })
    })
  }

  fnGetValidateTeamsSetUp() {
    return new Promise(resolve => {
      this.teamService.getValidateTeamsSetUp(this.workshopId)
        .subscribe(response => {
          resolve(response['validSetUpTeams']);
        }, error => {
          resolve(false);
        })
    })
  }

  checkBoxChange(event, item) {
    console.log(event);
    console.log(item);
    if (item.id == 11) {
      // this.checkBoxes[this.currentMonth>=3?10:8].blnCheck = true;
      // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      this.checkCheckbox(11, true);
      this.status = 11;
      this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
    } else if (item.id == 12) {
      this.boardService.getOrdersByTeamId(this.teamId, this.currentMonth).subscribe(response => {
        console.log(response, "response orders", this.cdkLstCash);
        if (response.intResponse == 200) {
          this.orders = response.orders as Array<any>;
          if (this.blnFacilitador && this.orders.length == 0) {
            this.orders.push(
              { Terms: 60, OrderNum: 101, Bid: 40, Quantity: 3, Delivered: false },
            )
          }
          if (!this.blnFacilitador) {
            if (this.currentMonth >= 3 && this.improvementsOptionsSelected.length > 0) {
              if (this.improvementsOptionsSelected.length == 1) {
                this.improvementsOptionsSelected.push({
                  improvement: '',
                  value: 0,
                  ImproveId: -1,
                  option: 0,
                  question: ''
                })
              }
              if ((this.improvementsOptionsSelected[0].improvement == "Quality" && this.improvementsOptionsSelected[0].value == 3
                || this.improvementsOptionsSelected[1].improvement == "Quality" && this.improvementsOptionsSelected[1].value == 3)) {
                this.orders.forEach(item => {
                  item.Bid = Math.round(item.Bid * 1.20);
                });
              }
            }
          }
        }
      });
      // this.checkBoxes[this.currentMonth>=3?12:10].blnCheck = true;
      // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      this.checkCheckbox(13, true);
      this.status = 14;
      this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
    }
  }

  notDropped(item: CdkDrag<number>) {
    return false;
  }

  fnDeliverOrder(item) {
    console.log(item);
    let totalRoyals = 0;
    if (this.status == 33 && this.boardMode == "NM") {
      this.move60 = true;
      setTimeout(() => {
        this.move60 = false;
      }, 8000);
      console.log("Vamos a liberar orden con items, ", item.units * 3)
      this.cdkLstCostGS.forEach(item => {
        totalRoyals += item.valor;
      });
      let lista = [];
      if (totalRoyals >= item.Quantity * 3) {
        lista.push({
          type: 1,
          valor: 20,
          id: null,
          draggable: false,
          draggingBy: ""
        });
        lista.push({
          type: 1,
          valor: 20,
          id: null,
          draggable: false,
          draggingBy: ""
        });
        // console.log(this.cdkLstReceivables60);
        // if(this.cdkLstReceivables60.hasOwnProperty('id')){
        //   console.log("si tiene id");
        // }
        // for(var i=0; i<this.cdkLstReceivables60.length; i++){
        //   if(this.cdkLstReceivables60[i].hasOwnProperty('id') && this.cdkLstReceivables60[i].id == null){
        //     console.log("si tiene id pero es nulo");
        //   }
        // }
        this.cdkLstReceivables60Anim = lista;
        console.log(this.cdkLstReceivables60Anim);
        if (this.cdkLstReceivables60Anim.length == 2) {
          this.move2Items = true;
        }
        setTimeout(() => {
          this.cdkLstReceivables60Anim = [];
          this.move2Items = false;
        }, 7000);
        this.socketService.setStacksOrder(
          lista,
          'cdkLstReceivables60',
          'cdkLstCostGS',
          this.cdkLstCostGS[0],
          this.roomId,
          this.teamId,
          item.OrderId ? item.OrderId : -1
        );
        // this.cdkLstCostGS = [];
        let tot = 0;
        this.cdkLstCostGS.forEach(item => {
          tot += item.valor;
          if (tot <= 12) {
            item.paid = true;
          }
        })
        this.status == this.status++;
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        item.Delivered = true;
        item.showOrder = false;
        setTimeout(() => {
          this.socketService.deliverOrder(item, [], this.roomId, this.teamId);
        }, 500);
      }
    } else if (/*this.isModeOpen && this.status >= 10*/ (this.currentMonth >= 2 || this.boardMode == "DM") && this.status >= 3) {
      console.log(item);
      this.itemsToMove = 0;
      let totQuantities = 0;
      this.orders.forEach(itemOrder => {
        totQuantities += itemOrder.Quantity;
      });
      console.log(totQuantities);
      if (this.boardMode == "DM" && this.currentMonth == 1) {
        let totCOGS = 0;
        this.cdkLstCostGS.forEach(item => {
          totCOGS += item.valor;
        });
        if ((totCOGS / 3) - this.countQuantitysDelivered < item.Quantity) {
          this.fnTrowToast("#errorGeneral", this.getErrorLabel('1043'));
          return;
        }
      } else {
        if (this.cdkLstCostGS.length - this.countQuantitysDelivered < item.Quantity) {
          this.fnTrowToast("#errorGeneral", this.getErrorLabel('1043'));
          return;
        }
      }
      // this.countQuantitysDelivered += item.Quantity;
      let package20 = 0;
      let packageLess20 = 0;
      let lista = []; let strIdList = "";
      if (item.Terms == 90) {
        this.move90 = true;
        setTimeout(() => {
          this.move90 = false;
          this.lista2 = [];
        }, 6000);
        strIdList = 'cdkLstReceivables90';
      } else if (item.Terms == 60) {
        this.move60 = true;
        setTimeout(() => {
          this.move60 = false;
          this.lista2 = [];
        }, 6000);
        strIdList = 'cdkLstReceivables60';
      } else if (item.Terms == 30) {
        this.move30 = true;
        setTimeout(() => {
          this.move30 = false;
          this.lista2 = [];
        }, 6000);
        strIdList = 'cdkLstReceivables30';
      } if (item.Terms == 0) {
        this.moveCash = true;
        setTimeout(() => {
          this.moveCash = false;
          this.lista2 = [];
        }, 6000);

        strIdList = 'cdkLstCash';
      }
      if (item.Bid >= 20) {
        package20 = Math.floor(item.Bid / 20);
        packageLess20 = item.Bid % 20;
      } else {
        packageLess20 = item.Bid;
      }
      for (let i = 0; i < package20; i++) {
        lista.push({
          type: 1,
          valor: 20,
          id: null,
          draggable: false,
          draggingBy: ""
        });
      }
      if (packageLess20 >= 1) {
        lista.push({
          type: 1,
          valor: packageLess20,
          id: null,
          draggable: false,
          draggingBy: ""
        });
      }
      this.socketService.setStacksOrder(
        lista,
        strIdList,
        'cdkLstCostGS',
        this.cdkLstCostGS[0],
        this.roomId,
        this.teamId,
        item.OrderId ? item.OrderId : -1
      );
      // item.paid = true;
      console.log(lista);
      for (var i = 0; i < lista.length; i++) {
        if (lista[i].id == null) {
          this.itemsToMove = this.itemsToMove + 1;
          console.log(this.itemsToMove);
          this.lista2.push(lista[i]);
          console.log(this.lista2);
        } else {
          console.log("no entra");
        }
      }
      item.Delivered = true;
      item.showOrder = false;

      let tot = item.Quantity;
      let piecesSold = [];
      for (let i = 0; i < this.cdkLstCostGS.length; i++) {
        let itemCGS = this.cdkLstCostGS[i];
        if (!itemCGS.paid) {
          tot -= 1;
          itemCGS.paid = true;
          piecesSold.push(itemCGS.id);
          if (tot <= 0) {
            break;
          }
        }
      }
      let ordersDelivered = true;
      this.spinner.show('my-custom-spinner');
      setTimeout(() => {
        this.socketService.deliverOrder(item, piecesSold, this.roomId, this.teamId);
      }, 500);
      this.orders.forEach(item => {
        if (!item.Delivered) {
          ordersDelivered = false;
          setTimeout(() => {
          this.spinner.hide('my-custom-spinner');
          }, 700);
          return;
        }
      });
      if (ordersDelivered) {
        // checkear el paso de liberar las ordenes
        // this.checkBoxes[this.currentMonth>=3?14:12].blnCheck = true;
        // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        setTimeout(() => {
          if (this.currentMonth >= 2) {
            // this.checkCheckbox(12, true);
            this.checkCheckbox(15, true);
            this.funcionBack("caseCOGS");
            setTimeout(() => {
              this.status = 14;
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
              setTimeout(() => {
              this.spinner.hide('my-custom-spinner')
              }, 200);
            }, 500);
          } else {
            this.status == this.status++;
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            item.Delivered = true;
            setTimeout(() => {
              this.socketService.deliverOrder(item, [], this.roomId, this.teamId);
              setTimeout(() => {
                this.spinner.hide('my-custom-spinner')
                }, 200);
            }, 600);
          }
        }, 500);
        // this.fnUpdateChecklistByStatus(100);
      }
    }
  }
  fnExpandOrder(item){
    console.log(item);
    item.showOrder = !item.showOrder;
    console.log(item);
  

  }
  updStatusMachine() {
    if (this.currentMonth == 1) {
      this.updStatusMonth1();
    } else if (this.currentMonth == 2) {
      if (this.status <= 3 || !this.isModeOpen) {
        this.updStatusMonth2();
      }
    } else if (this.currentMonth >= 3) {
      if (this.status <= 4 || !this.isModeOpen) {
        this.updStatusMonth3();
      }
    }

  }

  updStatusMonth1() {
    console.log("status machine 1")
    let totalCash = 0;
    switch (this.status) {
      case 0:
        totalCash = 0;
        this.cdkLstCash.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 60) {
          this.status = 1;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          this.spinner.show();
        }
        break;
      case 1:
        totalCash = 0;
        this.cdkLstCapitalS.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 60) {
          this.status = 2;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          this.spinner.show();
        }
        break;
      case 2:
        // llenado del balance sheets
        if (this.isClickBalanceSheet) {
          this.status = 3;
          this.isClickBalanceSheet = false;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          this.spinner.show();
        }
        break;
      case 3:
        totalCash = 0;
        this.cdkLstLandBuild.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 20) {
          this.status = 4;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          this.spinner.show();
        }
        break;
      case 4:
        this.paidCapEx.forEach((item, i) => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 20) {
          this.paidCapEx = [];
          // this.status = 6;
          this.spinner.show();
          console.log(this.status)
          // this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          setTimeout(() => {
            this.status = 6;
            // this.spinner.show();
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          }, 200);
        }
        break;
      case 6:
        // llenado del balance sheets
        if (this.isClickBalanceSheet) {
            this.status = 7;
            this.isClickBalanceSheet = false;
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            this.spinner.show();
        }
        break;
      case 7:
        totalCash = 0;
        this.cdkLstLocationMachine.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 1) {
          this.status = 8;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 8:
        totalCash = 0;
        this.cdkLstInsideMachineCapex.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 20) {
          this.status = 9;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 9:
        totalCash = 0;
        this.paidCapEx.forEach((item, i) => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 20) {
          // this.status = 11;
          // this.spinner.show();
          // this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          setTimeout(() => {
            this.status = 11;
            // this.spinner.show();
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          }, 200);
        }
        break;
      case 11:
        // llenado del balance sheets
        if (this.isClickBalanceSheet) {
          this.status = 12;
          this.spinner.show();
          this.isClickBalanceSheet = false;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 12:
        // dragging 12 people to insade machine
        this.cdkLstInsideMachinePeople.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 12) {
          this.status = 13;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 13:
        // dragging people to administration, marketing, change&inovation
        let isComplete = false;
        if (this.cdkLstAdministration.length > 0 && this.cdkLstMarketingS.length > 0 && this.cdkLstChangeI.length > 0) {
          this.status = 14;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 14:
        // dragging suppliers to row materials
        this.cdkLstMaterials.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 12) {
          this.status = 15;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 15:
        // pagar a suppliers los materiales
        this.paySuppliers.forEach((item, i) => {
          totalCash += parseInt(item.valor, 10);
        });
        this.paySuppliers = [];
        console.log(totalCash);
        if (totalCash >= 12) {
          // this.status = 16
          this.showBillAnimationStep16 = true;
          setTimeout(() => {
          this.showBillAnimationStep16 = false;
          }, 2000);
          // this.status = 17;
          // this.spinner.show();
          // this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          setTimeout(() => {
            this.status = 17;
            // this.spinner.show();
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          }, 200);
        }
        break;
      case 17:
        // llenado del balance sheets
        if (this.isClickBalanceSheet) {
          this.status = 18;
          this.isClickBalanceSheet = false;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 18:
        // pidiendo prestado al banco
        console.log("ENTRA PASO 18");
        let cashLoan90 = 0;
        let cashCash = 0;
        this.cdkLstLoans90.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        cashLoan90 = totalCash;
        totalCash = 0;
        this.cdkLstCash.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        cashCash = totalCash;
        totalCash = 0;
        if (cashLoan90 >= 20 && cashCash >= 28) {
          console.log("ADELANTAMOS A PASO 21");
          this.status = 21;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 19:
        // pagamos 1 de interes
        this.cdkLstInterest.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 1) {
          console.log("REGRESAMOS A PASO 29");
          this.status = 29;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          //Actualizar estatus de simple balance 
          this.balanceService.updateStep(this.workshopId, this.teamId, 6,29).subscribe((response) => {
            console.log("updateStep: ",response);
          });
          setTimeout(() => {
            this.status = 29;
            // this.spinner.show();
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          }, 1000);
        }
        break;
      case 21:
        // draggin materials to inside machine work in progress
        this.cdkLstInsideMachineWorkInProgress.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 12) {
          this.status = 22;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 22:
        // pagando a personas de la maquina
        this.fnConvertMaterialsToRoyals(this.cdkLstInsideMachineWorkInProgress, 'cdkLstInsideMachineWorkInProgress');
        // this.status = 24;
        // this.spinner.show();
        // this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        this.showBillAnimationStep23 = true;
          setTimeout(() => {
          this.showBillAnimationStep23 = false;
          }, 2000);
        setTimeout(() => {
          this.status = 24;
          // this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }, 200);

        break;
      case 24:
        // llenado del balance sheets
        if (this.isClickBalanceSheet) {
          this.status = 25;
          this.spinner.show();
          this.isClickBalanceSheet = false;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 25:
        // pagando 1 de deprecacion
        this.cdkLstDeprecation.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 1) {
          // this.status = 26;
          // this.spinner.show();
          // this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          this.showDepreciationAnimationStep26 = true;
          setTimeout(() => {
            this.showDepreciationAnimationStep26 = false;
          }, 2000);
          setTimeout(() => {
            this.status = 27;
            // this.spinner.show();
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          }, 200);
        }
        break;
      case 27:
        // pagando 1 de machine overhead
        this.cdkLstMachineOver.forEach(item => {
          totalCash += parseInt(item.valor, 10);
        });
        if (totalCash >= 1) {
          this.status = 28;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 28:
        // pagando a las personitas de gross profit
        console.log("REGRESAMOS A PASO 19");
        this.status = 19;
        this.spinner.show();
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        break;
      case 29:
        // preparando las bids
        if (this.isClickCustomers) {
          this.status = 30;
          this.isClickCustomers = false;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 30:
        // pagando 2 de advertising 
        this.cdkLstAdvertasing.forEach(value => {
          totalCash += parseInt(value.valor);
        });
        if (this.boardMode == "NM") {
          if (totalCash >= 2) {
            this.status = 31;
            this.spinner.show();
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            this.boardService.getOrdersByTeamId(this.teamId, this.currentMonth).subscribe(response => {
              console.log(response, "response orders", this.cdkLstCash);
              if (response.intResponse == 200) {
                this.orders = response.orders as Array<any>;
                this.orders.forEach(item => {
                  // item.Quantity = 3;
                  // item.Terms = 60;
                });
                if (this.blnFacilitador && this.orders.length == 0) {
                  console.log("SI entra!!")
                  this.orders.push(
                    { Terms: 60, OrderNum: 101, Bid: 40, Quantity: 3, Delivered: false },
                  )
                }
              }
            });
          }
        } else {
          this.cdkLstAdvertasing.forEach(value => {
            totalCash += parseInt(value.valor);
          });
          if (totalCash >= this.owesAdvertising) {
            this.status = 31;
            this.spinner.show();
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            this.boardService.getOrdersByTeamId(this.teamId, this.currentMonth).subscribe(response => {
              console.log(response, "response orders", this.cdkLstCash);
              if (response.intResponse == 200) {
                this.orders = response.orders as Array<any>;
                this.orders.forEach(item => {
                  // item.Quantity = 3;
                  // item.Terms = 60;
                });
                if (this.blnFacilitador && this.orders.length == 0) {
                  this.orders.push(
                    { Terms: 60, OrderNum: 101, Bid: 40, Quantity: 3, Delivered: false },
                  )
                }
                console.log(this.orders);
              }
            });
          }
        }
        break;
      case 31:
        // moviendo de work in progress a FGI
        this.cdkLstFinishedFI.forEach(value => {
          totalCash += parseInt(value.valor);
        });
        if (totalCash >= 12) {
          this.status = 32;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          if (this.orders.length == 0) {
            this.status = 34;
            this.spinner.show();
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          }
        }
        break;
      case 32:
        // moviendo FGI a cost of goods solds
        let totalOrders = 0;
        this.cdkLstCostGS.forEach(value => {
          totalCash += parseInt(value.valor);
        });
        this.orders.forEach(value => {
          totalOrders += value.Quantity;
        });
        console.log("totaaaaaal royals", totalCash);
        if (totalCash >= totalOrders * 3) {
          this.status = 33;
          this.spinner.show();
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        break;
      case 35:
        // moviendo de net income a retained earnings
        if (this.boardMode == "NM") {
          this.cdkLstRetainedE.forEach(value => {
            totalCash += parseInt(value.valor);
          });
          console.log("totaaaaaal royals", totalCash);
          if (totalCash >= 13) {
            this.status = 36;
            this.spinner.show();
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            setTimeout(() => {
              this.status = 37;
              this.spinner.show();
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            }, 3000);
          }
        } else {
          if (this.cdkLstNetIncome.length == 0) {
            this.status = 36;
            this.spinner.show();
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            setTimeout(() => {
              this.status = 37;
              // this.spinner.show();
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            }, 3000);
          }
        }
        break;
    }
  }

  fnIsReadyPaso2(jsonDragged, idList, quietly: boolean = false) {
    return new Promise(resolve => {

      const isReceivables30Ready = !this.liabilitiesReceivablesBeforeUpdates.receivables30;
      const isReceivables60Ready = !this.liabilitiesReceivablesBeforeUpdates.receivables60;
      const isReceivables90Ready = !this.liabilitiesReceivablesBeforeUpdates.receivables90;

      if (idList == "cdkLstReceivables60" && !(isReceivables30Ready && isReceivables60Ready)) {
        if(!quietly){
          this.fnTrowToastwithTimeMiliseconds("#errorGeneral", this.getErrorLabel('1022'), 3000);
        }
        resolve(false);
        return;
      } else if (idList == "cdkLstReceivables30" && (!isReceivables30Ready || (isReceivables30Ready && isReceivables60Ready))) {
        if(!quietly){
          this.fnTrowToastwithTimeMiliseconds("#errorGeneral", this.getErrorLabel('1021'), 3000);
        }
        resolve(false);
        return;
      } else if (idList == "cdkLstCash" && isReceivables30Ready) {
        if(!quietly){
          this.fnTrowToastwithTimeMiliseconds("#errorGeneral", this.getErrorLabel('1001'), 3000);
        }
        resolve(false);
        return;
      }

      if (idList == 'cdkLstCash') {
        this.totCdkLstReceivables30 -= this.itemDragged.valor;
      }

      if (
        (isReceivables30Ready || (0 === this.cdkLstReceivables30.reduce((acc, el) => acc + el.valor, 0) - jsonDragged.valor) && idList === 'cdkLstCash') &&
        (isReceivables60Ready || (this.liabilitiesReceivablesBeforeUpdates.receivables60 === this.cdkLstReceivables30.reduce((acc, el) => acc + el.valor, 0) + jsonDragged.valor) && idList === 'cdkLstReceivables30') &&
        (isReceivables90Ready || (this.liabilitiesReceivablesBeforeUpdates.receivables90 === this.cdkLstReceivables60.reduce((acc, el) => acc + el.valor, 0) + jsonDragged.valor) && idList === 'cdkLstReceivables60')
      ) {
        this.status = 2;
        //Deprecado nuevos checkboxes
        // if(this.currentMonth == 2){
        //   this.checkBoxes = [
        //     { id: 1, blnCheck: true, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
        //     { id: 2, blnCheck: true, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
        //     { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
        //     { id: 4, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
        //     { id: 5, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
        //     { id: 6, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
        //     { id: 7, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
        //     { id: 8, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
        //     { id: 9, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
        //     { id: 10, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
        //     { id: 11, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
        //     { id: 12, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
        //     { id: 13, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
        //     { id: 14, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
        //     { id: 15, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
        //     { id: 16, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
        //   ];
        //   this.boardService.createUpdCheckList(this.teamId, this.checkBoxes).subscribe(response => {
        //     console.log('response update check list mes 2', response);
        //     this.checkBoxes = response.checkList as Array<any>;
        //     this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        //   });
        // }
        //Deprecado nuevos checkboxes
        this.spinner.show();
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        this.checkCheckbox(2,true);
        //Deprecado nuevos checkboxes
          // if (this.isProjectsEnableByCurrentMonth && this.projects != 0) {
          //   this.checkBoxes[2].blnCheck = true;
            
          //   this.fnScrollCheckList(this.checkBoxes[2]);
          //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          // } else {
          //   this.checkBoxes[1].blnCheck = true;
          //   this.fnScrollCheckList(this.checkBoxes[1]);
          //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          // }
        //Deprecado nuevos checkboxes
        // this.cdkLstReceivables30Aux = [...this.cdkLstReceivables30];
        // this.cdkLstReceivables60Aux = [...this.cdkLstReceivables60];
        // this.cdkLstReceivables90Aux = [...this.cdkLstReceivables90];
        this.totCdkLstReceivables30 = this.returnTotalLst(this.cdkLstReceivables30);
        this.totCdkLstReceivables60 = this.returnTotalLst(this.cdkLstReceivables60);
        this.totCdkLstReceivables90 = this.returnTotalLst(this.cdkLstReceivables90);
        this.lstLoans = this.cdkLstLoans30;
        // this.fnUpdateChecklistByStatus(this.status);
        setTimeout(() => {
          this.fnIsReadyPaso3({ valor: 0 }, "cdkLstLoans90", true);
        }, 1000);
      }
      resolve(true);
    });
  }

  fnIsReadyPaso3(jsonDragged, idList, quietly: boolean = false) {
    return new Promise((resolve) => {
      console.log(this.liabilitiesReceivablesBeforeUpdates)
      this.spinner.show('my-custom-spinner');
      const isLiabilities30Ready = !this.liabilitiesReceivablesBeforeUpdates.liabilities30;
      const isLiabilities60Ready = !this.liabilitiesReceivablesBeforeUpdates.liabilities60;
      const isLiabilities90Ready = !this.liabilitiesReceivablesBeforeUpdates.liabilities90;

      const isPayables30Ready = !this.liabilitiesReceivablesBeforeUpdates.payables30;
      const isPayables60Ready = !this.liabilitiesReceivablesBeforeUpdates.payables60;

      if (idList == "cdkLstLoans90" && !(isLiabilities30Ready && isLiabilities60Ready)) {
        if(!quietly){
          this.fnTrowToastwithTimeMiliseconds("#errorGeneral", this.getErrorLabel('1022'), 3000);
        }
        this.spinner.hide('my-custom-spinner');
        resolve(false);
        return;
      } else if (idList == "cdkLstLoans60" && (!isLiabilities30Ready || (isLiabilities30Ready && isLiabilities60Ready))) {
        if(!quietly){
          this.fnTrowToastwithTimeMiliseconds("#errorGeneral", this.getErrorLabel('1021'), 3000);
        }
        this.spinner.hide('my-custom-spinner');
        resolve(false);
        return;
      } else if (idList == "cdkLstPayables60" && (!isPayables30Ready)) {
        if(!quietly){
          this.fnTrowToastwithTimeMiliseconds("#errorGeneral", this.getErrorLabel('1021'), 3000);
        }
        this.spinner.hide('my-custom-spinner');
        resolve(false);
        return;
      }
      console.log(isLiabilities30Ready,this.cdkLstLoans30.reduce((acc, el) => acc + el.valor, 0) - jsonDragged.valor, idList);
      console.log(isLiabilities60Ready,this.liabilitiesReceivablesBeforeUpdates.liabilities60, this.cdkLstLoans30.reduce((acc, el) => acc + el.valor, 0) + jsonDragged.valor, idList )
      console.log(isLiabilities90Ready,this.liabilitiesReceivablesBeforeUpdates.liabilities90 , this.cdkLstLoans60.reduce((acc, el) => acc + el.valor, 0) + jsonDragged.valor,  idList )
      console.log(isPayables30Ready,this.cdkLstPayables30.reduce((acc, el) => acc + el.valor, 0) - jsonDragged.valor, idList) 
      if (
        (isLiabilities30Ready || (0 === this.cdkLstLoans30.reduce((acc, el) => acc + el.valor, 0) - jsonDragged.valor) && idList === 'cdkLstLoans30') &&
        (isLiabilities60Ready || (this.liabilitiesReceivablesBeforeUpdates.liabilities60 === this.cdkLstLoans30.reduce((acc, el) => acc + el.valor, 0) + jsonDragged.valor) && idList === 'cdkLstLoans60') &&
        (isPayables30Ready || (0 === this.cdkLstPayables30.reduce((acc, el) => acc + el.valor, 0) - jsonDragged.valor) && idList === 'cdkLstPayables30') &&
        (isLiabilities90Ready || (this.liabilitiesReceivablesBeforeUpdates.liabilities90 === this.cdkLstLoans60.reduce((acc, el) => acc + el.valor, 0) + jsonDragged.valor) && idList === 'cdkLstLoans90') &&
        (isPayables60Ready || (this.liabilitiesReceivablesBeforeUpdates.payables60 === this.cdkLstPayables30.reduce((acc, el) => acc + el.valor, 0) + jsonDragged.valor) && idList === 'cdkLstPayables60')
      ) {
        this.status = 3;
        console.log("SPINNER STATUSUPDATE fnIsReadyPaso3")
        // this.spinner.show();
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        // if (this.isProjectsEnableByCurrentMonth && this.projects != 0) {
        //   this.checkBoxes[3].blnCheck = true;
        //   this.fnScrollCheckList(this.checkBoxes[3]);
        //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        // } else {
        //   this.checkBoxes[2].blnCheck = true;
        //   this.fnScrollCheckList(this.checkBoxes[2]);
        //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        // }
        this.checkCheckbox(3,true);
        setTimeout(() => {
          this.jsonBlnOpenModeActions.isPayablesPaid = false;
          this.socketService.setBlnControlModeOpen('isPayablesPaid', false, this.roomId, this.teamId);
        }, 100);
        setTimeout(() => {
          this.jsonBlnOpenModeActions.isLoansPaid = false;  
          this.socketService.setBlnControlModeOpen('isLoansPaid', false, this.roomId, this.teamId);
        }, 100);
        console.log('Actualizó status y checklist fnIsReadyPaso3')
        // this.fnUpdateChecklistByStatus(this.status);
        if (this.Cycles == 6 && (this.currentMonth == 4 && this.improvementsOptionsSelected[0].value == 3 || (this.currentMonth == 6 && this.improvementsOptionsSelected[0].value == 3 && this.improvementsOptionsSelected[1].value == 3))) {
          this.spinner.hide('my-custom-spinner');
          resolve(true);

          return;
        }
        if(this.projects == 0){
          this.spinner.hide('my-custom-spinner');
          resolve(true);

          return;
        }
        if(this.improvementsOptionsSelected.length > 1){
          if((this.improvementsOptionsSelected[0].option == 6 || this.improvementsOptionsSelected[1].option == 6) && !this.isProjectsEnableByCurrentMonth){
            this.spinner.hide('my-custom-spinner');
            resolve(true);
          return;
          }
        }else if(this.improvementsOptionsSelected.length == 1){
          if(this.improvementsOptionsSelected[0].option == 6 && !this.isProjectsEnableByCurrentMonth){
            this.spinner.hide('my-custom-spinner');
            resolve(true);
          return;
          }
        }
        setTimeout(() => {
          this.socketService.askImprovement(this.roomId, this.teamId);
          this.spinner.hide('my-custom-spinner');


        }, 750);
        console.log("SPINNER askImprovement FINAL fnIsReadyPaso3")
        this.spinner.hide('my-custom-spinner');

      }
      this.spinner.hide('my-custom-spinner');
      resolve(true);
    });
  }

  updStatusMonth2() {
    console.log("Entra status machine 2")
    let totalCash = 0;
    let total60 = 0;
    let total30 = 0;
    switch (this.status) {
      case 0:
        this.cdkLstAdvertasing.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstCostGS.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstMachineOver.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstDeprecation.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstChangeI.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstMarketingS.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstAdministration.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstAdminOver.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstInterest.forEach(coin =>{
          coin['draggable'] = false;
        });
        console.log(this.cdkLstAdvertasing)
        console.log(this.cdkLstCostGS)
        console.log(this.cdkLstMachineOver)
        console.log(this.cdkLstDeprecation)
        console.log(this.cdkLstChangeI)
        console.log(this.cdkLstMarketingS)
        console.log(this.cdkLstAdministration)
        console.log(this.cdkLstAdminOver)
        console.log(this.cdkLstInterest)
        console.log(this.orders);
        var ordersDeleted = 0;
        this.orders.forEach(order =>{
          if(order.blnDeleted == 1){
            ordersDeleted++
          }
        })
        var blnordersDeleted = ordersDeleted == this.orders.length ? true : false;
        // this.cdkLstAdvertasing.forEach(coin =>{
        //   if(coin.dra)
        // })
        if (
          this.cdkLstCostGS.length == 0 && this.cdkLstMachineOver.length == 0 && this.cdkLstDeprecation.length == 0 &&
          this.cdkLstChangeI.length == 1 && this.cdkLstMarketingS.length == 1 && this.cdkLstAdvertasing.length == 0 &&
          this.cdkLstAdministration.length == 1 && this.cdkLstAdminOver.length == 0 && this.cdkLstInterest.length == 0 &&
          blnordersDeleted
        ) {
          this.status = 1;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          this.boardService.storeLiabilitiesReceivablesUpdates(this.workshopId, this.teamId).subscribe(lrRes => {
            if (lrRes.intResponse === 200) {
              this.liabilitiesReceivablesBeforeUpdates = lrRes.jsonData;
              //Fix
              this.urlImgTrash = "../../../assets/boteblacocontachita.svg"
              console.log(this.status, "cambio a estado 2"); 
              //Deprecado nuevos checkboxes
              // if(this.currentMonth == 2){
              //   // this.checkBoxes = [
              //   //   { id: 1, blnCheck: true, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
              //   //   { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
              //   //   { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
              //   //   { id: 4, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
              //   //   { id: 5, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
              //   //   { id: 6, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
              //   //   { id: 7, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
              //   //   { id: 8, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
              //   //   { id: 9, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
              //   //   { id: 10, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
              //   //   { id: 11, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
              //   //   { id: 12, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
              //   //   { id: 13, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
              //   //   { id: 14, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
              //   //   { id: 15, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
              //   //   { id: 16, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
              //   // ];
              //   // this.boardService.createUpdCheckList(this.teamId, this.checkBoxes).subscribe(response => {
              //   //   console.log('response update check list mes 2', response);
              //   //   this.checkBoxes = response.checkList as Array<any>;
              //   //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
              //   // });
              // }
              //Deprecado nuevos checkboxes
              // if (this.currentMonth >= 3) {
              //   this.checkBoxes[1].blnCheck = true;
              //   this.fnScrollCheckList(this.checkBoxes[1]);
              //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
              // } else {
                //Deprecado nuevos checkboxes
                // this.checkBoxes[0].blnCheck = true;
                // this.fnScrollCheckList(this.checkBoxes[0]);
                // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
                //Deprecado nuevos checkboxes
              // }
              this.checkCheckbox(1,true);
              setTimeout(() => {
                this.fnIsReadyPaso2({ valor: 0 }, "cdkLstReceivables90", true);
              }, 1500);
              setTimeout(() => {
                this.compruebaCheckboxes();          
              }, 500);
            }
          });
          // this.fnUpdateChecklistByStatus(this.status);

        }
        break;
      case 1:
        setTimeout(() => {
          this.compruebaCheckboxes();          
        }, 500);
        // se actualizan los receibables
        // let totRec90 = 0;
        // let totRec60 = 0;
        // total60 = 0;
        // total30 = 0;
        // this.cdkLstReceivables90Aux.forEach(item => {
        //   totRec90 += item.valor;
        // });
        // this.cdkLstReceivables60Aux.forEach(item => {
        //   totRec60 += item.valor;
        // });
        // this.cdkLstReceivables30.forEach(item => {
        //   total30+=parseInt(item.valor, 10);
        // });
        // this.cdkLstReceivables60.forEach(item => {
        //   total60+=parseInt(item.valor, 10);
        // });
        // if(total30 >= totRec60 && total60 >= totRec90){
        //   this.status = 2;
        //   this.socketService.updateStatus(this.roomId, this.teamId,this.status, this.currentMonth);
        //   this.checkBoxes[1].blnCheck = true;
        //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        //   this.fnUpdateChecklistByStatus(this.status);
        // }
        this.spinner.hide()
        console.log('Cierra spinner paso3');
        break;
      case 2:
        setTimeout(() => {
          this.compruebaCheckboxes();          
        }, 500);
        // se actualizan lo liabilitis
        let totLoan90 = 0;
        let totLoan60 = 0;
        let totPayables60 = 0;
        let totPayables30 = 0;
        let total60L = 0;
        let total30L = 0;
        let total60P = 0;
        let total30P = 0;
        this.cdkLstLoans90Aux.forEach(item => {
          totLoan90 += item.valor;
        });
        this.cdkLstLoans60Aux.forEach(item => {
          totLoan60 += item.valor;
        });
        this.cdkLstPayables60Aux.forEach(item => {
          totPayables60 += item.valor;
        });
        this.cdkLstPayables30Aux.forEach(item => {
          totPayables30 += item.valor;
        });
        this.cdkLstLoans30.forEach(item => {
          total30L += parseInt(item.valor, 10);
        });
        this.cdkLstLoans60.forEach(item => {
          total60L += parseInt(item.valor, 10);
        });
        this.cdkLstPayables60.forEach(item => {
          total60P += item.valor;
        });
        this.cdkLstPayables30.forEach(item => {
          total30P += item.valor;
        });
        if (total60L >= totLoan90 && total30L >= totLoan60 && total30P >= totPayables60) {
          this.status = 3;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          //Deprecado nuevos checkboxes
          // if (this.currentMonth >= 3) {
          //   this.checkBoxes[3].blnCheck = true;
          //   this.fnScrollCheckList(this.checkBoxes[3]);
          //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          // } else {
          //   this.checkBoxes[2].blnCheck = true;
          //   this.fnScrollCheckList(this.checkBoxes[2]);
          //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          // }
          //Deprecado nuevos checkboxes

          // this.fnUpdateChecklistByStatus(this.status);
        }
        break;
      case 3:
        // comprar los row materials
        // dragging suppliers to row materials
        if (this.isModeOpen) {
          return;
        }
        this.spinner.show('my-custom-spinner')
        if (this.currentMonth >= 2) {
          let totalMaterials = 0;
          this.cdkLstMaterials.forEach(item => {
            totalMaterials += item.valor;
          });
          if (totalMaterials >= 12) {
            this.jsonBlnOpenModeActions.showMessageOwe12 = true;
            this.socketService.setBlnControlModeOpen('showMessageOwe12', true, this.roomId, this.teamId);
            this.jsonBlnOpenModeActions.isBuyMaterials = true;
            this.socketService.setBlnControlModeOpen('isBuyMaterials', true, this.roomId, this.teamId);
            this.socketService.createCoin('cdkLstSuppliers', {
              valor: 12,
              id: null,
              draggable: false,
              type: 3,
            }, this.roomId, this.teamId);
            setTimeout(() => {

              this.status = 4;
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
              setTimeout(() => {
              this.spinner.hide('my-custom-spinner')
                console.log("apagamos spiner")
              }, 800);
            }, 400);
          }else{
            setTimeout(() => {
              this.spinner.hide('my-custom-spinner')
              console.log("apagamos spiner")
                
              }, 300);
          }
        }
        break;
      case 4:
        // pagamos los materiales
        this.spinner.show('my-custom-spinner')

        this.status = 5;
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);

        if (this.cdkLstSuppliers.length > 0) {
          this.socketService.deleteCoin('cdkLstSuppliers', this.cdkLstSuppliers[0], this.roomId, this.teamId);
        }
        this.jsonBlnOpenModeActions.isBuyMaterials = false;
        this.socketService.setBlnControlModeOpen('isBuyMaterials', false, this.roomId, this.teamId);
        setTimeout(() => {

          this.status = 6;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          setTimeout(() => {
            this.jsonBlnOpenModeActions.showMessageOwe12 = false;
            this.socketService.setBlnControlModeOpen('showMessageOwe12', false, this.roomId, this.teamId);
            setTimeout(() => {
              this.spinner.hide('my-custom-spinner')
              console.log("apagamos spiner")
                
              }, 300);

            // this.fnUpdateChecklistByStatus(this.status);
          }, 300);
        }, 600);
        break;
      case 6:
        this.spinner.show('my-custom-spinner')
       
        this.status = 7;
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        setTimeout(() => {
          this.spinner.hide('my-custom-spinner')
        }, 600);
        // this.fnUpdateChecklistByStatus(this.status);
        break;
      case 7:
        this.status = 8;
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        // this.fnUpdateChecklistByStatus(this.status);
        break;
      case 8:
        this.status = 9;
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);

        // this.fnUpdateChecklistByStatus(this.status);
        break;
      case 9:
        // Pay Expenses DONE
        if (this.fnisReadyPaso8() && !this.getCheckCheckbox(10).blnCheck) {
          this.status = 10;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          // this.checkBoxes[this.currentMonth>=3?9:7].blnCheck = true;
          // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          this.checkCheckbox(7, true);
          //guardamos backup cuando el check se marque para volver a guardar la cantidad correcta de cash
          // this.fnSaveBackQuantities();
          console.log("HACER BACKUP")
          this.funcionBack("backOriginal");

          // this.fnUpdateChecklistByStatus(this.status);
        }
        break;
    }
  }
  compruebaCheckboxes(){
    console.log("Comprobamos!!")
    if(this.getCheckCheckbox(9).blnCheck){
      this.checkCheckbox(9,false);
    }
    setTimeout(() => {
      if(this.getCheckCheckbox(11).blnCheck){
        this.checkCheckbox(11,false);

      }
    }, 500);

  }
  updStatusMonth3() {
    console.log("Entra status machine 3")
    let totalCash = 0;
    let total60 = 0;
    let total30 = 0;
    switch (this.status) {
      case 0:
        console.log(this.cdkLstAdvertasing)
        console.log(this.cdkLstCostGS)
        console.log(this.cdkLstMachineOver)
        console.log(this.cdkLstDeprecation)
        console.log(this.cdkLstChangeI)
        console.log(this.cdkLstMarketingS)
        console.log(this.cdkLstAdministration)
        console.log(this.cdkLstAdminOver)
        console.log(this.cdkLstInterest)
        this.cdkLstAdvertasing.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstCostGS.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstMachineOver.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstDeprecation.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstChangeI.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstMarketingS.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstAdministration.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstAdminOver.forEach(coin =>{
          coin['draggable'] = false;
        });
        this.cdkLstInterest.forEach(coin =>{
          coin['draggable'] = false;
        });
        
        let thereArec = this.cdkLstChangeI.some(item => item.type == 12);
        let thereAreM = this.cdkLstMarketingS.some(item => item.type == 12);
        let thereAreA = this.cdkLstAdministration.some(item => item.type == 12);
        var ordersDeleted = 0;
        this.orders.forEach(order =>{
          if(order.blnDeleted == 1){
            ordersDeleted++
          }
        })
        var blnordersDeleted = ordersDeleted == this.orders.length ? true : false;
        if (
          this.cdkLstCostGS.length == 0 && this.cdkLstMachineOver.length == 0 && this.cdkLstDeprecation.length == 0 &&
          (this.cdkLstChangeI.length == 1 || this.cdkLstChangeI.length == 2 && thereArec) &&
          (this.cdkLstMarketingS.length == 1 || this.cdkLstMarketingS.length == 2 && thereAreM) &&
          this.cdkLstAdvertasing.length == 0 &&
          (this.cdkLstAdministration.length == 1 || this.cdkLstAdministration.length == 2 && thereAreA) &&
          this.cdkLstAdminOver.length == 0 && this.cdkLstInterest.length == 0 &&
          this.cdkLstRent.length == 0 && this.cdkLstRecruitmentT.length == 0 &&
          blnordersDeleted
        ) {
          this.status = 1;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          this.boardService.storeLiabilitiesReceivablesUpdates(this.workshopId, this.teamId).subscribe(lrRes=>{
            if (lrRes.intResponse === 200) {
              this.liabilitiesReceivablesBeforeUpdates = lrRes.jsonData;
            }
          });
          this.urlImgTrash = "../../../assets/boteblacocontachita.svg"
          console.log(this.status, "cambio a estado 2");
          this.spinner.show();
          // if (this.isProjectsEnableByCurrentMonth && this.projects != 0) {
          //   this.checkBoxes[1].blnCheck = true;
          //   this.fnScrollCheckList(this.checkBoxes[1]);
          //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          // } else {
          //   this.checkBoxes[0].blnCheck = true;
          //   this.fnScrollCheckList(this.checkBoxes[0]);
          //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          // }
          this.checkCheckbox(1,true);
          this.fnScrollCheckList(this.checkBoxes[1]);
          setTimeout(() => {
            this.compruebaCheckboxes();          
          }, 500);

          // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          setTimeout(() => {
            if (this.cdkLstReceivables30.length == 0 && this.cdkLstReceivables60.length == 0 && this.cdkLstReceivables90.length == 0) {
              this.status = 2;
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
              // if (this.isProjectsEnableByCurrentMonth && this.projects != 0) {
              //   this.checkBoxes[2].blnCheck = true;
              //   this.fnScrollCheckList(this.checkBoxes[2]);
              //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
              // } else {
              //   this.checkBoxes[1].blnCheck = true;
              //   this.fnScrollCheckList(this.checkBoxes[1]);
              //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
              // }
              this.checkCheckbox(2,true);
              this.fnScrollCheckList(this.checkBoxes[2]);
              // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
              // this.cdkLstReceivables30Aux = [...this.cdkLstReceivables30];
              // this.cdkLstReceivables60Aux = [...this.cdkLstReceivables60];
              // this.cdkLstReceivables90Aux = [...this.cdkLstReceivables90];
              this.totCdkLstReceivables30 = this.returnTotalLst(this.cdkLstReceivables30);
              this.totCdkLstReceivables60 = this.returnTotalLst(this.cdkLstReceivables60);
              this.totCdkLstReceivables90 = this.returnTotalLst(this.cdkLstReceivables90);
              this.lstLoans = this.cdkLstLoans30;
              setTimeout(() => {
                this.fnIsReadyPaso3({ valor: 0 }, "cdkLstLoans90", true);
              }, 1500);
            }
            this.spinner.hide();
            console.log('Cierra spinner paso3');

          }, 2000);
          // this.fnUpdateChecklistByStatus(this.status);
          
        }
        break;
      case 1:
        setTimeout(() => {
          this.compruebaCheckboxes();          
        }, 500);

        // se actualizan los receibables
        // let totRec90 = 0;
        // let totRec60 = 0;
        // total60 = 0;
        // total30 = 0;
        // this.cdkLstReceivables90Aux.forEach(item => {
        //   totRec90 += item.valor;
        // });
        // this.cdkLstReceivables60Aux.forEach(item => {
        //   totRec60 += item.valor;
        // });
        // this.cdkLstReceivables30.forEach(item => {
        //   total30+=parseInt(item.valor, 10);
        // });
        // this.cdkLstReceivables60.forEach(item => {
        //   total60+=parseInt(item.valor, 10);
        // });
        // if(total30 >= totRec60 && total60 >= totRec90){
        //   this.status = 2;
        //   this.socketService.updateStatus(this.roomId, this.teamId,this.status, this.currentMonth);
        //   this.checkBoxes[1].blnCheck = true;
        //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        //   this.fnUpdateChecklistByStatus(this.status);
        // }
        this.spinner.hide();

        break;
      case 2:
        setTimeout(() => {
          this.compruebaCheckboxes();          
        }, 500);

        this.spinner.hide();
        // se actualizan lo liabilitis
        // let totLoan90 = 0;
        // let totLoan60 = 0;
        // let totLoan30 = 0;
        // let totPayables60 = 0;
        // let totPayables30 = 0;
        // let total60L = 0;
        // let total30L = 0;
        // let total60P = 0;
        // let total30P = 0;
        // this.cdkLstLoans90Aux.forEach(item => {
        //   totLoan90 += item.valor;
        // });
        // this.cdkLstLoans60Aux.forEach(item => {
        //   totLoan60 += item.valor;
        // });
        // this.cdkLstLoans30Aux.forEach(item => {
        //   totLoan30 += item.valor;
        // });
        // this.cdkLstPayables60Aux.forEach(item => {
        //   totPayables60 += item.valor;
        // });
        // this.cdkLstPayables30Aux.forEach(item => {
        //   totPayables30 += item.valor;
        // });
        // this.cdkLstLoans30.forEach(item => {
        //   total30L += parseInt(item.valor, 10);
        // });
        // this.cdkLstLoans60.forEach(item => {
        //   total60L += parseInt(item.valor, 10);
        // });
        // this.cdkLstPayables60.forEach(item => {
        //   total60P += item.valor; 
        // });
        // this.cdkLstPayables30.forEach(item => {
        //   total30P += item.valor; 
        // });
        // if (total60L >= totLoan90 && total30L >= totLoan60 && total30P >= totPayables60 && totLoan30 == 0 && totPayables30 == 0) {
        //   this.status = 3;
        //   this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        //   if(this.currentMonth >= 3){
        //     this.checkBoxes[3].blnCheck = true;
        //     this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        //   } else {
        //     this.checkBoxes[2].blnCheck = true;
        //     this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
        //   }
        //   // this.fnUpdateChecklistByStatus(this.status);
        //   this.implementedImprovement();
        // }
        this.fnIsReadyPaso3({ valor: 0 }, "cdkLstLoans90", true);
        break;
      case 3:
        console.log("AQUI!!!!!")
        console.log(!this.getCheckCheckbox(3).blnCheck)
        // comprar los row materials
        // dragging suppliers to row materials
        // DONE 
        if (this.isModeOpen || (!this.getCheckCheckbox(5).blnCheck && this.getCheckCheckbox(5).show == 1) || (!this.getCheckCheckbox(4).blnCheck && this.getCheckCheckbox(4).show == 1)) {
          return;
        }
        if (this.currentMonth >= 2) {
          let totalMaterials = 0;
          this.cdkLstMaterials.forEach(item => {
            totalMaterials += item.valor;
          });
          // console.log("PASO 3!!!");
          // console.log(this.jsonBlnOpenModeActions.isEfficiency1, totalMaterials,this.itemDragged);
          this.buyRowMaterials(totalMaterials, this.itemDragged);
        }
        break;
      case 4:
        console.log("AQUI!!!!!")

        if (this.isModeOpen) {
          return;
        }
        // pagamos los materiales
        this.status = 5;
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        this.payRowMaterials();
        this.spinner.hide()
        break;
      case 6:
        let units = 0;
        this.cdkLstMaterials.forEach(item => {
          units += item.valor;
        });
        if (this.jsonBlnOpenModeActions.isSecondMachine &&
          (
            this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isEfficiency2 && units >= 36 ||
            (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2) && units >= 30 ||
            (!this.jsonBlnOpenModeActions.isEfficiency1 || !this.jsonBlnOpenModeActions.isEfficiency2) && units >= 24
          ) ||
          (
            this.jsonBlnOpenModeActions.isRawMaterials1 && this.jsonBlnOpenModeActions.isRawMaterials2 && units >= 8 ||
            (this.jsonBlnOpenModeActions.isRawMaterials1 || this.jsonBlnOpenModeActions.isRawMaterials2) && units >= 16 ||
            (!this.jsonBlnOpenModeActions.isRawMaterials1 || !this.jsonBlnOpenModeActions.isRawMaterials2) && units >= 24
          )
        ) {
          this.status = 4;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          setTimeout(async () => {
            const x = await this.buyRowMaterials(units, this.itemDragged);
            this.spinner.hide()

          }, 500);
          break;
        }
        if (this.jsonBlnOpenModeActions.isSecondMachine) {
          let machine1 = 0;
          let machine2 = 0;
          this.cdkLstInsideMachineWorkInProgress.forEach(item => {
            machine1 += item.valor;
          });
          this.cdkLstInsideMachineWorkInProgress2.forEach(item => {
            machine2 += item.valor;
          });
          if (this.jsonBlnOpenModeActions.isEfficiency1) {
            if (
              (this.jsonBlnOpenModeActions.isEfficiency1 && machine1 == 18 || !this.jsonBlnOpenModeActions.isEfficiency1 && machine1 == 12) &&
              (this.jsonBlnOpenModeActions.isEfficiency2 && machine2 == 18 || !this.jsonBlnOpenModeActions.isEfficiency2 && machine2 == 12)
            ) {
              this.status = 7;
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            }
          } if (this.jsonBlnOpenModeActions.isRawMaterials1) {
            if (
              (this.jsonBlnOpenModeActions.isRawMaterials1 && machine1 == 4 || !this.jsonBlnOpenModeActions.isRawMaterials1 && machine1 == 12) &&
              (this.jsonBlnOpenModeActions.isRawMaterials2 && machine2 == 4 || !this.jsonBlnOpenModeActions.isRawMaterials2 && machine2 == 12)) {
              this.status = 7;
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            }
          } else {
            if (machine1 == 12 && machine2 == 12) {
              this.status = 7;
              this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
            }
          }
        } else {
          this.status = 7;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        this.spinner.hide()
        // this.fnUpdateChecklistByStatus(this.status);
        break;
      case 7:
        if (this.jsonBlnOpenModeActions.isSecondMachine) {
          const machine1 = this.thereAreStack(this.cdkLstInsideMachineWorkInProgress, 7);
          const machine2 = this.thereAreStack(this.cdkLstInsideMachineWorkInProgress2, 7);
          if (machine1 || machine2) {
            break;
          } else {
            this.status = 8;
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          }
        } else {
          this.status = 8;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }
        // this.fnUpdateChecklistByStatus(this.status);
        break;
      case 8:
        if (this.jsonBlnOpenModeActions.isSecondMachine) {
          let totDep = 0;
          this.cdkLstDeprecation.forEach(item => {
            totDep += item.valor;
          });
          if (totDep >= 2) {
            this.status = 9;
            this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          }
        } else {
          this.status = 9;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        }

        // this.fnUpdateChecklistByStatus(this.status);
        break;
      case 9:
        // if (this.fnisReadyPaso8() && !this.getCheckCheckbox(7)) { 
        // DONE
        if (this.fnisReadyPaso8() && !this.getCheckCheckbox(10).blnCheck) {
          this.status = 10;
          this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
          // this.checkBoxes[this.currentMonth>=3?9:7].blnCheck = true;
          // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          // this.checkCheckbox(7, true);
          this.checkCheckbox(10, true);
          //guardamos backup cuando el check se marque para volver a guardar la cantidad correcta de cash
          // this.fnSaveBackQuantities();
          console.log("HACER BACKUP")
          this.funcionBack("backOriginal");

          // this.fnUpdateChecklistByStatus(this.status);
        }
        break;
    }
  }

  errorControl(event: CdkDragDrop<any>, jsonDragged) {
    if (this.currentMonth == 1) {
      this.erroControlMes1(event, jsonDragged);
    } else if (this.currentMonth >= 2 && (!this.isModeOpen || ((this.status >= -1 && this.status <= 2) || this.status === 15))) {
      this.errorcontrolMes2(event, jsonDragged);
    } else {
      this.errorControlOpenMode(event, jsonDragged);
    }
  }

  erroControlMes1(event: CdkDragDrop<any>, jsonDragged) {
    let target = "";
    let text = "";
    const errorMessage1001 = this.getErrorLabel('1001');
    const errorMessage1002 = this.getErrorLabel('1002');
    const noMoreLoansMessage = (event: CdkDragDrop<any>, jsonDragged) => {
      if (event.container.id == 'cdkLstLoans90' && jsonDragged.type == 9 && jsonDragged.valor == 20) {
        text = this.getErrorLabel('1004');
        target = '#errorGeneral'
      }
    }
    switch (this.status) {
      case 0:
        this.fnRegresaposicionContainer(jsonDragged.id);
        target = '#errorPaso1';
        text = errorMessage1001;
        break;
      case 1:
        target = '#errorPaso2'
        text = errorMessage1001;
        break;
      case 2:
        target = '#errorGeneral'
        text = errorMessage1002;
        break;
      case 3:
        target = '#errorPaso3'
        text = errorMessage1001;
        break;
      case 4:
        // pagar land and build (cash to capex)
        target = '#errorPaso4'
        text = errorMessage1001;
        break;
      case 6:
        target = '#errorGeneral'
        text = errorMessage1002;
        break;
      case 7:
        // mover machinary to location machinari
        target = '#errorPaso7'
        text = errorMessage1001;
        break;
      case 8:
        target = '#errorPaso7'
        text = errorMessage1001;
        break;
      case 9:
        target = '#errorPaso4'
        text = errorMessage1001;
        break;
      case 11:
        target = '#errorGeneral'
        text = errorMessage1002;
        break;
      case 12:
        target = '#errorPaso12'
        text = errorMessage1001;
        break;
      case 13:
        target = '#errorPaso13'
        text = errorMessage1001;
        break;
      case 14:
        target = '#errorPaso14'
        text = errorMessage1001;
        break;
      case 15:
        target = '#errorPaso15'
        text = errorMessage1001;
        if (jsonDragged.type === 1 && jsonDragged.valor != 12 && event.container.id == 'paySuppliers') {
          text = this.getErrorLabel('1003');
        }
        break;
      case 17:
        target = '#errorGeneral'
        text = errorMessage1002;
        break;
      case 18:
        target = '#errorPaso1'
        text = errorMessage1001;
        break;
      case 19:
        target = '#errorPaso19'
        text = errorMessage1001
        if (jsonDragged.type === 1 && jsonDragged.valor > 1 && event.container.id == 'cdkLstInterest') {
          text = this.getErrorLabel('1005');
          target = '#errorGeneral'
        }
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 21:
        target = '#errorPaso7'
        text = errorMessage1001;
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 22:
        target = '#errorPaso7'
        text = errorMessage1001;
        if (jsonDragged.type === 1 && jsonDragged.valor != 12 && event.container.id == 'cdkLstInsideMachinePeople') {
          text = this.getErrorLabel('1006');
        }
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 24:
        target = '#errorGeneral'
        text = errorMessage1002;
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 25:
        target = '#errorPaso25'
        text = errorMessage1001;
        if (jsonDragged.type === 4 && jsonDragged.valor > 1 && event.container.id == 'cdkLstDeprecation') {
          text = this.getErrorLabel('1007');
        }
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 27:
        target = '#errorPaso25'
        text = errorMessage1001;
        if (jsonDragged.type === 1 && jsonDragged.valor > 1 && event.container.id == 'cdkLstMachineOver') {
          text = this.getErrorLabel('1008');
        }
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 28:
        target = '#errorPaso13'
        text = errorMessage1001;
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 29:
        target = '#errorGeneral'
        text = this.getErrorLabel('1010');
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 30:
        target = '#errorPaso13'
        text = errorMessage1001;
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 31:
        target = '#errorPaso31'
        text = errorMessage1001;
        if (event.previousContainer.id == "cdkLstCash" && jsonDragged.type == 1) {
          text = this.getErrorLabel('1011')
        }
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 32:
        target = '#errorPaso25'
        text = errorMessage1001;
        noMoreLoansMessage(event, jsonDragged);
        break;
      case 35:
        target = '#errorPaso34'
        text = this.getErrorLabel('4025', '1013');
        if (event.previousContainer.id != "cdkLstNetIncome" && jsonDragged.type != 2) {
          text = this.getErrorLabel('1014');
        }
        noMoreLoansMessage(event, jsonDragged);
        break;
      default:
        target = '#errorGeneral';
        text = errorMessage1001;
        if (this.status > 18) {
          noMoreLoansMessage(event, jsonDragged);
        }
        break;
    }
    this.fnTrowToast(target, text);
    event.previousContainer.data[this.indexItemDragged].draggable = false;
    event.previousContainer.data[this.indexItemDragged].draggingBy = "";
    this.socketService.disablePiece(event.previousContainer.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);

  }

  errorcontrolMes2(event: CdkDragDrop<any>, jsonDragged) {
    let target = "";
    let text = this.getErrorLabel('1001');
    //Omitir mensaje de error si tiene que pagar interes
    if(this.jsonBlnOpenModeActions.showMessageInterest1 && !this.isModeOpen || this.shouldTakeBuildPayment(event.previousContainer.id) ){
      this.isHintInterest++;
      return;
    }
    //Mensajes de error para improvements 
    // NO IDEA
    console.log("ES AQUI!!!!")
    if (this.currentMonth >= 3 && !this.getCheckCheckbox(6).blnCheck && this.status == 3) {
      if (this.improvementsOptionsSelected.some(selected => selected.improvement === "Efficiency")) {
        text = this.getErrorLabel('1001');
        if (event.container.id === 'cdkLstInsideMachineWorkInProgress' && event.previousContainer.id !== 'cdkLstCapEx' && !(this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2)) {
          text = this.getErrorLabel('4008', '3002');
        }
        if (event.container.id === 'cdkLstInsideMachineCapex' && event.previousContainer.id !== 'cdkLstCapEx' && (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2)) {
          text = this.getErrorLabel('1046');
        }
        if (
          (event.previousContainer.id === 'cdkLstInsideMachineCapex' || 
           event.previousContainer.id === 'cdkLstInsideMachineCapex2') && !this.getCheckCheckbox(6).blnCheck && event.container.id === 'cdkLstDeprecation'
        ) {
          text = this.getErrorLabel('4010');
        }
      }
      if (this.improvementsOptionsSelected.some(selected => selected.improvement === "Raw Materials")) {
        if (event.previousContainer.id === 'cdkLstCash' && event.container.id !== 'cdkLstMachineOver') {
          text = this.getErrorLabel('1047');
        }
        if (event.previousContainer.id !== 'cdkLstCash') {

          text = this.getErrorLabel('1048');
        }
        if (
          (event.previousContainer.id === 'cdkLstInsideMachineCapex' || 
           event.previousContainer.id === 'cdkLstInsideMachineCapex2') && !this.getCheckCheckbox(6).blnCheck && event.container.id === 'cdkLstDeprecation'
        ) {
          console.log("here")
          text = this.getErrorLabel('4010');
        }
      }
      if (this.improvementsOptionsSelected.some(selected => selected.improvement === "Customizing")) {
        if (event.previousContainer.id !== 'cdkLstCapEx' && !this.jsonBlnOpenModeActions.isCustomizingShow) {
          text = this.getErrorLabel('1049');
        }
        if (event.previousContainer.id !== 'cdkLstCapEx' && this.jsonBlnOpenModeActions.isCustomizingShow) {
          text = this.getErrorLabel('1046');
        }
        if (this.cdkLstInsideMachineCapexCustomizing.length > 0 && this.jsonBlnOpenModeActions.isCustomizingShow) {
          text = this.getErrorLabel('1001');
        }
        if (
          (event.previousContainer.id === 'cdkLstInsideMachineCapex' || 
           event.previousContainer.id === 'cdkLstInsideMachineCapex2') && 
          !this.getCheckCheckbox(6).blnCheck && 
          event.container.id === 'cdkLstDeprecation'
        ) {
          text = this.getErrorLabel('4010');
        }
      }
      if (this.improvementsOptionsSelected.some(selected => selected.improvement === "Quality")) {
        if (event.previousContainer.id === 'cdkLstLabourP' && (event.container.id !== 'cdkLstChangeI' && event.container.id !== 'cdkLstMarketingS' && event.container.id !== 'cdkLstAdministration') &&
          (!this.cdkLstChangeI.some(element => element.type === 12) && !this.cdkLstMarketingS.some(element => element.type === 12) && !this.cdkLstAdministration.some(element => element.type === 12))
        ) {
          text = this.getErrorLabel('1050');
        }
        if (event.previousContainer.id !== 'cdkLstLabourP' && (!this.cdkLstChangeI.some(element => element.type === 12) && !this.cdkLstMarketingS.some(element => element.type === 12) && !this.cdkLstAdministration.some(element => element.type === 12))) {
          text = this.getErrorLabel('1051');
        }
        if ((event.previousContainer.id === 'cdkLstCash' && event.container.id !== 'cdkLstRecruitmentT') && (this.cdkLstChangeI.some(element => element.type === 12) || this.cdkLstMarketingS.some(element => element.type === 12) || this.cdkLstAdministration.some(element => element.type === 12))) {
          text = this.getErrorLabel('4009', '3009');
        }
        if ((event.previousContainer.id !== 'cdkLstCash') && (this.cdkLstChangeI.some(element => element.type === 12) || this.cdkLstMarketingS.some(element => element.type === 12) || this.cdkLstAdministration.some(element => element.type === 12))) {
          text = this.getErrorLabel('1039');
        }
        console.log(event.previousContainer.id, !this.getCheckCheckbox(6).blnCheck, event.container.id)
        if (
          (event.previousContainer.id === 'cdkLstInsideMachineCapex' || 
           event.previousContainer.id === 'cdkLstInsideMachineCapex2') && !this.getCheckCheckbox(6).blnCheck && event.container.id === 'cdkLstDeprecation'
        ) {
          text = this.getErrorLabel('4010');
        }
        
      }
      if (this.improvementsOptionsSelected.some(selected => selected.improvement === "Expansion")) {
        if (event.previousContainer.id === 'cdkLstCapEx' && jsonDragged.type === 5 && event.container.id !== 'cdkLstLocationMachine' && !this.jsonBlnOpenModeActions.isSecondMachine) {
          text = this.getErrorLabel('1001');
        }
        if (!this.jsonBlnOpenModeActions.isSecondMachine) {
          text = this.getErrorLabel('1049');
        }
        if (event.previousContainer.id === 'cdkLstCapEx' && jsonDragged.type === 4 && event.container.id !== 'cdkLstInsideMachineCapex2' && this.jsonBlnOpenModeActions.isSecondMachine) {
          text = this.getErrorLabel('1046');
        }
        if (event.previousContainer.id === 'cdkLstLabourP' && jsonDragged.type === 6 && event.container.id !== 'cdkLstInsideMachineCapex2' && this.jsonBlnOpenModeActions.isSecondMachine) {
          text = this.getErrorLabel('1053');
        }
        if (event.previousContainer.id === 'cdkLstCapEx' && jsonDragged.type === 3 && event.container.id !== 'cdkLstLoans60' && this.jsonBlnOpenModeActions.isSecondMachine) {
          text = this.getErrorLabel('1052');
        }
        if (event.previousContainer.id !== 'cdkLstLabourP' && this.cdkLstInsideMachineCapex2.some(element => element.type === 4) && !this.cdkLstInsideMachinePeople2.some(element => element.type === 6) && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showMessageOwe20) {
          text = this.getErrorLabel('1051');
        }
        if (event.previousContainer.id === 'cdkLstCash' && event.container.id !== 'cdkLstRecruitmentT' && this.jsonBlnOpenModeActions.showBill6) {
          text = this.getErrorLabel('4009', '3009');
        }
        if (event.previousContainer.id !== 'cdkLstCash' && this.jsonBlnOpenModeActions.showBill6) {
          text = this.getErrorLabel('1034');
        }
        if (
          (event.previousContainer.id === 'cdkLstInsideMachineCapex' || 
           event.previousContainer.id === 'cdkLstInsideMachineCapex2') && !this.getCheckCheckbox(6).blnCheck && event.container.id === 'cdkLstDeprecation'
        ) {
          console.log("here")
          text = this.getErrorLabel('4010');
        }
      }
      if (jsonDragged.type == 10) {
        // se arrastra la maquina de customizing
        return;
      }
      this.fnTrowToast("#errorGeneral", text);
      return;
    }
    const validatePayAdvertising = () => {
      if (this.owesAdvertising > 0 && this.showOwedAdvertising) {
        target = "#errorGeneral"
        text = this.getErrorLabel('1039');
        if (event.previousContainer.id === 'cdkLstCash' && event.container.id !== 'cdkLstAdvertasing') {
          text = this.getErrorLabel('4017', '2030');
        }
      }
    }
    switch (this.status) {
      case -1:
        target = '#errorGeneral';
        text = this.getErrorLabel('4030', '3001');
        break;
      case 0:
        target = '#errorPaso13';
        /* RO/WL */
        if (
          event.container.id !== 'cdkLstTrash' &&
          (
            event.previousContainer.id === 'cdkLstOrders' ||
            event.previousContainer.id === 'cdkLstCostGS' ||
            event.previousContainer.id === 'cdkLstMachineOver' ||
            event.previousContainer.id === 'cdkLstDeprecation' ||
            (event.previousContainer.id === 'cdkLstChangeI' && jsonDragged.type === 1) ||
            (event.previousContainer.id === 'cdkLstMarketingS' && jsonDragged.type === 1) ||
            (event.previousContainer.id === 'cdkLstAdvertasing' && jsonDragged.type === 1) ||
            (event.previousContainer.id === 'cdkLstAdministration' && jsonDragged.type === 1) ||
            (event.previousContainer.id === 'cdkLstAdminOver' && jsonDragged.type === 1) ||
            (event.previousContainer.id === 'cdkLstRecruitmentT' && jsonDragged.type === 1) ||
            (event.previousContainer.id === 'cdkLstRent' && jsonDragged.type === 1) ||
            (event.previousContainer.id === 'cdkLstInterest' && jsonDragged.type === 1)
          )
        ) {
          text = this.getErrorLabel('1015');
        } else if ( /* WO: Acuman */
          (event.previousContainer.id === 'cdkLstChangeI' && jsonDragged.type === 6) ||
          (event.previousContainer.id === 'cdkLstMarketingS' && jsonDragged.type === 6) ||
          (event.previousContainer.id === 'cdkLstAdvertasing' && jsonDragged.type === 6) ||
          (event.previousContainer.id === 'cdkLstAdministration' && jsonDragged.type === 6) ||
          (event.previousContainer.id === 'cdkLstAdminOver' && jsonDragged.type === 6) ||
          (event.previousContainer.id === 'cdkLstRecruitmentT' && jsonDragged.type === 6) ||
          (event.previousContainer.id === 'cdkLstRent' && jsonDragged.type === 6) ||
          (event.previousContainer.id === 'cdkLstInterest' && jsonDragged.type === 6)
        ) {
          text = this.getErrorLabel('1017');
        } else { /* WO */
          text = this.getErrorLabel('1016');
        }
        break;
      case 1:
        target = '#errorPaso31'
        if ((event.previousContainer.id === 'cdkLstReceivables90' || event.previousContainer.id === 'cdkLstReceivables60') && (event.container.id === 'cdkLstCash')) {
          text = this.getErrorLabel('1019');
        } else if (
          (event.container.id === 'cdkLstReceivables90' && event.previousContainer.id === 'cdkLstReceivables60') ||
          (event.container.id === 'cdkLstReceivables60' && event.previousContainer.id === 'cdkLstReceivables30')
        ) {
          text = this.getErrorLabel('1020');
        } else if ( /* RO/WL */
          ((event.container.id !== 'cdkLstReceivables90') && (event.container.id !== 'cdkLstReceivables60') && (event.container.id !== 'cdkLstReceivables30')) &&
          ((event.previousContainer.id === 'cdkLstReceivables90' && jsonDragged.type === 1) ||
            (event.previousContainer.id === 'cdkLstReceivables60' && jsonDragged.type === 1) ||
            (event.previousContainer.id === 'cdkLstReceivables30' && jsonDragged.type === 1))
        ) {
          text = this.getErrorLabel('1018');
        } else {
          text = this.getErrorLabel('1018');
        }
        break;
      case 2:
        target = '#errorPaso31'
        if ((event.previousContainer.id === 'cdkLstLoans90' || event.previousContainer.id === 'cdkLstLoans60') && (event.container.id === 'cdkLstCash')) {
          text = this.getErrorLabel('1019');
        } else if (
          (event.container.id === 'cdkLstLoans90' && event.previousContainer.id === 'cdkLstLoans60') ||
          (event.container.id === 'cdkLstLoans60' && event.previousContainer.id === 'cdkLstLoans30')
        ) {
          text = this.getErrorLabel('1025');
        } else if ( /* RO/WL */
          ((event.container.id !== 'cdkLstLoans90') && (event.container.id !== 'cdkLstLoans60') && (event.container.id !== 'cdkLstLoans30')) &&
          ((event.previousContainer.id === 'cdkLstLoans90' && jsonDragged.type === 3) ||
            (event.previousContainer.id === 'cdkLstLoans60' && jsonDragged.type === 3) ||
            (event.previousContainer.id === 'cdkLstLoans30' && jsonDragged.type === 3))
        ) {
          text = this.getErrorLabel('1024');
        } else {
          text = this.getErrorLabel('1024');
        }
        break;
      case 3:
        target = "#errorGeneral"
        if (event.previousContainer.id === 'cdkLstSuppliers' && event.container.id !== 'cdkLstMaterials') {
          text = this.getErrorLabel('1027');
        }
        if (event.previousContainer.id !== 'cdkLstSuppliers') {
          text = this.getErrorLabel('4010', '2015');
        }
        break;
      case 4:
        target = '#errorPaso4'
        text = this.getErrorLabel('1001');
        break;
      case 6:
        target = "#errorGeneral"
        text = this.getErrorLabel('4012', '2017');
        break;
      case 7:
        target = "#errorGeneral"
        text = this.getErrorLabel('1033');
        if (event.previousContainer.id === 'cdkLstCash' && event.container.id !== 'cdkLstInsideMachinePeople') {
          text = this.getErrorLabel('4014', '2019');
        }
        if (jsonDragged.type === 1 && jsonDragged.valor != 12 && event.container.id == 'cdkLstInsideMachinePeople') {
          text = this.getErrorLabel('1006');
        }
        if (event.previousContainer.id !== 'cdkLstCash' && event.container.id === 'cdkLstInsideMachinePeople') {
          text = this.getErrorLabel('1034');
        }
        break;
      case 8:
        target = "#errorGeneral"
        text = this.getErrorLabel('4015', '2022');
        if (event.previousContainer.id === 'cdkLstInsideMachineCapex' && event.container.id !== 'cdkLstDeprecation') {
          text = this.getErrorLabel('1001');
        }
        if (event.previousContainer.id === 'cdkLstInsideMachineCapex' && event.container.id === 'cdkLstDeprecation') {
          if(jsonDragged.valor > 1) {
            text = this.getErrorLabel('1007');
            console.log("aqui")
          }else{
            if (((this.currentMonth === 2 || !this.jsonBlnOpenModeActions.isSecondMachine) && jsonDragged.valor !== 1) || (this.jsonBlnOpenModeActions.isSecondMachine && jsonDragged.valor > 2)) {
              text = this.getErrorLabel('1007');
              console.log("aqui")
            }
          }
          
        }
        if (event.previousContainer.id === 'cdkLstCash' && event.container.id === 'cdkLstDeprecation') {
          text = this.getErrorLabel('1037');
        }
        break;
      case 9:
        target = "#errorGeneral"
        text = this.getErrorLabel('1039');
        if (event.previousContainer.id === 'cdkLstCash' && (event.container.id !== 'cdkLstMachineOver' && event.container.id !== 'cdkLstChangeI' && event.container.id !== 'cdkLstMarketingS' && event.container.id !== 'cdkLstAdministration' && event.container.id !== 'cdkLstAdminOver')) {
          text = this.getErrorLabel('4016', '2023');
        }
        if (event.previousContainer.id === 'cdkLstCash' && event.container.id === 'cdkLstAdvertasing') {
          text = this.getErrorLabel('1036');
        }
        if (event.previousContainer.id === 'cdkLstCash' && (event.container.id === 'cdkLstRecruitmentT' || event.container.id === 'cdkLstRent')) {
          text = this.getErrorLabel('1038');
        }
        if (event.previousContainer.id === 'cdkLstCash' &&  event.container.id === 'cdkLstRent' && event.previousContainer.data[0].valor>1){
          text = this.getErrorLabel('1035');
        }
        break;
      case 10:
        target = "#errorGeneral"
        text = this.getErrorLabel('1001');
        validatePayAdvertising();
        break;
      case 14:
        target = "#errorGeneral"
        /* FGI */
        text = this.getErrorLabel('1001');
        if ((event.previousContainer.id === 'cdkLstInsideMachineWorkInProgress' || event.previousContainer.id === 'cdkLstInsideMachineWorkInProgress2') && event.container.id !== 'cdkLstFinishedFI') {
          text = this.getErrorLabel('1040');
        }
        if (event.container.id === 'cdkLstFinishedFI' && (event.previousContainer.id !== 'cdkLstInsideMachineWorkInProgress' && event.previousContainer.id !== 'cdkLstInsideMachineWorkInProgress2')) {
          text = this.getErrorLabel('4019', '2034');
        }
        /* COGS */
        if (event.previousContainer.id === 'cdkLstFinishedFI' && event.container.id !== 'cdkLstCostGS') {
          text = this.getErrorLabel('1042');
        }
        validatePayAdvertising();
        break;
      case 15:
        target = "#errorGeneral"
        text = this.getErrorLabel('1014');
        if (event.previousContainer.id === 'cdkLstNetIncome' && event.container.id !== 'cdkLstRetainedE') {
          text = this.getErrorLabel('4025', '2040');
        }
        break;
      default:
        target = "#errorGeneral"
        text = this.getErrorLabel('1001');
        break;
    }
    this.fnTrowToast(target, text);
    event.previousContainer.data[this.indexItemDragged].draggable = false;
    event.previousContainer.data[this.indexItemDragged].draggingBy = "";
    this.socketService.disablePiece(event.previousContainer.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
  }

  errorControlContainer(type, event: CdkDragDrop<any>, jsonDragged) {
    //this.blnErrorContainer = true;
    let target = "";
    let text = "";
    switch (type) {
      case 0:
        target = '#errorContainerTrash';
        text = '87.- It does not belong Trash'
        break;
      case 1:
        target = '#errorContainerBank'
        text = '88.- It does not belong Bank'
        break;
      case 2:
        target = '#errorContainerBank'
        text = '89.- It does not belong Cap Ex'
        break;
      case 3:
        target = '#errorContainerBank'
        text = '90.- It does not belong Labour Pool'
        break;
      case 4:
        target = '#errorContainerBank'
        text = '91.- It does not belong Suppliers'
        break;

    }
    this.errorControl(event, jsonDragged);
    this.fnRegresaposicionContainer(jsonDragged.id);
    event.previousContainer.data[this.indexItemDragged].draggable = false;
    event.previousContainer.data[this.indexItemDragged].draggingBy = "";
    this.socketService.disablePiece(event.previousContainer.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);

  }

  errorControlOpenMode(event: CdkDragDrop<any>, jsonDragged) {
    let target = "#errorGeneral";
    let text = this.getErrorLabel('1001');
    /* Improvements */
    // NO IDEA
    if (this.currentMonth >= 3 && !this.getCheckCheckbox(2).blnCheck && this.status == 3) {
      if (this.improvementsOptionsSelected.some(selected => selected.improvement === "Efficiency")) {
        text = this.getErrorLabel('1001');
        if (event.container.id === 'cdkLstInsideMachineWorkInProgress' && event.previousContainer.id !== 'cdkLstCapEx' && !(this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2)) {
          text = this.getErrorLabel('4008', '3002');
        }
        console.log('(this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2): ' + (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2))
        if (event.container.id === 'cdkLstInsideMachineCapex' && event.previousContainer.id !== 'cdkLstCapEx' && (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2)) {
          text = this.getErrorLabel('1046');
        }
      }
      if (this.improvementsOptionsSelected.some(selected => selected.improvement === "Raw Materials")) {
        if (event.previousContainer.id === 'cdkLstCash' && event.container.id !== 'cdkLstMachineOver') {
          text = this.getErrorLabel('1047');
        }
        if (event.previousContainer.id !== 'cdkLstCash') {
          console.log(this.improvementsOptionsSelected)
          text = this.getErrorLabel('1048');
        }
        if (
          (event.previousContainer.id === 'cdkLstInsideMachineCapex' || 
           event.previousContainer.id === 'cdkLstInsideMachineCapex2') && 
          !this.getCheckCheckbox(6).blnCheck && 
          event.container.id === 'cdkLstDepreciation'
        ) {
          text = this.getErrorLabel('4010');
        }        
      }
      if (this.improvementsOptionsSelected.some(selected => selected.improvement === "Customizing")) {
        if (event.previousContainer.id !== 'cdkLstCapEx' && !this.jsonBlnOpenModeActions.isCustomizingShow) {
          text = this.getErrorLabel('1049');
        }
        if (event.previousContainer.id !== 'cdkLstCapEx' && this.jsonBlnOpenModeActions.isCustomizingShow) {
          text = this.getErrorLabel('1046');
        }
        if (this.cdkLstInsideMachineCapexCustomizing.length > 0 && this.jsonBlnOpenModeActions.isCustomizingShow) {
          text = this.getErrorLabel('1001');
        }
      }
      if (this.improvementsOptionsSelected.some(selected => selected.improvement === "Quality")) {
        if (event.previousContainer.id === 'cdkLstLabourP' && (event.container.id !== 'cdkLstChangeI' && event.container.id !== 'cdkLstMarketingS' && event.container.id !== 'cdkLstAdministration') &&
          (!this.cdkLstChangeI.some(element => element.type === 12) && !this.cdkLstMarketingS.some(element => element.type === 12) && !this.cdkLstAdministration.some(element => element.type === 12))
        ) {
          text = this.getErrorLabel('1050');
        }
        if (event.previousContainer.id !== 'cdkLstLabourP' && (!this.cdkLstChangeI.some(element => element.type === 12) && !this.cdkLstMarketingS.some(element => element.type === 12) && !this.cdkLstAdministration.some(element => element.type === 12))) {
          text = this.getErrorLabel('1051');
        }
        if ((event.previousContainer.id === 'cdkLstCash' && event.container.id !== 'cdkLstRecruitmentT') && (this.cdkLstChangeI.some(element => element.type === 12) || this.cdkLstMarketingS.some(element => element.type === 12) || this.cdkLstAdministration.some(element => element.type === 12))) {
          text = this.getErrorLabel('4009', '3009');
        }
        if ((event.previousContainer.id !== 'cdkLstCash') && (this.cdkLstChangeI.some(element => element.type === 12) || this.cdkLstMarketingS.some(element => element.type === 12) || this.cdkLstAdministration.some(element => element.type === 12))) {
          text = this.getErrorLabel('1039');
        }
      }
      if (this.improvementsOptionsSelected.some(selected => selected.improvement === "Expansion")) {
        if (event.previousContainer.id === 'cdkLstCapEx' && jsonDragged.type === 5 && event.container.id !== 'cdkLstLocationMachine' && !this.jsonBlnOpenModeActions.isSecondMachine) {
          text = this.getErrorLabel('1001');
        }
        if (!this.jsonBlnOpenModeActions.isSecondMachine) {
          text = this.getErrorLabel('1049');
        }
        if (event.previousContainer.id === 'cdkLstCapEx' && jsonDragged.type === 4 && event.container.id !== 'cdkLstInsideMachineCapex2' && this.jsonBlnOpenModeActions.isSecondMachine) {
          text = this.getErrorLabel('1046');
        }
        if (event.previousContainer.id === 'cdkLstLabourP' && jsonDragged.type === 6 && event.container.id !== 'cdkLstInsideMachineCapex2' && this.jsonBlnOpenModeActions.isSecondMachine) {
          text = this.getErrorLabel('1053');
        }
        if (event.previousContainer.id === 'cdkLstCapEx' && jsonDragged.type === 3 && event.container.id !== 'cdkLstLoans60' && this.jsonBlnOpenModeActions.isSecondMachine) {
          text = this.getErrorLabel('1052');
        }
        if (event.previousContainer.id !== 'cdkLstLabourP' && this.cdkLstInsideMachineCapex2.some(element => element.type === 4) && !this.cdkLstInsideMachinePeople2.some(element => element.type === 6) && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showMessageOwe20) {
          text = this.getErrorLabel('1051');
        }
        if (event.previousContainer.id === 'cdkLstCash' && event.container.id !== 'cdkLstRecruitmentT' && this.jsonBlnOpenModeActions.showBill6) {
          text = this.getErrorLabel('4009', '3009');
        }
        if (event.previousContainer.id !== 'cdkLstCash' && this.jsonBlnOpenModeActions.showBill6) {
          text = this.getErrorLabel('1034');
        }
      }
      this.fnTrowToast("#errorGeneral", text);
      return;
    }
    /* Buy Raw Materials */
    if (event.previousContainer.id === 'cdkLstSuppliers' && event.container.id !== 'cdkLstMaterials' && jsonDragged.type !== 3) {
      text = this.getErrorLabel('1027');
    }
    if (event.container.id === 'cdkLstMaterials' && event.previousContainer.id !== 'cdkLstSuppliers') {
      text = this.getErrorLabel('4010', '2015');
    }
    /* Load Raw Materials to Machine */
    if ((event.previousContainer.id === 'cdkLstMaterials' && event.container.id !== 'cdkLstInsideMachineWorkInProgress') || (event.previousContainer.id !== 'cdkLstMaterials' && event.container.id === 'cdkLstInsideMachineWorkInProgress')) {
      text = this.getErrorLabel('4012', '2017');
    }
    /* Pay Labor */
    if (jsonDragged.type === 1 && jsonDragged.valor != 12 && event.container.id == 'cdkLstInsideMachinePeople') {
      text = this.getErrorLabel('1006');
    }
    if (event.previousContainer.id !== 'cdkLstCash' && event.container.id === 'cdkLstInsideMachinePeople') {
      text = this.getErrorLabel('1034');
    }
    /* Take depreciation */
    if (event.previousContainer.id !== 'cdkLstInsideMachineCapex' && event.container.id === 'cdkLstDeprecation') {
      text = this.getErrorLabel('4015', '2022');
    }
    if (event.previousContainer.id === 'cdkLstInsideMachineCapex' && event.container.id === 'cdkLstDeprecation') {
      if (((this.currentMonth === 2 || !this.jsonBlnOpenModeActions.isSecondMachine) && jsonDragged.valor !== 1) || (this.jsonBlnOpenModeActions.isSecondMachine && jsonDragged.valor > 2)) {
        text = this.getErrorLabel('1007');
      }
    }
    if (event.previousContainer.id === 'cdkLstCash' && event.container.id === 'cdkLstDeprecation') {
      text = this.getErrorLabel('1037');
    }
    /* Pay Expenses */
    if (event.previousContainer.id === 'cdkLstCash' && event.container.id === 'cdkLstAdvertasing') {
      text = this.getErrorLabel('1036');
    }
    if (event.previousContainer.id === 'cdkLstCash' && (event.container.id === 'cdkLstRecruitmentT' || event.container.id === 'cdkLstRent')) {
      text = this.getErrorLabel('1038');
    }
    /* Pay Advertising */
    if (this.owesAdvertising > 0 && this.showOwedAdvertising) {
      if (event.previousContainer.id !== 'cdkLstCash' && event.container.id === 'cdkLstAdvertasing') {
        if (jsonDragged.valor > this.owesAdvertising) {
          this.fnTrowToast('#errorGeneral', this.getErrorLabel('1035'));
        } else {
          this.fnTrowToast('#errorGeneral', this.getErrorLabel('1096')); // not enough
        }
      }
    }
    /* Move Royals from the Machine */
    if ((event.previousContainer.id === 'cdkLstInsideMachineWorkInProgress' || event.previousContainer.id === 'cdkLstInsideMachineWorkInProgress2') && event.container.id !== 'cdkLstFinishedFI') {
      text = this.getErrorLabel('1040');
    }
    if (event.container.id === 'cdkLstFinishedFI' && (event.previousContainer.id !== 'cdkLstInsideMachineWorkInProgress' && event.previousContainer.id !== 'cdkLstInsideMachineWorkInProgress2')) {
      text = this.getErrorLabel('4019', '2034');
    }
    /* COGS */
    if (event.previousContainer.id === 'cdkLstFinishedFI' && event.container.id !== 'cdkLstCostGS') {
      text = this.getErrorLabel('1042');
    }
    this.fnTrowToast(target, text);
    event.previousContainer.data[this.indexItemDragged].draggable = false;
    event.previousContainer.data[this.indexItemDragged].draggingBy = "";
    this.socketService.disablePiece(event.previousContainer.id, this.indexItemDragged, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
  }

  fnTrowToast(target = '', text = '41.- error') {
    if (target.charAt(0) === '#') {
      const defaultContainer = '#errorGeneral';
      const elementContainer = document.getElementById(target.substring(1));
      if (!elementContainer) {
        console.log(`Target: ${target} not found, defaulting to: ${defaultContainer}. With text: ${text}`)
      }
      target = elementContainer ? target : defaultContainer;
    } else {
      target = '#errorGeneral';
    }
    Swal.mixin({
      target: target,
      customClass: {
        container: 'position-absolute',
        content: 'style2a calibriFont',
      },
      toast: true,
      timerProgressBar: true,
      showConfirmButton: false,
      timer: 3000,
      position: 'bottom-right',
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    }).fire({
      html: text
    }).then(resp => /*this.blnErrorContainer = false*/ console.log(""));
  }

  fnTrowToastwithTimeMiliseconds(target = '', text = '41.- error', intTimeMiliseconds) {
    if (target.charAt(0) === '#') {
      const defaultContainer = '#errorGeneral';
      const elementContainer = document.getElementById(target.substring(1));
      if (!elementContainer) {
        console.log(`Target: ${target} not found, defaulting to: ${defaultContainer}`)
      }
      target = elementContainer ? target : defaultContainer;
    } else {
      target = '#errorGeneral';
    }
    Swal.mixin({
      target: target,
      customClass: {
        container: 'position-absolute'
      },
      toast: true,
      timerProgressBar: true,
      showConfirmButton: false,
      timer: intTimeMiliseconds,
      position: 'bottom-right',
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    }).fire({
      html: text
    }).then(resp => /*this.blnErrorContainer = false*/ console.log(""));
  }

  async fnTrowSwalPayLoans(jsonDragged, idList: string) {
    console.log(jsonDragged, idList)
    let totalCash = 0;
    this.cdkLstCash.forEach(item => {
      totalCash += item.valor;
    });
    const { value: strPartTake, isConfirmed: isConfirmed } = await Swal.fire({
      title: 'Confirm',
      html: this.getErrorLabel('1072'),
      showCancelButton: true,
      allowEscapeKey:false,
      confirmButtonText: '&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
      cancelButtonText: 'Cancel',
    });

    if (isConfirmed) {
      const partTake = jsonDragged.valor;
      if (partTake <= 0) {
        return;
      }
      if (totalCash < partTake) {
        Swal.fire({
          html: this.getErrorLabel('1069'),
          icon: 'error'
        });
        return
      }
      console.log("ZONA")
      console.log(this.fnisReadyPaso8());
      
      console.log(partTake, "tomaaar");
      jsonDragged.valor = jsonDragged.valor - partTake;
      // actualizamos el item que se arrastro
      if (jsonDragged.valor <= 0) {
        this.spinner.show('my-custom-spinner')
        this.cdkLstLoans30.forEach((item, i) => {
          if (item.id == jsonDragged.id) {
            this.cdkLstLoans30.splice(i, 1)
          }
        });
        this.socketService.deleteCoin(idList, jsonDragged, this.roomId, this.teamId);
        if (idList === 'cdkLstLoans30' || idList === 'cdkLstLoans60' || idList === 'cdkLstLoans90') {
          this.socketService.createCoin('cdkLstBank', {
            type: 9,
            valor: 20,
            id: null,
            draggable: false
          }, this.roomId, this.teamId);
        }
        
        // Mandar mensaje facilitador, pago prestamo adelantado
        this.notifyFacilitator(1);
      } else {

      }
      this.socketService.payLoan(idList, jsonDragged, this.roomId, this.teamId);

      setTimeout(() => {
        this.cdkLstCash = [];
        let total = totalCash - partTake;
        const countGroup20 = Math.floor(total / 20);
        const lessPackege = total % 20;
        console.log("grupos de 20: ", countGroup20);
        console.log("paquetes individual: ", lessPackege);
        for (let i = 0; i < countGroup20; i++) {
          this.cdkLstCash.push({
            valor: 20,
            type: 1,
            id: null,
            draggable: false,
          })
        }
        if (lessPackege > 0) {
          this.cdkLstCash.push({
            valor: lessPackege,
            type: 1,
            id: null,
            draggable: false,
          });
        }
        this.socketService.splitCoin(this.cdkLstCash, 'cdkLstCash', this.roomId, this.teamId);
        console.log("Lista a mandar", this.cdkLstCash);
        this.spinner.hide('my-custom-spinner')

      }, 1500);
      setTimeout(() => {
        // if(this.fnisReadyPaso8()){
        //   // this.fnSaveBackQuantities(true);
        //   console.log("HACEMOS BACK")
        //   if(idList === 'cdkLstLoans30' || idList === 'cdkLstLoans60' || idList === 'cdkLstLoans90'){
        //     this.funcionBack("casePayLoan")
        //   }else{
        //     if(idList === 'cdkLstPayables30' || idList === 'cdkLstPayables60'){
        //       this.funcionBack("casePayPayable",partTake)

        //     }
        //   }
        // }
      }, 1000);
      
    } else {
      this.spinner.hide('my-custom-spinner')
      this.socketService.disablePiece(idList, 0, this.roomId, this.userId, this.userData.FirstName, false, jsonDragged);
      console.log("habilitando pieza");
    }
  }

  implementImprovement() {
    return new Promise(resolve => {
      this.improvementImplemented = true;
      console.log(this.improvementsOptionsSelected)
      let position = 0;
      if (this.currentMonth == 3 || this.currentMonth == 4 && this.improvementsOptionsSelected[0].value == 1 && this.improvementsOptionsSelected[0].option != 6) {
        position = 0;
      } else if (this.currentMonth == 4 && this.improvementsOptionsSelected[0].value == 3 || (this.improvementsOptionsSelected[0].value == 1 && this.improvementsOptionsSelected[0].option == 6)) {
        position = 1;
      } else if (this.currentMonth >= 4) {
        if (
          ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 5 && this.improvementsOptionsSelected[0].Approved == 0) ||
          ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 5 && this.improvementsOptionsSelected[1].Approved == 0)
        ) {
          console.log("Prioridad expansion")
          position = this.improvementsOptionsSelected[0].option == 5 ? 0 : 1;
        } else if (
          ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 2 && this.improvementsOptionsSelected[0].Approved == 0) ||
          ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 2 && this.improvementsOptionsSelected[1].Approved == 0)
        ) {
          position = this.improvementsOptionsSelected[0].option == 2 ? 0 : 1;
        } else if (
          ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 1 && this.improvementsOptionsSelected[0].Approved == 0) ||
          ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 1 && this.improvementsOptionsSelected[1].Approved == 0)
        ) {
          console.log("Prioridad raw")
          position = this.improvementsOptionsSelected[0].option == 1 ? 0 : 1;
        } else if (
          ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 3 && this.improvementsOptionsSelected[0].Approved == 0) ||
          ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 3 && this.improvementsOptionsSelected[1].Approved == 0)
        ) {
          position = this.improvementsOptionsSelected[0].option == 3 ? 0 : 1;
        } else {
          if(this.improvementsOptionsSelected[0].Approved == 1){
            position = 1
          }else{
            position = this.improvementsOptionsSelected[0].value == 3 || this.improvementsOptionsSelected[0].option == 6 ? 1 : 0;
          }
        }
      }
      console.log(position)
      if (this.improvementsOptionsSelected[position].option == 6 || (this.currentMonth == 3 && this.improvementsOptionsSelected[0].value == 3)) {
        resolve(true);
        return;
      }
      if (this.improvementImplemented && this.improvementsOptionsSelected[position].value == 3) {
        resolve(true);
        return;
      }
      this.spinner.show('my-custom-spinner')
      this.currentImprovement = this.improvementsOptionsSelected[position].improvement;
      if (this.improvementsOptionsSelected[position].improvement == 'Customizing') {
        // if (this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title != 'Buy or Upgrade Equipment' && this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title != 'Pay Recruitment & Training') {
        //   let title = 'Buy or Upgrade Equipment';
        //   // this.checkBoxes.splice(this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4, 0, { id: 4, blnCheck: false, title: title, idBd: null, disabled: true });
        // }else{
        //   this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = false;
        // }
        console.log(this.getCheckCheckbox(4).blnCheck)
        if(this.getCheckCheckbox(4).blnCheck){
          console.log("Debe entrar aqui")
          this.checkCheckbox(4,false);
        }else{
          this.showCheckbox(4,1);
        }
        let newArray = [{
          valor: 1,
          id: null,
          draggable: false,
          type: 10,
        }, {
          valor: 10,
          id: null,
          draggable: false,
          type: 4,
        }];
        if (this.cdkLstCapEx.some(item => item.type == 4) && (this.jsonBlnOpenModeActions.isSoldBuild || this.cdkLstLandBuild.length == 0)) {
          newArray.push({
            valor: 20,
            id: null,
            draggable: false,
            type: 4,
          })
        }
        this.socketService.splitCoin(newArray, 'cdkLstCapEx', this.roomId, this.teamId);
      } else if (this.improvementsOptionsSelected[position].improvement == 'Quality') {
        // let title = 'Pay Recruitment & Training';
        // if (this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title != 'Buy or Upgrade Equipment' && this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title != 'Pay Recruitment & Training') {
        //   // this.checkBoxes.splice(this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4, 0, { id: 4, blnCheck: false, title: title, idBd: null, disabled: true });
        //   console.log("Entra aqui")
        // }else{
        //   if(this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title == 'Buy or Upgrade Equipment' && !this.jsonBlnOpenModeActions.isSecondMachine){
        //     // this.checkBoxes.splice(this.checkBoxes[0].title == "Clear Income Statement" ? 4 : 5, 0, { id: 4, blnCheck: false, title: title, idBd: null, disabled: true });
        //     console.log("o aqui")
        //   }else{
        //     if(this.jsonBlnOpenModeActions.isSecondMachine){
        //       this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 4 : 5].blnCheck = false;
        //     }
        //     console.log("o aqui")

        //   }
        // }
        if(this.getCheckCheckbox(5).blnCheck == true){
          this.checkCheckbox(5,false);
        }else{
          this.showCheckbox(5,1);
        }
        this.socketService.splitCoin([{
          valor: 1,
          id: null,
          draggable: false,
          type: 12,
        }], 'cdkLstLabourP', this.roomId, this.teamId);
        this.jsonBlnOpenModeActions['showBill1'] = true;
        // this.jsonBlnOpenModeActions['isPayBill1'] = true;
        this.socketService.setBlnControlModeOpen('showBill1', true, this.roomId, this.teamId);        

      } else if (this.improvementsOptionsSelected[position].improvement == 'Expansion') {
        // let title = 'Buy or Upgrade Equipment';
        // let title2 = 'Pay Recruitment & Training';
        // if (this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title != 'Buy or Upgrade Equipment' && this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title != 'Pay Recruitment & Training') {
        //   // this.checkBoxes.splice(this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4, 0, { id: 4, blnCheck: false, title: title, idBd: null, disabled: true });
        //   // this.checkBoxes.splice(this.checkBoxes[0].title == "Clear Income Statement" ? 4 : 5, 0, { id: 4, blnCheck: false, title: title2, idBd: null, disabled: true });
        // }
        
        this.showCheckbox(4,1);
        setTimeout(() => {
        this.showCheckbox(5,1);
          
        }, 700);
        
        let newArray = [{
          valor: 1,
          id: null,
          draggable: false,
          type: 5,
        },
        {
          valor: 20,
          id: null,
          draggable: false,
          type: 4,
        }];
        if (this.cdkLstCapEx.some(item => item.type == 4) && (this.jsonBlnOpenModeActions.isSoldBuild || this.cdkLstLandBuild.length == 0)) {
          newArray.push({
            valor: 20,
            id: null,
            draggable: false,
            type: 4,
          })
        }
        this.socketService.splitCoin(newArray, 'cdkLstCapEx', this.roomId, this.teamId);
        setTimeout(() => {
          this.socketService.splitCoin([{
            valor: 12,
            id: null,
            draggable: false,
            type: 6,
          }], 'cdkLstLabourP', this.roomId, this.teamId);
          setTimeout(() => {
            this.socketService.splitCoin(this.cdkLstSuppliers.concat(this.createArrayCoins(3, 4, 7)), 'cdkLstSuppliers', this.roomId, this.teamId);
          }, 500);
        }, 1500);
        // this.jsonBlnOpenModeActions['showBill6'] = true;
        // // this.jsonBlnOpenModeActions['isPayBill1'] = true;
        // this.socketService.setBlnControlModeOpen('showBill6', true, this.roomId, this.teamId);

      } else if (this.improvementsOptionsSelected[position].improvement == 'Raw Materials') {
        let title = 'Buy or Upgrade Equipment';
        // if (this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title != 'Buy or Upgrade Equipment' && this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title != 'Pay Recruitment & Training') {
        //   // this.checkBoxes.splice(this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4, 0, { id: 4, blnCheck: false, title: title, idBd: null, disabled: true });
        // }else{
        //   this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = false;
        // }
        if(this.getCheckCheckbox(4).blnCheck == true){
          this.checkCheckbox(4,false);
        }else{
          this.showCheckbox(4,1);
        }
        this.jsonBlnOpenModeActions['isRawMaterials1'] = true;
        this.socketService.setBlnControlModeOpen('isRawMaterials1', true, this.roomId, this.teamId);

        setTimeout(() => {
          let newArray = this.createArrayCoins(1, 4, 14);
          if (this.currentMonth >= 4 && this.jsonBlnOpenModeActions.isEfficiency1) {
            newArray = this.createArrayCoins(1, 6, 14);
          } else if (this.currentMonth >= 4 && this.jsonBlnOpenModeActions.isSecondMachine) {
            const auxArray = this.createArrayCoins(1, 4, 14);
            newArray = this.createArrayCoins(3, 4, 7).concat(auxArray);
          }
          this.socketService.splitCoin(newArray, 'cdkLstSuppliers', this.roomId, this.teamId);
          setTimeout(() => {
            this.jsonBlnOpenModeActions['showBill2'] = true;
            this.socketService.setBlnControlModeOpen('showBill2', true, this.roomId, this.teamId);
            // resolve(true);
          }, 1500);
        }, 1000);
      } else if (this.improvementsOptionsSelected[position].improvement == 'Efficiency') {
        let title = 'Buy or Upgrade Equipment';
        // if (this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title != 'Buy or Upgrade Equipment' && this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].title != 'Pay Recruitment & Training') {
        //   // this.checkBoxes.splice(this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4, 0, { id: 4, blnCheck: false, title: title, idBd: null, disabled: true });
        // }else{
        //   this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = false;
        // }
        if(this.getCheckCheckbox(4).blnCheck == true){
          this.checkCheckbox(4,false);
        }else{
          this.showCheckbox(4,1);
        }

        // this.jsonBlnOpenModeActions['isEfficiency1'] = true;
        // this.socketService.setBlnControlModeOpen('isEfficiency1', true, this.roomId, this.teamId);

        setTimeout(() => {
          let newArray = this.createArrayCoins(2, 1, 11);
          if (this.cdkLstCapEx.some(item => item.type == 4) && (this.jsonBlnOpenModeActions.isSoldBuild || this.cdkLstLandBuild.length == 0)) {
            newArray.push({
              valor: 20,
              id: null,
              draggable: false,
              type: 4,
            })
          }
          this.socketService.splitCoin(newArray, 'cdkLstCapEx', this.roomId, this.teamId);
        }, 500);
      }


      // this.boardService.createUpdCheckList(this.teamId, this.checkBoxes).subscribe(response => {
      //   this.checkBoxes = response.checkList as Array<any>;
      //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      //   console.log(this.improvementsOptionsSelected);
      // });

      //   this.improvementService.updateImprovementOptionById(this.workshopId, this.improvementsOptionsSelected[position].ImproveId,this.improvementsOptionsSelected[position].option, 2, this.currentMonth).subscribe(resp =>{
      //     // this.improvementService.updateImprovementOptionByTeamId(this.improvementsOptionsSelected[position].ImproveId,this.improvementsOptionsSelected[position].option,2).subscribe(resp =>{
      //   console.log("fnSave",resp);
      //   if(resp['intResponse'] === 200){
      //     console.log("implementado");
      //   }
      // });
      this.improvementService.updateImproveOptionStatus(this.improvementsOptionsSelected[position].ImproveId, 3).subscribe(resp => {
        // this.improvementService.updateImprovementOptionByTeamId(this.improvementsOptionsSelected[position].ImproveId,this.improvementsOptionsSelected[position].option,2).subscribe(resp =>{
        console.log("fnSave", resp);
        if (resp['intResponse'] == 200) {
          console.log("implementado");
          this.improvementsOptionsSelected[position].value = 3;
          this.socketImprovementService.updateImprovementOptionStatus(this.teamId, this.roomId, this.improvementsOptionsSelected[position].option, 3);
          setTimeout(() => {
            setTimeout(() => {
              this.spinner.hide('my-custom-spinner')
              
            }, 1500);
            this.socketImprovementService.updateImproveOption(this.teamId, ("roomWorkShop" + this.workshopId), this.improvementsOptionsSelected[position].ImproveId, this.improvementsOptionsSelected[position].option, 3)
            resolve(true);
          }, 1500);
        }else{
          setTimeout(() => {
            this.spinner.hide('my-custom-spinner')
            
          }, 1500);
        }
      });
    })

  }
  changePositionForImprovementOption(){
    console.log("Entra a funcion Change Option...",this.currentMonth,this.improvementsOptionsSelected)
    if ((this.currentMonth>=4 && this.improvementsOptionsSelected[0].value==3) || (this.currentMonth >=4 && this.improvementsOptionsSelected[0].option == 6)) {
      return 1;
    }else{
      return 0;
    }
  }
  async askImprovement() {
    console.log("entra fn askImprovement",this.blnGrowNocontrol, this.improvementsOptionsSelected);
    let position = 0;
    let caseExpantion = ""
    // position = this.changePositionForImprovementOption(); //Se llama al inicio para que no afecte las demas validaciones, y por si el mes es mayor a 4 tambien aplique
    console.log("Posicion del arreglo...",position);
    if(this.improvementsOptionsSelected.length >= 3 && this.currentMonth >= 4){
      const indexToRemove = this.improvementsOptionsSelected.findIndex(option => option.option === 6);
      if (indexToRemove !== -1) {
          // Si se encuentra el índice, elimina el elemento con `splice`
          this.improvementsOptionsSelected.splice(indexToRemove, 1);
          console.log(this.improvementsOptionsSelected)
      }
    }
    if (this.currentMonth == 3 || this.currentMonth == 4 && (this.improvementsOptionsSelected[0].value == 1 && this.improvementsOptionsSelected[0].option != 6)
    ) {
      position = 0;
      // position = this.changePositionForImprovementOption(); // La vuelvo a llamar porque si es mes 4, entra a este if y si tenia 1, se vuelve a 0 de nuevo  
      console.log("Posicion del arreglo...",position);
    } else if (this.currentMonth == 4 && this.improvementsOptionsSelected[0].value == 3 &&
      ((this.improvementsOptionsSelected[0].value == 1 && this.improvementsOptionsSelected[0].option == 6)) &&
      this.improvementsOptionsSelected[1].value == 1 && this.improvementsOptionsSelected[1].option != 6
    ) {
      position = 1;
    } else if (this.currentMonth >= 4) {
      if (
        ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 5 && this.improvementsOptionsSelected[0].Approved == 0) ||
        ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 5 && this.improvementsOptionsSelected[1].Approved == 0)
      ) {
        position = this.improvementsOptionsSelected[0].option == 5 ? 0 : 1;
      } else if (
        ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 2 && this.improvementsOptionsSelected[0].Approved == 0) ||
        ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 2 && this.improvementsOptionsSelected[1].Approved == 0)
      ) {
        console.log("Caso EFFY")
        caseExpantion = "Efficiency"
        position = this.improvementsOptionsSelected[0].option == 2 ? 0 : 1;
      } else if (
        ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 1 && this.improvementsOptionsSelected[0].Approved == 0) ||
        ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 1 && this.improvementsOptionsSelected[1].Approved == 0)
      ) {
        position = this.improvementsOptionsSelected[0].option == 1 ? 0 : 1;
        console.log("Caso RAW")
        caseExpantion = "RM"
      } else if (
        ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 3 && this.improvementsOptionsSelected[0].Approved == 0) ||
        ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 3 && this.improvementsOptionsSelected[1].Approved == 0)
      ) {
        position = this.improvementsOptionsSelected[0].option == 3 ? 0 : 1;
      } else {
        console.log("AQUI")
        if(this.improvementsOptionsSelected[0].Approved == 1){
          position = 1
        }else{
          position = this.improvementsOptionsSelected[0].value == 3 || this.improvementsOptionsSelected[0].option == 6 ? 1 : 0;
        }
      }
    }
    if (/*DCM improv this.improvementsOptionsSelected[position].option == 6 ||*/ (this.currentMonth == 3 && this.improvementsOptionsSelected[0].value == 3) || (this.currentMonth == 4 && this.improvementsOptionsSelected[0].value == 3 && this.isPendingProject == 3) 
      || (this.currentMonth == 5 && this.improvementsOptionsSelected[0].value == 3 && this.isPendingProject == 3) || (this.currentMonth == 6 && this.improvementsOptionsSelected[0].value == 3 && this.isPendingProject == 3)) {
      return;
    }
    if ((this.improvementsOptionsSelected[0].value == 3 /*DCM improv || this.improvementsOptionsSelected[0].option == 6*/) && (this.improvementsOptionsSelected[1].value == 3 /*DCM improv || this.improvementsOptionsSelected[1].option == 6*/)) {
      console.log("Se sale aqui")
      if(this.status == 3 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isRawMaterials2 && this.jsonBlnOpenModeActions.isRawMaterials1){
        this.implementExpansionWithRawMaterial(2);
      }
      if(this.status == 3 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isEfficiency2 && this.jsonBlnOpenModeActions.isEfficiency1){
        this.implementExpansionWithEfficiency(2);
      }
      return;
    }
    if(this.jsonBlnOpenModeActions.isSecondMachine && caseExpantion == "Efficiency" && this.jsonBlnOpenModeActions.isEfficiency1){
      if(this.jsonBlnOpenModeActions.isEfficiency2){
        return
      }else{
        this.implementExpansionWithEfficiency(2);
      }
    }else if(this.jsonBlnOpenModeActions.isSecondMachine && caseExpantion == "RM" && this.jsonBlnOpenModeActions.isRawMaterials1){
      if(this.jsonBlnOpenModeActions.isRawMaterials2){
        return
      }else{
        this.implementExpansionWithRawMaterial(2);
      }
    }else{
      if(false){
   
        this.blnCanMoveitems = false //bloqueamos board para que no pueda hacer nada;
        
        this.blnShowerrorPasoImplementImprov = true;
        this.textErrorImplementImprov = this.improvementsOptionsSelected[position].question;
        /*await Swal.fire({
          html: '<div style="font-size: 12px !important;">'+this.improvementsOptionsSelected[position].question+'</div>',
          target: "#errorPasoCash",
          customClass: {
            container: 'position-absolute',
            content: 'style2a calibriFont',
          },
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.isConfirmed) {
            //this.isOpenMarketDisabledBoard = false;
            //const x = await this.implementImprovement();
          } else if (result.isDenied) {
            
  
          }
        }).catch(reason => {
          console.log(reason);
        });*/
      }
    else{
      console.log(this.improvementsOptionsSelected, position);
      if(this.improvementsOptionsSelected[position].improvement == "No Improvement"){
        return;
      }
        if (this.blnGrowNocontrol) {
          Swal.fire({
            html: this.getErrorLabel('1099'),
            showConfirmButton: true,
            confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + this.getErrorLabel('2090') + '&nbsp;&nbsp;&nbsp; ',
            confirmButtonColor: '#5EA0CC',
            allowOutsideClick: false,
            allowEscapeKey: false, // Evita el cierre al presionar "esc"
  
            icon: 'warning'
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.fntakeControl();
              this.isOpenMarketDisabledBoard = false;
              setTimeout(() => {
                this.socketService.askImprovement(this.roomId, this.teamId);
              }, 350);
            }
          });
        } else {
          console.log(this.blnGrowNocontrol)
          console.log(this.improvementsOptionsSelected[position])
          console.log(this.getSingleTextLabel('8075'))
          let stringImpru = this.improvementsOptionsSelected[position].improvement
          
          if(stringImpru == 'Efficiency'){
            stringImpru = this.getSingleTextLabel('91'); //To get LCR label instead of RM 
          }
          let originalString = this.getSingleTextLabel('8075')
          let modifiedString = originalString.replace('{{placeholder}}', stringImpru);
          console.log(modifiedString);
          if(caseExpantion == "RM" && this.jsonBlnOpenModeActions.isSecondMachine){
            originalString = this.getSingleTextLabel('8075')
            modifiedString = originalString.replace('{{placeholder}}', "RM");
            stringImpru = "on Machine " + ("1")
            modifiedString = modifiedString.replace('{{placeholder}}', stringImpru);
          }
          if(caseExpantion == "Efficiency" && this.jsonBlnOpenModeActions.isSecondMachine){
            originalString = this.getSingleTextLabel('8075')
            modifiedString = originalString.replace('{{placeholder}}', 'Efficiency');
            stringImpru = "on Machine " + ("1")
            modifiedString = modifiedString.replace('{{placeholder}}', stringImpru);
          }
  
          await Swal.fire({
            title: modifiedString,
            showDenyButton: !this.isOpenMarketDisabledBoard,
            showConfirmButton: !this.isOpenMarketDisabledBoard,
            confirmButtonText: `Yes`,
            denyButtonText: `No`,
            allowOutsideClick: false,
            allowEscapeKey: false // Evita el cierre al presionar "esc"
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.mejorasXMes++;
              this.isOpenMarketDisabledBoard = false;
              this.jsonBlnOpenModeActions.isActiveImprovement = true;
              this.socketService.setBlnControlModeOpen('isActiveImprovement', true, this.roomId, this.teamId);
              const x = await this.implementImprovement();
            } else if (result.isDenied) {
              // mandar confirmacion a facilitador
              this.notifyFacilitator(4);
              // Swal.fire('Changes are not saved', '', 'info')
              // this.socketImprovementService.askFacilitatorImprovementDeniedByTeam(this.teamId, this.roomId, this.improvementsOptionsSelected[position].option);
              // this.spinner.show();
              this.improvementService.updateImproveOptionQuestion(this.improvementsOptionsSelected[position].ImproveId, 1).subscribe(resp => {
                console.log("fnSaveStatus", resp);
              })
              this.improvementService.updateImproveOptionStatus(this.improvementsOptionsSelected[position].ImproveId, 2).subscribe(resp => {
                // this.improvementService.updateImprovementOptionByTeamId(this.improvementsOptionsSelected[position].ImproveId,this.improvementsOptionsSelected[position].option,2).subscribe(resp =>{
                console.log("fnSave", resp);
                if (resp['intResponse'] == 200) {
                  console.log("implementado");
                  this.improvementsOptionsSelected[position].value = 2;
                  this.socketImprovementService.updateImprovementOptionStatus(this.teamId, this.roomId, this.improvementsOptionsSelected[position].option, 2);
                  if (this.currentMonth <= 3 || this.currentMonth >= 4 && !this.isModeOpen) {
                    this.isOpenMarketDisabledBoard = true;
                    setTimeout(() => {
                      this.socketImprovementService.updateImproveOption(this.teamId, ("roomWorkShop" + this.workshopId),this.improvementsOptionsSelected[position].option, this.improvementsOptionsSelected[position].ImproveId,  2)
                    }, 1500);
                  }
                }
              });
    
            }
          }).catch(reason => {
            console.log(reason);
          });
        }
        
  
      }
    }
    
    console.log('sale');
  }

  async askAgainforImprovement() {
    console.log("entra fn askImprovement 2");
    let position = 0;
    let caseExpantion = ""
    // position = this.changePositionForImprovementOption(); //Se llama al inicio para que no afecte las demas validaciones, y por si el mes es mayor a 4 tambien aplique
    console.log("Posicion del arreglo...",position);
    if(this.improvementsOptionsSelected.length >= 3 && this.currentMonth >= 4){
      const indexToRemove = this.improvementsOptionsSelected.findIndex(option => option.option === 6);
      if (indexToRemove !== -1) {
          // Si se encuentra el índice, elimina el elemento con `splice`
          this.improvementsOptionsSelected.splice(indexToRemove, 1);
          console.log(this.improvementsOptionsSelected)
      }
    }
    if (this.currentMonth == 3 || this.currentMonth == 4 && (this.improvementsOptionsSelected[0].value == 1 && this.improvementsOptionsSelected[0].option != 6) 
    ) {
      position = 0;
      // position = this.changePositionForImprovementOption(); // La vuelvo a llamar porque si es mes 4, entra a este if y si tenia 1, se vuelve a 0 de nuevo  
      console.log("Posicion del arreglo...",position);
    } else if (this.currentMonth == 4 && this.improvementsOptionsSelected[0].value == 3 &&
      ((this.improvementsOptionsSelected[0].value == 1 && this.improvementsOptionsSelected[0].option == 6)) &&
      this.improvementsOptionsSelected[1].value == 1 && this.improvementsOptionsSelected[1].option != 6
    ) {
      position = 1;
    } else if (this.currentMonth >= 4) {
      if (
        ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 5 && this.improvementsOptionsSelected[0].Approved == 0) ||
        ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 5 && this.improvementsOptionsSelected[1].Approved == 0)
      ) {
        position = this.improvementsOptionsSelected[0].option == 5 ? 0 : 1;
      } else if (
        ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 2 && this.improvementsOptionsSelected[0].Approved == 0) ||
        ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 2 && this.improvementsOptionsSelected[1].Approved == 0)
      ) {
        position = this.improvementsOptionsSelected[0].option == 2 ? 0 : 1;
        console.log("Caso RAW")
        caseExpantion = "Efficiency"
      } else if (
        ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 1 && this.improvementsOptionsSelected[0].Approved == 0) ||
        ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 1 && this.improvementsOptionsSelected[1].Approved == 0)
      ) {
        position = this.improvementsOptionsSelected[0].option == 1 ? 0 : 1;
        console.log("Caso RAW")
        console.log(position)
        caseExpantion = "RM"
      } else if (
        ((this.improvementsOptionsSelected[0].value == 1 || this.improvementsOptionsSelected[0].value == 2) && this.improvementsOptionsSelected[0].option == 3 && this.improvementsOptionsSelected[0].Approved == 0) ||
        ((this.improvementsOptionsSelected[1].value == 1 || this.improvementsOptionsSelected[1].value == 2) && this.improvementsOptionsSelected[1].option == 3 && this.improvementsOptionsSelected[1].Approved == 0)
      ) {
        position = this.improvementsOptionsSelected[0].option == 3 ? 0 : 1;
      } else {
        position = this.improvementsOptionsSelected[0].value == 3 || this.improvementsOptionsSelected[0].option == 6 ? 1 : 0;
      }
    }
    if (/*DCM improv this.improvementsOptionsSelected[position].option == 6 ||*/ (this.currentMonth == 3 && this.improvementsOptionsSelected[0].value == 3)) {
      return;
    }
    if ((this.improvementsOptionsSelected[0].value == 3 /*DCM improv || this.improvementsOptionsSelected[0].option == 6*/) && (this.improvementsOptionsSelected[1].value == 3 /*DCM improv || this.improvementsOptionsSelected[1].option == 6*/)) {
      return;
    }
    if(this.jsonBlnOpenModeActions.isSecondMachine && caseExpantion == "Efficiency" && this.jsonBlnOpenModeActions.isEfficiency1){
      if(this.jsonBlnOpenModeActions.isEfficiency2){
        return
      }else{
        this.implementExpansionWithEfficiency(2);
      }
    }else if(this.jsonBlnOpenModeActions.isSecondMachine && caseExpantion == "RM" && this.jsonBlnOpenModeActions.isRawMaterials1){
      if(this.jsonBlnOpenModeActions.isRawMaterials2){
        return
      }else{
        this.implementExpansionWithRawMaterial(2);
      }
    }else{
      if(false){

        this.blnCanMoveitems = false //bloqueamos board para que no pueda hacer nada;
        
        this.blnShowerrorPasoImplementImprov = true;
        this.textErrorImplementImprov = this.improvementsOptionsSelected[position].question;
        console.log("EN RELOAD NO HACE NADA DE MOSTRAR MENSAJE");
        /*await Swal.fire({
          title: this.improvementsOptionsSelected[position].question,
          showCancelButton: false,
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.isConfirmed) {
            //this.isOpenMarketDisabledBoard = false;
            //const x = await this.implementImprovement();
          } else if (result.isDenied) {
            
  
          }
        }).catch(reason => {
          console.log(reason);
        });*/
      }
    else{
      if(this.improvementsOptionsSelected[position].improvement == "No Improvement"){
        return;
      }
      if (this.blnGrowNocontrol) {
        Swal.fire({
          html: this.getErrorLabel('1099'),
          showConfirmButton: true,
          confirmButtonText: ' &nbsp;&nbsp;&nbsp;' + this.getErrorLabel('2090') + '&nbsp;&nbsp;&nbsp; ',
          confirmButtonColor: '#5EA0CC',
          allowOutsideClick: false,
          allowEscapeKey: false, // Evita el cierre al presionar "esc"
  
          icon: 'warning'
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.fntakeControl();
            this.isOpenMarketDisabledBoard = false;
            setTimeout(() => {
              this.socketService.askImprovement(this.roomId, this.teamId);
            }, 350);
          }
        });
      } else {
        console.log(this.blnGrowNocontrol)
          console.log(this.improvementsOptionsSelected[position])
          console.log(this.getSingleTextLabel('8075'))
          let stringImpru = this.improvementsOptionsSelected[position].improvement
          let originalString = this.getSingleTextLabel('8075')
          let modifiedString = originalString.replace('{{placeholder}}', stringImpru);
          console.log(modifiedString);
          if(caseExpantion == "RM" && this.jsonBlnOpenModeActions.isSecondMachine){
            originalString = this.getSingleTextLabel('8075')
            modifiedString = originalString.replace('{{placeholder}}', 'RM');
            stringImpru = "on Machine " + ("1")
            modifiedString = modifiedString.replace('{{placeholder}}', stringImpru);
          }
          if(caseExpantion == "Efficiency" && this.jsonBlnOpenModeActions.isSecondMachine){
            originalString = this.getSingleTextLabel('8075')
            modifiedString = originalString.replace('{{placeholder}}', 'Efficiency');
            stringImpru = "on Machine " + ("1")
            modifiedString = modifiedString.replace('{{placeholder}}', stringImpru);
          }
  
      await Swal.fire({
        title: modifiedString,
        showDenyButton: true,
        showConfirmButton: true,
        allowEscapeKey:false,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isOpenMarketDisabledBoard = false;
          this.jsonBlnOpenModeActions.isActiveImprovement = true;
          this.socketService.setBlnControlModeOpen('isActiveImprovement', true, this.roomId, this.teamId);
          const x = await this.implementImprovement();
        } else if (result.isDenied) {
          // mandar confirmacion a facilitador
          this.notifyFacilitator(4);
          // Swal.fire('Changes are not saved', '', 'info')
          // this.socketImprovementService.askFacilitatorImprovementDeniedByTeam(this.teamId, this.roomId, this.improvementsOptionsSelected[position].option);
          // this.spinner.show();
          this.improvementService.updateImproveOptionQuestion(this.improvementsOptionsSelected[position].ImproveId, 1).subscribe(resp => {
            console.log("fnSaveStatus", resp);
          })
          this.improvementService.updateImproveOptionStatus(this.improvementsOptionsSelected[position].ImproveId, 2).subscribe(resp => {
            // this.improvementService.updateImprovementOptionByTeamId(this.improvementsOptionsSelected[position].ImproveId,this.improvementsOptionsSelected[position].option,2).subscribe(resp =>{
            console.log("fnSave", resp);
            if (resp['intResponse'] == 200) {
              console.log("implementado");
              this.improvementsOptionsSelected[position].value = 2;
              this.socketImprovementService.updateImprovementOptionStatus(this.teamId, this.roomId, this.improvementsOptionsSelected[position].option, 2);
              if (this.currentMonth <= 3 || this.currentMonth >= 4 && !this.isModeOpen) {
                this.isOpenMarketDisabledBoard = true;
                setTimeout(() => {
                  this.socketImprovementService.updateImproveOption(this.teamId, ("roomWorkShop" + this.workshopId), this.improvementsOptionsSelected[position].option, this.improvementsOptionsSelected[position].ImproveId, 2)
                }, 1500);
              }
            }
          });
  
        }
      }).catch(reason => {
        console.log(reason);
      });
      }
      console.log('sale');
    }
    }

  }


  fnAlertInfo(strMessage) {
    Swal.fire({
      text: '',
      icon: 'info',
      confirmButtonColor: '#5EA0CC',
      html: `<div>43.-` + strMessage + `</div>`,
    });
  }

  async buyRowMaterials(totalMaterials: number, item: any) {
    return new Promise(resolve => {
      console.log("AQUIIIIIIIIIII");
      console.log(totalMaterials,item)
      this.spinner.show('my-custom-spinner');

      // console.log(this.jsonBlnOpenModeActions.isEfficiency1,item.valor, totalMaterials,item);
      if (this.jsonBlnOpenModeActions.isRawMaterials1 && this.jsonBlnOpenModeActions.isEfficiency1) {
        if (totalMaterials >= 6) {
          this.jsonBlnOpenModeActions.showMessageOwe4 = true;
          this.socketService.setBlnControlModeOpen('showMessageOwe4', true, this.roomId, this.teamId);
          this.jsonBlnOpenModeActions.isBuyMaterials = true;
          setTimeout(() => {
            this.socketService.setBlnControlModeOpen('isBuyMaterials', true, this.roomId, this.teamId);
            setTimeout(() => {
              this.socketService.createCoin('cdkLstSuppliers', {
                valor: 6,
                id: null,
                draggable: false,
                type: 3,
              }, this.roomId, this.teamId);
              setTimeout(() => {
                this.status = 4;
                this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                setTimeout(() => {
                  this.spinner.hide('my-custom-spinner');
                  console.log("AQUIIIIIIIIIII");
      
                }, 500);

                resolve(true);
              }, 200);
            }, 200);
          }, 200);
        }else{
          setTimeout(() => {
            this.spinner.hide('my-custom-spinner');
            console.log("AQUIIIIIIIIIII");

          }, 1000);
        }
      } else if (this.jsonBlnOpenModeActions.isRawMaterials1) {
        if (item.type == 14) {
          if (totalMaterials >= 4) {
            this.jsonBlnOpenModeActions.showMessageOwe4 = true;
            this.jsonBlnOpenModeActions.isBuyMaterials = true;
            setTimeout(() => {
              this.socketService.setBlnControlModeOpen('showMessageOwe4', true, this.roomId, this.teamId);
              setTimeout(() => {
                this.socketService.setBlnControlModeOpen('isBuyMaterials', true, this.roomId, this.teamId);
                setTimeout(() => {
                  this.socketService.createCoin('cdkLstSuppliers', {
                    valor: 4,
                    id: null,
                    draggable: false,
                    type: 3,
                  }, this.roomId, this.teamId);
                  setTimeout(() => {
                    this.status = 4;
                    this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                    setTimeout(() => {
                      this.spinner.hide('my-custom-spinner');
                      console.log("AQUIIIIIIIIIII");
          
                    }, 500);

                    resolve(true);
                  }, 200);
                }, 200);
              }, 200);
            }, 500);
          }else{
            setTimeout(() => {
              this.spinner.hide('my-custom-spinner');
              console.log("AQUIIIIIIIIIII");

            }, 1000);
          }
        } else if (item.type == 7) {
          this.jsonBlnOpenModeActions.showMessageOwe12 = true;
          setTimeout(() => {
            this.socketService.setBlnControlModeOpen('showMessageOwe12', true, this.roomId, this.teamId);
            this.jsonBlnOpenModeActions.isBuyMaterials = true;
            setTimeout(() => {
              this.socketService.setBlnControlModeOpen('isBuyMaterials', true, this.roomId, this.teamId);
              setTimeout(() => {

                this.socketService.createCoin('cdkLstSuppliers', {
                  valor: 12,
                  id: null,
                  draggable: false,
                  type: 3,
                }, this.roomId, this.teamId);
                setTimeout(() => {
                  this.status = 4;
                  this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                  
                  setTimeout(() => {
                    this.spinner.hide('my-custom-spinner');
                    console.log("AQUIIIIIIIIIII");
        
                  }, 1200);
                  resolve(true);
                }, 200);
              }, 200);
            }, 200);
          }, 500);
        }else{
          setTimeout(() => {
            this.spinner.hide('my-custom-spinner');
            console.log("AQUIIIIIIIIIII");

          }, 1000);
        }
      } else if (this.jsonBlnOpenModeActions.isEfficiency1) {
        if (item.valor == 12 && item.type!=3) { 
          this.jsonBlnOpenModeActions.showMessageOwe12 = true;
          setTimeout(() => {
            this.socketService.setBlnControlModeOpen('showMessageOwe12', true, this.roomId, this.teamId);
            this.jsonBlnOpenModeActions.isBuyMaterials = true;
            setTimeout(() => {
              this.socketService.setBlnControlModeOpen('isBuyMaterials', true, this.roomId, this.teamId);
              setTimeout(() => {
             this.socketService.createCoin('cdkLstSuppliers', {
                  valor: 12,
                  id: null,
                  draggable: false,
                  type: 3,
                }, this.roomId, this.teamId);
                setTimeout(() => {
                  this.status = 4;
                  this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                  setTimeout(() => {
                    this.spinner.hide('my-custom-spinner');

                    console.log("AQUIIIIIIIIIII");
        
                  }, 500);

                  resolve(true);
                }, 200);
              }, 200);
            }, 200);
          }, 500); 
        } else if ((item.valor == 18 && item.type!=3) || totalMaterials == 18 || totalMaterials == 36 && this.jsonBlnOpenModeActions.isEfficiency2) {
          this.jsonBlnOpenModeActions.showMessageOwe18 = true;
          setTimeout(() => {
            this.socketService.setBlnControlModeOpen('showMessageOwe18', true, this.roomId, this.teamId);
            this.jsonBlnOpenModeActions.isBuyMaterials = true;
            setTimeout(() => {
              this.socketService.setBlnControlModeOpen('isBuyMaterials', true, this.roomId, this.teamId);
              setTimeout(() => {
                this.socketService.createCoin('cdkLstSuppliers', {
                  valor: 18,
                  id: null,
                  draggable: false,
                  type: 3,
                }, this.roomId, this.teamId);
                setTimeout(() => {
                  this.status = 4;
                  this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                  setTimeout(() => {
                    this.spinner.hide('my-custom-spinner');
                    console.log("AQUIIIIIIIIIII");
        
                  }, 1200);
                  resolve(true);
                }, 200);
              }, 200);
            }, 200);
          }, 500);
        }else if (totalMaterials == 30 && !this.jsonBlnOpenModeActions.isEfficiency2){
          this.jsonBlnOpenModeActions.showMessageOwe12 = true;
          setTimeout(() => {
            this.socketService.setBlnControlModeOpen('showMessageOwe12', true, this.roomId, this.teamId);
            this.jsonBlnOpenModeActions.isBuyMaterials = true;
            setTimeout(() => {
              this.socketService.setBlnControlModeOpen('isBuyMaterials', true, this.roomId, this.teamId);
              setTimeout(() => {
                this.socketService.createCoin('cdkLstSuppliers', {
                  valor: 12,
                  id: null,
                  draggable: false,
                  type: 3,
                }, this.roomId, this.teamId);
                setTimeout(() => {
                  this.status = 4;
                  this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                  setTimeout(() => {
                    this.spinner.hide('my-custom-spinner');
                    console.log("AQUIIIIIIIIIII");
        
                  }, 1200);

                  resolve(true);
                }, 200);
              }, 200);
            }, 200);
          }, 500); 
        }else{
          setTimeout(() => {
            this.spinner.hide('my-custom-spinner');
            console.log("AQUIIIIIIIIIII");

          }, 1000);
        }
      } else {
        if (totalMaterials >= 12) {
          this.jsonBlnOpenModeActions.showMessageOwe12 = true;
          this.jsonBlnOpenModeActions.isBuyMaterials = true;
          setTimeout(() => {
            this.socketService.setBlnControlModeOpen('showMessageOwe12', true, this.roomId, this.teamId);
            setTimeout(() => {
              this.socketService.setBlnControlModeOpen('isBuyMaterials', true, this.roomId, this.teamId);
              setTimeout(() => {
                this.socketService.createCoin('cdkLstSuppliers', {
                  valor: 12,
                  id: null,
                  draggable: false,
                  type: 3,
                }, this.roomId, this.teamId);
                setTimeout(() => {
                  resolve(true);
                  this.status = 4;
                  this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                  setTimeout(() => {
                    this.spinner.hide('my-custom-spinner');
                    console.log("AQUIIIIIIIIIII");
        
                  }, 500);
                }, 200);
              }, 200);
            }, 200);
          }, 500);
        }else{
          setTimeout(() => {
            this.spinner.hide('my-custom-spinner');
            console.log("AQUIIIIIIIIIII");

          }, 1000);
        }
      }
      
    });
  }

  async payRowMaterials() {
    return new Promise(resolve => {
      if (this.cdkLstSuppliers.length > 0) {
        let borrar;
        this.cdkLstSuppliers.forEach(item => {
          if (item.type == 3) {
            borrar = item;
          }
        })
        this.socketService.deleteCoin('cdkLstSuppliers', borrar, this.roomId, this.teamId);//dni
      }
      this.jsonBlnOpenModeActions.isBuyMaterials = false;
      this.socketService.setBlnControlModeOpen('isBuyMaterials', false, this.roomId, this.teamId);
      setTimeout(() => {
        this.status = 6;
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        setTimeout(() => {
          let id = this.jsonBlnOpenModeActions.showMessageOwe12 ? 'showMessageOwe12' :
            this.jsonBlnOpenModeActions.showMessageOwe18 ? 'showMessageOwe18' :
              this.jsonBlnOpenModeActions.showMessageOwe4 ? 'showMessageOwe4' : '';
          this.jsonBlnOpenModeActions[id] = false;
          this.socketService.setBlnControlModeOpen(id, false, this.roomId, this.teamId);
          // this.fnUpdateChecklistByStatus(this.status);
        }, 200);
      }, 200);
      resolve(true);
    })
  }

  fnGetListToPaySGA(valor) {
    return new Promise(resolve => {
      if (this.currentMonth >= 2) {
        this.spinner.show();
      }
      let lists = [];
      let valores = [];
      // let lists = ['cdkLstChangeI', 'cdkLstMarketingS', 'cdkLstAdministration', 'cdkLstAdminOver'];
      if (!this.thereAreStack(this.cdkLstChangeI, 1)) {
        lists.push('cdkLstChangeI')
        valores.push(this.cdkLstChangeI.length);
      }
      if (!this.thereAreStack(this.cdkLstMarketingS, 1)) {
        lists.push('cdkLstMarketingS')
        valores.push(this.cdkLstMarketingS.length);
      }
      if (!this.thereAreStack(this.cdkLstAdministration, 1)) {
        lists.push('cdkLstAdministration')
        valores.push(this.cdkLstAdministration.length);
      }
      if (this.cdkLstAdminOver.length == 0) {
        lists.push('cdkLstAdminOver')
        valores.push(1);
      }
      console.log(lists);
      console.log(valor);
      console.log(this.cdkLstAdminOver.some(
        (item) => item.type != 1 || !item
      ));
      let alcanza = [];
      let precio = [];
      let tot = 0;
      valores.forEach((item, i) => {
        if (item <= valor) {
          alcanza.push(lists[i]);
          precio.push(item);
          valor -= item;
        }
      })
      if (valor > 0) {
        console.log(valor);
        resolve([]);
        this.spinner.hide();
      }
      resolve([alcanza, precio]);
    });

  }

  thereAreStack(list: Array<any>, type) {
    let flag = false;
    list.forEach(item => {
      if (item.type == type) {
        flag = true;
        return;
      }
    });
    return flag;
  }

  isCompleteMachine(list: Array<any>, id: string): boolean {
    let totMaterials = 0;
    let tipo = -1;
    let impE = id == "machine1" ? "isEfficiency1" : "isEfficiency2";
    list.forEach(item => {
      totMaterials += item.valor;
      tipo = item.type;
    });
    return (totMaterials == 12 && tipo == 7 && !this.jsonBlnOpenModeActions[impE]) ? true :
      (totMaterials == 18 && tipo == 7 && this.jsonBlnOpenModeActions[impE]) ? true :
        (totMaterials == 12 && tipo == 8) ? true :
          (totMaterials == 4 && tipo == 14 && !this.jsonBlnOpenModeActions[impE]) ? true :
            (totMaterials == 6 && tipo == 14 && this.jsonBlnOpenModeActions[impE]) ? true :
              (totMaterials == 16 && tipo == 16) ? true :
                (totMaterials >= 20 && tipo == 17) ? true :
                  (totMaterials >= 12 && tipo == 18) ? true :
                    false;
  }

  fnUpdateLoan(lista: Array<any>, jsonCoin) {
    lista.forEach((item, i) => {
      if (item.id == jsonCoin.id) {
        console.log("iguales")
        if (jsonCoin.valor == 0) {
          this.cdkLstLoans30.splice(i, 1);
        } else {
          item.valor = jsonCoin.valor;
        }
        return;
      }
    })
  }

  fnConvertMaterialsToRoyals(lista: Array<any>, idList: string) {
    // si improvement raw materials ahora el tipo de royal es 16 y con valor de 4
    // si improvement efficiency ahora el tipo es 17 y con valor de 5
    // si improvement efficiency + row Materials ahora el tipo es 18 y con valor de 3
    let tipo, valor;
    let material = parseInt(lista[0].type);
    if (material == 7) {
      if (this.currentMonth >= 3 && (
        (this.jsonBlnOpenModeActions.isEfficiency1 && idList == 'cdkLstInsideMachineWorkInProgress') ||
        (this.jsonBlnOpenModeActions.isEfficiency2 && idList == 'cdkLstInsideMachineWorkInProgress2')
      )
      ) {
        tipo = 17; valor = 5;
      } else {
        tipo = 8; valor = 3;
      }
    } else if (material == 14) {
      if (this.currentMonth >= 3 && this.jsonBlnOpenModeActions.isRawMaterials1 && this.jsonBlnOpenModeActions.isEfficiency1) {
        tipo = 18; valor = 3;
      } else if (this.currentMonth >= 3 && (this.jsonBlnOpenModeActions.isRawMaterials1 && idList == 'cdkLstInsideMachineWorkInProgress' ||
        this.jsonBlnOpenModeActions.isRawMaterials2 && idList == 'cdkLstInsideMachineWorkInProgress2')) {
        tipo = 16; valor = 4;
      }
    }
    if (tipo == undefined) {
      if ((this.jsonBlnOpenModeActions.isEfficiency1 && idList == 'cdkLstInsideMachineWorkInProgress') ||
        (this.jsonBlnOpenModeActions.isEfficiency2 && idList == 'cdkLstInsideMachineWorkInProgress2')) {
        tipo = 17; valor = 5;
      } else if (this.currentMonth >= 3 && this.jsonBlnOpenModeActions.isRawMaterials1 && this.jsonBlnOpenModeActions.isEfficiency1) {
        tipo = 18; valor = 3;
      } else if (this.currentMonth >= 3 && (this.jsonBlnOpenModeActions.isRawMaterials1 && idList == 'cdkLstInsideMachineWorkInProgress' ||
        this.jsonBlnOpenModeActions.isRawMaterials2 && idList == 'cdkLstInsideMachineWorkInProgress2')) {
        tipo = 16; valor = 4;
      } else {
        tipo = 8; valor = 3;
      }
    }
    // if(this.currentMonth>=3 && this.jsonBlnOpenModeActions.isRawMaterials1 && this.jsonBlnOpenModeActions.isEfficiency1){
    //   tipo = 18; valor = 3;
    // } else if(this.currentMonth>=3 && this.jsonBlnOpenModeActions.isRawMaterials1){
    //   tipo = 16; valor = 4;
    // } else if(this.currentMonth>=3 && this.jsonBlnOpenModeActions.isEfficiency1){
    //   tipo = 17; valor = 5;
    // } else {
    //   tipo = 8; valor = 3;
    // }
    lista.forEach((item, i) => {
      item.type = tipo;
      item.valor = this.currentMonth >= 3 && (tipo == 18 || tipo == 16) ? valor * item.valor :
        this.currentMonth >= 3 && (tipo == 17) ? valor * Math.floor(item.valor / 3) : item.valor;
    });
    this.socketService.splitCoin(lista, idList, this.roomId, this.teamId);
  }

  async fnTrowBonusLabourAlert() {
    const { isConfirmed: isConfirmed } = await Swal.fire({
      title: 'Confirm',
      html: this.getErrorLabel('1073'),
      allowEscapeKey:false,
      showCancelButton: true,
      confirmButtonColor: '#5EA0CC',
      cancelButtonColor: '#CCCCCC',
      confirmButtonText: ' &nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp; ',
      cancelButtonText: 'Cancel',
    });

    if (isConfirmed) {
      // si pago un bonus
      Swal.fire({
        html: this.getErrorLabel('1074'),
        showCancelButton: true,
        allowEscapeKey:false,
        confirmButtonColor: '#5EA0CC',
        cancelButtonColor: '#CCCCCC',
        confirmButtonText: ' &nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp; ',
        cancelButtonText: 'Cancel',
      });
    } else {
      // no pago un bonus
    }

  }

  fnisReadyPaso8() {

    let isSoldBuild = this.jsonBlnOpenModeActions.isSoldBuild;

    let totPersonasChangeI = 0;
    let totCashChangeI = 0;
    this.cdkLstChangeI.forEach(item => {
      if (item.type == 6) {
        totPersonasChangeI += 1;
      } else if (item.type == 1) {
        totCashChangeI += 1;
      }
    });
    let totPersonasSales = 0;
    let totCashSales = 0;
    this.cdkLstMarketingS.forEach(item => {
      if (item.type == 6) {
        totPersonasSales += 1;
      } else if (item.type == 1) {
        totCashSales += 1;
      }
    });
    let totPersonasAdmin = 0;
    let totCashAdministration = 0;
    this.cdkLstAdministration.forEach(item => {
      if (item.type == 6) {
        totPersonasAdmin += 1;
      } else if (item.type == 1) {
        totCashAdministration += 1;
      }
    });
    let totCashMachineOver = 0;
    this.cdkLstMachineOver.forEach(item => {
      if (item.type == 1) {
        totCashMachineOver += item.valor;
      }
    });
    if (this.improvementsOptionsSelected.length > 0 && (this.improvementsOptionsSelected[0].value == 3 || this.improvementsOptionsSelected[1].value == 3)) {
      if (this.improvementsOptionsSelected.length == 1) {
        this.improvementsOptionsSelected.push({
          improvement: '',
          value: 0,
          ImproveId: -1,
          option: 0,
          question: ''
        })
      }
      console.log(totPersonasChangeI,totPersonasSales
        ,totCashSales,totCashChangeI,totPersonasAdmin,
        totCashAdministration,totCashMachineOver)
      if (this.improvementsOptionsSelected[0].improvement == 'Quality' || this.improvementsOptionsSelected[1].improvement == 'Quality') {
        let x1 = totCashChangeI >= totPersonasChangeI && totCashSales >= totPersonasSales && totCashAdministration >= totPersonasAdmin &&
          this.cdkLstAdminOver.length >= 1 &&
          (
            totCashMachineOver >= 6 && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 ||
            totCashMachineOver >= 4 && this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
            totCashMachineOver >= 4 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 ||
            totCashMachineOver >= 2 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
            totCashMachineOver >= 3 && !this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 ||
            totCashMachineOver >= 1 && !this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2
          ) &&
          ((isSoldBuild && this.cdkLstRent.length >= 1) || !isSoldBuild)
        let x2 = (this.cdkLstRecruitmentT.length >= 1 && (this.currentMonth == 3 && this.improvementsOptionsSelected[0].option == 4 || this.currentMonth == 4 && this.improvementsOptionsSelected[1].option == 4) ||
          this.cdkLstRecruitmentT.length >= 0 && (this.improvementsOptionsSelected[0].option != 4 || this.improvementsOptionsSelected[1].option != 4));
        return (x1 && x2);
      } else if (this.improvementsOptionsSelected[0].improvement == 'Raw Materials' && this.improvementsOptionsSelected[0].value == 3 || this.improvementsOptionsSelected[1].improvement == 'Raw Materials' && this.improvementsOptionsSelected[1].value == 3) {
        let needCashMachineOver = 0;
        if (this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2)
          needCashMachineOver = 6;
        else if ((this.jsonBlnOpenModeActions.showBill2Aux1 || this.jsonBlnOpenModeActions.showBill2Aux2) && !this.jsonBlnOpenModeActions.isSecondMachine)
          needCashMachineOver = 3;
        else if ((this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 || this.jsonBlnOpenModeActions.showBill2Aux2 && !this.jsonBlnOpenModeActions.showBill2Aux1) && this.jsonBlnOpenModeActions.isSecondMachine)
          needCashMachineOver = 4;
        else if (this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2)
          needCashMachineOver = 2;
        else if (!this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2)
          needCashMachineOver = 1;
        return totCashChangeI >= totPersonasChangeI && totCashSales >= totPersonasSales && totCashAdministration >= totPersonasAdmin &&
          this.cdkLstAdminOver.length >= 1 && (
            // totCashMachineOver >= 3 && !this.jsonBlnOpenModeActions.isRawMaterials2 || 
            // totCashMachineOver >= 6 && this.jsonBlnOpenModeActions.isRawMaterials1 && this.jsonBlnOpenModeActions.isRawMaterials2 || 
            // totCashMachineOver >= 4 && !this.jsonBlnOpenModeActions.isSecondMachine
            // totCashMachineOver >= 6 && this.jsonBlnOpenModeActions.showBill2Aux1 && this.jsonBlnOpenModeActions.showBill2Aux2 || 
            // totCashMachineOver >= 3 && (this.jsonBlnOpenModeActions.showBill2Aux1 || this.jsonBlnOpenModeActions.showBill2Aux2) && !this.jsonBlnOpenModeActions.isSecondMachine|| 
            // totCashMachineOver >= 4 && (this.jsonBlnOpenModeActions.showBill2Aux1 && !this.jsonBlnOpenModeActions.showBill2Aux2 || this.jsonBlnOpenModeActions.showBill2Aux2 && !this.jsonBlnOpenModeActions.showBill2Aux1) && this.jsonBlnOpenModeActions.isSecondMachine|| 
            // totCashMachineOver >= 2 && this.jsonBlnOpenModeActions.isSecondMachine ||
            // totCashMachineOver >= 1 && !this.jsonBlnOpenModeActions.isSecondMachine
            totCashMachineOver >= needCashMachineOver
          ) &&
          ((isSoldBuild && this.cdkLstRent.length >= 1) || !isSoldBuild)
      } else if (this.improvementsOptionsSelected[0].improvement == 'Expansion' || this.improvementsOptionsSelected[1].improvement == 'Expansion') {
        const respose = totCashChangeI >= totPersonasChangeI && totCashSales >= totPersonasSales && totCashAdministration >= totPersonasAdmin &&
          this.cdkLstAdminOver.length >= 1 && (
            totCashMachineOver >= 2 && this.jsonBlnOpenModeActions.isSecondMachine  && this.jsonBlnOpenModeActions.isRawMaterials1  && !this.jsonBlnOpenModeActions.isRawMaterials2 && this.improvementsOptionsSelected[0].improvement == 'Raw Materials' ||
            totCashMachineOver >= 4 && this.jsonBlnOpenModeActions.isSecondMachine  && this.jsonBlnOpenModeActions.isRawMaterials1  && !this.jsonBlnOpenModeActions.isRawMaterials2 && this.improvementsOptionsSelected[1].improvement == 'Raw Materials' ||
            totCashMachineOver >= 2 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isRawMaterials1  && !this.jsonBlnOpenModeActions.isRawMaterials2||
            totCashMachineOver >= 1 && !this.jsonBlnOpenModeActions.isSecondMachine
          ) &&
          ((isSoldBuild && this.cdkLstRent.length >= 1) || !isSoldBuild);
          console.log("AQUI",respose)
        return respose;
      } else {
        return totCashChangeI >= totPersonasChangeI && totCashSales >= totPersonasSales && totCashAdministration >= totPersonasAdmin &&
          this.cdkLstMachineOver.length >= 1 && this.cdkLstAdminOver.length >= 1 && ((isSoldBuild && this.cdkLstRent.length >= 1) || !isSoldBuild);
      }
    }
    if (isSoldBuild) {
      return totPersonasChangeI >= totCashChangeI && totPersonasSales >= totCashSales && totPersonasAdmin >= totCashAdministration &&
        this.cdkLstMachineOver.length >= 1 && this.cdkLstAdminOver.length >= 1 && this.cdkLstRent.length >= 1;
    } else {
      return totPersonasChangeI >= totCashChangeI && totPersonasSales >= totCashSales && totPersonasAdmin >= totCashAdministration &&
        this.cdkLstMachineOver.length >= 1 && this.cdkLstAdminOver.length >= 1;
    }
  }

  fnInitObserver() {
    this.listAux = this.cdkLstAdvertasing
    console.log('Iniciaaaaaaaaaaaa')
    this.socketOrder._order
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response) {
          console.log('RESPONSE fnInitObserver', response);
          if (response.status == 'openMarketServer' && response.data != undefined) {
            console.log("response.data", response.data);//{blnOpen}
            this.intOpenMarket = response.data.blnOpen;
            // checkear prepare bids
            if (response.data.blnOpen == 1) {
              this.isOpenMarket = true
              if(this.blnGrowNocontrol){
                this.isOpenMarketDisabledBoard = true;
              }
              // Prepare Bids DONE
              if (!this.getCheckCheckbox(11).blnCheck && this.currentMonth >= 2) {
                // if (!this.checkBoxes[this.currentMonth>=3?10:8].blnCheck) {
                // this.checkBoxes[this.currentMonth>=3?10:8].blnCheck = true;
                // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
                // this.checkCheckbox(8, true);
                this.checkCheckbox(11, true);

              }
                this.fnUpdateAdvBill();
            } else {
              this.isOpenMarketDisabledBoard = false;
              //validacion asignar control al COO
              this.AssingControl();
              // The market DONE
              if (!this.getCheckCheckbox(13).blnCheck && this.currentMonth >= 2) {
                // if (!this.checkBoxes[this.currentMonth>=3?12:10].blnCheck) {
                // this.checkBoxes[this.currentMonth>=3?12:10].blnCheck = true;
                // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
                // this.checkCheckbox(10, true);
                this.checkCheckbox(13, true);
              }
              if (this.status < 14 && this.currentMonth >= 2) {
                this.status = 14
                this.socketService.updateStatusAPI(this.roomId, this.teamId, this.status, this.currentMonth).subscribe(response => {
                  if (response.intResponse == 200) {
                    this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                  } else {
                    location.reload();
                  }
                });
              }
              
              if (this.currentMonth == 1 && this.boardMode == "DM" && this.status == 30 && this.owesAdvertising == 0) {
                this.status = 31;
                this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
              }
              this.boardService.getOrdersByTeamId(this.teamId, this.currentMonth).subscribe(response => {
                this.orders = response.orders as Array<any>;
                if (!this.blnFacilitador) {
                  if (this.improvementsOptionsSelected.length == 1) {
                    this.improvementsOptionsSelected.push({
                      improvement: '',
                      value: 0,
                      ImproveId: -1,
                      option: 0,
                      question: ''
                    });
                  }
                  if (this.currentMonth >= 3 && (this.improvementsOptionsSelected[0].improvement == "Quality" && this.improvementsOptionsSelected[0].value == 3
                    || this.improvementsOptionsSelected[1].improvement == "Quality" && this.improvementsOptionsSelected[1].value == 3)) {
                    this.orders.forEach(item => {
                      item.Bid = Math.round(item.Bid * 1.20);
                    });
                  }
                }
                if (this.blnFacilitador && this.orders.length == 0 && this.currentMonth == 1) {
                  // console.log("Entra AQUI!!")
                  this.orders.push(
                    { Terms: 60, OrderNum: 201, Bid: 40, Quantity: 3, Delivered: false },
                  )
                }
                // Make Delivery & Recieve Payment DONE
                if (this.orders.length == 0 && !this.getCheckCheckbox(15).blnCheck) {
                  setTimeout(() => {
                    // this.checkCheckbox(12, true);
                    this.checkCheckbox(15, true);
                    
                    this.funcionBack("caseCOGS");

                    if (this.currentMonth >= 2) {
                      setTimeout(() => {
                        this.status = 14;
                        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
                      }, 1000);
                    }
                  }, 500);
                }
              });
            }

          }
          if (response.status == 'updateAdvertising' && response.data != undefined) {
            if (this.teamId == response.data.teamId) {
              this.fnSetAdvertasing(response.data)
            }
          }
          if (response.status == 'openMarket' && response.data != undefined && this.blnFacilitador) {
            console.log("response.data", response.data);//{blnOpen}    
            // checkear prepare bids
            if(response.data.blnOpen == 2 ){
              this.intOpenMarket = response.data.blnOpen
            }
            if(response.data.blnOpen == 2 && this.blnFacilitador && this.status == 0){
              this.status = 14;
              console.log(this.status)
              setTimeout(() => {
              console.log(this.status)
                
              }, 1000);
            }
            if (response.data.blnOpen == 1) {
              // console.log("AQUIIIIIIIIIIIIIIIIIIIIIIIIIII")
              // DONE
              if (!this.getCheckCheckbox(11).blnCheck && this.currentMonth >= 2) {
                // this.checkCheckbox(8, true);
                this.checkCheckbox(11, true);
              }
            } else {
              //DONE
              if (!this.getCheckCheckbox(13).blnCheck && this.currentMonth >= 2) {
                // if (!this.checkBoxes[this.currentMonth>=3?12:10].blnCheck) {
                // this.checkBoxes[this.currentMonth>=3?12:10].blnCheck = true;
                // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
                // this.checkCheckbox(10, true);
                this.checkCheckbox(13, true);
                this.status = 14;
              }
              this.boardService.getOrdersByTeamId(this.teamId, this.currentMonth).subscribe(response => {
                this.orders = response.orders as Array<any>;
                console.log(this.orders)
                  if (this.improvementsOptionsSelected.length == 1) {
                    this.improvementsOptionsSelected.push({
                      improvement: '',
                      value: 0,
                      ImproveId: -1,
                      option: 0,
                      question: ''
                    });
                  }
                  if (this.currentMonth >= 3 && (this.improvementsOptionsSelected[0].improvement == "Quality" && this.improvementsOptionsSelected[0].value == 3
                    || this.improvementsOptionsSelected[1].improvement == "Quality" && this.improvementsOptionsSelected[1].value == 3)) {
                    this.orders.forEach(item => {
                      item.Bid = Math.round(item.Bid * 1.20);
                    });
                  }
                  // DONE 
                if (this.orders.length == 0 && !this.getCheckCheckbox(15).blnCheck) {
                  setTimeout(() => {
                    this.checkCheckbox(12, true);
                    this.funcionBack("caseCOGS");
                  }, 500);
                }
              });
            }

          }
        }
      })
  }

  fnSetAdvertasing(data) {
    let totalRefund = 0
    this.auxValTotalAdvertising = 0;
    this.cdkLstAdvertasing.forEach((item, index) => {
      this.auxValTotalAdvertising += item.valor;
    });
    this.advertising = data.advertising;
    totalRefund = this.auxValTotalAdvertising - data.advertising
    if (totalRefund > 0 && (this.currentMonth > 1 || this.boardMode == "DM")) {
      console.log('if1')
      this.showOwedAdvertising = false
      if (data.advertising > 0) {
        this.cdkLstAdvertasing = [
          {
            valor: data.advertising,
            type: 1,
            id: null,
            draggable: true,
            draggingBy: "Advertising: " + data.advertising
          }
        ]
      } else {
        this.cdkLstAdvertasing = []
      }
      if (totalRefund > 0 && !this.blnGrowNocontrol) {
        this.socketService.createCoin('cdkLstCash', { valor: totalRefund, id: null, draggable: false, type: 1, }, this.roomId, this.teamId);
      }
      this.boardService.updateBillStatus(this.IdStatusBid, this.teamId, 1, this.auxValTotalAdvertising).subscribe(response => console.log(response));
    } else if (totalRefund < 0 && (this.currentMonth > 1 || this.boardMode == "DM")) {
      console.log('if2')
      // this.checkCheckbox(9, false);
      if(!this.blnGrowNocontrol){
        this.checkCheckbox(12, false);
      }
      this.expandsAdvertising = false;
      this.showOwedAdvertising = true
      totalRefund = totalRefund * - 1
      if (this.auxValTotalAdvertising != 0) {
        this.cdkLstAdvertasing = [
          {
            valor: this.auxValTotalAdvertising,
            type: 1,
            id: null,
            draggable: false,
            draggingBy: "Advertising: " + data.advertising
          }
        ]
      } else {
        this.cdkLstAdvertasing = []
      }
      this.owesAdvertising = totalRefund
      this.boardService.updateBillStatus(this.IdStatusBid, this.teamId, 0, this.auxValTotalAdvertising).subscribe(response => console.log(response));
    } else if (totalRefund == 0 && (this.currentMonth > 1 || this.boardMode == "DM")) {
      console.log('if3')
      if (this.auxValTotalAdvertising > 0) {
        this.expandsAdvertising = false;
        this.cdkLstAdvertasing = [
          {
            valor: data.advertising,
            type: 1,
            id: null,
            draggable: true,
            draggingBy: "Advertising: " + data.advertising
          }
        ]
        // this.checkCheckbox(9, true);
        if(!this.blnGrowNocontrol){
          this.checkCheckbox(12, true);

        }
        this.showOwedAdvertising = false;
        this.boardService.updateBillStatus(this.IdStatusBid, this.teamId, 1, this.auxValTotalAdvertising).subscribe(response => console.log(response));
      }
    } else {
      console.log('if4')
      // this.checkCheckbox(9, true);
      if(!this.blnGrowNocontrol){
        this.checkCheckbox(12, true);

      }
      this.expandsAdvertising = false;
      this.showOwedAdvertising = false
      this.boardService.updateBillStatus(this.IdStatusBid, this.teamId, 1, this.auxValTotalAdvertising).subscribe(response => console.log(response));
    }
    this.socketService.splitCoin(this.cdkLstAdvertasing, 'cdkLstAdvertasing', this.roomId, this.teamId);
    if (data.advertising === 0) {
      this.expandsAdvertising = false;
      this.showOwedAdvertising = false;
      this.boardService.updateBillStatus(this.IdStatusBid, this.teamId, 1, 0).subscribe(response => console.log(response));
    }
  }

  dragEnded(event: CdkDragEnd) {
    console.log("Entramos dragEnded")
    console.log("this.lstPositionsActual::", this.lstPositions)
    console.log("this.lstPositionBefore::", this.lstPositionBefore)
    let idmoved = event.source.element.nativeElement.id;
    console.log("IDACTUAL::", idmoved);
    /*let x=document.getElementById("stack0").style.left;
    let y= $("#stack0").css( "left" );
    let z= $("#stack0").css( "left" );
      var elem1 = document.getElementById(idmoved);
      var elem = (document.getElementById(idmoved) as HTMLDivElement)
      console.log("elx::", elem);
      let leftVal= event.source.element.nativeElement.style.left;//elem.style.getPropertyValue("left");
      console.log("leftVal:",leftVal);*/
    let objHtml = this.lstPositions.filter(el => {
      if (el.id == idmoved)//view board
        return el;
    });
    console.log("objHtml:", objHtml);
    if (objHtml.length > 0) {

      console.log(" actual objHtml[0].left: ", objHtml[0].left, " le sumaremos:", event.distance.x, " objHtml[0].top: ", objHtml[0].top, " le sumamos:", event.distance.y);
      //console.log("leftVal:",leftVal);
      //let topVal= event.source.element.nativeElement.style.top;// elem.style.getPropertyValue("top");
      //let idmoved= event.source.element.nativeElement.id;
      var elem = document.getElementById(idmoved);
      console.log("this.lstPositionsFINAL::", this.lstPositions, this.lstPositionBefore)
      let newX = objHtml[0].left + event.distance.x - 658;
      let newY = objHtml[0].top + event.distance.y - 256;
      if (newX < 0 || newX > 165 || newY < 0 || newY > 145) {
        const jsonDragged = event.source.dropContainer.data[this.indexItemDragged];
        this.fnRegresaposicionContainer(jsonDragged.id);
        return;
      }
      let strStyles = "left:" + (newX) + "px !important;top:" + (newY) + "px !important; position: absolute !important;"
      console.log("Existe LEFT: strStyles a aplicar::::::", strStyles);
      elem.style.cssText = strStyles;
      let lstTmpPositions = [];
      let lstTmpPositionsBefore = [];
      try {


        this.lstPositions.forEach((element, index) => {
          var elementBefore = element;
          if (element.id == idmoved) { /* lstTmpPositions[index].left = objHtml[0].left + event.distance.x;
              lstTmpPositions[index].top = objHtml[0].top + event.distance.y;*/
            //asignamos al alemento actual el nuevo position

            element.left = objHtml[0].left + event.distance.x;
            element.top = objHtml[0].top + event.distance.y;
            elementBefore.left = objHtml[0].left;
            elementBefore.top = objHtml[0].top;
            // indexGlobal= index;
          }
          lstTmpPositions.push(element);
          lstTmpPositionsBefore.push(elementBefore);
        });
      } catch (error) {
        console.log("descError:", error);
      }
      /*console.log("this.lstPositions[indexGlobal]  lstTmpPositions[0]  index:", this.lstPositions[indexGlobal], lstTmpPositions[0], indexGlobal);
      this.lstPositions[indexGlobal+1] =lstTmpPositions[0];*/
      this.lstPositions = [];
      this.lstPositions = lstTmpPositions;
      this.lstPositionBefore = lstTmpPositionsBefore;


    }
    else {
      console.log("NO HAY POSICION PREVIA");
      let element = event.source.getRootElement();
      let boundingClientRect = element.getBoundingClientRect();
      //let parentPosition = this.getPosition(element);
      let newX = boundingClientRect.left + event.distance.x - 658;
      let newY = boundingClientRect.top + event.distance.y - 256;
      if (newX < 0 || newX > 159 || newY < 0 || newY > 145) {
        const jsonDragged = event.source.dropContainer.data[this.indexItemDragged];
        this.fnRegresaposicionContainer(jsonDragged.id);
        return;
      }
      this.lstPositionBefore.push(
        {
          id: idmoved,
          left: boundingClientRect.left,
          top: boundingClientRect.top
        }
      )
      let strStyles = "left:" + (newX) + "px !important;top:" + (newY) + "px !important; position: absolute !important;"
      console.log("Noexiste Left strStyles a aplicar::::::", strStyles);
      element.style.cssText = strStyles;

      this.lstPositions.push(
        {
          id: idmoved,
          left: boundingClientRect.left + event.distance.x,
          top: boundingClientRect.top + event.distance.y
        }
      )
      console.log("this.lstPositionsFINAL::", this.lstPositions)


    }

    /*
    console.log("************************************************");
    console.log("boundingClientRect:",boundingClientRect);
    console.log("parentPosition:", parentPosition);
    console.log('Primer calculo --- x: ' + (boundingClientRect.x - parentPosition.left), 'y: ' + (boundingClientRect.y - parentPosition.top)); 
    */
    //movimiento 

    //console.log("acabo drag idelemento: ",idmoved, " 2do calculo event.distance.x:",event.distance.x, "  event.distance.y: ",event.distance.y);
    //const jsonDragged = event.source.dropContainer.data[this.indexItemDragged];

  }

  fnasignaposicionContainerCorrecto(idElement) {
    console.log("vamos a sacarlo de su container y dejarlo al cash:::", idElement, this.lstPositions);
    this.lstPositions.forEach((element, index) => {
      if (element.id = idElement) {
        console.log("this.lstPositions[index]:", this.lstPositions[index]);
        console.log("element de before:", element);
        this.lstPositions[index] = element;
        var elem = document.getElementById(idElement);
        let strStyles = "left:" + element.left + "px !important;top:" + element.top + "px !important; position: fixed !important;"
        console.log("strStyles a aplicar::::::", strStyles);
        elem.style.cssText = strStyles;
      }
    });

    console.log("this.lstPositionsfinal asignado:-- ", this.lstPositions);
  }

  fnRegresaposicionContainer(idElement) {
    console.log("vamos a regresarlo a su container original:::", idElement, this.lstPositionBefore);
    var lstTmp = this.lstPositions;
    var lstMigatePosition = [];
    var lstMigatePositionBefore = [];
    this.lstPositionBefore.forEach((elementBefore, index) => {
      if (elementBefore.id = idElement) {
        console.log("this.lstPositions[index]:", this.lstPositions[index]);
        console.log("element de before:", elementBefore);
        lstTmp.forEach((element, index) => {
          if (element.id == idElement) {
            this.lstPositions[index] = elementBefore;
            console.log("A ESTE PUNTO LAS 2 LISTAS DEBEN TEENR EL MISMO ESTILO EN EL ITEM:", idElement, this.lstPositions, this.lstPositionBefore)
            var elem = document.getElementById(idElement);
            // let strStyles = "left:" + elementBefore.left + "px !important;top:" + elementBefore.top + "px !important; position: absolute !important;"
            let strStyles = "left:" + (0) + "px !important;top:" + (0) + "px !important; position: absolute !important;"
            console.log("strStyles a aplicar::::::", strStyles);
            elem.style.cssText = strStyles;
          } else {//llenamos una lista sin el elemento que fue regresado, esto para que pueda reestablecerse a la posicion inicial
            lstMigatePosition.push(element);
          }
        });
      }
      else {//llenamos una lista sin el elemento que fue regresado, esto para que pueda reestablecerse a la posicion inicial
        lstMigatePositionBefore.push(elementBefore);

      }


    });

    //restablecemos las listas a unas nuevas sin el elemento que fue reseteado
    this.lstPositions = []
    this.lstPositionBefore = []
    this.lstPositions = lstMigatePosition
    this.lstPositionBefore = lstMigatePositionBefore
    console.log("A ESTE PUNTO LAS 2 LISTAS Ya no  DEBEN TEENR EL MISMO ITEM:", idElement, this.lstPositions, this.lstPositionBefore)
    if(this.jsonBlnOpenModeActions.showMessageInterest1){
      this.isHintInterest++;
    }
  }

  updateStatus(status) {
    this.spinner.show();
    this.status = status;
    this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
  }

  createArrayCoins(value: number, length: number, type: number) {
    let auxArray = [];
    for (let i = 0; i < length; i++) {
      auxArray.push({
        valor: value,
        type: type,
        id: null,
        draggable: false,
      })
    }
    return auxArray;

  }

  optionsBeforeStartMonth(option) {
    this.statusBeforeMonth = option as number;
    if (this.statusBeforeMonth == 3) {
      this.isOpenMarketDisabledBoard = false;
    }
  }

  clickShareholderEquity() {
    if (this.statusBeforeMonth == 2) {
      return;
    }
    console.log("click shareHolderEquity")
  }


  dragMachineCustomizing(eventend: CdkDragEnd) {
    const jsonDragged = eventend.source.dropContainer.data[this.indexItemDragged];
    if ((jsonDragged.type != 10 && jsonDragged.type != 5) || this.currentMonth < 3) {
      return;
    }
    if (jsonDragged.type == 10) {
      this.jsonBlnOpenModeActions.isCustomizingShow = true;
      this.socketService.setBlnControlModeOpen('isCustomizingShow', true, this.roomId, this.teamId);
      setTimeout(() => {
        this.deleteCoinByAreaID('cdkLstCapEx', jsonDragged.id);
      }, 2000);
      this.socketService.deleteCoin('cdkLstCapEx', jsonDragged, this.roomId, this.teamId);
    } else if (jsonDragged.type == 5) {
      this.jsonBlnOpenModeActions.isSecondMachine = true;
      this.socketService.setBlnControlModeOpen('isSecondMachine', true, this.roomId, this.teamId);
      this.deleteCoinByAreaID('cdkLstCapEx', jsonDragged.id);
      this.socketService.deleteCoin('cdkLstCapEx', jsonDragged, this.roomId, this.teamId);
    }
  }

  checkCheckbox(idCheck: number, value: boolean) {
    console.log("Procedemos a checkear");
    const checkbox = this.checkBoxes.find(cb => cb.id === idCheck);
    console.log(checkbox)
    if (checkbox) {
      checkbox.blnCheck = value;
    }
    this.fnScrollCheckList(this.checkBoxes[idCheck]);
    this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId, idCheck);
  }

  showCheckbox(idCheck: number, value: number) {
    console.log("Procedemos a mostrar checkbox");
    const checkbox = this.checkBoxes.find(cb => cb.id === idCheck);
    console.log(checkbox)
    if (checkbox) {
      checkbox.show = value;
    }
    this.fnScrollCheckList(this.checkBoxes[idCheck]);
    this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
  }

  getCheckCheckbox(idCheck: number): { id: number, blnCheck: boolean, title: string, idBd: any, disabled: boolean, show: number } {
      console.log("Procedemos a obtener check");
      const checkbox = this.checkBoxes.find(cb => cb.id === idCheck);
      console.log(checkbox)
      return checkbox;
    }
  

  localUpdLoansAndReceivables() {
    // this.cdkLstReceivables30Aux = [...this.cdkLstReceivables30];
    // this.cdkLstReceivables60Aux = [...this.cdkLstReceivables60];
    // this.cdkLstReceivables90Aux = [...this.cdkLstReceivables90];
    this.totCdkLstReceivables30 = this.returnTotalLst(this.cdkLstReceivables30);
    this.totCdkLstReceivables60 = this.returnTotalLst(this.cdkLstReceivables60);
    this.totCdkLstReceivables90 = this.returnTotalLst(this.cdkLstReceivables90);
    this.cdkLstLoans30Aux = [...this.cdkLstLoans30];
    this.cdkLstLoans60Aux = [...this.cdkLstLoans60];
    this.cdkLstLoans90Aux = [...this.cdkLstLoans90];
    this.cdkLstPayables30Aux = [...this.cdkLstPayables30];
    this.cdkLstPayables60Aux = [...this.cdkLstPayables60];
    this.lstPayables = this.cdkLstPayables30;
  }

  fnChangeBoardTeam() {

    this.teamService.getAllTeamsAndMembersById(this.workshopId).subscribe(resp => {
      console.log("fnGetAllTeamsAndMembers", resp);
      let lstTeams = [];
      resp['teams'].forEach(element => {
        lstTeams.push(element.team)
      });

      this.dialogRef = this._matDialog.open(ChangeboardComponent, {
        width: '700px',
        height: '400px',
        panelClass: 'event-form-dialog',
        data: {
          teamsList: lstTeams,
          actualTeam: this.teamId,
          arrTextLabels: this.arrTextLabels,
        },
      });

      this.dialogRef.afterClosed().subscribe((resp) => {
        console.log("RETURNED DIALOG change board", resp);
        //validamos si cambió de team para recargar todo
        if (resp.jsnTeamSelected[0].TeamId == 0) {

        }
        else {
          if (resp.jsnTeamSelected[0].TeamId != this.teamId) {
            //borramos los elementos de lainterfaz y reseteamos las banderas globales actuales
            let auxTeamId = this.teamId;
            window.open("https://"+ _IP +"/play/board/"+this.workshopId+"/view/"+resp.jsnTeamSelected[0].TeamId);

            // this.fnClearCurrentBoard().then(() => {
            //   console.log("vamos a matar los sockets de la actual sesión");

            //   this.spinner.show();
            //   this.socketService.closeListeners("room" + auxTeamId);
            //   /*this.destroy$.next(true);
            //   // Now let's also unsubscribe from the subject itself:
            //   this.destroy$.unsubscribe();*/
            //   console.log("Closing WS...");
            //   //this.socketService.terminarConexion(); 
            //   setTimeout(() => {
            //     console.log("despues matar sockets inicializamos el board con el nuevo team")
            //     this.fnCargaBoardbyTeam(resp.jsnTeamSelected[0].TeamId, true)
            //   }, 500);

            // });


          }
        }


        //this.jsnUserSelected = resp.jsnTeamSelected;

      })
      /*
        */
    })
  }

  fnCargaBoardbyTeam(idTeamdestino, blnchangedTeam) {

    //Aqui va iniciar la carga casi identica como cuando se inicia el board de una persona
    //la diferencia es que se une al nuevo team el usuario
    console.log("vamos a cargar bopard de team: ", idTeamdestino, this.jsnUserSelected[0])
    var userSelect = this.jsnUserSelected[0];
    this.userData = userSelect;
    this.blnFacilitador = (userSelect.type == 2 || (userSelect.type == 6 && userSelect.IsFacilitator == 1) || (userSelect.type == 1 && userSelect.IsFacilitator == 1));
    //validamos si el usuario seleccionado es un observer debemos pedirle que elija algún board, ya que el no tiene
    if (userSelect.type == 4 || userSelect.type == 5) {
      Swal.fire({
        html: this.getErrorLabel('1058'),
        imageUrl: '../../../../assets/iconPrevention.svg',
        showCancelButton: false,
        allowEscapeKey:false,
        confirmButtonColor: '#6FB45C',
        cancelButtonColor: '#ED5050',
        confirmButtonText: 'OK!',
        reverseButtons: true,
      }).then((result) => {
        this.fnChangeBoardTeam();
      });

    }
    else {//sigue flujo normal de carga

    

      this.blnCanMoveitems = true;//en cambio de board nos servirá para restringirle mover cosas a members y observer que solo visualizan
      this.userId = userSelect.UserID;
      this.teamId = idTeamdestino;
      this.teamIdOriginal = idTeamdestino;
      this.roomId = "room" + idTeamdestino;
      this.roomWorkShopId = "roomWorkShop" + this.workshopId;
      console.log("this.roomWorkShopId", this.roomWorkShopId);
      console.log("CYCLEEES", this.Cycles);
      console.log(this.roomId);
      this.fnInitSocket();
      this.socketService.setupSocketConnection(
        this.userId,
        this.roomId,
        this.roomWorkShopId
      );
      this.workshopService.getWorkshopById(this.workshopId).subscribe(response => {
        console.log("getWorkshopById", response);
        if (response['intResponse'] == 200) {
          this.improveStatus = response['data'][0]['ImproveOption'];
          this.statusBeforeMonth = response['data'][0]['ImproveOption'] + 1;
          var lngInitial = { LanguageId: response['data'][0]['LanguageId'], Language: response['data'][0]['Language'], langBase: 1 }
          this.lstLanguagesBoard.push(lngInitial);
          //this.getTextLabelsByLanguage(this.idLenguage);
          if (response['data'][0]['LngOrganizationalPrivate'] != "") {
            this.chargeotherLanguages(response['data'][0]['LngOrganizationalPrivate']);
          }
          if (this.statusBeforeMonth == 0) {
            this.isOpenMarketDisabledBoard = true;
          }
          if (this.statusBeforeMonth >= 3) {
            this.isOpenMarketDisabledBoard = false;
          }

          //buscamos si hay algun valor de lenguaje guardado previamente por el usuario
          this.languageservice.getLanguageSaved(this.workshopId,this.userId)
          .subscribe(respLangSaved => {
            console.log("resp languageSAVED:", respLangSaved);
            if(respLangSaved["jsnAnswer"]["LanguageId"] != 0){
              this.getTextLabelsByLanguage(respLangSaved["jsnAnswer"]["LanguageId"]);
            }else{
              this.getTextLabelsByLanguage(lngInitial.LanguageId);
            }
            
          })

        }
      });

      this.orderService.getOpenMarketByWorkShopId(this.workshopId).subscribe(response => {
        console.log(response, 'isOpenMarket');
        if (response.intResponse == 200) {
          this.isOpenMarketDisabledBoard = (response.openMarket as number) === 1 ? true : false;
          this.intOpenMarket = response.openMarket as number;
        }
      });

      this.boardService.getInitBoard(idTeamdestino).subscribe(async responseBoard => {
        console.log("responseBoard", responseBoard);
        this.fnInitObserver();
        if (responseBoard.teamInf.intResponse == 200 && responseBoard.board.intResponse == 200) {
          this.TeamName = responseBoard.teamInf.team.Avatar;
          this.TeamNameArray = this.TeamName != null ? this.TeamName.split(" ") : [];
          this.fnLoadBoardBackground(responseBoard.teamInf.team.Color);
          const checklist = responseBoard.checkList.response as Array<any>
          this.checkBoxes = [];
          checklist.forEach((item, i) => {
            this.checkBoxes.push({
              id: item.idCheck,
              blnCheck: item.valor,
              title: item.title,
              idBd: item.CheckListId,
              disabled: (item.title == 'Pay Advertising' && this.checkBoxes[i - 2].blnCheck || item.title == 'Prepare Bids' && this.checkBoxes[i - 1].blnCheck) ? false : true,
              show:item.show
            });
          })
          // para las actions del board;
          const temArr = responseBoard.actionsBoard.response as Array<any>
          temArr.forEach(item => {
            this.jsonBlnOpenModeActions[item.Name] = item.Value as boolean;
          });
          console.log(this.jsonBlnOpenModeActions);
          // fin para las actions del board;


          const x = await this.fnAssignPiecesArea(responseBoard.board.board);
          // this.jsonBlnOpenModeActions.isSoldBuild = this.cdkLstLandBuild.length == 0;
          // this.cdkLstReceivables90Aux = [...this.cdkLstReceivables90];
          // this.cdkLstReceivables60Aux = [...this.cdkLstReceivables60];
          // this.cdkLstReceivables30Aux = [...this.cdkLstReceivables30];
          this.totCdkLstReceivables30 = this.returnTotalLst(this.cdkLstReceivables30);
          this.totCdkLstReceivables60 = this.returnTotalLst(this.cdkLstReceivables60);
          this.totCdkLstReceivables90 = this.returnTotalLst(this.cdkLstReceivables90);
          this.cdkLstLoans90Aux = [...this.cdkLstLoans90];
          this.cdkLstLoans60Aux = [...this.cdkLstLoans60];
          this.cdkLstLoans30Aux = [...this.cdkLstLoans30];
          this.cdkLstPayables60Aux = [...this.cdkLstPayables60];
          this.cdkLstPayables30Aux = [...this.cdkLstPayables30];
          // this.cdkLstSuppliers.forEach(item => {
          //   if(item.type == 3){
          //     // this.jsonBlnOpenModeActions.showMessageOwe12 = true;
          //     // this.socketService.setBlnControlModeOpen('showMessageOwe12', true, this.roomId, this.teamId);
          //     // this.jsonBlnOpenModeActions.isBuyMaterials = true;
          //     // this.socketService.setBlnControlModeOpen('isBuyMaterials', true, this.roomId, this.teamId);
          //     return;
          //   }
          // })
          this.status = responseBoard.teamInf.team.Status == null ? 0 : responseBoard.teamInf.team.Status;
          this.currentMonth = responseBoard.teamInf.team.Month == null ? 1 : responseBoard.teamInf.team.Month;
          if (this.currentMonth >= 4 && this.status == 2) {
            this.lstLoans = this.cdkLstLoans30;
          }
          this.boardService.getAdvertisingBill(this.teamId, this.currentMonth).subscribe(response => {
            console.log("Response advertising", response);
            this.owesAdvertising = response.Advertising;
            this.advertising = response.Advertising;
            let tot = 0;
            this.cdkLstAdvertasing.forEach(item => {
              tot += item.valor;
            })
            this.owesAdvertising -= tot;
            if (this.owesAdvertising > 0)
              this.showOwedAdvertising = true;
          })
          this.boardService.getImprovements(idTeamdestino).subscribe(async response => {
            console.log("rseponse improvements", response);
            if (response.intResponse == 200 && response.improvements != null && this.currentMonth >= 3) {
              this.improvementsOptionsSelected = [];
              let askImprovement = true;
              let showNeedFacilitatorAproval = false;
              const options = response.improvements as Array<any>;
              options.forEach(item => {
                this.improvementsOptionsSelected.push({
                  improvement: item.Option == 3 ? 'Customizing' :
                    item.Option == 4 ? 'Quality' :
                      item.Option == 5 ? 'Expansion' :
                        item.Option == 1 ? 'Raw Materials' :
                          item.Option == 2 ? 'Efficiency' : '',
                  value: item.Status,
                  ImproveId: item.ImproveOptionId,
                  Approved: item.Approved,
                  option: item.Option,
                  question: item.Option == 3 ? 'Recalibrate this Machine for customizing materials? Cost is 10.  This cannot be undone.' :
                    item.Option == 4 ? 'Hire a new person for your company? Cost is 1 . This cannot be undone.' :
                      item.Option == 5 ? 'Install a second machine? CapEx is 20. Recruit & Train expense is 6.  Increased depreciation and Machine Overheads. This cannot be undone.' :
                        item.Option == 1 ? 'Recalibrate this Machine for cheaper Raw Materials? OpEx is 2 This cannot be undone.' :
                          item.Option == 2 ? 'Increase efficiency of this machine?  CapEx is 10.  This cannot be undone.' : ''
                });
                if ((item.Status == 1) && item.Approved == 0) {
                  // this.isOpenMarketDisabledBoard = true;
                  askImprovement = true;
                } else if (item.Status == 2 && item.Approved == 1) {
                  askImprovement = false;
                } else if (item.Status == 2 && item.Approved == 0){
                  askImprovement = false;
                  showNeedFacilitatorAproval = true;
                }
              })
              if(this.improvementsOptionsSelected.length >= 3 && this.currentMonth >= 4){
                const indexToRemove = this.improvementsOptionsSelected.findIndex(option => option.option === 6);
                if (indexToRemove !== -1) {
                    // Si se encuentra el índice, elimina el elemento con `splice`
                    this.improvementsOptionsSelected.splice(indexToRemove, 1);
                    console.log(this.improvementsOptionsSelected)
                }
              }
              if (this.status == 3) {
                if (this.blnFacilitador) {
                  return;
                }
                if (askImprovement && !(this.Cycles == 6 && (this.currentMonth == 4 || this.currentMonth == 6))) {
                  await this.askImprovement();
                }
                if (showNeedFacilitatorAproval) {
                  Swal.mixin({
                    text: 'You need the approval of the facilitator to cancel your project and be able to continue. Please ask them.',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowEscapeKey: false
                  }).fire();
                }
              }
            }
            const x = await this.getOrders();
          });
          console.log('Mes', this.currentMonth)
          if (this.status >= 6 && this.currentMonth >= 2) {
            if (this.cdkLstAdvertasing[0] != undefined) {
              this.auxValTotalAdvertising = this.cdkLstAdvertasing[0].valor
            } else {
              this.auxValTotalAdvertising = 0
            }

          }
          setTimeout(() => {
            if (this.jsnUserSelected[0].type == 5)//es usuario tipo observer solo de vista no puede interactuar con el board
            {
              this.blnCanMoveitems = false;
              return;
            }
            if (!blnchangedTeam) {//solo hizo reload, osea que pertenece al mismo team, se aplican las validaciones de COO o facilitador
              //depues de la carga de piezas en el board validamos si el user tiene el rol de CFO que es el asignado para poder controlar el BOARD al inicio
              this.orderService.getValidationControlRol(this.userId, this.teamId).subscribe(response => {
                console.log("respuesta de fn getValidationControlRol", response);
                if (response.intResponse == 200) {
                  this.isFacilitatorTeamAccount = response.isFacilitatorAccount;
                  this.isOpenMarketDisabledBoard = response.IsCFO > 0 && this.statusBeforeMonth != 0 ? false : true;
                  this.blnGrowNocontrol = response.IsCFO > 0 && this.statusBeforeMonth != 0 ? false : true;
                  if (response.IsCFO > 0) {
                    this.fntakeControl();
                  }
                  console.log("blnGrowNocontrol true i am COO;;;;;;");
                }
                else {
                  this.isOpenMarketDisabledBoard = true;
                  this.blnGrowNocontrol = true;
                  console.log("blnGrowNocontrol true;;;;;;");
                }

                if (this.blnFacilitador || response.memberCount === 1) {
                  this.isOpenMarketDisabledBoard = false;
                  this.blnGrowNocontrol = false;
                  console.log("blnGrowNocontrol false facilitator;;;;;;");
                }
                
                /* if( response.IsCFO <= 0 ){
                   this.blnGrowNocontrol = true;
                   console.log("blnGrowNocontrol true;;;;;;");
                 }*/
              });
            } else {
              if (idTeamdestino == this.userData.TeamId && this.currentMonth >= 2) {
                if(this.blnFacilitador){
                  this.boardService.getFacilitatorMonth(this.teamId).subscribe(response => {
                    console.log(response);
                    if(response['month'] != this.currentMonth){
                      this.monthFacilitator = response['month'];
                      // this.blnCanMoveFacilitator = true;
                      // console.log("NO PUEDE MOVER")
                      this.improvementsOptionsSelected.push({
                        improvement: '',
                        value: 0,
                        ImproveId: -1,
                        option: 0,
                        question: ''
                      })
                      if(this.isProjectsEnableByCurrentMonth){
                        this.showCheckListFacilitator = true;
                        this.checkBoxesForFacilitator = this.checkBoxesForFacilitatorImprovement;
                      }else{
                        this.showCheckListFacilitator = true;

                        this.checkBoxesForFacilitator = this.checkBoxesForFacilitatorNoImprovement;
                      }
                    }else{
                      // console.log("PUEDE MOVER")
                      // this.blnCanMoveFacilitator = false;
                      this.showCheckListFacilitator = false;
                    }
                  })
                } 
              }else{
                console.log("Equipos DIFFFF")
                this.blnCanMoveFacilitator = false;
                this.showCheckListFacilitator = false;
              }
                        
              //significa que es un usuario  que se está cambiando de equipo y no padrá mover nada
              this.isOpenMarketDisabledBoard = true;
              this.blnGrowNocontrol = true;
              //adicionalmente validamos si es un tipo observer que edita o un facilitador si podrá solicitar el control
              //sino es esos tipos es un member de otro equipo que intenta entrar al team

              if (this.jsnUserSelected[0].type != 4 || !this.blnFacilitador) {
                this.blnAccessCUSTOMERISBS = false;
                this.blnGrowNocontrol = false;
                if (idTeamdestino == this.userData.TeamId) {
                  this.blnAccessCUSTOMERISBS = true;
                  this.blnGrowNocontrol = true;
                  
                  this.fntakeControl();
                }
              } else {
                this.blnAccessCUSTOMERISBS = true;
                this.blnGrowNocontrol = true;
              }
            }

          }, 2000);

          this.isModeOpen = responseBoard.teamInf.team.Mode == 1 ? true : false;
          console.log(this.isModeOpen, "ismodeopen?");
          this.boardService.getLiabilitiesReceivablesUpdates(this.workshopId, this.teamId).subscribe(res => {
            if (res.intResponse === 200) {
              this.liabilitiesReceivablesBeforeUpdates = res.jsonData;
            }
          });
        } else {
          Swal.fire('Error!', responseBoard.strAnswer, 'error');
        }
      });

      //   });


      //Obtenes estatus de bill
      setTimeout(() => {
        this.boardService.getBillStatus(this.teamId).subscribe(response => {
          if (response.intResponse == 200) {
            this.IdStatusBid = response.StatusBill.idBillStatus
            if (this.currentMonth >= 2 && this.cdkLstAdvertasing.length > 0) {
              this.auxValTotalAdvertising = response.StatusBill.value
              this.AdvertisingExtra = this.cdkLstAdvertasing[0].valor - this.auxValTotalAdvertising
              // this.cdkLstAdvertasing[0].draggingBy = 'Advertising: ' + this.auxValTotalAdvertising + ' Brand Awareness: ' + this.AdvertisingExtra
              this.cdkLstAdvertasing[0].draggingBy = 'Advertising: ' + this.auxValTotalAdvertising
              if (response.StatusBill.Status == 0) {
                // this.checkBoxes[this.currentMonth>=3?11:9].blnCheck = false;
                this.checkCheckbox(9, false);
                this.showOwedAdvertising = true;
              }
              console.log(response.StatusBill.value, 'Respuesta Status', response.StatusBill.Status)
            }
          }
        });
        //Se obtiene el total de advertising gastado
        this.fnGetAllOrders(this.workshopId)
      }, 200);

    }
    //funcion ciclica para verificar conexion socket
    setTimeout(() => {
      this.fnCheckSocket();
    }, 10000);



    this.spinner.hide();

  }

  fnClearCurrentBoard() {
    return new Promise((resolve) => {
      //limpiamos otras variables globales
      this.improveStatus = -1;
      this.checkBoxes = [
        { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
        { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
        { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
        { id: 4, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
        { id: 5, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
        { id: 6, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
        { id: 7, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
        { id: 8, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
        { id: 9, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
        { id: 10, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
        { id: 11, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
        { id: 12, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
        { id: 13, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
        { id: 14, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
        { id: 15, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
        { id: 16, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
      ];
      this.arrTextLabels = [];
      this.improvementsOptionsSelected = [];
      this.improvementSelected = false;
      this.improvementImplemented = false;
      this.boardDisable = true;
      this.jsonBlnOpenModeActions = {
        'isDepreciateMachine1': false,
        'isDepreciateMachine2': false,
        'isDepreciation': false,
        'isBuyMaterials': false,
        'showMessageOwe12': false,
        'showMessageOwe18': false,
        'isBuyLoan': false,
        'showMessageInterest1': false,
        'isSoldBuild': false,
        'showMessageOwe20': false,
        'showMessageOwe10': false,
        'isPayBill': false,
        'isBuyBuild': false,
        'isBuySecondMachine': false,
        // para hacer mas grande una maquina
        'isEfficiency1': false,
        'isEfficiency2': false,
        // para mostrar maquina de customizing
        'isCustomizingShow': false,
        // para mostrar segunda maquina
        'isSecondMachine': false,
        'showBill20': false,
        'isPayBill20': false,
        'showBill10': false,
        'isPayBill10': false,
        'showBill1': false,
        'isPayBill1': false,
        'showBill6': false,
        'showBill2': false,
        'showBill2Aux1': false,
        'showBill2Aux2': false,
        'isRawMaterials1': false,
        'isRawMaterials2': false,
        'showMessageOwe4': false,
        'isActiveImprovement':false,
        'isLoansPaid':false,
        'isPayablesPaid':false
      }
      this.countQuantitysDelivered = 0;
      this.TeamName = "Team Name";
      this.TeamNameArray = [];
      this.blnErrorContainer = true;
      this.isModeOpen = true;
      this.isOpenMarket = null;
      this.isOpenMarketDisabledBoard = false;
      this.intOpenMarket = 0;
      this.isTrashContainer = false;
      this.urlImgTrash = ' ../../../assets/boteblancoabierto.svg';
      this.currentMonth = 2;
      this.arrOrders = [];
      this.totalCashMAchine1 = 0;
      this.totalCashMAchine2 = 0;
      /*destroy$: Subject<boolean> = new Subject<boolean>();
      this.dialogRef: any;*/
      //NN this.userId = 0;
      this.abrioModal = false;
      this.expandsAdvertising = false;
      this.workshopCurrentMonthTeamAvanzado = 0;
      //Nose borra para persistir el dato this.workshopId = 0;
      //Nose borra para persistir el dato this.jsnUserSelected;
      this.owesAdvertising = 0;
      this.auxValTotalAdvertising = 0;
      this.advertising = 0;
      this.AdvertisingExtra = 0;
      this.IdStatusBid = 0;
      this.showOwedAdvertising = false;
      this.showPaidAdvertising = false;
      this.roomId = "";
      this.roomWorkShopId = "";
      this.teamId = 0;
      // this.Cycles = 0;
      //NN this.userData;
      this.isClickBalanceSheet = false;
      this.isClickCustomers = false;
      this.itemDragged = null;
      this.indexItemDragged = -1;
      this.listAux;
      //this.users = ['Usuario1', 'Usuario2', 'Usuario3', 'Usuario4', 'Usuario5'];
      this.contUser = 0;
      this.room = ['Room1', 'Room2', 'Room3', 'Room4', 'Room5'];
      this.contRoom = 0;
      this.strBackground = "./../../../assets/DARKTHEME2.jpg";
      /* para maquina de estados */
      this.status = 0;
      // opcion de deshabilitacion de botones
      this.statusBeforeMonth = 0;
      //this.isChrome;

      this.blnFacilitador = true  /* bandera para saber si es facilitador */
      this.blnMove = false;
      this.blnAllowAnim = false;
      this.blnAnimRawMat = false;
      this.blnAnimRawMat2 = false;
      this.blnAnimCoinPeople = false;
      this.blnMoveMonth2 = false;
      this.blnMoveMonth3 = false;
      this.blnMoveMonth4 = false;
      this.blnMoveMonth5 = false;
      this.blnMoveMonth6 = false;
      this.move30 = false;
      this.move60 = false;
      this.move90 = false;
      this.moveCash = false;
      this.blnBackgroundBlue = false;
      this.blnBackground2 = false;
      this.blnBackground4 = false;
      this.blnBackground5 = false;
      this.blnBackground6 = false;
      this.blnBackground7_8 = false;
      this.blnBackground9 = false;
      this.itemMove = 0;
      //this.idLenguage = 1;  // ID del lenguaje (1=ingles)
      /*++++++++++++VARIABLES PARA CONTROL DE CAMBIO DE BOARD */
      //estas variable no se borra en cambio de Board,
      // variable global de todos los usuarios del board, observers members y facilitadores para usarla en el cambio de board
      this.lstGloblalUsersBoard = [];
      this.blnCanMoveitems = true;
      /*++++++++++++ FIN VARIABLES PARA CONTROL DE CAMBIO DE BOARD */
      this.lblUserwithControl = "";
      this.isConnected = true;


      //#region listas areas draggables
      this.lstLoans = [];
      this.lstPayables = [];
      this.cdkLstTrash = [
      ];
      this.cdkLstTrashCash = [
      ];
      this.cdkLstNoAreaDragg = [
      ];
      this.cdkLstTrashCapitalS = [
      ];
      this.cdkLstBank = [
      ];
      this.cdkLstCapEx = [
      ];
      this.paidCapEx = [];
      this.paySuppliers = [];
      this.orders = [
        // {Terms: 60, OrderNum: 201, Bid: 25, Quantity: 2, Delivered: false},
        // {Terms: 90, OrderNum: 205, Bid: 10, Quantity: 1, Delivered: false},
        // {Terms: 30, OrderNum: 209, Bid: 15, Quantity: 1, Delivered: false},
      ];
      this.cdkLstLabourP = [
      ];
      this.cdkLstSuppliers = [
      ];
      this.cdkLstMaterials = [
      ];
      this.cdkLstLandBuild = [
      ];
      this.cdkLstFinishedFI = [
      ];
      this.cdkLstLoans90 = [
      ];
      this.cdkLstLoans60 = [
      ];
      this.cdkLstLoans30 = [
      ];
      this.cdkLstLoans90Aux = [];
      this.cdkLstLoans60Aux = [];
      this.cdkLstLoans30Aux = [];
      this.cdkLstCash = [
      ];
      this.cdkLstReceivables90 = [
      ];
      this.cdkLstReceivables60 = [
      ];
      this.cdkLstReceivables90Anim = [];
      this.cdkLstReceivables60Anim = [];
      this.cdkLstReceivables30Anim = [];
      this.lista2 = [];
      this.itemsToMove = 0;
      this.move2Items = false;
      this.move3Items = false;
      this.move1Items = false;
      this.cdkLstReceivables30 = [
      ];
      this.totCdkLstReceivables30 = 0;
      this.totCdkLstReceivables60 = 0;
      this.totCdkLstReceivables90 = 0;
      this.cdkLstCapitalS = [
      ];
      this.cdkLstRetainedE = [
      ];
      this.cdkLstNetIncome = [
      ];
      this.cdkLstTaxes = [
      ];
      this.cdkLstIncomeBT = [
      ];
      this.cdkLstInterest = [
      ];
      this.cdkLstRent = [
      ];
      this.cdkLstRecruitmentT = [
      ];
      this.cdkLstAdminOver = [
      ];
      this.cdkLstAdministration = [
      ];
      this.cdkLstAdvertasing = [
      ];
      this.cdkLstMarketingS = [
      ];
      this.cdkLstChangeI = [
      ];
      this.cdkLstDeprecation = [
      ];
      this.cdkLstMachineOver = [
      ];
      this.cdkRles = [
      ];
      this.cdkLstCostGS = [
      ];
      this.cdkLstLocationMachine = [
      ];
      this.cdkLstInsideMachineCapex = [];
      this.cdkLstInsideMachinePeople = [];
      this.cdkLstInsideMachineWorkInProgress = [];
      this.cdkLstInsideMachineCapex2 = [];
      this.cdkLstInsideMachinePeople2 = [];
      this.cdkLstInsideMachineWorkInProgress2 = [];
      this.cdkLstInsideMachineCapexCustomizing = [];
      this.cdkLstInsideMachineCapex3 = [];
      this.cdkLstInsideMachinePeople3 = [];
      this.cdkLstInsideMachineWorkInProgress3 = [];
      this.cdkLstPayables60 = [];
      this.cdkLstPayables30 = [];
      this.cdkLstPayables60Aux = [];
      this.cdkLstPayables30Aux = [];
      this.cdkLstOther = [
      ];
      this.lstPositions = [];
      this.lstPositionBefore = [];
      //#endregion listas areas draggables
      this.blnGrowNocontrol = false;
      this.growNocontrolTaked = false;
      this.lstLanguagesBoard = [];
      this.blnAccessCUSTOMERISBS = false;
      this.teamIdOriginal = 0;
      resolve(true);
    });

  }


  //Funcion para recargar y regresar al board del usuario
  //ya que limpia el board actual independientemente del board en el que este
  //y toma el idTeam de la variable global del usuario actual this.jsnUserSelected
  //por lo que carga su board
  fnReloadMyBoard() {
    let auxTeamId = this.teamId;
    this.fnClearCurrentBoard().then(() => {
      console.log("vamos a matar los sockets de la actual sesión");
      this.spinner.show();
      this.socketService.closeListeners("room" + auxTeamId);
      /*this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();*/
      console.log("Closing WS...");
      //this.socketService.terminarConexion(); 
      setTimeout(() => {
        console.log("despues matar sockets inicializamos el board con el nuevo team")
        this.fnCargaBoardbyTeam(this.jsnUserSelected[0].TeamId, false);
      }, 500);

    });
  }

  fnModalImprovements() {
    if (!this.blnAccessCUSTOMERISBS) {
      return;
    }
    console.log("Es aqui",this.improveStatus)
    let blncanReselect = false;
    let enableSelect = this.improveStatus === 2 ? true : false;

    this.dialogRef = this._matDialog.open(DialogImprovementsComponent, {
      disableClose: false,
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        enableSelect: (enableSelect && this.isProjectsEnableByCurrentMonth && this.projects != 0 && !this.blnGrowNocontrol),
        teamId: this.teamId,
        currentMonth: this.isPendingProject != 0 ? this.isPendingProject : this.currentMonth,
        month: this.currentMonth,
        workshopId: this.workshopId,
        statusBoard: this.status,
        arrTextLabels: this.arrTextLabels,
        canReSelect : blncanReselect,
        Cycles: this.Cycles,
        boardType: this.businessModel      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG 1", resp, this.improvementsOptionsSelected)
      if(!this.blnGrowNocontrol){
        // this.fntakeControl();
      }
      if(resp.status == 1){
        console.log("se escogio proyecto!!")
        this.checkCheckbox(0, true);
      }
      if(this.currentMonth >=4 && resp.status == 1 && this.isPendingProject == 0){
        setTimeout(() => {
        this.boardService.getImprovements(this.userData.TeamId).subscribe(async response => {
          console.log("rseponse improvements", response);
          if (response.intResponse == 200 && response.improvements != null && this.currentMonth >= 3) {
            //this.improvementsOptionsSelected = [];
            let improvementsOptionAux = [];
            const options = response.improvements as Array<any>;
            options.forEach(item => {
              improvementsOptionAux.push({
                improvement: item.Option == 3 ? 'Customizing' :
                  item.Option == 4 ? 'Quality' :
                    item.Option == 5 ? 'Expansion' :
                      item.Option == 1 ? 'Raw Materials' :
                        item.Option == 2 ? 'Efficiency' : 
                        /*DCM improv */
                        item.Option == 6 ? 'No Improvement' : '',
                value: item.Status,
                ImproveId: item.ImproveOptionId,
                Approved: item.Approved,
                option: item.Option,
                question: item.Option == 3 ? 'Do you want to implement Customizing for this month?' :
                  item.Option == 4 ? 'Do you want to implement Quality for this month?' :
                    item.Option == 5 ? 'Do you want to implement Expansion for this month?' :
                      item.Option == 1 ? 'Do you want to implement Raw Materials for this month?' :
                        item.Option == 2 ? 'Do you want to implement Efficiency for this month?' : 
                          item.Option == 6 ? 'You selected "No implement improvement" facilitator must approve this option in order you can continue. Please ask them or select an improvement now.' : ''
              });
            })
            if(this.improvementsOptionsSelected.length >= 3 && this.currentMonth >= 4){
              const indexToRemove = this.improvementsOptionsSelected.findIndex(option => option.option === 6);
              if (indexToRemove !== -1) {
                  // Si se encuentra el índice, elimina el elemento con `splice`
                  this.improvementsOptionsSelected.splice(indexToRemove, 1);
                  console.log(this.improvementsOptionsSelected)
              }
            }
            if(this.improvementsOptionsSelected[1].ImproveId == -1){
              console.log("ERRRRROOOOR")
              this.improvementsOptionsSelected = improvementsOptionAux;
            }
            console.log("RETURNED DIALOG 1", resp, this.improvementsOptionsSelected,improvementsOptionAux)
          }
        });
      }, 1500);
      }
      setTimeout(() => {
        if(this.improvementsOptionsSelected.length >= 3 && this.currentMonth >= 4){
          const indexToRemove = this.improvementsOptionsSelected.findIndex(option => option.option === 6);
          if (indexToRemove !== -1) {
              // Si se encuentra el índice, elimina el elemento con `splice`
              this.improvementsOptionsSelected.splice(indexToRemove, 1);
              console.log(this.improvementsOptionsSelected)
          }
        }
        
      }, 1000);
    });
  }

  fnModalTMPImprovements() {
    let enableSelect = true;
    let blncanReselect = false;
    this.dialogRef = this._matDialog.open(DialogImprovementsComponent, {
      disableClose: false,
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        enableSelect: enableSelect,
        teamId: this.teamId,
        currentMonth: 3,
        month: 3,
        workshopId: this.workshopId,
        arrTextLabels: this.arrTextLabels,
        canReSelect : blncanReselect,
        Cycles: this.Cycles
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG")
    });
  }

  fnSelectImprovement(option, id) {
    console.log(this.improvementsOptionsSelected)
    if (this.currentMonth >= 3 && this.improvementSelected) {
      console.log('entrando a agregar a array de improvements, pero return');
      return;
    }
    console.log("AQUIIIIIIIIII",JSON.stringify(this.improvementsOptionsSelected))
    if(this.projects == 0){
      const position = this.currentMonth == 3 ? 0 : this.currentMonth == 4 || this.currentMonth == 5 ? 1 : 0;
    const strImprovement = option == 3 ? 'Customizing' : option == 4 ? 'Quality' : option == 5 ? 'Expansion' : option == 1 ? 'Raw Materials' :
      option == 2 ? 'Efficiency' : option == 6 ? 'You selected "No implement improvement" facilitator must approve this option in order you can continue, Please ask them.' : '';
    this.improvementsOptionsSelected.splice(position, 0, {
      improvement: strImprovement,
      option: option,
      value: 1,
      Approved: 0,
      ImproveId: id,
      // question: option == 3 ? 'Recalibrate this Machine for customizing materials? Cost is 10.  This cannot be undone.':
      // option == 4 ? 'Hire a new person for your company? Cost is 1 . This cannot be undone.' :
      // option == 5 ? 'Install a second machine? CapEx is 20. Recruit & Train expense is 6.  Increased depreciation and Machine Overheads. This cannot be undone.':
      // option == 1 ? 'Recalibrate this Machine for cheaper Raw Materials? OpEx is 2 This cannot be undone.':
      // option == 2 ? 'Increase efficiency of this machine?  CapEx is 10.  This cannot be undone.':
      // ''
      question: (option != 6 ? `Do you want to implement ${strImprovement} for this month?` : strImprovement)
    });
    console.log("AQUIIIIIIIIII",JSON.stringify(this.improvementsOptionsSelected))
    if (this.improvementsOptionsSelected.length == 1) {
      this.improvementsOptionsSelected.push({
        improvement: '',
        value: 0,
        ImproveId: -1,
        option: 0,
        question: ''
      })
    }

    }else{
    this.improvementSelected = option == 6 ? false : true;
    this.status = 0;
    this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
    // this.checkBoxes[0].blnCheck = true;
    // if(!this.blnGrowNocontrol){
    //   this.checkCheckbox(0,true);
    //   this.fnScrollCheckList(this.checkBoxes[0]);

    // }
    // setTimeout(() => {
    //   this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
    // }, 1000);
    console.log("AQUI FUNCION IMPRU")
    const position = this.currentMonth == 3 ? 0 : this.currentMonth == 4 || this.currentMonth == 5 ? 1 : 0;
    const strImprovement = option == 3 ? 'Customizing' : option == 4 ? 'Quality' : option == 5 ? 'Expansion' : option == 1 ? 'Raw Materials' :
      option == 2 ? 'Efficiency' : option == 6 ? 'You selected "No implement improvement" facilitator must approve this option in order you can continue, Please ask them.' : '';
      console.log(position,strImprovement)
      this.improvementsOptionsSelected.splice(position, 0, {
      improvement: strImprovement,
      option: option,
      value: 1,
      Approved: 0,
      ImproveId: id,
      // question: option == 3 ? 'Recalibrate this Machine for customizing materials? Cost is 10.  This cannot be undone.':
      // option == 4 ? 'Hire a new person for your company? Cost is 1 . This cannot be undone.' :
      // option == 5 ? 'Install a second machine? CapEx is 20. Recruit & Train expense is 6.  Increased depreciation and Machine Overheads. This cannot be undone.':
      // option == 1 ? 'Recalibrate this Machine for cheaper Raw Materials? OpEx is 2 This cannot be undone.':
      // option == 2 ? 'Increase efficiency of this machine?  CapEx is 10.  This cannot be undone.':
      // ''
      question: (option != 6 ? `Do you want to implement ${strImprovement} for this month?` : strImprovement)
    });
    console.log("AQUIIIIIIIIII",JSON.stringify(this.improvementsOptionsSelected))
    if (this.improvementsOptionsSelected.length == 1) {
      this.improvementsOptionsSelected.push({
        improvement: '',
        value: 0,
        ImproveId: -1,
        option: 0,
        question: ''
      })
    }
    if(this.improvementsOptionsSelected.length >= 3 && this.currentMonth >= 4){
      const indexToRemove = this.improvementsOptionsSelected.findIndex(option => option.option === 6);
      if (indexToRemove !== -1) {
          // Si se encuentra el índice, elimina el elemento con `splice`
          this.improvementsOptionsSelected.splice(indexToRemove, 1);
          console.log(this.improvementsOptionsSelected)
      }
    }
    console.log("AQUIIIIIIIIII",JSON.stringify(this.improvementsOptionsSelected))
    return;
  }
  }
  /*
    fnReSelectImprovementAgain(option, id) {
    
      console.log("Armamos pregunta de mplementacion")
      this.improvementSelected = option == 6 ? false : true;
      this.status = 0;
      this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
      this.checkBoxes[0].blnCheck = true;
      this.fnScrollCheckList(this.checkBoxes[0]);
      setTimeout(() => {
        this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
      }, 1000);
      const position = this.currentMonth == 3 ? 0 : this.currentMonth == 4 || this.currentMonth == 5 ? 1 : 0;
      const strImprovement = option == 3 ? 'Customizing' : option == 4 ? 'Quality' : option == 5 ? 'Expansion' : option == 1 ? 'Raw Materials' :
        option == 2 ? 'Efficiency' : option == 6 ? 'You selected "No implement improvement" facilitator must approve this option in order you can continue, Please ask them.' : '';
      this.improvementsOptionsSelected.splice(position, 0, {
        improvement: strImprovement,
        option: option,
        value: 1,
        Approved: 0,
        ImproveId: id,
        // question: option == 3 ? 'Recalibrate this Machine for customizing materials? Cost is 10.  This cannot be undone.':
        // option == 4 ? 'Hire a new person for your company? Cost is 1 . This cannot be undone.' :
        // option == 5 ? 'Install a second machine? CapEx is 20. Recruit & Train expense is 6.  Increased depreciation and Machine Overheads. This cannot be undone.':
        // option == 1 ? 'Recalibrate this Machine for cheaper Raw Materials? OpEx is 2 This cannot be undone.':
        // option == 2 ? 'Increase efficiency of this machine?  CapEx is 10.  This cannot be undone.':
        // ''
        question: (option != 6 ? `Do you want to implement ${strImprovement} for this month?` : strImprovement)
      });
      if (this.improvementsOptionsSelected.length == 1) {
        this.improvementsOptionsSelected.push({
          improvement: '',
          value: 0,
          ImproveId: -1,
          option: 0,
          question: ''
        })
      }
      return;
    }*/

  fnInitObserverImprovements() {
    //this.socketImprovement.setupSocketConnection();
    this.socketImprovement._improvement
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (response) => {
        if (response) {
          console.log('RESPONSE IMPROVEMENTS BOARD', response);
          if (response.status == 'improveOptionServer' && response.data != undefined) {
            console.log('improveOptionServer Listener del soket', response);
            // response.data.improveOption = 1 for Raw materials
            // 2 for efficiency
            // 3 for customizing
            // 4 for quality
            // 5 for expansion 
            // 6 for no improvement
            // this.improvementsOptionsSelected = [];
            if (response.data.teamId == this.teamId && response.data.improveOption && response.data.improveStatus === 1) {
              // this.isOpenMarketDisabledBoard = false;
              if (response.data.improveOption == 6) {
                this.isOpenMarketDisabledBoard = true;
                this.notifyFacilitator(3);
              }
              this.fnSelectImprovement(response.data.improveOption, response.data.improveOptionId);
            }

          }
          else if (response.status == 'improveOptionServerbyReselect' && response.data != undefined) {
            console.log('3.-improveOptionServer Listener del soket AqUI DEBERIAMOS RELOAD', response);
            // response.data.improveOption = 1 for Raw materials
            // 2 for efficiency
            // 3 for customizing
            // 4 for quality
            // 5 for expansion 
            // 6 for no improvement
            // this.improvementsOptionsSelected = [];
            if (response.data.teamId == this.teamId && response.data.improveOption && response.data.improveStatus === 1) {
              console.log("if (response.data.teamId scket para mostrar pregunta implementacion otra vez");
              this.isOpenMarketDisabledBoard = false;
              if (response.data.improveOption == 6) {
                this.isOpenMarketDisabledBoard = true;
                this.notifyFacilitator(3);
              }
              //this.fnReSelectImprovementAgain(response.data.improveOption, response.data.improveOptionId);
              location.reload();
            }
            //location.reload();

          }
           else if (response.status == 'improveOptionStatusServer' && response.data != undefined) {
            console.log('improveOptionStatusServer', response);
            this.improvementsOptionsSelected.forEach(item => {
              if (item.option == response.data.improveOption) {
                item.value = response.data.status;
              }
            });
            console.log(this.improvementsOptionsSelected)
            Swal.close({ isConfirmed: false, isDenied: false, isDismissed: false });
            if (response.data.status === 2) {
              let stringImpru = response.data.improveOption == 3 ? 'Customizing' :
                          response.data.improveOption == 4 ? 'Quality' :
                            response.data.improveOption == 5 ? 'Expansion' :
                              response.data.improveOption == 1 ? 'Raw Materials' :
                                response.data.improveOption == 2 ? 'Efficiency' : 
                                /*DCM improv */
                                response.data.improveOption == 6 ? 'No Improvement' : ''
              let originalString = this.getSingleTextLabel('8074')
              let modifiedString = originalString.replace(/(your)(\s*)(project)/, `$1$2${stringImpru}$2$3`);
              if(
                ((this.improvementsOptionsSelected[0].value == 3) && this.improvementsOptionsSelected[0].option == 5 && this.improvementsOptionsSelected[0].Approved == 0) ||
                ((this.improvementsOptionsSelected[1].value == 3) && this.improvementsOptionsSelected[1].option == 5 && this.improvementsOptionsSelected[1].Approved == 0)
              ) {
                let strMachine = ""
                  if(this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isRawMaterials1){
                    strMachine = "for Machine 2"

                  }else{
                    strMachine = "for Machine 1"
                  }
                  modifiedString = modifiedString.replace(/(project)(\s*)(and)/, `$1$2${strMachine}$2$3`);

                }
              Swal.mixin({
                text: modifiedString,
                allowOutsideClick: false,
                showConfirmButton: false,
                showCancelButton: false,
                allowEscapeKey: false
              }).fire();
            }
          } else if (response.status == 'declineAceptImprovementServer' && response.data != undefined) {
            console.log('declineAceptImprovementServer 12961', response);
            this.spinner.hide();
            if (response.data.acepted) {
              return;
            } else {
              const x = await this.implementImprovement();
            }


          } else if (response.status == 'aceptDeclineImprovementServer' && response.data != undefined) {
            console.log("entra aceptDeclineImprovementServer 12971 -------");
            if (response.data.teamId == this.teamId) {
              if (response.data.improveStatus === 1 && this.status == 3) {
                console.log("13077 mns Dani improve==1 tons fac rechazo opcion de team de no implementar debemos volver a preguntar");
               //falta validar que si el improv = 6 no debe preguntar si no recargar, el fac debio haberle asignado un improvement 
                if(response.data.IdImprovementPrevious == 6){               
                  location.reload();
                 }else{
                  const x = await this.askAgainforImprovement();
                  this.isOpenMarketDisabledBoard = false;
                 }
                

              } else if (response.data.improveStatus === 2 && (this.status == 3 || this.status == 0)) {
                console.log("improve==2 tons fac acepto opcion de team de no implementar debemos hacer reload")
                location.reload();
               /* this.isOpenMarketDisabledBoard = false;
                if (this.currentMonth >= 4) {
                  this.improvementsOptionsSelected.forEach(item => {
                    if (item.ImproveId == response.data.improveOptionId) {
                      item.Approved = 1;
                    }
                  });
                }*/
              }
            }
          }else if (response.status == 'reselectImprovement'){
            if (response.data.teamId == this.teamId) {
              this.improvementSelected = false
              if(this.currentMonth == 3){
                this.improvementsOptionsSelected[0] = {
                  "improvement": "",
                  "value": 0,
                  "ImproveId": -1,
                  "option": 0,
                  "question": "",
                  "Approved": 0
              }
              }else{
                this.improvementsOptionsSelected[1] = {
                  "improvement": "",
                  "value": 0,
                  "ImproveId": -1,
                  "option": 0,
                  "question": "",
                  "Approved": 0
              }
              }
              // console.log("Entrareselect",this.checkBoxes[0]);
              // this.checkBoxes[0].blnCheck = false;
              // console.log(this.improvementImplemented, this.improvementSelected, this.jsonBlnOpenModeActions, this.improvementsOptionsSelected)
              // this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
              this.checkCheckbox(0,false);
               Swal.fire({
                text: '',
                imageUrl: '../../../../assets/iconSuccessfully.svg',
                imageAlt: 'icon successfully',
                confirmButtonColor: '#5EA0CC',
                html: `You can reselect improvement`,
              });
            }
          }
        }
      })
  }

  async implementExpansionWithRawMaterial(machine: number) {
    let strMachine = machine == 1 ? 'first' : 'second';
    let blnAplied = machine == 1 ? 'isRawMaterials1' : 'isRawMaterials2';
    let originalString = this.getSingleTextLabel('8075')
    let modifiedString = originalString.replace('{{placeholder}}', 'RM');
    let stringImpru = "on Machine " + (strMachine == "first" ? "1" : "2")
    modifiedString = modifiedString.replace('{{placeholder}}', stringImpru);
    console.log(stringImpru);
    console.log(originalString);
    console.log(modifiedString);
    await Swal.fire({
      title: modifiedString,
      showDenyButton: true,
      allowEscapeKey: false,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      allowOutsideClick: false
    }).then(async (result) => {
      console.log(result);
      if (result.isConfirmed) {
        this.spinner.show('my-custom-spinner')
        this.socketService.setBlnControlModeOpen('isActiveImprovement', true, this.roomId, this.teamId);
      this.jsonBlnOpenModeActions.isActiveImprovement = true;
        this.jsonBlnOpenModeActions[blnAplied] = true;
        this.socketService.setBlnControlModeOpen(blnAplied, true, this.roomId, this.teamId);
        // this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = false
        this.checkCheckbox(4,false);
        this.currentImprovement = "Raw Materials"
        setTimeout(() => {
          this.cdkLstSuppliers.forEach(item => {
            item.type = 14;
            item.valor = 1;
          });
          if (this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isRawMaterials2) {
            for (let i = 0; i < 4; i++) {
              this.cdkLstSuppliers[i].type = 7;
              this.cdkLstSuppliers[i].valor = 3;
            }
          }
          this.socketService.splitCoin(this.cdkLstSuppliers, 'cdkLstSuppliers', this.roomId, this.teamId);
          setTimeout(() => {
            this.jsonBlnOpenModeActions['showBill2'] = true;
            this.socketService.setBlnControlModeOpen('showBill2', true, this.roomId, this.teamId);
              var position = 0;
              this.improvementsOptionsSelected.forEach((item, i) => {
                if (item.option == 1) {
                  position = i;
                  item.value = 3;
                }
              })
              this.improvementService.updateImproveOptionStatus(this.improvementsOptionsSelected[position].ImproveId, 3).subscribe(resp => {
                // this.improvementService.updateImprovementOptionByTeamId(this.improvementsOptionsSelected[position].ImproveId,this.improvementsOptionsSelected[position].option,2).subscribe(resp =>{
                console.log("fnSave", resp);
                if (resp['intResponse'] == 200) {
                  console.log("implementado");
                  this.improvementsOptionsSelected[position].value = 3;
                  setTimeout(() => {
                    this.socketImprovementService.updateImproveOption(this.teamId, ("roomWorkShop" + this.workshopId), this.improvementsOptionsSelected[position].option, this.improvementsOptionsSelected[position].ImproveId, 3)
                    setTimeout(() => {
                      setTimeout(() => {
                        this.spinner.hide('my-custom-spinner')
                        
                      }, 300);
                      this.socketImprovementService.updateImprovementOptionStatus(this.teamId, this.roomId, this.improvementsOptionsSelected[position].option, 3);
                    }, 500);
                  }, 750);
                }
              });
            
          }, 750);
        }, 500);
      } else if (result.isDenied) {
        // mandar confirmacion a facilitador
        this.notifyFacilitator(6);
        let position = 0;
        this.improvementsOptionsSelected.forEach((item, i) => {
          if (item.option == 1) {
            position = i;
            item.value = 3;
          }
        });
        console.log(this.improvementsOptionsSelected, position)
        this.improvementService.updateImproveOptionQuestion(this.improvementsOptionsSelected[position].ImproveId, 1).subscribe(resp => {
          console.log("fnSaveStatus", resp);
        })
        this.improvementService.updateImproveOptionStatus(this.improvementsOptionsSelected[position].ImproveId, 2).subscribe(resp => {
          // this.improvementService.updateImprovementOptionByTeamId(this.improvementsOptionsSelected[position].ImproveId,this.improvementsOptionsSelected[position].option,2).subscribe(resp =>{
          console.log("fnSave", resp);
          if (resp['intResponse'] == 200) {
            console.log("implementado");
            this.improvementsOptionsSelected[position].value = 2;
            this.socketImprovementService.updateImprovementOptionStatus(this.teamId, this.roomId, this.improvementsOptionsSelected[position].option, 2);
            if (this.currentMonth <= 3 || this.currentMonth >= 4 && !this.isModeOpen) {
              this.isOpenMarketDisabledBoard = true;
              setTimeout(() => {
                this.socketImprovementService.updateImproveOption(this.teamId, ("roomWorkShop" + this.workshopId), this.improvementsOptionsSelected[position].option, this.improvementsOptionsSelected[position].ImproveId, 2)
              }, 1500);
            }
          }
        });
      }
    }).catch(reason => {
      console.log(reason);
    });
  }

  async implementExpansionWithEfficiency(machine: number) {
    let strMachine = machine == 1 ? 'first' : 'second'
    let originalString = this.getSingleTextLabel('8075')
    let modifiedString = originalString.replace('{{placeholder}}', 'Efficiency');
    let stringImpru = "on Machine " + (strMachine == "first" ? "1" : "2")
    modifiedString = modifiedString.replace('{{placeholder}}', stringImpru);
    console.log(stringImpru);
    console.log(originalString);
    console.log(modifiedString);
    await Swal.fire({
      title: modifiedString,
      showDenyButton: true,
      allowEscapeKey: false,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      allowOutsideClick: false
    }).then(async (result) => {
      console.log(result)
      if (result.isConfirmed) {
        this.spinner.show('my-custom-spinner')
        this.socketService.setBlnControlModeOpen('isActiveImprovement', true, this.roomId, this.teamId);
      this.jsonBlnOpenModeActions.isActiveImprovement = true;
        setTimeout(() => {
          // if(strMachine == 'first'){
          //   this.checkBoxes[this.checkBoxes[0].title == "Clear Income Statement" ? 3 : 4].blnCheck = false
          // }
          this.checkCheckbox(4,false);
          this.currentImprovement = "Efficiency"
          let newArray = this.createArrayCoins(2, 1, 11);
          if (this.cdkLstCapEx.some(item => item.type == 4) && (this.jsonBlnOpenModeActions.isSoldBuild || this.cdkLstLandBuild.length == 0)) {
            newArray.push({
              valor: 20,
              id: null,
              draggable: false,
              type: 4,
            })
          }
          console.log(machine)
          this.socketService.splitCoin(newArray, 'cdkLstCapEx', this.roomId, this.teamId);
            var position = 0;
            console.log(position);

            this.improvementsOptionsSelected.forEach((item, i) => {
              if (item.option == 2) {
                position = i;
                item.value = 3;
              }
            })
            console.log(position)
            this.improvementService.updateImproveOptionStatus(this.improvementsOptionsSelected[position].ImproveId, 3).subscribe(resp => {
              // this.improvementService.updateImprovementOptionByTeamId(this.improvementsOptionsSelected[position].ImproveId,this.improvementsOptionsSelected[position].option,2).subscribe(resp =>{
              console.log("fnSave", resp);
              if (resp['intResponse'] == 200) {
                console.log("implementado");
                this.improvementsOptionsSelected[position].value = 3;
                setTimeout(() => {
                  this.socketImprovementService.updateImproveOption(this.teamId, ("roomWorkShop" + this.workshopId), this.improvementsOptionsSelected[position].option, this.improvementsOptionsSelected[position].ImproveId, 3)
                  setTimeout(() => {
                    setTimeout(() => {
                      this.spinner.hide('my-custom-spinner')
                    }, 300);
                    this.socketImprovementService.updateImprovementOptionStatus(this.teamId, this.roomId, this.improvementsOptionsSelected[position].option, 3);
                  }, 1000);
                }, 1500);
              }
            });
          
        }, 500);
      } else if (result.isDenied) {
        // mandar confirmacion a facilitador
        this.notifyFacilitator(5);
        let position = 0;
        console.log(this.improvementsOptionsSelected, position)

        this.improvementsOptionsSelected.forEach((item, i) => {
          if (item.option == 2) {
            position = i;
            item.value = 3;
          }
        });
        console.log(this.improvementsOptionsSelected, position)
        this.improvementService.updateImproveOptionQuestion(this.improvementsOptionsSelected[position].ImproveId, 1).subscribe(resp => {
          console.log("fnSaveStatus", resp);
        })
        this.improvementService.updateImproveOptionStatus(this.improvementsOptionsSelected[position].ImproveId, 2).subscribe(resp => {
          // this.improvementService.updateImprovementOptionByTeamId(this.improvementsOptionsSelected[position].ImproveId,this.improvementsOptionsSelected[position].option,2).subscribe(resp =>{
          console.log("fnSave", resp);
          if (resp['intResponse'] == 200) {
            console.log("implementado");
            this.improvementsOptionsSelected[position].value = 2;
            this.socketImprovementService.updateImprovementOptionStatus(this.teamId, this.roomId, this.improvementsOptionsSelected[position].option, 2);
            if (this.currentMonth <= 3 || this.currentMonth >= 4 && !this.isModeOpen) {
              this.isOpenMarketDisabledBoard = true;
              setTimeout(() => {
                this.socketImprovementService.updateImproveOption(this.teamId, ("roomWorkShop" + this.workshopId), this.improvementsOptionsSelected[position].option, this.improvementsOptionsSelected[position].ImproveId, 2)
              }, 1500);
            }
          }
        });

      }
    }).catch(reason => {
      console.log(reason);
    });
  }


  fnOpenChatComponent() {
    this.dialogRef = this._matDialog.open(ChatComponent, {

      width: '355px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        actualTeam: this.teamId,
        userId: this.userId,
        userName: this.jsnUserSelected[0].FirstName,
        roomId: this.roomId,
        roomWorkShopId: this.roomWorkShopId,
        teamName: this.TeamName,
        workshopId: this.workshopId,
        isFacilitator: this.blnFacilitador ? 1 : 0

      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG CHATT", resp);
      //validamos si cambió de team para recargar todo
      /*if (resp.jsnTeamSelected[0].TeamId != this.teamId) {
        
      }*/
      //this.jsnUserSelected = resp.jsnTeamSelected;

    })
  }


  fntakeControl() {
    console.log("solicitamos el control y vamos a consumir el socket");
    if (!this.blnAccessCUSTOMERISBS) {
      return;
    }
    this.socketService.disableControl(
      this.roomId,
      this.userId,
      this.userData.FirstName
    )
    //consumimos el socket para notificar al resto del team que ya tengo el control y  se los quitamos a ellos

  }
  checkTeams(){ //Funcion para saber si los equipos han pasado el mercado, si es así habilitamos la opcion para que el facilitador pueda ir al siguiente mes
    console.log();
    let teams
    this.teamsService.getAllTeamsById(this.workshopId).subscribe(response => {
      console.log(response,this.currentMonth)
      teams = response.teams; 
      let aux:any = teams.filter(x => x.Status < 14 || x.Month < this.currentMonth);
      console.log(aux);
      if (aux.length>0){
          let auxEquipos:string = "" 
          aux.forEach(element => {
            auxEquipos += " - " +element.Avatar
          }); 
          console.log(auxEquipos)
          Swal.fire({
            html: "All teams should complete the current's month market to go to the next month. <p>Incomplete Teams: <strong>"+auxEquipos+"</strong></p>",
            showCancelButton: false,
            showConfirmButton:true,
            confirmButtonText: ' &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp; ',
            confirmButtonColor: '#5EA0CC',
            allowOutsideClick: false,
            icon: 'warning'
          })
        }
    })
    
    
  }
  updateMonthFacilitator(month: number, isNext : boolean) {
    console.log(this.currentMonth);
        if (this.blnFacilitador) {
          if(month > this.currentMonth){
            this.monthAuxMonthByMonth = month - 1;
          }
          this.spinner.show();
          this.socketService.setIsMyCallTrue();
          this.boardService.updateMonthAndBoard(this.teamId, month).subscribe(async (response: any) => {
            if (response.board.intResponse == 200 && response.updateMonth.intResponse == 200) {
              this.blnGoToMonth = false;
              this.currentMonth = month;
              // this.fnUpdateOpenMarketByWorkshopId(this.workshopId, 0);
              //this.  = 0;
              //this.clearAreasDrag();
              //const x = await this.fnAssignPiecesArea(response.board.board);
              // this.checkBoxes.forEach(item => {
              //   item.blnCheck = false;
              // })
              this.boardService.getFacilitatorMonth(this.teamId).subscribe(response => {
                console.log(response);
                if(response['month'] != this.currentMonth){
                  this.monthFacilitator = response['month'];
                  // this.blnCanMoveFacilitator = true;
                  // console.log("NO PUEDE MOVER")
                  if(this.isProjectsEnableByCurrentMonth){
                    this.showCheckListFacilitator = true;
                    this.checkBoxesForFacilitator = this.checkBoxesForFacilitatorImprovement;
                    /*if(this.improvementsOptionsSelected.length == 0){
                      this.improvementsOptionsSelected.push({
                        improvement: '',
                        value: 0,
                        ImproveId: -1,
                        option: 0,
                        question: ''
                      })
                    } else if (this.improvementsOptionsSelected.length == 1 && (month == 4 || month ==5)){
                      this.improvementsOptionsSelected.push({
                        improvement: '',
                        value: 0,
                        ImproveId: -1,
                        option: 0,
                        question: ''
                      })
                    }  */
                    if (this.improvementsOptionsSelected.length == 0){
                      if ( this.currentMonth == 3) {
                        this.improvementsOptionsSelected.push({
                          improvement: '',
                          value: 0,
                          ImproveId: -1,
                          option: 0,
                          question: ''
                        });
                      }else if ( this.currentMonth == 4 || this.currentMonth ==5){
                        this.improvementsOptionsSelected.push({
                          improvement: '',
                          value: 0,
                          ImproveId: -1,
                          option: 0,
                          question: ''
                        });
                        this.improvementsOptionsSelected.push({
                          improvement: '',
                          value: 0,
                          ImproveId: -1,
                          option: 0,
                          question: ''
                        });
                      }
                      
                    }
                    else if (this.improvementsOptionsSelected.length == 1 && ( this.currentMonth == 4 || this.currentMonth ==5)){
                      this.improvementsOptionsSelected.push({
                        improvement: '',
                        value: 0,
                        ImproveId: -1,
                        option: 0,
                        question: ''
                      });
                      }
    
                  }else{
                    this.showCheckListFacilitator = true;
                    this.checkBoxesForFacilitator = this.checkBoxesForFacilitatorNoImprovement;
                    console.log(this.checkBoxesForFacilitator);
                  }
                }else{
                  // console.log("PUEDE MOVER")
                  // this.blnCanMoveFacilitator = false;
                  this.showCheckListFacilitator = false;
                }
              })
              this.spinner.hide();
            }
          })
        }
    
    
  }

  fnUpdateOpenMarketByWorkshopId(workshopId, blnOpen) {
    this.orderService.updateOpenMarketByWorkshopId(workshopId, blnOpen)
      .subscribe(response => {
        console.log("fnUpdateOpenMarketByWorkshopId", response);
      })
  }

  clearAreasDrag() {
    this.cdkLstTrash = [];
    this.cdkLstTrashCash = [];
    this.cdkLstTrashCapitalS = [];
    this.cdkLstBank = [];
    this.cdkLstCapEx = [];
    this.paidCapEx = [];
    this.paySuppliers = [];
    this.orders = [];
    this.cdkLstLabourP = [];
    this.cdkLstSuppliers = [];
    this.cdkLstMaterials = [];
    this.cdkLstLandBuild = [];
    this.cdkLstFinishedFI = [];
    this.cdkLstLoans90 = [];
    this.cdkLstLoans60 = [];
    this.cdkLstLoans30 = [];
    this.cdkLstLoans90Aux = [];
    this.cdkLstLoans60Aux = [];
    this.cdkLstLoans30Aux = [];
    this.cdkLstCash = [];
    this.cdkLstReceivables90 = [];
    this.cdkLstReceivables60 = [];
    this.cdkLstReceivables30 = [];
    this.totCdkLstReceivables30 = 0;
    this.totCdkLstReceivables60 = 0;
    this.totCdkLstReceivables90 = 0;
    this.cdkLstCapitalS = [];
    this.cdkLstRetainedE = [];
    this.cdkLstNetIncome = [];
    this.cdkLstTaxes = [];
    this.cdkLstIncomeBT = [];
    this.cdkLstInterest = [];
    this.cdkLstRent = [];
    this.cdkLstRecruitmentT = [];
    this.cdkLstAdminOver = [];
    this.cdkLstAdministration = [];
    this.cdkLstAdvertasing = [];
    this.cdkLstMarketingS = [];
    this.cdkLstChangeI = [];
    this.cdkLstDeprecation = [];
    this.cdkLstMachineOver = [];
    this.cdkRles = [];
    this.cdkLstCostGS = [];
    this.cdkLstLocationMachine = [];
    this.cdkLstInsideMachineCapex = [];
    this.cdkLstInsideMachinePeople = [];
    this.cdkLstInsideMachineWorkInProgress = [];
    this.cdkLstInsideMachineCapex2 = [];
    this.cdkLstInsideMachinePeople2 = [];
    this.cdkLstInsideMachineWorkInProgress2 = [];
    this.cdkLstInsideMachineCapex3 = [];
    this.cdkLstInsideMachinePeople3 = [];
    this.cdkLstInsideMachineWorkInProgress3 = [];
    this.cdkLstPayables60 = [];
    this.cdkLstPayables30 = [];
    this.cdkLstPayables60Aux = [];
    this.cdkLstPayables30Aux = [];
    this.cdkLstOther = [];
    this.lstPositions = [];
    this.lstPositionBefore = [];
  }

  returnTotalLst(lst: Array<any>) {
    let tot = 0;
    lst.forEach(item => {
      tot += item.valor;
    });
    return tot;
  }

  notifyFacilitator(mensajeId: number) {
    switch (mensajeId) {
      case 1:
        // pagar por adelantado
        console.log('pagar por adelantado');
        break;
      case 2:
        // vender edificio
        console.log('vender edificio');
        break;
      case 3:
        // seleccionar no improvement option
        console.log('seleccionar no improvement option');
        break;
      case 4:
        // no implementar improvement
        console.log('no implementar improvement');
        break;
      case 5:
        // no implementar efficiency en 2da maquina
        console.log('no implementar efficiency en 2da maquina');
        break;
      case 6:
        // no implementar raw materials en 2da maquina
        console.log('no implementar raw materials en 2da maquina');
        break;
    }
  }


  fnChangeLanguage() {


    this.dialogRef = this._matDialog.open(ChangeLanguageComponent, {
      width: '700px',
      height: '400px',
      panelClass: 'event-form-dialog',
      data: {
        actualLanguage: this.idLenguage,
        lstLanguages: this.lstLanguagesBoard,
        arrTextLabels: this.arrTextLabels,
      },
    });
    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG change board", resp);
      //validamos si cambió de team para recargar todo

      if (resp.jsnLangSelected[0].LanguageId != this.idLenguage) {
        console.log("IF LANGUAJE:", resp.jsnLangSelected[0]);
        const newLanguage = resp.jsnLangSelected[0];
        this.idLenguage = newLanguage.LanguageId;
        this.languageservice.setRtl(newLanguage["isRight"] == 1 ? true : false);
        console.log("ISRTL",this.languageservice.getRtl())
        this.getTextLabelsByLanguage(this.idLenguage);
        //vamos a insertar/guardar el registro de lenguaje seleccionado
        this.languageservice.fnInsUpdLanguageSelected({LanguageId : newLanguage.LanguageId, WorkshopId : this.workshopId, UserId : this.userId})
                  .subscribe(respLangSavedselected => {
                    console.log("resp languagesdataSelected:", respLangSavedselected);
                   
                    
                  })
      }



      //this.jsnUserSelected = resp.jsnTeamSelected;

    })
    //})
  }

  chargeotherLanguages(strLanguages) {//atrLanguages format like 1,5,n,
    console.log("recibimos strlanguages:", strLanguages);
    var arrintIdLangs = strLanguages.split(",");
    if (arrintIdLangs.length > 0) {
      for (let index = 0; index < (arrintIdLangs.length - 1); index++) {
        console.log("obteniendo datos del arrintIdLangs[x]", arrintIdLangs[index])
        this.languageservice.getDescByLanguageId(arrintIdLangs[index])
          .subscribe(response => {
            console.log("resp languagesdata:", response);
            this.lstLanguagesBoard.push(response["jsnAnswer"]);
            console.log("this.lstLanguagesBoard actual:", this.lstLanguagesBoard);
          })

      }
    }

  }

  fnopenFinantialReport() {
    this.teamService.getAllTeamsAndMembersById(this.workshopId).subscribe(resp => {
      console.log("fnGetAllTeamsAndMembers", resp);
      let lstTeams = [];
      if (this.blnFacilitador) {
        const facilitatorTeam = { Avatar: "Facilitator", TeamId: resp.facilitators[0].TeamId, }
        lstTeams.push(facilitatorTeam)
      }
      resp['teams'].forEach(element => {
        lstTeams.push(element.team)
      });
      let teamsMonth = lstTeams.filter(x => x.Month == this.currentMonth + 1);
      console.log(teamsMonth);
      let blnValidationForFac = true
      if(this.blnFacilitador && teamsMonth.length == lstTeams.length-1){ //Quiere decier que todos los teams terminaron su BS y falta el fac de cambiar de mes 
         blnValidationForFac = false;
      }
      console.log(blnValidationForFac)
      let totSales = 0;
      this.orders.forEach(item => {
        totSales += item.Bid;
      })
      console.log(this.status, this.currentMonth);
      // Obtenemos el paso correcto de intOpenMarket
      this.orderService.getOpenMarketByWorkShopId(this.workshopId).subscribe(response => {
        console.log(response, 'isOpenMarket finantialResport');
        if (response.intResponse == 200) {
          this.intOpenMarket = response.openMarket as number;
          console.log(this.fnAllTeamsBeforeMarket(lstTeams))
          this.dialogRef = this._matDialog.open(ReportsComponent, {
            width: '1000px',
            height: '690px',
            panelClass: 'event-form-dialog',
            disableClose: false,
          hasBackdrop: false,
            data: {
              languageId: this.idLenguage,
              teamId: this.teamId,
              currentMonth: this.intOpenMarket != 2 ? this.currentMonth - 1 : (!this.blnFacilitador && this.status < 14) || (this.blnFacilitador && this.currentMonth > 1 && this.fnAllTeamsBeforeMarket(lstTeams) && blnValidationForFac) ? this.currentMonth -1 : this.currentMonth,
              cycles: this.Cycles,
              workshopId: this.workshopId,
              canEdit: false,
              arrTextLabels: this.arrTextLabels,
              blnFacilitador: this.blnFacilitador,
              teamsList: lstTeams,
              actualTeam: this.teamId,
              totSales: totSales,
              control: this.isOpenMarketDisabledBoard,
              accessResults: this.blnAllowSeeResultTabs,
              userTeam: this.jsnUserSelected[0].WSUTeamId,
              status: this.status,
              intOpenMarket: this.intOpenMarket,
              businessModel: this.businessModel,
              currentMonthToResults: this.currentMonth,
              userName : this.userData['FirstName']

            },
            /* data: {
              teamsList: lstTeams,
              actualTeam: this.teamId,
              arrTextLabels: this.arrTextLabels,
          }*/
          });
    
          this.dialogRef.afterClosed().subscribe((resp) => {
            console.log("RETURNED DIALOG change board", resp);
            //validamos si cambió de team para recargar todo
            if (resp.jsnTeamSelected[0].TeamId == 0) {
    
            }
            else {
              if (resp.jsnTeamSelected[0].TeamId != this.teamId) {
                // this.blnAllowSeeResultTabs = resp.jsnTeamSelected[0].TeamId === this.jsnUserSelected[0].TeamId || this.blnFacilitador;
                //borramos los elementos de lainterfaz y reseteamos las banderas globales actuales
                let auxTeamId = this.teamId;
                console.log(auxTeamId,resp.jsnTeamSelected[0].TeamId)
                window.open("https://"+_IP+"/play/board/"+this.workshopId+"/view/"+resp.jsnTeamSelected[0].TeamId);
    
                // this.fnClearCurrentBoard().then(() => {
                //   console.log("vamos a matar los sockets de la actual sesión");
    
                //   this.spinner.show();
                //   this.socketService.closeListeners("room" + auxTeamId);
                //   /*this.destroy$.next(true);
                //   // Now let's also unsubscribe from the subject itself:
                //   this.destroy$.unsubscribe();*/
                //   console.log("Closing WS...");
                //   //this.socketService.terminarConexion(); 
                //   setTimeout(() => {
                //     console.log("despues matar sockets inicializamos el board con el nuevo team")
                //     this.fnCargaBoardbyTeam(resp.jsnTeamSelected[0].TeamId, true)
                //   }, 500);
    
                // });
    
    
              }
            }
    
    
            //this.jsnUserSelected = resp.jsnTeamSelected;
    
          })
        }
      });
      //
      


    })//fin obtener teams

  }

  fnAllTeamsBeforeMarket(data) {
    console.log('ENTRA fnAllTeamsBeforeMarket',data);
    const response = data
    .filter(item => item.Status !== undefined) 
    .every(item => item.Status < 14); 
    return response
  }

  fnopenINCOMEREPORT() {
    let totSales = 0;
    this.orders.forEach(item => {
      totSales += item.Bid;
    })
    this.dialogRef = this._matDialog.open(IncomestatementreportComponent, {
      disableClose: false,
      width: '930px',
      height: '590px',
      panelClass: 'event-form-dialog',
      data: {
        languageId: this.idLenguage,
        teamId: this.teamId,
        currentMonth: this.currentMonth,
        cycles: this.Cycles,
        workshopId: this.workshopId,
        totSales: totSales,
        canEdit: false,
        control: this.isOpenMarketDisabledBoard,
        arrTextLabels: this.arrTextLabels,
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      if (!resp) {
      }
    });

  }


  getTextLabelsByLanguage(languageId: number) {
    this.languageservice.getScreenLabelsByLanguage(languageId).subscribe(res => {
      this.arrTextLabels = res.textLabels ? res.textLabels : [];
    });
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp ? tmp.Label : '';
  }

  getErrorLabel(labelNumber: string, code: string | null = null): string {
    const tmp = this.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    let msg = ''
    if (tmp) {
      const LabelBase = tmp.LabelBase;
      let errorCode = code;
      if (!code) {
        const array = LabelBase.split(':');
        errorCode = array.length > 0 ? array[0] : '';
      }
      const className = environment.isProd ? 'lightgray' : 'gray';
      let lbl = (typeof tmp.Label) === 'string' ? tmp.Label.replaceAll(new RegExp('=', 'g'), '<span style="font-family: Calibri">=</span>') : tmp.Label;
      lbl = (typeof lbl) === 'string' && (tmp.LabelNumber === '1003' || tmp.LabelNumber === '1006') ? lbl.replaceAll(new RegExp('[ ]{2,}', 'g'), '<br>') : tmp.Label;
      msg = `<div style="text-align: left;"><span style="color:${className}; font-size: 12px">${errorCode}: </span>${lbl}</div>`;
    }
    return msg;
  }

  AssingControl() {
    if (this.teamId == this.teamIdOriginal) {//solo hizo reload, osea que pertenece al mismo team, se aplican las validaciones de COO o facilitador
      //depues de la carga de piezas en el board validamos si el user tiene el rol de CFO que es el asignado para poder controlar el BOARD al inicio
      this.orderService.getValidationControlRol(this.userId, this.teamId).subscribe(response => {
        console.log("respuesta de fn getValidationControlRol", response, this.isOpenMarketDisabledBoard, this.blnGrowNocontrol);
        if (response.intResponse == 200) {
          if(this.blnGrowNocontrol == true){
            this.isFacilitatorTeamAccount = response.isFacilitatorAccount;
            this.isOpenMarketDisabledBoard = response.IsCFO > 0 && this.statusBeforeMonth != 0 ? false : true;
            this.blnGrowNocontrol = response.IsCFO > 0 && this.statusBeforeMonth != 0 ? false : true;
          }     
          if(!this.blnGrowNocontrol && (this.status == 0 || this.status == -1)) { //Quitar bloqueo al iniciar el mes al que tiene el control
            this.isOpenMarketDisabledBoard = false;
          }
          if (response.IsCFO > 0) {
            this.fntakeControl();
          }
          console.log("blnGrowNocontrol true i am COO;;;;;;");
          //this.fntakeControl();
          console.log("respuesta de fn getValidationControlRol", response, this.isOpenMarketDisabledBoard, this.blnGrowNocontrol);

        }
        else {
          this.isOpenMarketDisabledBoard = true;
          this.blnGrowNocontrol = true;
          console.log("blnGrowNocontrol true;;;;;;");
        }
        if (this.blnFacilitador || response.memberCount === 1) {
          this.isOpenMarketDisabledBoard = false;
          this.blnGrowNocontrol = false;
          console.log("blnGrowNocontrol false facilitator;;;;;;");
        }

        /* if( response.IsCFO <= 0 ){
           this.blnGrowNocontrol = true;
           console.log("blnGrowNocontrol true;;;;;;");
         }*/
      });
    } else {
      //significa que es un usuario  que se está cambiando de equipo y no padrá mover nada
      this.isOpenMarketDisabledBoard = true;
      this.blnGrowNocontrol = true;
      //adicionalmente validamos si es un tipo observer que edita o un facilitador si podrá solicitar el control
      //sino es esos tipos, es un member de otro equipo que intenta entrar al team y se bloquean los IS Blancesheet customer etc

      if (this.jsnUserSelected[0].type != 4 && !this.blnFacilitador) {
        this.blnAccessCUSTOMERISBS = false;
        this.blnGrowNocontrol = false;
      }
    }
  }

  fnClickStandardBalanceSheetREPORT() {
    console.log("this.statusBeforeMonth:", this.statusBeforeMonth);
    // Proceso para llenado de  Standard balance sheet aqui
    this.dialogRef = this._matDialog.open(BalancesheetreportComponent, {
      disableClose: false,
      width: '777px',
      height: '860px',
      panelClass: 'event-form-dialog',
      data: {
        languageId: this.idLenguage,
        teamId: this.teamId,
        currentMonth: this.currentMonth,
        cycles: this.Cycles,
        workshopId: this.workshopId,
        canEdit: false,
        arrTextLabels: this.arrTextLabels,
      },
    });


    this.dialogRef.afterClosed().subscribe((resp) => {
    })
  }

  mapCheckboxTitleToLabel(title: string): string {
    switch (title) {
      case 'Clear Income Statement': return this.getSingleTextLabel('4004');
      case 'Update Receivables': return this.getSingleTextLabel('4005');
      case 'Update Liabilities': return this.getSingleTextLabel('4006');
      case 'Buy Raw Materials': return this.getSingleTextLabel('4010');
      case 'Load Raw Materials to machine': return this.getSingleTextLabel('4012');
      case 'Pay Labor': return this.getSingleTextLabel('4014');
      case 'Take Depreciation': return this.getSingleTextLabel('4015');
      case 'Pay Expenses': return this.getSingleTextLabel('4016');
      case 'Prepare Bids': return this.getSingleTextLabel('4029');
      case 'Pay Advertising': return this.getSingleTextLabel('4017');
      case 'The Market': return this.getSingleTextLabel('4018');
      case 'Take Product from Machine': return this.getSingleTextLabel('4019');
      case 'Make Delivery & Recieve Payment': return `${this.getSingleTextLabel('4020')} & ${this.getSingleTextLabel('4022')}`;
      case 'Complete the Income Statement': return this.getSingleTextLabel('4024');
      case 'Adjust Retained Earnings': return this.getSingleTextLabel('4025');
      case 'Adjust Retained Earnings*(Black Chips)': return this.getSingleTextLabel('4025');
      case 'Complete the Balance Sheet': return this.getSingleTextLabel('4026');
      case 'Select Improvement Option': return this.getSingleTextLabel('4030');
      case 'Buy or Upgrade Equipment': return this.getSingleTextLabel('4008');
      case 'Pay Recruitment & Training': return this.getSingleTextLabel('4009');
      default: return title;
    }
  }

  fnOpenHelpDialog(type: 'operating income' | 'income statement' | 'balance sheet') {
    this.dialogRef = this._matDialog.open(DialogHelpInfoComponent, {
      panelClass: 'event-form-dialog',
      width: type === 'operating income' ? 'auto' : '1093px',
      data: {
        type,
        arrTextLabels: this.arrTextLabels,
      },
    });
  }

  fnOpenLearningSlides() {
    this.dialogRef = this._matDialog.open(LearningSlidesComponent, {
      panelClass: 'event-form-dialog',
      width: '1200px',
      height: '600px',
      data: {
        currentMonth: this.currentMonth,
        arrTextLabels: this.arrTextLabels,
        boardMode: this.boardMode,
        cycles: this.Cycles,
        status: this.status,
        blnFacilitator: this.blnFacilitador
      },
    });
  }

  fnScrollCheckList(checkBox: { id: number, blnCheck: boolean, title: string, idBd?: number, disabled: boolean }) {
    if (!this.isModeOpen) {
      const nextCheck = this.checkBoxes.find(check => check.id === checkBox.id - 1);
      const nameToScroll = 'checkBoxScroll-' + (nextCheck ? nextCheck.id : 1);
      const containerId = 'checkList-Scrollable';
      let elements = document.getElementsByClassName(nameToScroll);
      let element = elements[0] as HTMLElement;
      if (checkBox.title === 'Buy or Upgrade Equipment' || checkBox.title === 'Pay Recruitment & Training') {
        elements = document.getElementsByClassName('checkBoxScroll-4');
        if (elements.length >= 2) {
          element = elements[1] as HTMLElement;
        }
      }
      const container = document.getElementById(containerId);
      if (element && container) {
        const topOffset = element.offsetTop - element.offsetHeight;
        container.scrollTop = topOffset;
      }
    }
  }

  totRoyals(): number {
    let count = 0;
    this.cdkLstFinishedFI.forEach((item) => {
      if (item.type == 8) {
        count += (item.valor / 3);
      } else if (item.type == 16) {
        count += item.valor / 4;
      } else if (item.type == 17) {
        count += item.valor / 5;
      } else if (item.type == 18) {
        count += item.valor / 3;
      }
    });
    this.cdkLstInsideMachineWorkInProgress.forEach((item) => {
      if (item.type == 8) {
        count += (item.valor / 3);
      } else if (item.type == 16) {
        count += item.valor / 4;
      } else if (item.type == 17) {
        count += item.valor / 5;
      } else if (item.type == 18) {
        count += item.valor / 3;
      }
    });
    this.cdkLstInsideMachineWorkInProgress2.forEach((item) => {
      if (item.type == 8) {
        count += (item.valor / 3);
      } else if (item.type == 16) {
        count += item.valor / 4;
      } else if (item.type == 17) {
        count += item.valor / 5;
      } else if (item.type == 18) {
        count += item.valor / 3;
      }
    });
    return count;
  }

  async calcularMaxSupply(): Promise<number> {
    let count = 0;
    if (this.jsonBlnOpenModeActions.isSecondMachine && this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isEfficiency2) {
      count = 12;
    } else if (this.jsonBlnOpenModeActions.isSecondMachine && (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2)) {
      count = 10;
    } else if (this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isEfficiency1 && !this.jsonBlnOpenModeActions.isEfficiency2) {
      count = 8;
    } else if (!this.jsonBlnOpenModeActions.isSecondMachine && (this.jsonBlnOpenModeActions.isEfficiency1 || this.jsonBlnOpenModeActions.isEfficiency2)) {
      count = 6;
    } else if (!this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isEfficiency1 && !this.jsonBlnOpenModeActions.isEfficiency2) {
      count = 4;
    }
    const response = await this.teamService.getAllTeamsById(this.workshopId).toPromise();
    if (response.intResponse === 200) {
      const team = response.teams.find(el => el.TeamId === this.teamId);
      if (team) {
        count += team.numberRoyals ? team.numberRoyals : 0;
      }
    }
    return count;
  }

  calcularRoyalsEnSupply(): number {
    let count = 0;
    this.cdkLstFinishedFI.forEach((item) => {
      if (item.type == 8) {
        count += (item.valor / 3);
      } else if (item.type == 16) {
        count += item.valor / 4;
      } else if (item.type == 17) {
        count += item.valor / 5;
      } else if (item.type == 18) {
        count += item.valor / 3;
      }
    });
    this.cdkLstInsideMachineWorkInProgress.forEach((item) => {
      if (item.type == 8) {
        count += (item.valor / 3);
      } else if (item.type == 16) {
        count += item.valor / 4;
      } else if (item.type == 17) {
        count += item.valor / 5;
      } else if (item.type == 18) {
        count += item.valor / 3;
      }
    });
    this.cdkLstInsideMachineWorkInProgress2.forEach((item) => {
      if (item.type == 8) {
        count += (item.valor / 3);
      } else if (item.type == 16) {
        count += item.valor / 4;
      } else if (item.type == 17) {
        count += item.valor / 5;
      } else if (item.type == 18) {
        count += item.valor / 3;
      }
    });
    this.cdkLstCostGS.forEach((item) => {
      if (item.type == 8) {
        count += (item.valor / 3);
      } else if (item.type == 16) {
        count += item.valor / 4;
      } else if (item.type == 17) {
        count += item.valor / 5;
      } else if (item.type == 18) {
        count += item.valor / 3;
      }
    });
    return count;
  }

  goToMarket(): Promise<any> {
    return new Promise(async (resolve, reyect) => {
      if (this.status < 29 && this.currentMonth == 1) {
        // /*this.boardService.goToMarketMonth1(this.teamId, +this.workshopId).subscribe((response: any) => {
        //   console.log(response);
        //   if (response.intResponse == 200) {
        //     resolve(true);
        //   } else {
        //     resolve(false);
        //   }
        // });*/
        this.socketService.magicBtnDemo(this.workshopId, this.teamId);
      } else if (this.status < 10 && this.currentMonth > 1 && this.status >= 0) {
        if (this.status >= 1) {
          this.fnTrowToastwithTimeMiliseconds('#errorGeneral', 'Is not possible go to market, some steps have already been completed before the market', 3000);
          resolve(false);
          return;
        }
        const x = await this.goToMarketMonth2();
        resolve(true);
      } else if (this.status == -1) {
        this.fnTrowToastwithTimeMiliseconds('#errorGeneral', 'Please select improvement', 3000);
        resolve(false);
      } else {
        resolve(false);
      }
    });
  }

  goToMarketMonth2(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      console.log("Entra aqui")
      if (this.status <= 0) {
        // primer paso, limpiamos las monedas
        const clean = await this.cleanSGA();
        this.boardService.storeLiabilitiesReceivablesUpdates(this.workshopId, this.teamId).subscribe(lrRes=>{
          if (lrRes.intResponse === 200) {
            this.liabilitiesReceivablesBeforeUpdates = lrRes.jsonData;
          }
        });
      }
      if (this.status <= 1) {
        // segundo paso actualizamos receivables
        const actualizacion1 = await this.movePieces(this.cdkLstReceivables30, 'cdkLstReceivables30', this.cdkLstCash, 'cdkLstCash');
        const actualizacion2 = await this.movePieces(this.cdkLstReceivables60, 'cdkLstReceivables60', this.cdkLstReceivables30, 'cdkLstReceivables30');
        const actualizacion3 = await this.movePieces(this.cdkLstReceivables90, 'cdkLstReceivables90', this.cdkLstReceivables60, 'cdkLstReceivables60');
      }
      if (this.status <= 2) {
        // tercer paso actualizamos loans
        const actualizacion1 = await this.movePieces(this.cdkLstLoans60, 'cdkLstLoans60', this.cdkLstLoans30, 'cdkLstLoans30');
        const actualizacion2 = await this.movePieces(this.cdkLstLoans90, 'cdkLstLoans90', this.cdkLstLoans60, 'cdkLstLoans60');

        const actualizacion3 = await this.movePieces(this.cdkLstPayables60, 'cdkLstPayables60', this.cdkLstPayables30, 'cdkLstPayables30');
        // pendiente pago de loans cuando lleguen a 30 y se deban pagar
      }

      if (this.Cycles == 4 && (this.currentMonth == 3 || this.currentMonth == 4)) {
        const implementar = await this.implementImprovementMagicButton();
      }

      if (this.status <= 3) {
        // se compran los materiales a credito
        const comprar = await this.buyRawMaterialsMagicButton();
      }

      if (this.status <= 6) {
        this.spinner.show();
        // se mueven los materiales de RM a WIP
        const load = await this.loadMaterialsToWIP();
      }

      if (this.status <= 7) {
        this.spinner.show();
        // se pagan a los trabajadores
        const pago = await this.convertRoyals();
      }

      if (this.status <= 8) {
        this.spinner.show();
        // se deprecia la maquina
        const pago = await this.payDepreciation();
      }

      if (this.status <= 9) {
        this.spinner.show();
        // se pagan los expenses
        const pago = await this.payExpenses();
      }




      resolve(true);
    });
  }

  delay(miliseconds: number){
    return new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve(true);
      }, miliseconds);
    });
  }

  cleanSGA(): Promise<boolean> {
    return new Promise(async(resolve, reject) => {
      let countStacks = 0;
      let d;
      this.cdkLstMachineOver = [];
      this.socketService.splitCoin([],"cdkLstMachineOver",this.roomId, this.teamId);
      d = await this.delay(700);
      this.cdkLstDeprecation = [];
      this.socketService.splitCoin([],"cdkLstDeprecation",this.roomId, this.teamId);
      d = await this.delay(700);
      for (let i = 0; i < this.cdkLstChangeI.length; i++) {
        if (this.cdkLstChangeI[i].type == 1) {
          countStacks++;
          d = await this.delay(i * 700);
          this.socketService.deleteCoin('cdkLstChangeI', this.cdkLstChangeI[i], this.roomId, this.teamId);
        }
      }
      for (let i = 0; i < this.cdkLstMarketingS.length; i++) {
        if (this.cdkLstMarketingS[i].type == 1) {
          countStacks++;
          d = await this.delay(i * 700);
            this.socketService.deleteCoin('cdkLstMarketingS', this.cdkLstMarketingS[i], this.roomId, this.teamId);
        }
      }
      for (let i = 0; i < this.cdkLstAdministration.length; i++) {
        if (this.cdkLstAdministration[i].type == 1) {
          countStacks++;
          d = await this.delay(i * 700);
          this.socketService.deleteCoin('cdkLstAdministration', this.cdkLstAdministration[i], this.roomId, this.teamId);
        }
      }
      this.cdkLstAdvertasing = [];
      this.socketService.splitCoin([],"cdkLstAdvertasing",this.roomId, this.teamId);
      d = await this.delay(700);
      this.cdkLstAdminOver = [];
      this.socketService.splitCoin([],"cdkLstAdminOver",this.roomId, this.teamId);
      d = await this.delay(700);
      this.cdkLstRecruitmentT = [];
      this.socketService.splitCoin([],"cdkLstRecruitmentT",this.roomId, this.teamId);
      d = await this.delay(700);
      this.cdkLstRent = [];
      this.socketService.splitCoin([],"cdkLstRent",this.roomId, this.teamId);
      d = await this.delay(700);
      this.cdkLstInterest = [];
      this.socketService.splitCoin([],"cdkLstInterest",this.roomId, this.teamId);
      d = await this.delay(700);
      this.cdkLstCostGS = [];
      this.socketService.splitCoin([],"cdkLstCostGS",this.roomId, this.teamId);
      d = await this.delay(700);
      
      for (let i = 0; i < this.orders.length; i++) {
        countStacks++;
        d = await this.delay(i * 700);
        this.socketService.deleteOrder(this.orders[i], this.roomId, this.teamId);
      }

      setTimeout(() => {
        resolve(true);
      }, (countStacks+10) * 700);
    });
  }

  implementImprovementMagicButton(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const x = await this.implementImprovement();
      setTimeout(async () => {
        let position = this.currentMonth == 3 ? 0 : 1;
        if (this.improvementsOptionsSelected[position].improvement == 'Customizing') {
          this.cdkLstCapEx.forEach(item => {
            if (item.type == 10) {
              this.socketService.deleteCoin('cdkLstCapEx', item, this.roomId, this.teamId);
            }
          });
          await this.collectMoney(10);
          setTimeout(async () => {
            this.jsonBlnOpenModeActions.isCustomizingShow = true;
            this.socketService.setBlnControlModeOpen('isCustomizingShow', true, this.roomId, this.teamId);
            const x = await this.movePieces(this.cdkLstCapEx, 'cdkLstCapEx', this.cdkLstInsideMachineCapexCustomizing, 'cdkLstInsideMachineCapexCustomizing');
            setTimeout(() => {
              resolve(true);
            }, 1000);
          }, 1500);
        } else if (this.improvementsOptionsSelected[position].improvement == 'Quality') {
          this.movePieces(this.cdkLstLabourP, 'cdkLstLabourP', this.cdkLstChangeI, 'cdkLstChangeI');
          setTimeout(async () => {
            this.socketService.createCoin('cdkLstRecruitmentT', {
              valor: 1,
              id: null,
              draggable: false,
              type: 1,
            }, this.roomId, this.teamId);
            const x = await this.collectMoney(1);
            setTimeout(() => {
              this.jsonBlnOpenModeActions['showBill1'] = false;
              this.socketService.setBlnControlModeOpen('showBill1', false, this.roomId, this.teamId);
              setTimeout(() => {
                resolve(true);
              }, 1000);
            }, 1000);
          }, 1500);
        } else if (this.improvementsOptionsSelected[position].improvement == 'Raw Materials') {
          this.socketService.createCoin('cdkLstMachineOver', {
            valor: 2,
            id: null,
            draggable: false,
            type: 1,
          }, this.roomId, this.teamId);
          const x = await this.collectMoney(2);
          setTimeout(() => {
            this.jsonBlnOpenModeActions['showBill2'] = false;
            this.socketService.setBlnControlModeOpen('showBill2', false, this.roomId, this.teamId);
            setTimeout(() => {
              resolve(true);
            }, 1000);
          }, 1000);
        } else {
          resolve(true);
        }
      }, 1500);
    });
  }

  buyRawMaterialsMagicButton(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let tipo = this.cdkLstSuppliers[0].type;
      let cantidad = this.returnTotalLst(this.cdkLstSuppliers);
      this.cdkLstSuppliers = [{
        valor: cantidad,
        id: null,
        draggable: false,
        type: tipo,
      }];
      this.socketService.splitCoin(this.cdkLstSuppliers, 'cdkLstSuppliers', this.roomId, this.teamId);

      setTimeout(async () => {
        let actualizacion = await this.movePieces(this.cdkLstSuppliers, 'cdkLstSuppliers', this.cdkLstMaterials, 'cdkLstMaterials');

        if (cantidad > 20) {
          this.socketService.createCoin('cdkLstPayables60', {
            valor: cantidad / 2,
            id: null,
            draggable: false,
            type: 3,
          }, this.roomId, this.teamId);
          setTimeout(() => {
            this.socketService.createCoin('cdkLstPayables60', {
              valor: cantidad / 2,
              id: null,
              draggable: false,
              type: 3,
            }, this.roomId, this.teamId);
          }, 400);
        } else {
          this.socketService.createCoin('cdkLstPayables60', {
            valor: cantidad,
            id: null,
            draggable: false,
            type: 3,
          }, this.roomId, this.teamId);
        }

        setTimeout(() => {
          resolve(true);
        }, 800);
      }, 2000);
    });
  }

  loadMaterialsToWIP(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let tipo = this.cdkLstMaterials[0].type;
      let cantidad = this.returnTotalLst(this.cdkLstMaterials);
      if (cantidad <= 18 && tipo == 7 || cantidad <= 6 && tipo == 14) {
        this.movePieces(this.cdkLstMaterials, 'cdkLstMaterials', this.cdkLstInsideMachineWorkInProgress, 'cdkLstInsideMachineWorkInProgress')
      } else {
        this.socketService.createCoin('cdkLstInsideMachineWorkInProgress', {
          valor: cantidad / 2,
          id: null,
          draggable: false,
          type: tipo,
        }, this.roomId, this.teamId);
        setTimeout(() => {
          this.socketService.createCoin('cdkLstInsideMachineWorkInProgress2', {
            valor: cantidad / 2,
            id: null,
            draggable: false,
            type: tipo,
          }, this.roomId, this.teamId);
          setTimeout(() => {
            this.socketService.deleteCoin('cdkLstSuppliers', this.cdkLstSuppliers[0], this.roomId, this.teamId);
          }, 500);
        }, 500);
      }
      setTimeout(() => {
        resolve(true);
      }, 2500);
    });
  }

  convertRoyals(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.jsonBlnOpenModeActions.isSecondMachine) {
        // nada mas tenemos 1 maquina
        this.cdkLstInsideMachineWorkInProgress.forEach(item => {
          if (this.jsonBlnOpenModeActions.isEfficiency1) {
            if (this.jsonBlnOpenModeActions.isRawMaterials1) {
              let t = item.valor / 1;
              item.valor = t * 3;
              item.type = 18;
            } else {
              let t = item.valor / 3;
              item.valor = t * 5;
              item.type = 17;
            }
          } else {
            if (this.jsonBlnOpenModeActions.isRawMaterials1) {
              let t = item.valor / 1;
              item.valor = t * 4;
              item.type = 16;
            } else {
              item.type = 8;
            }
          }
        });
        this.socketService.splitCoin(this.cdkLstInsideMachineWorkInProgress, 'cdkLstInsideMachineWorkInProgress', this.roomId, this.teamId);
        setTimeout(async () => {
          const x = await this.collectMoney(12);
          setTimeout(() => {
            resolve(true);
          }, 1500);
        }, 1500);
      }
    });
  }

  payDepreciation(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.socketService.createCoin('cdkLstDeprecation', {
        valor: 1,
        id: null,
        draggable: false,
        type: 4,
      }, this.roomId, this.teamId);
      setTimeout(() => {
        this.cdkLstInsideMachineCapex[0].valor = this.cdkLstInsideMachineCapex[0].valor - 1;
        if (this.jsonBlnOpenModeActions.isSecondMachine) {
          this.cdkLstInsideMachineCapex2[0].valor = this.cdkLstInsideMachineCapex2[0].valor - 1;
          this.socketService.createCoin(this.cdkLstDeprecation, {
            valor: 1,
            id: null,
            draggable: false,
            type: 4,
          }, this.roomId, this.teamId);
          this.socketService.splitCoin(this.cdkLstInsideMachineCapex2, 'cdkLstInsideMachineCapex2', this.roomId, this.teamId);
        }
        this.socketService.splitCoin(this.cdkLstInsideMachineCapex, 'cdkLstInsideMachineCapex', this.roomId, this.teamId);
        setTimeout(() => {
          resolve(true);
        }, 1000);
      }, 500);
    });
  }

  payExpenses(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let total = this.cdkLstChangeI.length + this.cdkLstMarketingS.length + this.cdkLstAdministration.length + this.cdkLstAdminOver.length + 1;
      this.socketService.payGrossProfit(['cdkLstChangeI', 'cdkLstMarketingS', 'cdkLstAdministration', 'cdkLstAdminOver'],
        [this.cdkLstChangeI.length, this.cdkLstMarketingS.length, this.cdkLstAdministration.length, this.cdkLstAdminOver.length + 1], {
        valor: total,
        id: null,
        draggable: false,
        type: 1,
      }, this.roomId, this.teamId, this.userId);
      this.socketService.createCoin('cdkLstMachineOver', {
        valor: 1,
        id: null,
        draggable: false,
        type: 1,
      }, this.roomId, this.teamId);
      total += 1;
      setTimeout(async () => {
        const x = await this.collectMoney(total);
        setTimeout(() => {
          for (let i = 0; this.checkBoxes[i].title != "Prepare Bids"; i++) {
            this.checkBoxes[i].blnCheck = true;
          }
          this.checkBoxes.forEach(checkBox => {
            if (checkBox.title === 'The Market' || checkBox.title === 'Prepare Bids') {
              checkBox.blnCheck = false;
            }
          });
          this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          setTimeout(() => {
            this.status = 10;
            this.socketService.updateStatus(this.roomId, this.teamId, 10, this.currentMonth);
            resolve(true);
          }, 1000);
        }, 1000);
      }, 1000);
    });
  }

  goToNextMonth(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.cdkLstAdvertasing.length > 0) {
        this.fnTrowToastwithTimeMiliseconds('#errorGeneral', 'Is not possible go to the next month, some steps have already been completed after the market', 3000);
        resolve(false);
        return;
      }
      // if (this.currentMonth == 1 && this.boardMode == "NM") {
      const respOpenMarket = await this.orderService.getOpenMarketByWorkShopId(this.workshopId).toPromise();
      if (respOpenMarket.intResponse == 200) {
        this.intOpenMarket = respOpenMarket.openMarket as number;
      }
      if (this.intOpenMarket == 2 && this.currentMonth == 1 && this.boardMode == "NM") {
        // const resp = await this.orderService.getOrdersByWorkShopId(this.workshopId).toPromise();
        // let arrOrders = resp['orders'].filter(el => {
        //   el.Winers = [];
        //   if (el.Month == 1)//view board
        //     return el;
        // });
        // let blnContinue = false;
        // arrOrders.forEach(order => {
        //   order.blnStatusOrder = false;
        //   order.Bids.push({Bid:0,BidId:0,OrderId:order.OrderId,TeamId:0,intStatusTeam:0,blnNotSupplies:false});
        //   order.Bids.forEach(bid =>{ 
        //     bid.intStatusTeam = 0;
        //     bid.blnNotSupplies = false;
        //     if(this.teamId === bid['TeamId']){
        //       blnContinue = true;
        //     }
        //   });
        // });
        // if(blnContinue){
        console.log("AQUI --- AL FINALIZAR MES CON MAGIC BUTTONS")
        
          this.checkBoxes = [
            { id: 0, blnCheck: false, title: "Select Improvement Option", idBd: null, disabled: true, show: 0 },
            { id: 1, blnCheck: false, title: "Clear Income Statement", idBd: null, disabled: true, show: 1 },
            { id: 2, blnCheck: false, title: "Update Receivables", idBd: null, disabled: true, show: 1 },
            { id: 3, blnCheck: false, title: "Update Liabilities", idBd: null, disabled: true, show: 1 },
            { id: 4, blnCheck: false, title: "Buy or Upgrade Equipment", idBd: null, disabled: true, show: 0 },
            { id: 5, blnCheck: false, title: "Pay Recruitment & Training", idBd: null, disabled: true, show: 0 },
            { id: 6, blnCheck: false, title: "Buy Raw Materials", idBd: null, disabled: true, show: 1 },
            { id: 7, blnCheck: false, title: "Load Raw Materials to machine", idBd: null, disabled: true, show: 1 },
            { id: 8, blnCheck: false, title: "Pay Labor", idBd: null, disabled: true, show: 1 },
            { id: 9, blnCheck: false, title: "Take Depreciation", idBd: null, disabled: true, show: 1 },
            { id: 10, blnCheck: false, title: "Pay Expenses", idBd: null, disabled: true, show: 1 },
            { id: 11, blnCheck: false, title: "Prepare Bids", idBd: null, disabled: true, show: 1 },
            { id: 12, blnCheck: false, title: "Pay Advertising", idBd: null, disabled: true, show: 1 },
            { id: 13, blnCheck: false, title: "The Market", idBd: null, disabled: true, show: 1 },
            { id: 14, blnCheck: false, title: "Take Product from Machine", idBd: null, disabled: true, show: 1 },
            { id: 15, blnCheck: false, title: "Make Delivery & Recieve Payment", idBd: null, disabled: true, show: 1 },
            { id: 16, blnCheck: false, title: "Complete the Income Statement", idBd: null, disabled: true, show: 1 },
            { id: 17, blnCheck: false, title: "Adjust Retained Earnings", idBd: null, disabled: true, show: 1 },
            { id: 18, blnCheck: false, title: "Complete the Balance Sheet", idBd: null, disabled: true, show: 1 }
          ];
          this.checkBoxes.forEach(item => {
            item.blnCheck = false;
          });
          this.boardService.createUpdCheckList(this.teamId, this.checkBoxes).subscribe(response => {
            console.log('response update check list mes 2', response);
            this.checkBoxes = response.checkList as Array<any>;
            this.socketService.updateCheckList(JSON.stringify(this.checkBoxes), this.roomId, this.teamId);
          });
        
        
        const x = await this.incomeService.newRegister(this.workshopId, this.teamId, this.Cycles).toPromise();
        const z = await this.balanceService.newRegister(this.workshopId, this.teamId, this.Cycles).toPromise();
        if(this.blnFacilitador){
          this.boardService.updateFacilitatorMonth(this.teamId,this.currentMonth+1).subscribe(response => {
            console.log(response);
          }) 
        }
        /*this.boardService.goToFinishMonth1(this.teamId, this.workshopId).subscribe((response: any) => {
          console.log(response);
          if (response.intResponse == 200) {
            resolve(true);
          } else {
            resolve(false);
          }
        });*/
        await this.socketService.magicBtnFinishNormal(this.workshopId, this.teamId, this.businessModel);
        this.checkBoxes.forEach(checkBox => checkBox.blnCheck = false);
        resolve(true);
        
        // } else {
        //   resolve(false);
        // }
      } else if (this.intOpenMarket == 2 && this.status >= 10) {
        if(this.blnFacilitador){
          this.boardService.updateFacilitatorMonth(this.teamId,this.currentMonth+1).subscribe(response => {
            console.log(response);
          }) 
        }
        
        //primer paso, cobrar advertising
        const adv = await this.payAdvertising();
        if(adv) {
          this.funcionBack("caseAdvertasting")
        }
        // segundo paso, mover los royals de machine a fgi
        const royals1 = await this.movePieces(
          this.cdkLstInsideMachineWorkInProgress,
          'cdkLstInsideMachineWorkInProgress',
          this.cdkLstFinishedFI,
          'cdkLstFinishedFI'
        );
        const royals2 = await this.movePieces(
          this.cdkLstInsideMachineWorkInProgress2,
          'cdkLstInsideMachineWorkInProgress2',
          this.cdkLstFinishedFI,
          'cdkLstFinishedFI'
        );
        this.cdkLstInsideMachineWorkInProgress = []
        this.cdkLstInsideMachineWorkInProgress2 = []
        // tercer paso mover la cantidad necesaria a COGS
        let quantity = 0;
        let totalSale = 0;
        this.orders.forEach(item => {
          quantity += item.Quantity;
          totalSale += item.Bid;
        });
        const moveRoyalsToCOGS = await this.moveRoyalsToCOGS(quantity);
        // cuarto paso, hacer deliver de las ordenes
        const order = await this.deliverOrders();
        if (this.currentMonth == 1 && this.status < 34 || this.currentMonth >= 2 && this.status >= 10) {
          // quinto paso, llenar income
          const llenar = await this.llenarIncome(totalSale);
        } else if (this.cdkLstNetIncome.length > 0) {
          const mover = await this.movePieces(this.cdkLstNetIncome, 'cdkLstNetIncome', this.cdkLstRetainedE, 'cdkLstRetainedE');
        }
        if (this.currentMonth == 1 && this.status < 37 || this.currentMonth >= 2 && this.status >= 10) {
          // ultimo paso, llenar balance
          const llenarB = await this.llenarBalance();
        }
        this.socketService.magicBtnFinishDemo(this.workshopId, this.teamId);
        resolve(true);
      } else {
        resolve(false);
      }
    });

  }

  collectMoney(cantidad: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let total = this.returnTotalLst(this.cdkLstCash);
      if (total >= cantidad) {
        this.cdkLstCash = [];
        total = total - cantidad;
        const countGroup20 = Math.floor(total / 20);
        const lessPackege = total % 20;
        for (let i = 0; i < countGroup20; i++) {
          this.cdkLstCash.push({
            valor: 20,
            type: 1,
            id: null,
            draggable: false,
          })
        }
        if (lessPackege > 0) {
          this.cdkLstCash.push({
            valor: lessPackege,
            type: 1,
            id: null,
            draggable: false,
          });
        }
        this.socketService.splitCoin(this.cdkLstCash, 'cdkLstCash', this.roomId, this.teamId);
        resolve(true);
      } else if (this.cdkLstBank.length > 0) {
        this.socketService.borrowFromBank(
          'cdkLstBank',
          'cdkLstLoans90',
          this.cdkLstBank[0],
          this.roomId,
          this.teamId,
          this.currentMonth,
          [],
          []
        );
        setTimeout(() => {
          // this.socketService.deleteCoin('cdkLstBank',this.cdkLstBank[0], this.roomId, this.teamId);
          setTimeout(async () => {
            this.socketService.createCoin('cdkLstInterest', {
              valor: 1,
              id: null,
              draggable: false,
              type: 1,
            }, this.roomId, this.teamId);
            const x = await this.collectMoney(1);
            setTimeout(async () => {
              const x1 = await this.collectMoney(cantidad);
              setTimeout(() => {
                resolve(true);
              }, 500);
            }, 2500);
          }, 500);
        }, 500);
      }
    });
  }

  payAdvertising(): Promise<boolean> {
    return new Promise((resolve, reyect) => {
      if (this.owesAdvertising > 0 && this.owesAdvertising != this.returnTotalLst(this.cdkLstAdvertasing)) {
        this.socketService.createCoin('cdkLstAdvertasing', {
          valor: this.owesAdvertising,
          id: null,
          draggable: false,
          type: 1,
        }, this.roomId, this.teamId);
        setTimeout(() => {
          this.collectMoney(this.owesAdvertising);
          this.owesAdvertising = 0; this.showOwedAdvertising = false;
          resolve(true);
        }, 500);
      } else {
        resolve(true);
      }
    })
  }

  movePieces(from: Array<any>, fromId: string, to: Array<any>, toId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let length = from.length;
      for (let i = 0; i < length; i++) {
        setTimeout(() => {
          this.socketService.dropACoin(
            fromId,
            toId,
            from[i],
            i,
            this.roomId,
            this.userId,
            this.userData.FirstName,
            this.teamId,
            this.status
          );
        }, 500);
        to.push(from[i]);
      }
      setTimeout(() => {
        from = [];
        resolve(true);
      }, (length + 1) * 500);
    });
  }

  moveRoyalsToCOGS(cantidad: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let cantidadAux = cantidad;
      const royal3_3 = this.returnTotalLst(this.cdkLstFinishedFI.filter(item => item.type == 8)) / 3;
      const royal3_2 = this.returnTotalLst(this.cdkLstFinishedFI.filter(item => item.type == 17)) / 5;
      const royal1_3 = this.returnTotalLst(this.cdkLstFinishedFI.filter(item => item.type == 16)) / 4;
      const royal1_2 = this.returnTotalLst(this.cdkLstFinishedFI.filter(item => item.type == 18)) / 3;
      cantidad = cantidad - royal3_3;
      this.cdkLstCostGS = [];
      if (cantidad <= 0) {
        for (let i = 0; i < cantidadAux; i++) {
          this.cdkLstCostGS.push({
            valor: 3,
            type: 8,
            id: null,
            draggable: false
          });
        }
        this.cdkLstFinishedFI = [
          {
            valor: 3 * cantidad * -1,
            type: 8,
            id: null,
            draggable: false,
          },
          {
            valor: 5 * royal3_2,
            type: 17,
            id: null,
            draggable: false,
          },
          {
            valor: 4 * royal1_3,
            type: 16,
            id: null,
            draggable: false,
          }
          ,
          {
            valor: 3 * royal1_2,
            type: 18,
            id: null,
            draggable: false,
          }
        ]
      } else {
        cantidadAux = cantidad;
        cantidad = cantidad - royal3_2;
        if (cantidad <= 0) {
          for (let i = 0; i < royal3_3; i++) {
            this.cdkLstCostGS.push({
              valor: 3,
              type: 8,
              id: null,
              draggable: false
            });
          }
          for (let i = 0; i < cantidadAux; i++) {
            this.cdkLstCostGS.push({
              valor: 5,
              type: 17,
              id: null,
              draggable: false
            });
          }
          // this.cdkLstCostGS = [{
          //     valor: 3*royal3_3,
          //     type: 8,
          //     id: null,
          //     draggable: false
          //   }, {
          //     valor: 5*cantidadAux,
          //     type: 17,
          //     id: null,
          //     draggable: false
          // }];

          this.cdkLstFinishedFI = [
            {
              valor: 5 * cantidad * -1,
              type: 17,
              id: null,
              draggable: false,
            },
            {
              valor: 4 * royal1_3,
              type: 16,
              id: null,
              draggable: false,
            },
            {
              valor: 3 * royal1_2,
              type: 18,
              id: null,
              draggable: false,
            }
          ]
        } else {
          cantidadAux = cantidad;
          cantidad = cantidad - royal1_3;
          if (cantidad <= 0) {
            for (let i = 0; i < royal3_3; i++) {
              this.cdkLstCostGS.push({
                valor: 3,
                type: 8,
                id: null,
                draggable: false
              });
            }
            for (let i = 0; i < royal3_2; i++) {
              this.cdkLstCostGS.push({
                valor: 5,
                type: 17,
                id: null,
                draggable: false
              });
            }
            for (let i = 0; i < cantidadAux; i++) {
              this.cdkLstCostGS.push({
                valor: 4,
                type: 16,
                id: null,
                draggable: false
              });
            }
            // this.cdkLstCostGS = [{
            //     valor: 3*royal3_3,
            //     type: 8,
            //     id: null,
            //     draggable: false
            //   }, {
            //     valor: 5*royal3_2,
            //     type: 17,
            //     id: null,
            //     draggable: false
            //   },{
            //     valor: 4*cantidadAux,
            //     type: 16,
            //     id: null,
            //     draggable: false
            // }];
            this.cdkLstFinishedFI = [
              {
                valor: 4 * cantidad * -1,
                type: 16,
                id: null,
                draggable: false,
              },
              {
                valor: 3 * royal1_2,
                type: 18,
                id: null,
                draggable: false,
              }
            ]
          }
        }
      }
      this.cdkLstFinishedFI = this.cdkLstFinishedFI.filter(item => item.valor != 0);
      this.cdkLstCostGS = this.cdkLstCostGS.filter(item => item.valor != 0);
      this.socketService.splitCoin(
        this.cdkLstFinishedFI,
        'cdkLstFinishedFI',
        this.roomId, this.teamId
      );
      setTimeout(() => {
        this.socketService.splitCoin(
          this.cdkLstCostGS,
          'cdkLstCostGS',
          this.roomId,
          this.teamId
        );
        setTimeout(() => {
          resolve(true);
        }, 1000);
      }, 500);
    });
  }

  deliverOrders(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.orders.forEach(item => {
        setTimeout(() => {
          this.fnDeliverOrder(item);
        }, 500);
      });
      setTimeout(() => {
        resolve(true);
      }, (this.orders.length + 1) * 500);
    });
  }

  llenarIncome(totalSales: number): Promise<boolean> {
    return new Promise(async (resolve, reyect) => {
      // const {IncomeStatementService} = await import('src/app/services/IncomeStatement/IncomeStatement.service');
      // const incomeService = this.injector.get(IncomeStatementService);
      let jsonCantidades;
      let costGoodSoldBD = 0;
      let factotyOverheadsBD = 0;
      let sellingBD = 0;
      let interestBD = 0;
      let taxesBD = 0;
      this.incomeService.getCantidadesBoard(this.teamId).subscribe(async (response) => {
        jsonCantidades = response['data'];
        console.log("Cantidades: ", jsonCantidades);
        let costGoodSold = jsonCantidades.filter(x => x.AreaDrag === 31);
        costGoodSold.forEach(x => {
          if (x.tipo == 8) {
            costGoodSoldBD += (x.cantidad) * 2;
          } else {
            costGoodSoldBD += (x.cantidad);
          }
        });

        let factotyOverheads = jsonCantidades.filter(x => x.AreaDrag === 20 || x.AreaDrag === 21);
        factotyOverheads.forEach(x => {
          factotyOverheadsBD = (factotyOverheadsBD) + (x.cantidad);
        });

        let selling = jsonCantidades.filter(x => x.AreaDrag === 22 || x.AreaDrag === 23 || x.AreaDrag === 24 || x.AreaDrag === 25 || x.AreaDrag === 26 || x.AreaDrag === 27 || x.AreaDrag === 28);
        selling.forEach(x => {
          sellingBD = (sellingBD) + (x.cantidad);
        });

        let interest = jsonCantidades.filter(x => x.AreaDrag === 29);
        interest.forEach(x => {
          interestBD = x.cantidad;
        });

        let taxes = jsonCantidades.filter(x => x.AreaDrag === 36);
        taxes.forEach(x => {
          taxesBD = x.cantidad;
        });
        let resultado = totalSales - costGoodSoldBD;
        let resultado2 = resultado - factotyOverheadsBD;
        let resultado3 = resultado2 - sellingBD;
        let resultado4 = resultado3 - interestBD;
        let resultado5 = resultado4 - taxesBD;
        console.log(resultado, resultado2, resultado3, resultado4, resultado5);
        if (this.currentMonth == 1) {
          const response = await this.incomeService.getCountCantidades(this.teamId).toPromise();
          if (response.data.total == 0) {
            const x = await this.incomeService.newRegister(this.workshopId, this.teamId, this.Cycles).toPromise();
          }
        }
        const responseUpdate = await this.incomeService.updateCantidades(
          this.workshopId,
          this.teamId,
          this.currentMonth,
          totalSales.toString(),
          costGoodSoldBD.toString(),
          resultado.toString(),
          factotyOverheadsBD.toString(),
          resultado2.toString(),
          sellingBD.toString(),
          resultado3.toString(),
          interestBD.toString(),
          resultado4.toString(),
          taxesBD.toString(),
          resultado5.toString()
        ).toPromise();
        const responseUpdMonth = await this.incomeService.updateMonth(this.workshopId, this.teamId, this.currentMonth).toPromise();
        let totRetainedEarning = this.returnTotalLst(this.cdkLstRetainedE);
        totRetainedEarning += resultado5;
        const isNegative = totRetainedEarning < 0;
        let package20 = 0;
        let packageLess20 = 0;
        package20 = Math.floor(totRetainedEarning / (isNegative ? -20 : 20));
        packageLess20 = totRetainedEarning % 20;
        this.cdkLstRetainedE = [];
        for (let i = 0; i < package20; i++) {
          this.cdkLstRetainedE.push({
            valor: isNegative ? -20 : 20,
            id: null,
            draggable: false,
            type: 2,
          });
        }
        if (packageLess20 != 0) {
          this.cdkLstRetainedE.push({
            valor: packageLess20,
            id: null,
            draggable: false,
            type: 2,
          });
        }
        this.socketService.splitCoin(this.cdkLstRetainedE, 'cdkLstRetainedE', this.roomId, this.teamId);
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
    });
  }

  llenarBalance(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // const {BalanceSheetService} = await import('src/app/services/balance-sheet/balance.service');
      // const balanceService = this.injector.get(BalanceSheetService);
      let jsonCantidades;
      let cashBD = 0;
      let receivablesBD = 0;
      let finishGoodBD = 0;
      let workProcessBD = 0;
      let rawMaterialsBD = 0;
      let landBuildingsBD = 0;
      let payablesBD = 0;
      let loansBD = 0;
      let taxesBD = 0;
      let capitalStockBD = 0;
      let machineBD = 0;
      let retainedBD = 0;

      this.balanceService.getCantidadesBoard(this.teamId).subscribe(async (response) => {
        jsonCantidades = response['data'];

        let cash = jsonCantidades.filter(x => x.AreaDrag === 30);
        cash.forEach(x => {
          cashBD = x.cantidad;
        });

        let receivables = jsonCantidades.filter(x => x.AreaDrag === 15 || x.AreaDrag === 16 || x.AreaDrag === 17);
        receivables.forEach(x => {
          receivablesBD = (receivablesBD) + (x.cantidad);
        });

        let finishGood = jsonCantidades.filter(x => x.AreaDrag === 11);
        finishGood.forEach(x => {
          if (x.tipo == 8) {
            finishGoodBD += x.cantidad * 2;
          } else {
            finishGoodBD += x.cantidad;
          }
        });

        let workProcess = jsonCantidades.filter(x => x.AreaDrag === 49 || x.AreaDrag === 48 || x.AreaDrag === 9);
        workProcess.forEach(x => {
          workProcessBD = (workProcessBD) + (x.tipo == 8 ? (x.cantidad) * 2 : x.cantidad);
        });

        let rawMaterials = jsonCantidades.filter(x => x.AreaDrag === 6);
        rawMaterials.forEach(x => {
          rawMaterialsBD = x.cantidad;
        });

        let landBuildings = jsonCantidades.filter(x => x.AreaDrag === 10);
        landBuildings.forEach(x => {
          landBuildingsBD = x.cantidad;
        });

        let payables = jsonCantidades.filter(x => x.AreaDrag === 37 || x.AreaDrag === 38);
        payables.forEach(x => {
          payablesBD = (payablesBD) + (x.cantidad);
        });


        let loans = jsonCantidades.filter(x => x.AreaDrag === 12 || x.AreaDrag === 13 || x.AreaDrag === 14);
        loans.forEach(x => {
          loansBD = (loansBD) + (x.cantidad);
        });

        let taxes = jsonCantidades.filter(x => x.AreaDrag === 36);
        taxes.forEach(x => {
          taxesBD = x.cantidad;
        });

        let capitalStock = jsonCantidades.filter(x => x.AreaDrag === 18);
        capitalStock.forEach(x => {
          capitalStockBD = x.cantidad;
        });

        let machine = jsonCantidades.filter(x => x.AreaDrag === 7 || x.AreaDrag === 41 || x.AreaDrag === 47);
        machine.forEach(x => {
          machineBD += x.cantidad;
        });

        let retained = jsonCantidades.filter(x => x.AreaDrag === 19);
        retained.forEach(x => {
          retainedBD = x.cantidad;
        });


        let resultado = (cashBD) + (receivablesBD) +
          (finishGoodBD) + (workProcessBD) +
          (rawMaterialsBD);
        let resultado2 = (machineBD) + (landBuildingsBD);
        let resultado3 = (resultado) + (resultado2);
        let resultado4 = (payablesBD) + (loansBD) +
          (taxesBD);
        let resultado5 = (capitalStockBD) + (retainedBD);
        let resultado6 = (resultado4) + (resultado5);

        if (this.currentMonth == 1) {
          const response = await this.balanceService.getCountCantidades(this.teamId).toPromise();
          if (response.data.total == 0) {
            const x = await this.balanceService.newRegister(this.workshopId, this.teamId, this.Cycles).toPromise();
          }
        }

        this.balanceService.updateCantidades(
          this.workshopId,
          this.teamId,
          this.currentMonth,
          cashBD.toString(),
          receivablesBD.toString(),
          finishGoodBD.toString(),
          workProcessBD.toString(),
          rawMaterialsBD.toString(),
          resultado.toString(),
          machineBD.toString(),
          landBuildingsBD.toString(),
          resultado2.toString(),
          resultado3.toString(),
          payablesBD.toString(),
          loansBD.toString(),
          taxesBD.toString(),
          resultado4.toString(),
          capitalStockBD.toString(),
          retainedBD.toString(),
          resultado5.toString(),
          resultado6.toString()).subscribe((response) => {
            this.balanceService.updateMonth(this.workshopId, this.teamId, this.currentMonth).subscribe(async (response) => {
              console.log("update month: ", response);
              if (this.currentMonth == 1) {
                this.status = 37;
              } else {
                this.status = 14;
              }
              const x = await this.actualizarMes({ status: 'save' });
              setTimeout(() => {
                resolve(true);
              }, 1000);
            });
          });
      });
    });
  }

  get shouldBlockMagicButtonByImprovements(): boolean {
    /*
      En caso de haber seleccionado los improvements de Expansion o Efficiency 
      se bloquea el botón "Go to Market" ya que no esta contemplado para la funcionalidad.
    */
    return !this.improvementsOptionsSelected.some(improvement => improvement.option === 5 || improvement.option === 2);
  }

  isCheckListCompleteBeforeThan(title: string): boolean {
    const index = this.checkBoxes.findIndex(checkBox => checkBox.title === title);
    if (index !== -1) {
      let res = true;
      for (let i = 0; i < index; i++) {
        res = res && this.checkBoxes[i].blnCheck;
      }
      return res && !this.checkBoxes[index].blnCheck;
    } else {
      return false;
    }
  }

  get getCurrentImprovement(): 'Customizing' | 'Quality' | 'Expansion' | 'Raw Materials' | 'Efficiency' | '' | undefined | string {
    return this.currentImprovement
    // try {
    //   let position = 0;
    //   if (this.currentMonth == 3 || this.currentMonth == 4 && this.improvementsOptionsSelected[0]?.value == 1 && this.improvementsOptionsSelected[0]?.option != 6) {
    //     position = 0;
    //   } else if (this.currentMonth == 4 && this.improvementsOptionsSelected[0]?.value == 3 || (this.improvementsOptionsSelected[0]?.value == 1 && this.improvementsOptionsSelected[0]?.option == 6)) {
    //     position = 1;
    //   } else if (this.currentMonth >= 4) {
    //     if (
    //       ((this.improvementsOptionsSelected[0]?.value == 1 || this.improvementsOptionsSelected[0]?.value == 2) && this.improvementsOptionsSelected[0]?.option == 5 && this.improvementsOptionsSelected[0]?.Approved == 0) ||
    //       ((this.improvementsOptionsSelected[1]?.value == 1 || this.improvementsOptionsSelected[1]?.value == 2) && this.improvementsOptionsSelected[1]?.option == 5 && this.improvementsOptionsSelected[1]?.Approved == 0)
    //     ) {
    //       position = this.improvementsOptionsSelected[0]?.option == 5 ? 0 : 1;
    //     } else if (
    //       ((this.improvementsOptionsSelected[0]?.value == 1 || this.improvementsOptionsSelected[0]?.value == 2) && this.improvementsOptionsSelected[0]?.option == 2 && this.improvementsOptionsSelected[0]?.Approved == 0) ||
    //       ((this.improvementsOptionsSelected[1]?.value == 1 || this.improvementsOptionsSelected[1]?.value == 2) && this.improvementsOptionsSelected[1]?.option == 2 && this.improvementsOptionsSelected[1]?.Approved == 0)
    //     ) {
    //       position = this.improvementsOptionsSelected[0]?.option == 2 ? 0 : 1;
    //     } else if (
    //       ((this.improvementsOptionsSelected[0]?.value == 1 || this.improvementsOptionsSelected[0]?.value == 2) && this.improvementsOptionsSelected[0]?.option == 1 && this.improvementsOptionsSelected[0]?.Approved == 0) ||
    //       ((this.improvementsOptionsSelected[1]?.value == 1 || this.improvementsOptionsSelected[1]?.value == 2) && this.improvementsOptionsSelected[1]?.option == 1 && this.improvementsOptionsSelected[1]?.Approved == 0)
    //     ) {
    //       position = this.improvementsOptionsSelected[0]?.option == 1 ? 0 : 1;
    //     } else if (
    //       ((this.improvementsOptionsSelected[0]?.value == 1 || this.improvementsOptionsSelected[0]?.value == 2) && this.improvementsOptionsSelected[0]?.option == 3 && this.improvementsOptionsSelected[0]?.Approved == 0) ||
    //       ((this.improvementsOptionsSelected[1]?.value == 1 || this.improvementsOptionsSelected[1]?.value == 2) && this.improvementsOptionsSelected[1]?.option == 3 && this.improvementsOptionsSelected[1]?.Approved == 0)
    //     ) {
    //       position = this.improvementsOptionsSelected[0]?.option == 3 ? 0 : 1;
    //     } else {
    //       position = this.improvementsOptionsSelected[0]?.value == 3 || this.improvementsOptionsSelected[0]?.option == 6 ? 1 : 0;
    //     }
    //   }
    //   const result = this.improvementsOptionsSelected[position]?.improvement ? this.improvementsOptionsSelected[position]?.improvement : ''
    //   return result;
    // } catch (error) {
    //   console.log(error);
    //   return '';
    // }
  }

  get isType11InCapEx(): boolean {
    return this.cdkLstCapEx.some(el => el.type == 11);
  }

  get isType4InCapEx(): boolean {
    return this.cdkLstCapEx.some(el => el.type == 4);
  }

  get isType12inLabor(): boolean {
    return this.cdkLstLabourP.some(el => el.type == 12);
  }

  get isType5InCapEx(): boolean {
    return this.cdkLstCapEx.some(el => el.type == 5);
  }
  fnModalLogs(){
    console.log("Funciona",this.lstGloblalUsersBoard,this.userData);
    this._matDialog.open(DialogLogsComponent, {
    //width: '550px',
      //height: '600px',
      disableClose: false,
      hasBackdrop: true,
      panelClass: ['resize-checklist'],
      data: {
        workshopId: this.workshopId,
        teamsData: this.lstGloblalUsersBoard,
        facTeamId: this.teamId,
      }
    });
  }
  fnModalTeamsChecklist() {
    let aux = this.isProjectsEnableByCurrentMonth;

    this._matDialog.open(DialogChecklistComponent, {
      // width: '550px',
      // height: '600px',
      disableClose: false,
      hasBackdrop: false,
      panelClass: ['resize-checklist'],
      data: {
        workshopId: this.workshopId,
        muestraImprovements: aux,
        openMarket: this.intOpenMarket,
        lastStartMonth: this.lastStartMonth,
        boardType: this.businessModel,
        userName : this.userData['FirstName'],
        month : this.currentMonth,
        teamId : this.teamId
      }
    });
    // this.dialogRef.afterClosed().subscribe((resp) => {
    //   console.log("RETURNED DIALOG change board", resp);
    //   //validamos si cambió de team para recargar todo
    // })
  }

  allowTeamSelectImprov() {
    if (!this.blnAccessCUSTOMERISBS) {
      return;
    }
    let blncanReselect = true;
    let enableSelect = true;
    //borramos el div del mensaje de error para que no se sobreponga con el modal de improvements
    this.blnShowerrorPasoImplementImprov = false;
    this.dialogRef = this._matDialog.open(DialogImprovementsComponent, {
      disableClose: false,
      width: '1400px',
      height: '600px',
      panelClass: 'event-form-dialog',
      data: {
        enableSelect: enableSelect,
        teamId: this.teamId,
        currentMonth: this.currentMonth,
        month: this.currentMonth,
        workshopId: this.workshopId,
        statusBoard: this.status,
        arrTextLabels: this.arrTextLabels,
        canReSelect: blncanReselect,
        Cycles: this.Cycles
      },
    });

    this.dialogRef.afterClosed().subscribe((resp) => {
      console.log("RETURNED DIALOG Improvement:", resp);
      if (resp == undefined) {
        console.log("IF  UNDEFINEDD");
        this.blnShowerrorPasoImplementImprov = true;
      }
      else if (blncanReselect == true && resp.status == 1) {
        console.log("RESTARTING .....despues de modal improvements aHORAMOSTRAMOS PREGUNTA:::")
        // this.fnReSelectImprovementAgain(resp.improvementId, resp.improveOptionId);
        location.reload();
      } else {
        console.log("else no selected improv .....")
        this.blnShowerrorPasoImplementImprov = true;
      }
    });
  }

  get isProjectsEnableByCurrentMonth(): boolean {
    if(this.isPendingProject != 0){
      return true;
    }
    switch (this.Cycles) {
      case 4:
        return this.currentMonth === 3 || this.currentMonth === 4;
      case 5:
        return this.currentMonth === 3 || this.currentMonth === 4;
      case 6:
        return this.currentMonth === 3 || this.currentMonth === 5;
      default:
        return false;
    }
  }

  shouldTakeBuildPayment(initialContainerId: string) {
    return this.jsonBlnOpenModeActions.isSoldBuild && !this.isModeOpen && this.cdkLstCapEx.some(el => (el.type === 1 && el.valor === 20)) && (initialContainerId !== 'cdkLstCapEx' || this.itemDragged.type !== 1 || this.itemDragged.valor !== 20);
  }

  //#region Region  CONTROL DE ERRORES EN QUE LOS PASOS ESTANCAN EL JUEGO
  fnFixErrorstatus5() {
    console.log("ENTRA A FUNCION")
    if (this.status == 5 && this.currentMonth >= 2) {
      // console.log("Entra a funcion", this.improvementsOptionsSelected)
      //Control de error de desconexion donde se quedó intermedio el paso 5 pero ya fue pagado el RM
      this.checkCheckbox(3, true); //se actualiza el checklist, se checkea el paso de buy row materials
      setTimeout(() => {
        console.log("ENTRAMOS STATUS 5 mes > 2 control de error paso 5 estancado, updating status to 6")
        this.status = 6;
        this.socketService.updateStatus(this.roomId, this.teamId, this.status, this.currentMonth);
        setTimeout(() => {
          console.log("setBlnControlModeOpen");
          let id = this.jsonBlnOpenModeActions.showMessageOwe12 ? 'showMessageOwe12' :
            this.jsonBlnOpenModeActions.showMessageOwe18 ? 'showMessageOwe18' :
              this.jsonBlnOpenModeActions.showMessageOwe4 ? 'showMessageOwe4' : '';
          this.jsonBlnOpenModeActions[id] = false;
          this.socketService.setBlnControlModeOpen(id, false, this.roomId, this.teamId);
          //borramos si hay algo en suppliers: 
          if (this.cdkLstSuppliers.length > 0) {
            let borrar;
            this.cdkLstSuppliers.forEach(item => {
              if (item.type == 3) {
                borrar = item;
              }
            })
            this.socketService.deleteCoin('cdkLstSuppliers', borrar, this.roomId, this.teamId);
          }
          //leer que imporrvements tenemos
          console.log("Antes de condiciones", this.cdkLstPayables60);
          // this.cdkLstPayables60.pop();
          //Sin imrpovements mes > = 2
          if (!this.jsonBlnOpenModeActions.isRawMaterials1 && !this.jsonBlnOpenModeActions.isEfficiency1 && !this.jsonBlnOpenModeActions.isSecondMachine && this.currentMonth >= 2) {
            console.log(this.cdkLstPayables60);
            if (this.cdkLstPayables60.length > 0) { console.log("SI ESTAN LOS PAYABLES") } else {
              console.log("NO ESTAN LOS PAYABLES");
              //Agregar uno de 12
              this.createPayable60(12);
            }
          }
          //Raw material en mes >= 3 Pero no expancion o eficiencia
          if (this.jsonBlnOpenModeActions.isRawMaterials1 && !this.jsonBlnOpenModeActions.isEfficiency1 && !this.jsonBlnOpenModeActions.isSecondMachine && this.currentMonth >= 3) {
            console.log(this.cdkLstPayables60);
            if (this.cdkLstPayables60.length > 0) { console.log("SI ESTAN LOS PAYABLES") } else {
              //Agregar un 4
              console.log("NO ESTAN LOS PAYABLES");
              this.createPayable60(4);
            }
          }
          //Eficiencia en mes >= 3 Pero no expancion o raw materials
          if (this.jsonBlnOpenModeActions.isEfficiency1 && !this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isRawMaterials1 && this.currentMonth >= 3) {
            console.log(this.cdkLstPayables60);
            if (this.cdkLstPayables60.length > 0) { console.log("SI ESTAN LOS PAYABLES") } else {
              //Agregar un 18
              console.log("NO ESTAN LOS PAYABLES");
              this.createPayable60(18);

            }
          }
          //Expancion en mes >= 3 pero no eficiencia o raw material
          if (!this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isSecondMachine && !this.jsonBlnOpenModeActions.isRawMaterials1 && this.currentMonth >= 3) {
            console.log(this.cdkLstPayables60);
            let aux = 0;
            this.cdkLstPayables60.forEach(element => {
              if (element.valor == 12) {
                aux++;
              }
            });
            if (aux == 2) {
              console.log("SI ESTAN LOS PAYABLES");
            } else {
              if (aux == 1) {
                //Agregar un 12
                console.log("NO ESTAN LOS PAYABLES");
                this.createPayable60(12);
              } else {
                if (aux == 0) {
                  //Agregar 2 de 12
                  console.log("NO ESTAN LOS PAYABLES");
                  this.createPayable60(12,true);
                }
              }
            }
          }
          //Eficiencia y expancion en mes >= 4
          if (this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isEfficiency2 && this.jsonBlnOpenModeActions.isSecondMachine && this.currentMonth >= 4) {
            console.log(this.cdkLstPayables60);
            let aux = 0;
            this.cdkLstPayables60.forEach(element => {
              if (element.valor == 18) {
                aux++;
              }
            });
            if (aux == 2) {
              console.log("SI ESTAN");
            } else {
              if (aux == 1) {
                //Agregar un 18
                this.createPayable60(18);
              } else {
                if (aux == 0) {
                  //Agregar 2 de 18
                  console.log("NO ESTAN LOS PAYABLES");
                  this.createPayable60(18,true);
                }
              }
            }

          }
          //Raw material y eficiencia en mes >= 4
          if (this.jsonBlnOpenModeActions.isEfficiency1 && this.jsonBlnOpenModeActions.isRawMaterials1 && this.currentMonth >= 4) {
            console.log(this.cdkLstPayables60);
            if (this.cdkLstPayables60.length > 0) { console.log("SI ESTAN LOS PAYABLES") } else {
              //Agregar un 6
              console.log("NO ESTAN LOS PAYABLES");
              this.createPayable60(6);
            }
          }
          //Raw material y expancion en mes >= 4
          if (this.jsonBlnOpenModeActions.isRawMaterials1 && this.jsonBlnOpenModeActions.isRawMaterials2 && this.jsonBlnOpenModeActions.isSecondMachine && this.currentMonth >= 4) {
            console.log(this.cdkLstPayables60);
            let aux = 0;
            this.cdkLstPayables60.forEach(element => {
              if (element.valor == 4) {
                aux++;
              }
            });
            if (aux == 2) {
              console.log("SI ESTAN");
            } else {
              if (aux == 1) {
                //Agregar un 4
                console.log("NO ESTAN LOS PAYABLES");
                this.createPayable60(4);
              } else {
                if (aux == 0) {
                  //Agregar 2 de 4
                  console.log("NO ESTAN LOS PAYABLES");
                  this.createPayable60(4,true);

                }
              }
            }

          }


          //leer array de actionboards eficciency = 18 payables mes = 3, if mes =2 (12 payables)
          // 2 de 12 o 2 de 18 si hay expantion + efficiency mes > 4  
          // this.fnUpdateChecklistByStatus(this.status);
        }, 500);
      }, 500);
    }

  }
  //Asegurar que el socket del mercado esta activo
  //Si cualquier usuario recarga o abre el board cuando se está en el paso de bid prep se abrirá 
  //automaticamente  la vista de customer para que se generen los sockets de Mercado
  fnFixErrorSocketMarket(){
    console.log(this.intOpenMarket,this.isOpenMarket,this.status,this.currentMonth);
    if((this.status == 10 || this.status == 11) || (this.status == 30 && this.boardMode == "DM")){//this.intOpenMarket == 1
      setTimeout(() => {
        this.fnClickCustomers();
      }, 1000);
    }
  }
  createPayable60(value: number, isDoubleCoin: boolean = false) {
    let nuevaPieza = {
      "StatusBoardId": -1,
      "TeamId": this.teamId,
      "AreaDrag": 37,
      "strAreaDrag": 'Payables60',
      'Valor': value,
      'tipo': 3
    }
    console.log("Se agrega el payable faltante", nuevaPieza, isDoubleCoin);
    if(isDoubleCoin){
      this.boardService.createCoin(nuevaPieza).subscribe(response => {
        if(response.intResponse == 200){
          this.boardService.createCoin(nuevaPieza).subscribe(response => {
            if(response.intResponse == 200){
              Swal.fire({ icon: 'success', title: "We fix an error, Your board will be reloaded" });
              setTimeout(() => {
                location.reload()
              }, 1000);
            }else{
              Swal.fire({ icon: 'error', title: 'Something wrong' });
            }
          })
        }
      })
    }else{
      this.boardService.createCoin(nuevaPieza).subscribe(response => {
        if (response.intResponse == 200) {
          Swal.fire({ icon: 'success', title: "We fix an error, Your board will be reloaded" });
          setTimeout(() => {
            location.reload()
          }, 1000);
        } else {
          Swal.fire({ icon: 'error', title: 'Something wrong' });
        }
      })
    }
    
  }

  fnFixCheckAutoDeliverWhenNoOrdersWon()
    {
      let totOrders= 0;
          this.orders.forEach(item => {
            totOrders += item.Quantity;
          });
          if(totOrders == 0){
          //alert("NOO hya ordenes")
            this.checkCheckbox(12, true);
            this.funcionBack("caseCOGS");
          }else{
           // alert("Si hya ordenes")
          }
    }

    // fnSaveBackQuantities(casePayLoans = false){
    //   //validar si es la primera vez que guardamos back consumimos getcoins para el mes actual y vemos si hay registros, si no hay guardamos el backup
    //   this.boardService.GetBackOfCoins(this.teamId, this.currentMonth).subscribe(response => {
    //     if (response.intResponse == 200) {
    //       console.log("BACKUPPPP")
    //         console.log("Resp data in Backup:",response)
    //         var Backup_CashBD = [];
    //         Backup_CashBD = response['data'].filter(el => {
    //           if (el.AreaDrag == 30){ return el;} 
    //         });
    //         console.log("Backup_CashBD:",Backup_CashBD);
    //         if (Backup_CashBD.length == 0 || casePayLoans ){
    //           this.boardService.createBackOfCoin(this.teamId, this.currentMonth, 0,0,0).subscribe(response => {
    //             console.log("createBackOfCoin::",response );
    //             if (response.intResponse == 200) {
    //               console.log("guardamos back despues de Expenses:",response)
    //              /* Swal.fire({ icon: 'success', title: "We fix an error, Your board will be reloaded" });
    //               setTimeout(() => {
    //                 location.reload()
    //               }, 1000);*/
        
    //             } else {
    //               Swal.fire({ icon: 'error', title: 'Something wrong trying backUp coins' });
    //             }
    //           })
    //         }
          
    //     }
    //   })
      
    //   //if area ==0 guardamos por default area de cash y retained earning del momento actual (paso pay expenses recien completado)
      

    // }

    // fnFixAdvertisingCashQuantities()
    // {
    //   //obtenemos las bids que apostaron para despues restarlas al cash que habia antes del mercado
    //   this.orderService.getOrdersByWorkShopId(this.workshopId).subscribe(resp => {
    //     this.spinner.show();
    //     console.log("fnGetAllOrdersCALCULA", resp);
    //     var arrOrdersTMP = resp['orders'].filter(el => {
    //       if (el.Month === this.currentMonth)//view board
    //       {
    //         return el;
    //       }
    //       //else { resolve('no'); }
    //     });
    //     console.log("arrOrdersTMP final", arrOrdersTMP);
    //     let numBids = 0;
    //     for (var i = 0; i < arrOrdersTMP.length; i++)
    //     {
    //       for (var j = 0; j < arrOrdersTMP[i].Bids.length; j++)
    //       {
    //         if (arrOrdersTMP[i].Bids[j].Bid != 0 && arrOrdersTMP[i].Bids[j].TeamId == this.teamId) 
    //         {
    //           numBids = numBids +1;
    //         }
    //       }
    //     }
    //     console.log("numBids final", numBids);
    //     //Obtenemos la cantidad de cash antes de mercado para hacer la resta de cash - advertising y validar si la cantidad actual está bien
    //     this.boardService.GetBackOfCoins(this.teamId, this.currentMonth).subscribe(response => {
    //       if (response.intResponse == 200) {
    //         console.log("Resp data in Backup:",response)
    //         var CurrentCashBD = [];
    //         CurrentCashBD = response['data'].filter(el => {
    //           console.log("");
    //           if (el.id === "cash"){ return el;} 
    //         });
    //         console.log("Cash en BD:",CurrentCashBD)
    //         var Backup_CashBD = [];
    //         Backup_CashBD = response['data'].filter(el => {
    //           if (el.AreaDrag == 30){ return el;} 
    //         });
    //         console.log("Backup_CashBD:",Backup_CashBD)

    //         var Backup_RetainedEarBD = response['data'].filter(el => {
    //           if (el.AreaDrag == 19){ return el;} 
    //         });
    //         console.log("Backup_RetainedEarBD:",Backup_RetainedEarBD);
    //         var mustHaveCash = 0;
    //         if(Backup_CashBD.length > 0){
    //           //debemos sumar todo lo que llego a cash entre el paso de prep bids y subir wip a fgi y luego restar numBids
    //           var cashTotalBackup = 0;
    //           Backup_CashBD.forEach(element => {
    //             cashTotalBackup = cashTotalBackup + element.Valor;
    //           });
    //            mustHaveCash = cashTotalBackup - numBids;
    //         }
            
    //         //obtenemosel cash actual
    //         let totalCashBoard = this.returnTotalLst(this.cdkLstCash);
    //         console.log("cash obtenido del board:",totalCashBoard);
    //         console.log("mustHaveCash == totalCashBoard:",mustHaveCash, totalCashBoard);
    //         var advertisingActual= 0;
    //         console.log("this.cdkLstAdvertasing:", this.cdkLstAdvertasing);
    //         if(this.cdkLstAdvertasing.length > 0){
    //           advertisingActual = this.cdkLstAdvertasing[0].valor
    //         }
    //         console.log("advertisingActual:", advertisingActual);
    //         //VALIDAMOS SI ES EL MISMO CASH Y EL MISMO ADVERTISING
    //         if(mustHaveCash == totalCashBoard && (numBids == advertisingActual)){
    //           console.log("mismocash no hacemos fix de nada");
    //           this.spinner.hide();
    //         }else{//si no son iguales vamos a poner en advertising y cash lo que corresponde
    //           //alert("no iguales, agregamos lo que deba ser");
    //              setTimeout(() => {
    //                 this.cdkLstCash = [];
    //                 setTimeout(() => {
    //                   this.cdkLstCash = [];
    //                   this.cdkLstAdvertasing = [];
    //                   const countGroup20 = Math.floor(mustHaveCash / 20);
    //                   const lessPackege = mustHaveCash % 20;
    //                   for (let i = 0; i < countGroup20; i++) {
    //                     this.cdkLstCash.push({
    //                       valor: 20,
    //                       type: 1,
    //                       id: null,
    //                       draggable: false,
    //                     })
    //                   }
    //                   if (lessPackege > 0) {
    //                     this.cdkLstCash.push({
    //                       valor: lessPackege,
    //                       type: 1,
    //                       id: null,
    //                       draggable: false,
    //                     });
    //                   }
    //                   this.socketService.splitCoin(this.cdkLstCash, 'cdkLstCash', this.roomId, this.teamId);
    //                   //agregamos a advertising solo lo que debió haber tenido
                      
    //                   this.cdkLstAdvertasing = [{
    //                     valor: numBids,
    //                     type: 1,
    //                     id: null,
    //                     draggable: false,
    //                     // draggingBy: "Advertising: " + this.auxValTotalAdvertising + ' Brand Awareness: ' + this.AdvertisingExtra
    //                     //draggingBy: "Advertising: " + this.auxValTotalAdvertising
    //                   }]
    //                   this.socketService.splitCoin(this.cdkLstAdvertasing, 'cdkLstAdvertasing', this.roomId, this.teamId);
    //                   this.spinner.hide();
    //                 }, 100);
    //               }, 100);
                  
    //         }

    //       } else {
    //         Swal.fire({ icon: 'error', title: 'Something wrong trying GET backUp coins' });
    //         this.spinner.hide();
    //       }
    //     })

    //   });

    // }
    

    //funcion para validar si debe guarda en tabla backup 19 de loan o de vender edificio
    // checkifNeedbackupCoin(){
    //   console.log("this.checkBoxes",this.checkBoxes);
    //   var blnPayExpenses = false;
    //   var blnMoveWIP_to_FGI = true;
    //   this.checkBoxes.forEach(el => {
        
    //     if (el.title === "Pay Expenses")//view board
    //     {
    //       blnPayExpenses = el.blnCheck;
    //     }
    //     if (el.title === "Take Product from Machine")//view board
    //     {
    //       blnMoveWIP_to_FGI = el.blnCheck;
    //     }

    //   });
    //   console.log("boleanos:blnPayExpenses blnMoveWIP_to_FGI:",blnPayExpenses,blnMoveWIP_to_FGI);
    //   if(this.currentMonth >= 2 && this.status > 9){
    //       console.log("Aún esta permitido vender o pedir prestado");
    //       this.boardService.createBackOfCoin(this.teamId, this.currentMonth, 30,19,1).subscribe(response => {
    //         if (response.intResponse == 200) {
    //           console.log("back de prestamo o venta edificio guardad correctamente:",response)
    //          /* Swal.fire({ icon: 'success', title: "We fix an error, Your board will be reloaded" });
    //           setTimeout(() => {
    //             location.reload()
    //           }, 1000);*/
    //         } else {
    //           Swal.fire({ icon: 'error', title: 'Something wrong trying backUp coins' });
    //         }
    //       })

    //   }else{
    //     console.log("YA NO esta permitido vender o pedir prestado");
    //   }
      
    // }


fnCHECKBOX(){
  var blnPayExpenses = false;
                        this.checkBoxes.forEach(el => {
                          
                          if (el.title === "Pay Expenses")//view board
                          {
                            blnPayExpenses = el.blnCheck;
                          }
                          

                        });
 console.log("fncheckbox:", blnPayExpenses);
}
  //#End Region  FIN CONTROL DE ERRORE EN QUE LOS PASOS ESTANCAN

  //BACK UP PARA PREDECIR
  funcionBack(caso?:string, aux?:number){
    console.log(this.orders);

    this.cantidadesIncome = { 
      costGoodSold: 0,
      factotyOverheads: 0,
      selling: 0,
      interest:0,
    };
    this.cantidadesBalance = {
      cash: 0,
      receivables:0,
      finishGood: 0,
      workProcess: 0,
      rawMaterials:0,
      landBuildings:0,
      payables: 0,
      loans: 0,
      taxes: 0,
      capitalStock:0,
      machine: 0,
      retained: 0,
    };

    setTimeout(() => {
      switch (caso) {
        case "backOriginal":
          this.obtenerCantidades();
          break;
        case "caseEdificio":
          console.log("caseEdificio")
          this.boardService.setBackBalanceAndIncomeCaseBuilding(this.teamId,this.currentMonth).subscribe((response) => {
            console.log(response)
          });

          break;
        case "casePrestamo":
          console.log("casePrestamo")
          this.boardService.setBackBalanceAndIncomeCaseBank(this.teamId,this.currentMonth).subscribe((response) => {
            console.log(response)
          });
          break;
        case "caseAdvertasting":
          console.log("caseAdvertasting")
          console.log(this.cdkLstAdvertasing);
          let totSales = 0;
          let auxReceibables = 0;
          let auxCash = 0;
          let auxAdvertasting = 0;
          this.cdkLstAdvertasing.forEach(x =>{
              auxAdvertasting += (x.valor) ;
            })
          this.orders.forEach(item => {
            totSales += item.Bid;
            if(item.Terms >=30){
              auxReceibables+=item.Bid
            }else{
              auxCash+=item.Bid
            }
          })
          console.log(totSales,auxCash,auxReceibables,auxAdvertasting)
          this.boardService.setBackBalanceAndIncomeCaseAdv(this.teamId,this.currentMonth,totSales,auxCash,auxReceibables,auxAdvertasting).subscribe((response) => {
            console.log(response)
          });
            break;
        
        case "caseCOGS":
          console.log("caseCOGS")
          console.log(this.cdkLstCostGS);
          let auxCogs = 0;
          this.cdkLstCostGS.forEach(x =>{
            if(x.type == 8){
              auxCogs += (x.valor) *2 ;
            } else {
              auxCogs += (x.valor);
            }
          });
          console.log(auxCogs);
          this.boardService.setBackOfTeamCaseCogs(this.teamId,this.currentMonth,auxCogs).subscribe((response) => {
            console.log(response)
          });
          break;
        case "casePayLoan":
          this.boardService.setBackOfTeamCasePayLoan(this.teamId,this.currentMonth,20,1).subscribe((response) => {
            console.log(response)
          });
          break;
        case "casePayPayable":
          this.boardService.setBackOfTeamCasePayLoan(this.teamId,this.currentMonth,aux,2).subscribe((response) => {
             console.log(response)
           });
        break;
        default:
          break;
      }

    }, 500);
  }

  obtenerCantidades(){
      //Obtener cantidades Income
      console.log(this.cantidadesBalance)
        this.incomeService.getCantidadesBoard(this.teamId).subscribe((response) => {
          let jsonCantidades = response['data'];
          console.log("Cantidades: ",jsonCantidades);
          let costGoodSold = jsonCantidades.filter(x=>x.AreaDrag === 31);
          costGoodSold.forEach(x =>{
            if(x.tipo == 8){
              this.cantidadesIncome.costGoodSold += (x.cantidad) *2 ;
            } else {
              this.cantidadesIncome.costGoodSold += (x.cantidad);
            }
          });
      
          let factotyOverheads = jsonCantidades.filter(x=>x.AreaDrag === 20 || x.AreaDrag === 21);
          factotyOverheads.forEach(x =>{
            this.cantidadesIncome.factotyOverheads = (this.cantidadesIncome.factotyOverheads) + (x.cantidad);
          });
         
          let selling = jsonCantidades.filter(x=>x.AreaDrag === 22 || x.AreaDrag === 23 || x.AreaDrag === 24|| x.AreaDrag === 25|| x.AreaDrag === 26|| x.AreaDrag === 27|| x.AreaDrag === 28);
          selling.forEach(x =>{
            this.cantidadesIncome.selling = (this.cantidadesIncome.selling) + (x.cantidad);
          });
      
          let interest = jsonCantidades.filter(x=>x.AreaDrag === 29);
          interest.forEach(x =>{
            this.cantidadesIncome.interest = x.cantidad;
          });
        
          let totSales = 0;
          this.orders.forEach(item => {
            totSales += item.Bid;
          })
          console.log(this.orders)
          console.log(this.cantidadesIncome,totSales)
          //Obtener cantidades Balance
          this.balanceService.getCantidadesBoard(this.teamId).subscribe((response) => {
            let jsonCantidades = response['data'];
            console.log("Cantidades: ",jsonCantidades);
    
          let cash = jsonCantidades.filter(x=>x.AreaDrag === 30);
          cash.forEach(x =>{
            this.cantidadesBalance.cash = x.cantidad;
          });
      
          let receivables = jsonCantidades.filter(x=>x.AreaDrag === 15 || x.AreaDrag === 16 || x.AreaDrag === 17);
          receivables.forEach(x =>{
            this.cantidadesBalance.receivables = (this.cantidadesBalance.receivables) + (x.cantidad);
          });
         
          let  finishGood = jsonCantidades.filter(x=>x.AreaDrag === 11);
          finishGood.forEach(x =>{
            if(x.tipo == 8){
              this.cantidadesBalance.finishGood += x.cantidad * 2;
            }else{
              this.cantidadesBalance.finishGood += x.cantidad;
            }
          });
       
          let workProcess = jsonCantidades.filter(x=>x.AreaDrag === 49 || x.AreaDrag === 48 || x.AreaDrag === 9);
          workProcess.forEach(x =>{
            this.cantidadesBalance.workProcess = (this.cantidadesBalance.workProcess) + (x.tipo == 8 ? (x.cantidad) * 2:x.cantidad);
          });
         
          let  rawMaterials = jsonCantidades.filter(x=>x.AreaDrag === 6);
          rawMaterials.forEach(x =>{
            this.cantidadesBalance.rawMaterials = x.cantidad;
          });
    
          let  landBuildings = jsonCantidades.filter(x=>x.AreaDrag === 10);
          landBuildings.forEach(x =>{
            this.cantidadesBalance.landBuildings = x.cantidad;
          });
    
          let payables = jsonCantidades.filter(x=>x.AreaDrag === 37 || x.AreaDrag === 38);
          payables.forEach(x =>{
            this.cantidadesBalance.payables = (this.cantidadesBalance.payables) + (x.cantidad);
          });
    
          
          let loans = jsonCantidades.filter(x=>x.AreaDrag === 12 || x.AreaDrag === 13 || x.AreaDrag === 14);
          loans.forEach(x =>{
            this.cantidadesBalance.loans = (this.cantidadesBalance.loans) + (x.cantidad);
          });
          
          let  taxes = jsonCantidades.filter(x=>x.AreaDrag === 36);
          taxes.forEach(x =>{
            this.cantidadesBalance.taxes = x.cantidad;
          });
    
          let capitalStock = jsonCantidades.filter(x=>x.AreaDrag === 18);
          capitalStock.forEach(x =>{
            this.cantidadesBalance.capitalStock = x.cantidad;
          });
    
          let machine = jsonCantidades.filter(x=>x.AreaDrag === 7 || x.AreaDrag === 41 || x.AreaDrag === 47);
          machine.forEach(x =>{
            this.cantidadesBalance.machine += x.cantidad;
          });
    
          let retained = jsonCantidades.filter(x=>x.AreaDrag === 19);
          retained.forEach(x =>{
            this.cantidadesBalance.retained = x.cantidad;
          });
          console.log(this.cantidadesBalance)
          this.calcularIncomeAndBalance()
          });
            
          })
    
  }

  calcularIncomeAndBalance(){
    let totSales = 0;
    let retainedE = totSales - this.cantidadesIncome.costGoodSold - this.cantidadesIncome.factotyOverheads - this.cantidadesIncome.selling - this.cantidadesIncome.interest;
    console.log(retainedE);
    let totalAssets = (this.cantidadesBalance.cash) + (this.cantidadesBalance.receivables) + this.cantidadesBalance.rawMaterials + this.cantidadesBalance.finishGood + this.cantidadesBalance.workProcess + this.cantidadesBalance.machine + this.cantidadesBalance.landBuildings;
    let totalLiabilities = this.cantidadesBalance.payables + this.cantidadesBalance.loans + this.cantidadesBalance.capitalStock + (this.cantidadesBalance.retained+retainedE);
    console.log(totalAssets,totalLiabilities)
    if(totalAssets == totalLiabilities){
      console.log("COINCIDEEEE")
      this.boardService.setBackBalanceAndIncome(this.teamId,this.currentMonth,this.cantidadesBalance,this.cantidadesIncome).subscribe((response) => {
          console.log(response);
      });
    }
  }
  compruebaBalance(){
    console.log("RECUPERA BACK")
    this.boardService.getBackOfTeam(this.teamId,this.currentMonth).subscribe((response) => {
      if(response.intResponse == 200){
        console.log(response);
        let cantidadesIncome : datosIncome = { 
          totSales: response.back.totSales,
          costGoodSold: response.back.costGoodSold,
          factotyOverheads: response.back.factotyOverheads,
          selling: response.back.selling,
          interest:response.back.interest,
          advertasting:response.back.advertasting,
        };
        let cantidadesBalance :datosBalance = {
          cash: response.back.cash,
          receivables:response.back.receivables,
          finishGood: response.back.finishGood,
          workProcess: response.back.workProcess,
          rawMaterials:response.back.rawMaterials,
          landBuildings:response.back.landBuildings,
          payables: response.back.payables,
          loans: response.back.loans,
          taxes: response.back.taxes,
          capitalStock:response.back.capitalStock,
          machine: response.back.machine,
          retained: response.back.retained,
        };
        console.log(cantidadesIncome,cantidadesBalance);
        let retainedE = cantidadesIncome.totSales - cantidadesIncome.costGoodSold - cantidadesIncome.factotyOverheads - cantidadesIncome.selling -cantidadesIncome.interest -cantidadesIncome.advertasting;
        console.log(retainedE);
        let totalAssets = (cantidadesBalance.cash) + (cantidadesBalance.receivables) + cantidadesBalance.rawMaterials + cantidadesBalance.finishGood + cantidadesBalance.workProcess + cantidadesBalance.machine + cantidadesBalance.landBuildings;
        let totalLiabilities = cantidadesBalance.payables + cantidadesBalance.loans + cantidadesBalance.capitalStock + (cantidadesBalance.retained+retainedE);
        console.log(totalAssets,totalLiabilities)
      //   if(totalAssets==totalLiabilities && this.getCheckCheckbox(14).blnCheck && this.getCheckCheckbox(15).blnCheck && !this.getCheckCheckbox(16).blnCheck){
      //     //fix cash DONE
      //     console.log(this.returnTotalLst(this.cdkLstCash))
      //     if(this.returnTotalLst(this.cdkLstCash) != cantidadesBalance.cash){
      //          console.log("CASH NO ES IGUAL"); 
      //          setTimeout(() => {
      //            this.cdkLstCash = [];
      //            setTimeout(() => {
      //              this.cdkLstCash = [];
      //              const countGroup20 = Math.floor(cantidadesBalance.cash / 20);
      //              const lessPackege = cantidadesBalance.cash % 20;
      //              for (let i = 0; i < countGroup20; i++) {
      //                this.cdkLstCash.push({
      //                  valor: 20,
      //                  type: 1,
      //                  id: null,
      //                  draggable: false,
      //                })
      //              }
      //              if (lessPackege > 0) {
      //                this.cdkLstCash.push({
      //                  valor: lessPackege,
      //                  type: 1,
      //                  id: null,
      //                  draggable: false,
      //                });
      //              }
      //              this.socketService.splitCoin(this.cdkLstCash, 'cdkLstCash', this.roomId, this.teamId);
      //              this.spinner.hide();
      //            }, 100);
      //          }, 100);
      //     }
      //     console.log(this.returnTotalLst(this.cdkLstCostGS))
      //     // if(this.returnTotalLst(this.cdkLstCostGS) != cantidadesIncome.costGoodSold){
      //     //      console.log("COGS NO ES IGUAL"); 
      //     // }
      //     console.log(this.returnTotalLst(this.cdkLstRetainedE), cantidadesBalance.retained)
      //     if(this.returnTotalLst(this.cdkLstRetainedE) != cantidadesBalance.retained){
      //       console.log("cdkLstRetainedE NO ES IGUAL"); 
      //       setTimeout(() => {
      //         this.cdkLstRetainedE = [];
      //         setTimeout(() => {
      //           this.cdkLstRetainedE = [];
      //           const countGroup20 = Math.floor(cantidadesBalance.retained / 20);
      //           const lessPackege = cantidadesBalance.retained % 20;
      //           for (let i = 0; i < countGroup20; i++) {
      //             this.cdkLstRetainedE.push({
      //               valor: 20,
      //               type: 2,
      //               id: null,
      //               draggable: false,
      //             })
      //           }
      //           if (lessPackege > 0) {
      //             this.cdkLstRetainedE.push({
      //               valor: lessPackege,
      //               type: 2,
      //               id: null,
      //               draggable: false,
      //             });
      //           }
      //           this.socketService.splitCoin(this.cdkLstRetainedE, 'cdkLstRetainedE', this.roomId, this.teamId);
      //           this.spinner.hide();
      //         }, 100);
      //       }, 100);
      //     }
      //     console.log(this.returnTotalLst(this.cdkLstAdvertasing))
      //     if(this.returnTotalLst(this.cdkLstAdvertasing) != cantidadesIncome.advertasting){
      //       console.log("advertasting NO ES IGUAL"); 
      //       setTimeout(() => {
      //         this.cdkLstAdvertasing = [];
      //         setTimeout(() => {
      //           this.cdkLstAdvertasing = [];
      //           this.cdkLstAdvertasing = [{
      //             valor: cantidadesIncome.advertasting,
      //             type: 1,
      //             id: null,
      //             draggable: false,
      //           }]
      //           this.socketService.splitCoin(this.cdkLstAdvertasing, 'cdkLstAdvertasing', this.roomId, this.teamId);

      //           this.spinner.hide();
      //         }, 100);
      //       }, 100);
      //  }

      //   }
      }else{
        console.log("xxxxxxxxxxxxx")
      }
  
    });
  }
  
  fnUpdateAdvBill(){
    console.log("Entra aqui AAAAAAAAAAAA")
    this.boardService.getAdvertisingBill(this.teamId, this.currentMonth).subscribe(response => {
      console.log("Response advertising", response);
      if(response.Advertising == 0 && !this.blnFacilitador){
        location.reload();
      }
      this.owesAdvertising = response.Advertising;
      this.advertising = response.Advertising;
      let tot = 0;
      this.cdkLstAdvertasing.forEach(item => {
        tot += item.valor;
      })
      this.owesAdvertising -= tot;
      if (this.owesAdvertising > 0)
        this.showOwedAdvertising = true;
      else
        this.showOwedAdvertising = false;
    })
  }
  funcionSectreta() {
    if (this.blnFacilitador) {
      this.contadorSecreto++;
      if (this.contadorSecreto == 7) {
        this.fnModalStacksBoardTeams();
      }
    }

  }
  fnOpenWorkshop(idWorkshop){
    console.log(idWorkshop)
    this.workshopService.openTimeZone(idWorkshop,this.currentMonth).subscribe((response) => {
      console.log(response)
      if(response['intResponse']==200){
        console.log("Ok")
        this.blnChangeOpenBoard = true;
        Swal.fire(this.getErrorLabel('1066.1'));
        //location.reload();
      }
    })
  }
  fnCloseWorkshop(idWorkshop){
    console.log(idWorkshop)
    this.workshopService.closeTimeZone(idWorkshop).subscribe((response) => {
      console.log(response)
      if(response['intResponse']==200){
        console.log("Ok")
        this.blnChangeOpenBoard = false;
        Swal.fire(this.getErrorLabel('1066.2'));
        //location.reload();
      }
    })
  }
}
