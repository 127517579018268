<div mat-dialog-title style="border-bottom: 1px solid #f3f3f3; width: 100% !important; text-align: center; margin: 0 0 2px;">
    &nbsp; &nbsp; &nbsp; &nbsp;<label class="FontMontserratSubTitles style1 calibriFont" style="margin: 0;">{{getSingleTextLabel('141')}}</label>
</div>
<mat-dialog-content class="contenedor" style="height: 90% !important;">
    <div *ngIf="blnHas4Cycles" class="subTitle style2a calibriFont">
        {{getSingleTextLabel('8070')}}
    </div>
    <div *ngIf="!blnHas4Cycles" class="subTitle style2a calibriFont">
        {{getSingleTextLabel('8073')}}
    </div>
    <div class="subTitle style2a calibriFont">
        {{getSingleTextLabel('8071')}}
    </div>
    <div class="grid-container">
        <div class="grid-item" [ngStyle]="{'border-color': improvement.color}" *ngFor="let improvement of improvements">
            <div style="display: flex;" [ngStyle]="{'color': improvement.color, 'align-items': improvement.benefit === ''? 'flex-end':''}">
                <div *ngIf="improvement.benefit === ''" style="height: 170px;"></div>
                <div style="width: 10%;"></div>
                <div class="title-item style4 calibriFont" [style]="'width: 80%; padding: 3px;' + 'background-color: '+improvement.color+' !important;'" >
                    {{getSingleTextLabel(improvement.titleLabelNumber)}}
                </div>
                <div style="text-align: center;" *ngIf="improvement.benefit !== ''">
                    <i class="fas fa-search-plus" (click)="fnImprovementView(improvement, true)" style="width: 10%; font-size: 20px; cursor: pointer;"></i>
                </div>
            </div>
            <div class="subTitle-item calibriFont" *ngIf="improvement.benefit !== ''">{{getSingleTextLabel('8068')}}:</div>
            <div class="text-item calibriFont">{{getSingleTextLabel(improvement.benefitLabelNumber)}}</div>
            <div style="text-align: center;">
                <img 
                  [src]="'../../../assets/improvements/' + improvement.benefitUrlImage" 
                  [ngStyle]="{
                    'width': boardMode === 'S&S EBITDA' && improvement.benefitUrlImage === 'SVC_EXPANSION-BENEFIT.svg' 
                      ? improvement.width1 * 0.70 + 'px' 
                      : (improvement.benefitUrlImage === 'SVC_RAW MATERIALS BENEFIT.svg' ? 150 + 'px' : improvement.width1 + 'px'),
                    'height': boardMode === 'S&S EBITDA' && improvement.benefitUrlImage === 'SVC_EXPANSION-BENEFIT.svg' 
                      ? improvement.height1 * 0.70 + 'px' 
                      : (improvement.benefitUrlImage === 'SVC_RAW MATERIALS BENEFIT.svg' ? 60 + 'px' : improvement.height1 + 'px')
                  }">
              </div>              
            <div class="subTitle-item calibriFont" *ngIf="improvement.benefit !== ''">{{getSingleTextLabel('8069')}}:</div>
            <div class="text-item calibriFont">{{getSingleTextLabel(improvement.costLabelNumber)}}</div>
            <div style="text-align: center;">
                <img 
                    [src]="'../../../assets/improvements/' + improvement.costUrlImage" 
                    [ngStyle]="{
                        'width': boardMode === 'S&S EBITDA' && improvement.costUrlImage === 'SVC_EXPANSION OPTION COST.svg' 
                        ? calculateSize(improvement.width2, 'width') + 'px' 
                        : improvement.width2 + 'px',
                        'height': boardMode === 'S&S EBITDA' && improvement.costUrlImage === 'SVC_EXPANSION OPTION COST.svg' 
                        ? calculateSize(improvement.height2, 'height') + 'px' 
                        : improvement.height2 + 'px'
                    }">
            </div>              
            <div *ngIf="improvement.benefit === ''" style="height: 119px;"></div>
            <div class="button-container">
                <button class="btn button-item style8 calibriFont" style="color: white; align-self: center;" [ngStyle]="{'background-color': improvement.color,'border': '2px solid ' + improvement.color}" (click)="fnSelectImprovement(improvement)" [disabled]="improvement.disabled"
                [ngStyle]="{'background-color': !improvement.disabled ? improvement.color:'rgb(106 106 106)', 'box-shadow': improvement.selected ? '#b5d8f7 0px 0px 5px 5px' : ''}">
                {{getSingleTextLabel('137')}}
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="improvementZoom.zoom === true">
        <div class="grid-item-zoom" [ngStyle]="{'border-color': improvementZoom.color}">
            <div style="display: flex;" [ngStyle]="{'color': improvementZoom.color}" *ngIf="improvementZoom.benefit !== ''">
                <div style="width: 10%;"></div>
                <div class="title-item-zoom style4 calibriFont" [style]="'width: 80%; padding: 3px;' + 'background-color: '+improvementZoom.color+' !important;'" >
                    {{getSingleTextLabel(improvementZoom.titleLabelNumber)}}
                </div>
                <div style="text-align: center;">
                    <i class="fas fa-search-minus" (click)="fnImprovementView(improvementZoom, false)" style="width: 10%; font-size: 20px; cursor: pointer;"></i>
                </div>
            </div>
            <div class="subTitle-item-zoom calibriFont" *ngIf="improvementZoom.benefit !== ''">{{getSingleTextLabel('8068')}}:</div>
            <div class="text-item-zoom calibriFont">{{getSingleTextLabel(improvementZoom.benefitLabelNumber)}}</div>
            <div style="text-align: center;">
                <img [src]="'../../../assets/improvements/'+improvementZoom.benefitUrlImage" [ngStyle]="{'width': (improvementZoom.width1*1.18)+'px', 'height': (improvementZoom.height1*1.18)+'px'}">
            </div>
            <div class="subTitle-item-zoom calibriFont" *ngIf="improvementZoom.benefit !== ''">{{getSingleTextLabel('8069')}}:</div>
            <div class="text-item-zoom calibriFont">{{getSingleTextLabel(improvementZoom.costLabelNumber)}}</div>
            <div style="text-align: center;">
                <img [src]="'../../../assets/improvements/'+improvementZoom.costUrlImage" [ngStyle]="{'width': (improvementZoom.width2*1.18)+'px', 'height': (improvementZoom.height2*1.18)+'px'}">
            </div>
            <div class="button-container">
                <button class="btn button-item-zoom style8 calibriFont" style="color: white;" [ngStyle]="{'background-color': improvementZoom.color,'border': '2px solid ' + improvementZoom.color}" (click)="fnSelectImprovement(improvementZoom)" [disabled]="improvementZoom.disabled"
                [ngStyle]="{'background-color': !improvementZoom.disabled ? improvementZoom.color:'rgb(106 106 106)', 'box-shadow': improvementZoom.selected ? '#b5d8f7 0px 0px 5px 5px' : ''}">
                {{getSingleTextLabel('137')}}
                </button>
            </div>
        </div>
    </div>
    <ngx-spinner bdColor="rgba(0,0,0,0.75)" size="large" color="#ffffff" type="ball-clip-rotate-multiple"
        [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
</mat-dialog-content>
<mat-dialog-actions align="center" style="min-height: 0; padding: 3px 0;">
    <button *ngIf="(statusBoard == -1 || statusBoard == 0)  && this.enableSelect" mat-button class="btn btn-primary style8 calibriFont" (click)="fnSave()">
        &nbsp;&nbsp;&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;&nbsp;&nbsp;
    </button>
    <button *ngIf="blnCanReSelect" mat-button class="btn btn-primary style8 calibriFont" (click)="fnSave()">
        &nbsp;&nbsp;&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;&nbsp;&nbsp;
    </button>
    <button mat-button class="btn btn-secondary mx-3 style9 calibriFont" (click)="matDialogRef.close({status: 0})" >{{getSingleTextLabel('135')}}</button>
</mat-dialog-actions>
