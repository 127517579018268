import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-learning-slides-services',
  templateUrl: './learning-slides-services.component.html',
  styleUrls: ['./learning-slides-services.component.scss']
})
export class LearningSlidesServicesComponent implements OnInit {
  blnDemoMode: boolean = false;
  constructor(
    public matDialogRef: MatDialogRef<LearningSlidesServicesComponent>,
    public languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentMonth: number,
      arrTextLabels: Array<any>,
      boardMode: string,
      cycles: number,
      status: number,
      blnFacilitator: boolean
    },
  ) { 
    this.blnDemoMode = this.data.boardMode == "DM";
    console.log(this.cycles ==this.currentMonth && this.status >= 14)
    console.log(!(this.cycles ==this.currentMonth && this.status >= 14))
  }

  ngOnInit(): void {
    console.log(this.data);
    console.log("RTL", this.languageService.getRtl());

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      (<any>$('.carousel')).carousel({
        interval: false,
      });
    }, 1000); 
  }

  getSingleTextLabel(labelNumber: string): string {
    const tmp = this.data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
    return tmp.Label ? tmp.Label : '';
  }
  get blnFacilitator(){
    return this.data.blnFacilitator;
  }
  get currentMonth(){
    return this.data.currentMonth;
  }
  get cycles(){
    return this.data.cycles;
  }
  get status(){
    return this.data.status;
  }
}
