<div mat-dialog-title class="style1 calibriFont" style="border-bottom: 1px solid #f3f3f3; width: 100% !important; text-align: center;">
    &nbsp; &nbsp; &nbsp; &nbsp;<label class="style1 calibriFont" >Start Month {{month}}</label>
</div>
<mat-dialog-content>
    <div class="subTitle style3 calibriFont">
        Use Start/Stop to de-activate game elements during Debrief & Talk Spots.
    </div>
    <table>
        <tr>
            <th></th>
            <th></th>
            <th *ngIf="showBtnImpOpt">
                <mat-radio-group  [disabled]="improvementStatus < 0" [(ngModel)]="selectedOption" (change)="onOptionChange($event)" class="FontMontserratTextInputs">
                  <mat-radio-button [disabled]="improvementStatus == 2" value="1" [color]="'primary'">
                    Yes
                  </mat-radio-button>
                  <mat-radio-button [disabled]="improvementStatus == 2" value="0" [color]="'primary'">
                    No
                  </mat-radio-button>
                </mat-radio-group>
            </th>
        </tr>
        <tr>
            <th></th>
            <th *ngIf="month != 1">    
                <button mat-button class="btn btn-primary buttonTable" (click)="fnSelectOption(0)" [disabled]="previousMonth == month || (cycles == 1 && month == 2)">
                    Stop Month {{month-1}}
                </button>
            </th>
            <th *ngIf="showBtnImpOpt">    
                <button mat-button class="btn btn-primary buttonTable" [disabled]="improvementStatus < 0 || selectedOption == '0' || improvementStatus == 2" (click)="fnSelectOption(1)">
                    Start Projects
                </button>
            </th>
            <th>    
                <button [ngClass]="{'grow': improvementStatus == 2  }" mat-button class="btn btn-primary buttonTable" [disabled]="((improvementStatus < 1 && showBtnImpOpt || improvementStatus < 0 && !showBtnImpOpt) && month > 1) || disableStartMonth || improvementStatus == 2" (click)="fnSelectOption(2)">
                    Start Month {{month}}
                </button>
            </th>
        </tr>
        <tr *ngFor="let jsonStartMonth of arrStartMonth">
            <td class="style2a calibriFont">{{jsonStartMonth.td1}}</td>
            <td *ngFor="let item of arrIterator; let i = index">
                <div *ngIf="jsonStartMonth['td'+(i+2)] === 1" [ngStyle]="{'color': improvementStatus < i ? 'grey' : 'green'}">
                    <i class="far fa-eye"></i>
                </div>
                <div *ngIf="jsonStartMonth['td'+(i+2)] === 2" [ngStyle]="{'color': improvementStatus < i ? 'grey' : 'red'}">
                    <i class="far fa-eye-slash"></i>
                </div>
                <div *ngIf="jsonStartMonth['td'+(i+2)] === 3" [ngStyle]="{'color': improvementStatus < i ? 'grey' : 'green'}">
                    <i class="far fa-eye" style="padding-right: 5px;"></i><i class="fas fa-pencil-alt"></i>
                </div>
            </td>
        </tr>
      </table>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button class="btn btn-secondary style9 calibriFont mx-3" (click)="onCancel()">{{getSingleTextLabel('135')}}
    </button>
</mat-dialog-actions>
