  import { Component, OnInit, Inject, AfterViewInit, ElementRef} from '@angular/core';
  import { BalanceSheetService } from 'src/app/services/balance-sheet/balance.service';
  import { NgxSpinnerService } from 'ngx-spinner';
  import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
  import { BalanceIncomeService } from 'src/app/services/sockets/balance-income/balance-income.service';
  import { Subject } from 'rxjs';
  import { takeUntil } from 'rxjs/operators';
  import { SocketioService } from 'src/app/services/socketio.service';
  
  @Component({
    selector: 'app-standard-balance-sheet-pro-ss',
    templateUrl: './standard-balance-sheet-pro-ss.component.html',
    styleUrls: ['./standard-balance-sheet-pro-ss.component.scss']
  })
  export class StandardBalanceSheetProSsComponent implements OnInit, AfterViewInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    blnShownButtonOk = false;
    
    arrColumnas=[];
    jsonLabels = [];
    jsonCantidades = [];
    mes: number;
    deleteMes: number;
    canEdit = true;
    /*áreas*/
    cash: number = 0;
    intentos: number = 0;
    receivables: number = 0;
    // finishGood: number = 0;
    workProcess: number = 0;
    // rawMaterials: number = 0;
    landBuildings: number = 0;
    payables: number = 0;
    loans: number = 0;
    taxes: number = 0;
    capitalStock: number = 0;
    machine : number = 0;
    retained: number = 0;
    userId: number;
    isInCurrentStatus: boolean;
    elemento : any;
    newWidth: string;
    newHeight: string;
    constructor(private balanceService: BalanceSheetService, private spinner: NgxSpinnerService,
                private socketService: SocketioService,
                public matDialogRef: MatDialogRef< StandardBalanceSheetProSsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { teamId: any, isInCurrentStatus:boolean, userId: number, languageId: any, currentMonth: any, cycles: any, workshopId: any, canEdit: boolean, arrTextLabels: any},
                private balanceIncomeService: BalanceIncomeService,
                private elementRef: ElementRef              ) { }
  
    
    ngOnDestroy(): void {
      this.balanceIncomeService.updateSize(2,this.userId,this.newWidth+','+this.newHeight).subscribe(res =>{
        if(res.intResponse == 200){
          this.destroy$.next(true);
          // Now let's also unsubscribe from the subject itself:
          this.destroy$.unsubscribe();
          this.balanceIncomeService.closeListener();
        }
      });
     
    }
    ngOnInit(): void {
      this.matDialogRef.keydownEvents().subscribe(event => {
        if (event.key === "Escape") {
            this.onCancel();
        }
      });
  
      this.spinner.show();
      this.fnLabels();
      console.log("team",this.data.teamId);
      console.log("mes ",this.data.currentMonth);
      console.log("Cycles ",this.data.cycles , this.data.workshopId);
      
      //this.data.currentMonth = 2;
      this.mes = this.data.currentMonth - 1;
      this.fnCantidadesBoard();
      this.canEdit = this.data.canEdit;
      this.userId = this.data.userId;
      this.isInCurrentStatus = this.data.isInCurrentStatus;
  
      this.balanceService.getCountCantidades(this.data.teamId).subscribe(response => {
        console.log("mio",response.data.total); 
        if(response.data.total == 0){
          console.log("ciclos, no habia registro");
          this.fnLlenado();
        }
        else{
          if(response.data.total == this.data.cycles){
            console.log("ciclos igual");
            this.fnObtenerDatos();
          } else{
              console.log("ciclos cambiaron");
              
              if(response.data.total>this.data.cycles){
                this.deleteMes = response.data.total - this.data.cycles;
                console.log("total",this.deleteMes)
                this.fnDelete();
              }
              else{
                this.fnLlenado();
              }
             
          }
        }
      });
      this.initSocket();
    }
  
    ngAfterViewInit() {
      // Acciones después de que la vista se ha inicializado
      console.log(this.matDialogRef);
      this.elemento = document.getElementById(this.matDialogRef.id);
      console.log(this.elemento);
      const observer = new MutationObserver((mutationsList, observer) => {
        // Recorre las mutaciones en busca de cambios en las dimensiones
        mutationsList.forEach((mutation) => {
          if (mutation.type === 'attributes' && (mutation.attributeName === 'style' || mutation.attributeName === 'class')) {
            // Aquí puedes realizar acciones en respuesta a cambios en el estilo o clases del elemento
            // Por ejemplo, puedes verificar si el ancho o alto cambió y actuar en consecuencia
            this.newHeight = window.getComputedStyle(this.elemento).getPropertyValue('height');
            this.newWidth = window.getComputedStyle(this.elemento).getPropertyValue('width');
            // console.log(`Nuevo ancho: ${newWidth}px, Nuevo alto: ${newHeight}px`);
          }
        });
      });
      const observerOptions = {
        attributes: true,
        attributeFilter: ['style', 'class'],
        subtree: true,
      };
      
      // Registra el elemento de destino y comienza a observar
      observer.observe(this.elemento, observerOptions);
    }
    initSocket(){
      this.balanceIncomeService.setupSocketConnection();
      this.socketService._coin
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.status == "pieceDisabled") {
          if(response.data.userId != this.userId){
            this.canEdit = false;
          }
        }
        else if (response.status == "controlDisabled") {
          if(response.data.userId != this.userId){
            this.canEdit = false;
          }
        }
      });
      this.balanceIncomeService._balance
      .pipe(takeUntil(this.destroy$)).subscribe(response => {
        if(response){
          if(response.status == 'updateInputBalanceServer'){
            this.arrColumnas[response.data.i][response.data.id] = response.data.value
          }
        }
      });
    }
  
    onCancel(): void {
      this.matDialogRef.close();
    }
  
    fnDelete(){
      this.balanceService.deleteMonth(this.data.teamId,this.deleteMes ).subscribe(response => {
        console.log("delete mes: ",response);
        this.fnObtenerDatos();
      });
    }
    
    fnLlenado(){
      this.balanceService.newRegister(this.data.workshopId, this.data.teamId, this.data.cycles).subscribe(response => {
        console.log("newRecord: ",response);
        this.fnObtenerDatos();
      });
    }
  
    fnObtenerDatos(){
      this.balanceService.getCantidadesLlenado(this.data.teamId).subscribe(async (response) => {
        this.spinner.hide();
        console.log(response['data']);
        this.arrColumnas = response['data'];
        let responseTemp = await this.balanceService.getCountCantidadesTemp(this.data.teamId, this.data.currentMonth).toPromise();
        const id = this.arrColumnas[this.data.currentMonth-1].balanceSheetSaveID;
        this.arrColumnas[this.data.currentMonth-1] = responseTemp.data ? responseTemp.data:this.arrColumnas[this.data.currentMonth-1];
        this.arrColumnas[this.data.currentMonth-1].balanceSheetSaveID = id;
        // this.spinner.hide();
      });
    }
  
     /*obtener etiquetas de lenguaje*/
     fnLabels(){
      this.balanceService.getLabelsByLanguageId(this.data.languageId).subscribe((response) => {
        console.log('response', response)
        this.jsonLabels = response['data'];
        console.log("Labels Obtenidas: ",this.jsonLabels );
        //this.spinner.hide();
      });
    }
  
     /*obtener el texto dependiendo del id*/
    fnObtenerLabel(id){
      let valor = this.jsonLabels.filter(x=>x.LabelNumber === id)
      let label = valor[0].Label;
      return label;
    }
  
    /*obtencion de datos del board*/
    fnCantidadesBoard(){
      this.balanceService.getCantidadesBoard(this.data.teamId).subscribe((response) => {
        this.jsonCantidades = response['data'];
        console.log("Cantidades: ",this.jsonCantidades);
  
        let cash = this.jsonCantidades.filter(x=>x.AreaDrag === 30);
        cash.forEach(x =>{
          this.cash = x.cantidad;
        });
    
        let receivables = this.jsonCantidades.filter(x=>x.AreaDrag === 15 || x.AreaDrag === 16 || x.AreaDrag === 17);
        receivables.forEach(x =>{
            this.receivables = (this.receivables) + (x.cantidad);
        });
        
        /* let  finishGood = this.jsonCantidades.filter(x=>x.AreaDrag === 11);
        finishGood.forEach(x =>{
          if(x.tipo == 8){
            this.finishGood += x.cantidad * 2;
          }else{
            this.finishGood += x.cantidad;
          }
        });
        */
     
        let workProcess = this.jsonCantidades.filter(x=> x.AreaDrag === 6);
        workProcess.forEach(x =>{
            this.workProcess = (this.workProcess) + (x.tipo == 8 ? (x.cantidad) * 2:x.cantidad);
        });
        console.log('work process',workProcess);
       /*
        let  rawMaterials = this.jsonCantidades.filter(x=>x.AreaDrag === 6);
        rawMaterials.forEach(x =>{
          this.rawMaterials = x.cantidad;
        });
        */
       /*
        let  landBuildings = this.jsonCantidades.filter(x=>x.AreaDrag === 10);
        landBuildings.forEach(x =>{
          this.landBuildings = x.cantidad;
        });
        */
  
        let payables = this.jsonCantidades.filter(x=>x.AreaDrag === 37 || x.AreaDrag === 38);
        payables.forEach(x =>{
            this.payables = (this.payables) + (x.cantidad);
        });
  
        
        let loans = this.jsonCantidades.filter(x=>x.AreaDrag === 12 || x.AreaDrag === 13 || x.AreaDrag === 14);
        loans.forEach(x =>{
            this.loans = (this.loans) + (x.cantidad);
        });
        
       /*
        let  taxes = this.jsonCantidades.filter(x=>x.AreaDrag === 36);
        taxes.forEach(x =>{
          this.taxes = x.cantidad;
        });
        */
  
        let capitalStock = this.jsonCantidades.filter(x=>x.AreaDrag === 18);
        capitalStock.forEach(x =>{
          this.capitalStock = x.cantidad;
        });
  
        let machine = this.jsonCantidades.filter(x=>x.AreaDrag === 7 || x.AreaDrag === 41 || x.AreaDrag === 47);
        machine.forEach(x =>{
          this.machine += x.cantidad;
        });
  
        let retained = this.jsonCantidades.filter(x=>x.AreaDrag === 19);
        retained.forEach(x =>{
          this.retained = x.cantidad;
        });
      });
    }
    
    /*calcular suma*/
    fnResultado(number){
      let defaultvalue=0;
     
      switch(number){
      case 1:
        if(!parseInt((document.getElementById('input10') as HTMLInputElement).value)){(document.getElementById('input10') as HTMLInputElement).value = defaultvalue.toString();}
        if(!parseInt((document.getElementById('input20') as HTMLInputElement).value)){(document.getElementById('input20') as HTMLInputElement).value = defaultvalue.toString();}
        //if(!parseInt((document.getElementById('input30') as HTMLInputElement).value)){(document.getElementById('input30') as HTMLInputElement).value = defaultvalue.toString();} 
        if(!parseInt((document.getElementById('input40') as HTMLInputElement).value)){(document.getElementById('input40') as HTMLInputElement).value = defaultvalue.toString();}
        //if(!parseInt((document.getElementById('input50') as HTMLInputElement).value)){(document.getElementById('input50') as HTMLInputElement).value = defaultvalue.toString();}
        //var resultado = ((parseInt((document.getElementById('input10') as HTMLInputElement).value)) + (parseInt((document.getElementById('input20') as HTMLInputElement).value)) +
          //               (parseInt((document.getElementById('input30') as HTMLInputElement).value)) + (parseInt((document.getElementById('input40') as HTMLInputElement).value)) +
          //               (parseInt((document.getElementById('input50') as HTMLInputElement).value)));
        resultado = ((parseInt((document.getElementById('input10') as HTMLInputElement).value)) + (parseInt((document.getElementById('input20') as HTMLInputElement).value)) +
          + (parseInt((document.getElementById('input40') as HTMLInputElement).value)));
        (document.getElementById('input60') as HTMLInputElement).value = resultado.toString();
        this.fnChangeInput(resultado, 'totalCurrent1',this.mes);
        break;
      case 2:
        if(!parseInt((document.getElementById('input70') as HTMLInputElement).value)){(document.getElementById('input70') as HTMLInputElement).value = defaultvalue.toString();}
        //if(!parseInt((document.getElementById('input80') as HTMLInputElement).value)){(document.getElementById('input80') as HTMLInputElement).value = defaultvalue.toString();}
        var resultado = parseInt((document.getElementById('input70') as HTMLInputElement).value);
        (document.getElementById('input90') as HTMLInputElement).value = resultado.toString();
        this.fnChangeInput(resultado, 'totalFixed',this.mes);
        break;
      case 3:
        if(!parseInt((document.getElementById('input60') as HTMLInputElement).value)){(document.getElementById('input60') as HTMLInputElement).value = defaultvalue.toString();}
        if(!parseInt((document.getElementById('input90') as HTMLInputElement).value)){(document.getElementById('input90') as HTMLInputElement).value = defaultvalue.toString();}
        var resultado = ((parseInt((document.getElementById('input60') as HTMLInputElement).value)) + (parseInt((document.getElementById('input90') as HTMLInputElement).value)));
        (document.getElementById('input100') as HTMLInputElement).value = resultado.toString();
        this.fnChangeInput(resultado, 'totalCurrent2',this.mes);
        break;
      case 4:
        if(!parseInt((document.getElementById('input110') as HTMLInputElement).value)){(document.getElementById('input110') as HTMLInputElement).value = defaultvalue.toString();}
        if(!parseInt((document.getElementById('input120') as HTMLInputElement).value)){(document.getElementById('input120') as HTMLInputElement).value = defaultvalue.toString();}
        //if(!parseInt((document.getElementById('input130') as HTMLInputElement).value)){(document.getElementById('input130') as HTMLInputElement).value = defaultvalue.toString();}
        var resultado = ((parseInt((document.getElementById('input110') as HTMLInputElement).value)) + (parseInt((document.getElementById('input120') as HTMLInputElement).value)) );
        (document.getElementById('input140') as HTMLInputElement).value = resultado.toString();
        this.fnChangeInput(resultado, 'totalLiabilities',this.mes);
        break; 
      case 5:
        if(!parseInt((document.getElementById('input150') as HTMLInputElement).value)){(document.getElementById('input150') as HTMLInputElement).value = defaultvalue.toString();}
        if(!parseInt((document.getElementById('input160') as HTMLInputElement).value)){(document.getElementById('input160') as HTMLInputElement).value = defaultvalue.toString();}
        var resultado = ((parseInt((document.getElementById('input150') as HTMLInputElement).value)) + (parseInt((document.getElementById('input160') as HTMLInputElement).value)));
        (document.getElementById('input170') as HTMLInputElement).value = resultado.toString();
        this.fnChangeInput(resultado, 'totalShareholder',this.mes);
        break;
      case 6:
        if(!parseInt((document.getElementById('input140') as HTMLInputElement).value)){(document.getElementById('input140') as HTMLInputElement).value = defaultvalue.toString();}
        if(!parseInt((document.getElementById('input170') as HTMLInputElement).value)){(document.getElementById('input170') as HTMLInputElement).value = defaultvalue.toString();}
        var resultado = ((parseInt((document.getElementById('input140') as HTMLInputElement).value)) + (parseInt((document.getElementById('input170') as HTMLInputElement).value)));
        (document.getElementById('input180') as HTMLInputElement).value = resultado.toString();
        this.fnChangeInput(resultado, 'totalLiabilitiesAndEquity',this.mes);
        break;   
     }
    }
  
    fnValidateInput(valueInp){
      //console.log(valueInp);
      let varStrId= "input1"+valueInp;
      const valueEdited = (document.getElementById(varStrId) as HTMLInputElement);
    }
  
    fnSave(){
      if(!parseInt((document.getElementById('input10') as HTMLInputElement).value )) { (document.getElementById('input10') as HTMLInputElement).value = "0"}
      if(!parseInt((document.getElementById('input20') as HTMLInputElement).value )) { (document.getElementById('input20') as HTMLInputElement).value = "0" }
      //if(!parseInt((document.getElementById('input30') as HTMLInputElement).value )) { (document.getElementById('input30') as HTMLInputElement).value = "0" }
      if(!parseInt((document.getElementById('input40') as HTMLInputElement).value )) { (document.getElementById('input40') as HTMLInputElement).value = "0" }
      //if(!parseInt((document.getElementById('input50') as HTMLInputElement).value )) { (document.getElementById('input50') as HTMLInputElement).value = "0" }
      if(!parseInt((document.getElementById('input70') as HTMLInputElement).value )) { (document.getElementById('input70') as HTMLInputElement).value = "0" }
      //if(!parseInt((document.getElementById('input80') as HTMLInputElement).value )) { (document.getElementById('input80') as HTMLInputElement).value = "0" }
      
      if(!parseInt((document.getElementById('input110') as HTMLInputElement).value )) { (document.getElementById('input110') as HTMLInputElement).value = "0" }
      if(!parseInt((document.getElementById('input120') as HTMLInputElement).value )) { (document.getElementById('input120') as HTMLInputElement).value = "0" }
      //if(!parseInt((document.getElementById('input130') as HTMLInputElement).value )) { (document.getElementById('input130') as HTMLInputElement).value = "0" }
      if(!parseInt((document.getElementById('input150') as HTMLInputElement).value )) { (document.getElementById('input150') as HTMLInputElement).value = "0" }
      if(!parseInt((document.getElementById('input160') as HTMLInputElement).value )) { (document.getElementById('input160') as HTMLInputElement).value = "0" }
      
      
      //let resultado = ((parseInt((document.getElementById('input10') as HTMLInputElement).value)) + (parseInt((document.getElementById('input20') as HTMLInputElement).value)) +
        //              (parseInt((document.getElementById('input30') as HTMLInputElement).value)) + (parseInt((document.getElementById('input40') as HTMLInputElement).value)) +
        //              (parseInt((document.getElementById('input50') as HTMLInputElement).value)) );
      let resultado = ((parseInt((document.getElementById('input10') as HTMLInputElement).value)) + (parseInt((document.getElementById('input20') as HTMLInputElement).value)) +
        + (parseInt((document.getElementById('input40') as HTMLInputElement).value)));
      let resultado2 = ((parseInt((document.getElementById('input70') as HTMLInputElement).value)) );
      let resultado3 = ((parseInt((document.getElementById('input60') as HTMLInputElement).value)) + (parseInt((document.getElementById('input90') as HTMLInputElement).value)));
      let resultado4 = ((parseInt((document.getElementById('input110') as HTMLInputElement).value)) + (parseInt((document.getElementById('input120') as HTMLInputElement).value)) );
      let resultado5 = ((parseInt((document.getElementById('input150') as HTMLInputElement).value)) + (parseInt((document.getElementById('input160') as HTMLInputElement).value)));
      let resultado6 = ((parseInt((document.getElementById('input140') as HTMLInputElement).value)) + (parseInt((document.getElementById('input170') as HTMLInputElement).value)));
      
      console.log("sumas",resultado, resultado2, resultado3, resultado4,resultado5, resultado6);
      console.log("cantidades", "cash:",this.cash, "receivables:",this.receivables, "workProcess:",this.workProcess, "machine:",this.machine,"landBuildings:",this.landBuildings,"payables:", this.payables, "loans:",this.loans, "taxes:",this.taxes, "capitalStock:",this.capitalStock, "retained:",this.retained)
    
     if((parseInt((document.getElementById('input10') as HTMLInputElement).value)== this.cash) && 
        (parseInt((document.getElementById('input20') as HTMLInputElement).value)== this.receivables) && 
        (parseInt((document.getElementById('input70') as HTMLInputElement).value)== this.machine) &&
        (parseInt((document.getElementById('input160') as HTMLInputElement).value)== this.retained) &&
        
        (parseInt((document.getElementById('input40') as HTMLInputElement).value)== this.workProcess) &&
        
        
        (parseInt((document.getElementById('input110') as HTMLInputElement).value)== this.payables) &&
        (parseInt((document.getElementById('input120') as HTMLInputElement).value)== this.loans) &&
        
        (parseInt((document.getElementById('input150') as HTMLInputElement).value)== this.capitalStock) &&
        (parseInt((document.getElementById('input60') as HTMLInputElement).value)== resultado) && 
        (parseInt((document.getElementById('input90') as HTMLInputElement).value)== resultado2)  && 
        (parseInt((document.getElementById('input100') as HTMLInputElement).value)== resultado3) &&
        (parseInt((document.getElementById('input140') as HTMLInputElement).value)== resultado4) &&
        (parseInt((document.getElementById('input170') as HTMLInputElement).value)== resultado5) && 
        (parseInt((document.getElementById('input180') as HTMLInputElement).value)== resultado6)){  
        console.log("si es igual")
        
        this.balanceService.updateCantidadesServicesBoard(
          this.data.workshopId, 
          this.data.teamId,
          this.data.currentMonth, 
          this.cash.toString(),
          this.receivables.toString(),
          //this.finishGood.toString(),
          this.workProcess.toString(),
          //this.rawMaterials.toString(),
          resultado.toString(),
          (document.getElementById('input70') as HTMLInputElement).value,
          this.landBuildings.toString(),
          resultado2.toString(),
          resultado3.toString(),
          this.payables.toString(),
          this.loans.toString(),
          this.taxes.toString(),
          resultado4.toString(),
          this.capitalStock.toString(),
          (document.getElementById('input160') as HTMLInputElement).value,
          resultado5.toString(),
          resultado6.toString()).subscribe((response) => {
          console.log("updateRecord: ",response);
        });
  
        console.log(this.data.workshopId, this.data.teamId, this.data.currentMonth);
        this.balanceService.updateMonth(this.data.workshopId, this.data.teamId, this.data.currentMonth).subscribe((response) => {
          console.log("update month: ",response);
        });
        this.socketService.finishBalance(this.data.workshopId,this.data.teamId,this.data.currentMonth)
  
        this.matDialogRef.close({
          status: 'save'
        });
         /*GUARDADO DE INFO  FALTA*/
     }
     else{
      console.log("NO es igual");
      (document.getElementById('input60') as HTMLInputElement).value = '0';
      (document.getElementById('input90') as HTMLInputElement).value = '0';
      (document.getElementById('input100') as HTMLInputElement).value = '0';
      (document.getElementById('input140') as HTMLInputElement).value = '0';
      (document.getElementById('input170') as HTMLInputElement).value = '0';
      this.intentos+=1;
      console.log(this.intentos);
      if((parseInt((document.getElementById('input60') as HTMLInputElement).value)== resultado) && 
      (parseInt((document.getElementById('input90') as HTMLInputElement).value)== resultado2)  && 
      (parseInt((document.getElementById('input100') as HTMLInputElement).value)== resultado3)){
        //console.log("si es igual la suma")
      }
      else{
        (document.getElementById('input100') as HTMLInputElement).value = '0';
      }
  
      if((parseInt((document.getElementById('input140') as HTMLInputElement).value)== resultado4) &&
      (parseInt((document.getElementById('input170') as HTMLInputElement).value)== resultado5) && 
      (parseInt((document.getElementById('input180') as HTMLInputElement).value)== resultado6)){
        //console.log("si es igual la suma")
      }
      else{
        (document.getElementById('input180') as HTMLInputElement).value = '0';
      }
  
     }
    }
    fnCanel(){
      this.matDialogRef.close({
        status: 'cancel'
      });
    }
  
    validateKey(nextInput){
      document.getElementById(nextInput).focus();
    }
  
    fnChangeInput(value, id, i){
      this.balanceIncomeService.updateInputStandarBalance(value, id, 'room'+this.data.teamId,i, this.data.workshopId, this.data.teamId, this.data.currentMonth);
    }
  
    getSingleTextLabel(labelNumber: string): string {
      const tmp = this.data.arrTextLabels.find(element => element.LabelNumber === labelNumber);
      return tmp ? tmp.Label : '';
    }
  
    validateInputNumber(event: KeyboardEvent) {
      const pattern = /[0-9/-]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {    
          event.preventDefault();
      }
    }
  
    fnResize(blnSizeMin){
      console.log("resizing");
      if(blnSizeMin)
      {
        blnSizeMin = false; 
        this.matDialogRef.updateSize("777px","860px");
        
      }
      else
      {
        blnSizeMin= true;
        this.matDialogRef.updateSize("330px","400px");
      }
      console.log("after resize");
    }
  
  }
  
