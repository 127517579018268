<!doctype html>
<html lang="en">

<head>
    <!-- Compiled and minified Bootstrap CSS -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">

</head>
<body class="all" [ngClass]="{'rtl': languageService.getRtl()}">
    <div *ngIf="blnFacilitator; else caseTeam">
        <div id="carouselExampleCaptions" class="carousel carousel-dark slide" style="height: 100%;">
            <div id="carouselExampleControls" class="carousel slide">
                <div class="carousel-inner" style="height: 100%;">
                    
                    <div class="carousel-item active">
                        <div class="row justify-content-center">
                            <div class="caption firstSlide" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 1</span> -->
                                <span class="firstSlideTitle font-weight-bold calibriFont">{{getSingleTextLabel('7007') | uppercase}}</span>
                                <span class="firstSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="firstSlideISDesc style2a calibriFont">{{getSingleTextLabel('7017')}}</span>
                                <span class="firstSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <span class="firstSlideBSDesc style2a calibriFont">{{getSingleTextLabel('7018')}}</span>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 1-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption secondSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 2</span> -->
                                <!--<span class="secondSlideDescription calibriFont">{{getSingleTextLabel('3018')}}</span>-->
                                <span class="secondSlideDescription calibriFont">
                                    <span class="font-weight-bold">{{ getSingleTextLabel('3018') }}</span>
                                    <br><span class="textAfterColon">{{ getSingleTextLabel('3018.1') }}</span>
                                </span>
                                <span class="secondSlideText2 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <span class="secondSlideText1 style2a calibriFont">{{getSingleTextLabel('7018')}}</span>
                                <span class="secondSlide3026 calibriFont">{{getSingleTextLabel('3026')}}:</span>
                                <span class="secondSlide79 calibriFont">{{getSingleTextLabel('79')}}</span>
                                <span class="secondSlide66 calibriFont">{{getSingleTextLabel('66')}}</span>
                                <span class="secondSlide3025 calibriFont">{{getSingleTextLabel('3025')}}:</span>
                                <span class="secondSlide45 calibriFont">{{getSingleTextLabel('45')}}</span>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 2-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption thirdSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 3</span> -->
                                <span class="thirdSlideTitle calibriFont">
                                    <span class="font-weight-bold">{{getSingleTextLabel('7139') | uppercase}}:
                                    </span><br>{{getSingleTextLabel('96')}}, {{getSingleTextLabel('7136')}}, {{getSingleTextLabel('7137')}},
                                    {{getSingleTextLabel('7138')}}
                                </span>
                                <div class="thirdSlide96 calibriFont">
                                    <span>{{getSingleTextLabel('96')}}</span>
                                </div>
                                <div class="thirdSlide7136 calibriFont">
                                    <span>{{getSingleTextLabel('7136')}}</span>
                                </div>
                                <div class="thirdSlide7137 calibriFont">
                                    <span>{{getSingleTextLabel('7137')}}</span>
                                </div>
                                <div class="thirdSlide7138 calibriFont">
                                    <span>{{getSingleTextLabel('7138')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 3-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption fourthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 4</span> -->
                                <!--<span class="fourthSlideTitle calibriFont">{{getSingleTextLabel('3002')}}</span>-->
                                <span class="fourthSlideTitle calibriFont">
                                    <span class="font-weight-bold">{{ getSingleTextLabel('3002') }}</span>
                                    <br><span class="textAfterColon">{{ getSingleTextLabel('3002.1') }}</span>
                                </span>
                                <span class="fourthSlideSales calibriFont">{{getSingleTextLabel('15')}}</span>
                                <span class="fourthSlideExpenses calibriFont">{{getSingleTextLabel('95')}}</span>
                                <span class="fourthSlideProfit calibriFont">{{getSingleTextLabel('96')}}</span>
                                <span class="fourthIncomeStatement style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="fourthIncomeStatementDesc style2a calibriFont">{{getSingleTextLabel('7017')}}</span>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 4-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption fifthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 5</span> -->
                                <span class="fifthSlideTitle calibriFont">{{getSingleTextLabel('7140')}}</span>
                                <span class="fifthSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="fifthSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="fithMiniBox">
                                    <span class="fifthSlide7141 style2a calibriFont"><span class="style2c">{{getSingleTextLabel('96')}}</span> {{getSingleTextLabel('7141')}}. {{getSingleTextLabel('7142')}}</span>
                                    <span class="fifthSlide7143 style2a calibriFont"><span class="style2c">{{getSingleTextLabel('7002')}}</span> {{getSingleTextLabel('7143')}}. {{getSingleTextLabel('7144')}}</span>
                                </div>                                
                                <div class="fifthSlide96 calibriFont">
                                    <span>{{getSingleTextLabel('96')}}</span>
                                </div>
                                <div class="fifthSlide7002 calibriFont">
                                    <span>{{getSingleTextLabel('7002')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 5-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption sixthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 6</span> -->
                                <span class="sixthSlideTitle calibriFont">{{getSingleTextLabel('7145')}} {{getSingleTextLabel('13')}}</span>
                                <span class="sixthSlide13 style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="sixthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="sixMiniBox1">
                                    <span class="sixthSlide7146 style2c calibriFont">{{getSingleTextLabel('7146')}}:</span>
                                    <span class="sixthSlide7147 style2a calibriFont">{{getSingleTextLabel('7147')}}</span>
                                    <ul style="margin-bottom: 0;line-height: 15px;">
                                        <li class="sixthSlide7148 style2a calibriFont">{{getSingleTextLabel('7148')}}</li>
                                        <li class="sixthSlide7149 style2a calibriFont">{{getSingleTextLabel('7149')}}</li>
                                        <li class="sixthSlide7150 style2a calibriFont">{{getSingleTextLabel('7150')}}</li>
                                    </ul>
                                    <span class="sixthSlide71472 style2a calibriFont">{{getSingleTextLabel('7147.2')}}</span>
                                    <ul style="margin-bottom: 0;line-height: 15px;">
                                        <li class="sixthSlide714821 style2a calibriFont">{{getSingleTextLabel('7174.2.1')}}</li>
                                    </ul>
                                </div>
                                
                                <div class="sixMiniBox2"> 
                                    <span class="sixthSlide7151 style2c calibriFont">{{getSingleTextLabel('7151')}}:</span>
                                    <span class="sixthSlide7152 style2a calibriFont">{{getSingleTextLabel('7152')}}</span>
                                    <ul style="margin-bottom: 0;line-height: 13px;">
                                        <li class="sixthSlide7154 style2a calibriFont">{{getSingleTextLabel('7154')}}</li>
                                        <li class="sixthSlide7155 style2a calibriFont">{{getSingleTextLabel('7155')}}</li>
                                        <li class="sixthSlide7153 style2a calibriFont">{{getSingleTextLabel('7153')}}</li>
                                    </ul>
                                    <span class="sixthSlide71521 style2a calibriFont">{{getSingleTextLabel('7152.1')}}</span>
                                    <ul style="margin-bottom: 0;line-height:13px;">
                                        <li class="sixthSlide715212 style2a calibriFont">{{getSingleTextLabel('7152.2')}}</li> 
                                        <li class="sixthSlide7157 style2a calibriFont">{{getSingleTextLabel('7157')}}</li> 
                                        <li class="sixthSlide7156 style2a calibriFont">{{getSingleTextLabel('7156')}}</li> 
                                        <li class="sixthSlide7193 style2a calibriFont">{{getSingleTextLabel('7193')}}</li> 
                                     
                                    </ul>
                                    <span class="sixthSlide71931 style2a calibriFont">{{getSingleTextLabel('7193.1')}}</span>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                    <!--fin vista 6-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption seventhSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 7</span> -->
                                <span class="seventhSlide7145 calibriFont">{{getSingleTextLabel('7145') | uppercase}} {{getSingleTextLabel('44') | uppercase}}</span>
                                <span class="seventhSlide13 style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="seventhSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <span class="seventhSlide7002 style2c calibriFont">{{getSingleTextLabel('7002')}}:</span>
                                <ul class="style2a calibriFont seventhSlideList1">
                                    <li>{{getSingleTextLabel('7159')}}</li>
                                    <li>{{getSingleTextLabel('7158')}}</li>
                                    <li>{{getSingleTextLabel('7160')}}</li>
                                </ul>
                                <span class="seventhSlide7161 style2c calibriFont">{{getSingleTextLabel('7161')}}:</span>
                                <ul class="style2a calibriFont seventhSlideList2">
                                    <li>{{getSingleTextLabel('7162')}}</li>
                                    <li>{{getSingleTextLabel('7163')}}</li>
                                    <li>{{getSingleTextLabel('7164')}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 7-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption eighthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 8</span> -->
                                <span class="eighthSlideTitle calibriFont">{{getSingleTextLabel('7166')}}</span>
                                <span class="eighthSlide13 style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="eighthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div style="top: 157px; left: 315px; position: absolute; max-width: 374px;">
                                    <div class="eighthSlide7167 style2a calibriFont">{{getSingleTextLabel('7167')}}</div>
                                    <span class="eighthSlide7168 style2c calibriFont">{{getSingleTextLabel('7168')}}</span>
                                    <ul class="eighthSlideList1 style2a calibriFont">
                                        <li>{{getSingleTextLabel('7169')}}</li>
                                        <li>{{getSingleTextLabel('7170')}}</li>
                                    </ul>
                                    <span class="eighthSlide7171 style2c calibriFont">{{getSingleTextLabel('7171')}}</span>
                                    <ul class="eighthSlideList2 style2a calibriFont">
                                        <li>{{getSingleTextLabel('25')}}</li>
                                        <li>{{getSingleTextLabel('7108')}}</li>
                                        <li>{{getSingleTextLabel('7172')}}</li>
                                        <li>{{getSingleTextLabel('30')}}</li>
                                        <li>{{getSingleTextLabel('33')}}</li>
                                        <li>{{getSingleTextLabel('7173')}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 8-->
    
                    <div class="carousel-item" >
                        <div class="row justify-content-center">
                            <div class="caption ninthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 9</span> -->
                                <span class="ninthSlideTitle calibriFont">{{getSingleTextLabel('7174')}}</span>
                                <span class="ninthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="ninthBox">
                                    <div class="ninthSlide7195 style2a calibriFont">{{getSingleTextLabel('7195')}}</div>
                                    <div>
                                        <span class="ninthSlide7175 style2c calibriFont">{{getSingleTextLabel('7175')}}</span>
                                        <ul class="ninthSlideList1 style2a calibriFont">
                                            <li>{{getSingleTextLabel('7176')}}</li>
                                            <li>{{getSingleTextLabel('7177')}}</li>
                                            <li>{{getSingleTextLabel('7178')}}</li>
                                            <li>{{getSingleTextLabel('7179')}}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <span class="ninthSlide7171 style2c calibriFont">{{getSingleTextLabel('7171')}}</span>
                                        <ul class="ninthSlideList2 style2a calibriFont" >
                                            <li>{{getSingleTextLabel('7180')}}</li>
                                            <li>{{getSingleTextLabel('7181')}}</li>
                                            <li>{{getSingleTextLabel('7182')}}</li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 9-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption tenthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 10</span> -->
                                <span class="calibriFont tenthSlideTitle">{{getSingleTextLabel('7187')}}</span>
                                <span class="calibriFont tenthSlideSubTitle">{{getSingleTextLabel('7196')}}</span>
                                <span class="tenthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="tenBox">
                                    <span class="calibriFont tenthSlide7183">
                                        <span class="style2c">{{getSingleTextLabel('7183')}}</span><br/><span class="style2a" >{{getSingleTextLabel('7184')}}</span>
                                    </span>
                                    <ul class="tenthSlideList1 style2a calibriFont">
                                        <li>{{getSingleTextLabel('7185')}}</li>
                                        <li>{{getSingleTextLabel('7186')}}</li>
                                    </ul>
                                    <span class="style2a calibriFont tenthSlide7178">{{getSingleTextLabel('7183')}} {{getSingleTextLabel('7188')}}</span>
                                    <span class="tenthSlide7171 style2c calibriFont">{{getSingleTextLabel('7171')}}</span>
                                    <ul class="tenthSlideList2 style2a calibriFont">
                                        <li>{{getSingleTextLabel('47')}}</li>
                                        <li>{{getSingleTextLabel('7189')}}</li>
                                    </ul>
                                    
                                </div>
                                <span class="tenthSlide7103 style2a calibriFont">
                                    <span class="style2c">{{getSingleTextLabel('7103')}}</span>
                                    <br/>
                                    {{getSingleTextLabel('7191')}}
                                </span>
                                <span class="tenthSlide7197 calibriFont"><span style="color: #234b91;">{{getSingleTextLabel('46')}}:</span>{{getSingleTextLabel('7197')}}</span>
                                <span class="tenthSlide7198 calibriFont"><span style="color: #234b91;">{{getSingleTextLabel('155')}}:</span> {{getSingleTextLabel('7198')}}</span>

                                
                            </div>
                        </div>
                    </div>
                    <!--fin vista 10-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption eleventhSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 11</span> -->
                                <span class="eleventhSlideTitle calibriFont">{{getSingleTextLabel('7005') | uppercase}}</span>
                                <span class="eleventhSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="eleventhSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="eleventhSlide15 calibriFont">
                                    <span>{{getSingleTextLabel('15')}}</span>
                                </div>
                                <div class="eleventhSlide96 calibriFont">
                                    <span>{{getSingleTextLabel('96')}}</span>
                                </div>
                                <div class="eleventhSlide45 calibriFont">
                                    <span>{{getSingleTextLabel('45')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 11-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption twelfthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 12</span> -->
                                <div style="height: 75px;">
                                    <span class="twelfthSlideTitle calibriFont">{{getSingleTextLabel('7006') | uppercase}}</span>

                                </div>
                                <span class="twelfthSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="twelfthSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                
                                <span class="twelfthSlide15 calibriFont">{{getSingleTextLabel('15')}}</span>
                                <span class="twelfthSlide96 calibriFont">{{getSingleTextLabel('96')}}</span>
                                <span class="twelfthSlide45 calibriFont">{{getSingleTextLabel('45')}}</span>
    
                                <div class="twelfthSlide7010 calibriFont">
                                    <span><span class="twelfthSlide7013">{{getSingleTextLabel('7013')}}</span><br>{{getSingleTextLabel('7010')}} = {{getSingleTextLabel('96')}} / {{getSingleTextLabel('15')}} {{getSingleTextLabel('7194')}}</span>
                                </div>
                                <div class="twelfthSlide7011 calibriFont">
                                    <span><span class="twelfthSlide7014">{{getSingleTextLabel('7014')}}</span><br>{{getSingleTextLabel('7011')}} = {{getSingleTextLabel('15')}} / {{getSingleTextLabel('45')}}</span>
                                </div>
                                <div  class="twelfthSlide7012 calibriFont">
                                    <span><span class="twelfthSlide7015">{{getSingleTextLabel('7015')}}</span><br>{{getSingleTextLabel('7012')}} = {{getSingleTextLabel('96')}} / {{getSingleTextLabel('45')}} {{getSingleTextLabel('7194')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 12-->
    
                </div>
    
    
                <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
    <ng-template #caseTeam>
        
    <div *ngIf="!(cycles ==currentMonth && status >= 14); else otherCase">
        <div id="carouselExampleCaptions" class="carousel carousel-dark slide" style="height: 100%;">
            <div id="carouselExampleControls" class="carousel slide">
                <div class="carousel-inner" style="height: 100%;">
                    
                    <div class="carousel-item active" *ngIf="(currentMonth===1 || currentMonth===2) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption firstSlide" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 1</span> -->
                                <span class="firstSlideTitle font-weight-bold calibriFont">{{getSingleTextLabel('7007') | uppercase}}</span>
                                <span class="firstSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="firstSlideISDesc style2a calibriFont">{{getSingleTextLabel('7017')}}</span>
                                <span class="firstSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <span class="firstSlideBSDesc style2a calibriFont">{{getSingleTextLabel('7018')}}</span>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 1-->
    
                    <div class="carousel-item" *ngIf="(currentMonth===1 || currentMonth===2) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption secondSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 2</span> -->
                                <!--<span class="secondSlideDescription calibriFont">{{getSingleTextLabel('3018')}}</span>-->
                                <span class="secondSlideDescription calibriFont">
                                    <span class="font-weight-bold">{{ getSingleTextLabel('3018') }}</span>
                                    <br><span class="textAfterColon">{{ getSingleTextLabel('3018.1') }}</span>
                                </span>
                                <span class="secondSlideText2 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <span class="secondSlideText1 style2a calibriFont">{{getSingleTextLabel('7018')}}</span>
                                <span class="secondSlide3026 calibriFont">{{getSingleTextLabel('3026')}}:</span>
                                <span class="secondSlide79 calibriFont">{{getSingleTextLabel('79')}}</span>
                                <span class="secondSlide66 calibriFont">{{getSingleTextLabel('66')}}</span>
                                <span class="secondSlide3025 calibriFont">{{getSingleTextLabel('3025')}}:</span>
                                <span class="secondSlide45 calibriFont">{{getSingleTextLabel('45')}}</span>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 2-->
    
                    <div class="carousel-item" *ngIf="(currentMonth===1 || currentMonth===2) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption thirdSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 3</span> -->
                                <span class="thirdSlideTitle calibriFont">
                                    <span class="font-weight-bold">{{getSingleTextLabel('7139') | uppercase}}:
                                    </span>{{getSingleTextLabel('96')}}, {{getSingleTextLabel('7136')}}, {{getSingleTextLabel('7137')}},
                                    {{getSingleTextLabel('7138')}}
                                </span>
                                <div class="thirdSlide96 calibriFont">
                                    <span>{{getSingleTextLabel('96')}}</span>
                                </div>
                                <div class="thirdSlide7136 calibriFont">
                                    <span>{{getSingleTextLabel('7136')}}</span>
                                </div>
                                <div class="thirdSlide7137 calibriFont">
                                    <span>{{getSingleTextLabel('7137')}}</span>
                                </div>
                                <div class="thirdSlide7138 calibriFont">
                                    <span>{{getSingleTextLabel('7138')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 3-->
    
                    <div class="carousel-item" *ngIf="(currentMonth===1 || currentMonth===2) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption fourthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 4</span> -->
                                <!--<span class="fourthSlideTitle calibriFont">{{getSingleTextLabel('3002')}}</span>-->
                                <span class="fourthSlideTitle calibriFont">
                                    <span class="font-weight-bold">{{ getSingleTextLabel('3002') }}</span>
                                    <br><span class="textAfterColon">{{ getSingleTextLabel('3002.1') }}</span>
                                </span>
                                <span class="fourthSlideSales calibriFont">{{getSingleTextLabel('15')}}</span>
                                <span class="fourthSlideExpenses calibriFont">{{getSingleTextLabel('95')}}</span>
                                <span class="fourthSlideProfit calibriFont">{{getSingleTextLabel('96')}}</span>
                                <span class="fourthIncomeStatement style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="fourthIncomeStatementDesc style2a calibriFont">{{getSingleTextLabel('7017')}}</span>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 4-->
    
                    <div class="carousel-item"  *ngIf="(currentMonth>=1 && currentMonth<=2) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption fifthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 5</span> -->
                                <span class="fifthSlideTitle calibriFont">{{getSingleTextLabel('7140')}}</span>
                                <span class="fifthSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="fifthSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <<div class="fithMiniBox">
                                    <span class="fifthSlide7141 style2a calibriFont"><span class="style2c">{{getSingleTextLabel('96')}}</span>
                                        {{getSingleTextLabel('7141')}}. {{getSingleTextLabel('7142')}}</span>
                                    <span class="fifthSlide7143 style2a calibriFont"><span class="style2c">{{getSingleTextLabel('7002')}}</span>
                                        {{getSingleTextLabel('7143')}}. {{getSingleTextLabel('7144')}}</span>
                                    </div>
                                    <div class="fifthSlide96 calibriFont">
                                        <span>{{getSingleTextLabel('96')}}</span>
                                    </div>
                                    <div class="fifthSlide7002 calibriFont">   <span>{{getSingleTextLabel('7002')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 5-->
    
                    <div class="carousel-item" [ngClass]="{'active': currentMonth>=3}" *ngIf="(currentMonth>=3) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption sixthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 6</span> -->
                                <span class="sixthSlideTitle calibriFont">{{getSingleTextLabel('7145')}} {{getSingleTextLabel('13')}}</span>
                                <span class="sixthSlide13 style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="sixthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="sixMiniBox1">
                                    <span class="sixthSlide7146 style2c calibriFont">{{getSingleTextLabel('7146')}}:</span>
                                    <span class="sixthSlide7147 style2a calibriFont">{{getSingleTextLabel('7147')}}</span>
                                    <ul style="margin-bottom: 0;line-height: 15px;">
                                        <li class="sixthSlide7148 style2a calibriFont">{{getSingleTextLabel('7148')}}</li>
                                        <li class="sixthSlide7149 style2a calibriFont">{{getSingleTextLabel('7149')}}</li>
                                        <li class="sixthSlide7150 style2a calibriFont">{{getSingleTextLabel('7150')}}</li>
                                    </ul>
                                    <span class="sixthSlide71472 style2a calibriFont">{{getSingleTextLabel('7147.2')}}</span>
                                    <ul style="margin-bottom: 0;line-height: 15px;">
                                        <li class="sixthSlide714821 style2a calibriFont">{{getSingleTextLabel('7174.2.1')}}</li>
                                    </ul>
                                </div>
                                
                                <div class="sixMiniBox2"> 
                                    <span class="sixthSlide7151 style2c calibriFont">{{getSingleTextLabel('7151')}}:</span>
                                    <span class="sixthSlide7152 style2a calibriFont">{{getSingleTextLabel('7152')}}</span>
                                    <ul style="margin-bottom: 0;line-height: 13px;">
                                        <li class="sixthSlide7154 style2a calibriFont">{{getSingleTextLabel('7154')}}</li>
                                        <li class="sixthSlide7155 style2a calibriFont">{{getSingleTextLabel('7155')}}</li>
                                        <li class="sixthSlide7153 style2a calibriFont">{{getSingleTextLabel('7153')}}</li>
                                    </ul>
                                    <span class="sixthSlide71521 style2a calibriFont">{{getSingleTextLabel('7152.1')}}</span>
                                    <ul style="margin-bottom: 0;line-height:13px;">
                                        <li class="sixthSlide715212 style2a calibriFont">{{getSingleTextLabel('7152.2')}}</li> 
                                        <li class="sixthSlide7157 style2a calibriFont">{{getSingleTextLabel('7157')}}</li> 
                                        <li class="sixthSlide7156 style2a calibriFont">{{getSingleTextLabel('7156')}}</li> 
                                        <li class="sixthSlide7193 style2a calibriFont">{{getSingleTextLabel('7193')}}</li> 
                                     
                                    </ul>
                                    <span class="sixthSlide71931 style2a calibriFont">{{getSingleTextLabel('7193.1')}}</span>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                    <!--fin vista 6-->
    
                    <div class="carousel-item" *ngIf="(currentMonth>=3 || blnDemoMode)">
                        <div class="row justify-content-center">
                            <div class="caption seventhSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 7</span> -->
                                <span class="seventhSlide7145 calibriFont">{{getSingleTextLabel('7145') | uppercase}} {{getSingleTextLabel('44') | uppercase}}</span>
                                <span class="seventhSlide13 style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="seventhSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <span class="seventhSlide7002 style2c calibriFont">{{getSingleTextLabel('7002')}}:</span>
                                <ul class="style2a calibriFont seventhSlideList1">
                                    <li>{{getSingleTextLabel('7159')}}</li>
                                    <li>{{getSingleTextLabel('7158')}}</li>
                                    <li>{{getSingleTextLabel('7160')}}</li>
                                </ul>
                                <span class="seventhSlide7161 style2c calibriFont">{{getSingleTextLabel('7161')}}:</span>
                                <ul class="style2a calibriFont seventhSlideList2">
                                    <li>{{getSingleTextLabel('7162')}}</li>
                                    <li>{{getSingleTextLabel('7163')}}</li>
                                    <li>{{getSingleTextLabel('7164')}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 7-->
    
                    <div class="carousel-item" *ngIf="(currentMonth>=3) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption eighthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 8</span> -->
                                <span class="eighthSlideTitle calibriFont">{{getSingleTextLabel('7166')}}</span>
                                <span class="eighthSlide13 style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="eighthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div style="top: 157px; left: 315px; position: absolute; max-width: 374px;">
                                    <div class="eighthSlide7167 style2a calibriFont">{{getSingleTextLabel('7167')}}</div>
                                    <span class="eighthSlide7168 style2c calibriFont">{{getSingleTextLabel('7168')}}</span>
                                    <ul class="eighthSlideList1 style2a calibriFont">
                                        <li>{{getSingleTextLabel('7169')}}</li>
                                        <li>{{getSingleTextLabel('7170')}}</li>
                                    </ul>
                                    <span class="eighthSlide7171 style2c calibriFont">{{getSingleTextLabel('7171')}}</span>
                                    <ul class="eighthSlideList2 style2a calibriFont">
                                        <li>{{getSingleTextLabel('25')}}</li>
                                        <li>{{getSingleTextLabel('7108')}}</li>
                                        <li>{{getSingleTextLabel('7172')}}</li>
                                        <li>{{getSingleTextLabel('30')}}</li>
                                        <li>{{getSingleTextLabel('33')}}</li>
                                        <li>{{getSingleTextLabel('7173')}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 8-->
    
                    <div class="carousel-item" *ngIf="(currentMonth>=3) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption ninthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 9</span> -->
                                <span class="ninthSlideTitle calibriFont">{{getSingleTextLabel('7174')}}</span>
                                <span class="ninthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="ninthBox">
                                    <div class="ninthSlide7195 style2a calibriFont">{{getSingleTextLabel('7195')}}</div>
                                    <div>
                                        <span class="ninthSlide7175 style2c calibriFont">{{getSingleTextLabel('7175')}}</span>
                                        <ul class="ninthSlideList1 style2a calibriFont">
                                            <li>{{getSingleTextLabel('7176')}}</li>
                                            <li>{{getSingleTextLabel('7177')}}</li>
                                            <li>{{getSingleTextLabel('7178')}}</li>
                                            <li>{{getSingleTextLabel('7179')}}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <span class="ninthSlide7171 style2c calibriFont">{{getSingleTextLabel('7171')}}</span>
                                        <ul class="ninthSlideList2 style2a calibriFont" >
                                            <li>{{getSingleTextLabel('7180')}}</li>
                                            <li>{{getSingleTextLabel('7181')}}</li>
                                            <li>{{getSingleTextLabel('7182')}}</li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 9-->
    
                    <div class="carousel-item" *ngIf="(currentMonth>=3) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption tenthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 10</span> -->
                                <span class="calibriFont tenthSlideTitle">{{getSingleTextLabel('7187')}}</span>
                                <span class="calibriFont tenthSlideSubTitle">{{getSingleTextLabel('7196')}}</span>
                                <span class="tenthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="tenBox">
                                    <span class="calibriFont tenthSlide7183">
                                        <span class="style2c">{{getSingleTextLabel('7183')}}</span><br/><span class="style2a" >{{getSingleTextLabel('7184')}}</span>
                                    </span>
                                    <ul class="tenthSlideList1 style2a calibriFont">
                                        <li>{{getSingleTextLabel('7185')}}</li>
                                        <li>{{getSingleTextLabel('7186')}}</li>
                                    </ul>
                                    <span class="style2a calibriFont tenthSlide7178">{{getSingleTextLabel('7183')}} {{getSingleTextLabel('7188')}}</span>
                                    <span class="tenthSlide7171 style2c calibriFont">{{getSingleTextLabel('7171')}}</span>
                                    <ul class="tenthSlideList2 style2a calibriFont">
                                        <li>{{getSingleTextLabel('47')}}</li>
                                        <li>{{getSingleTextLabel('7189')}}</li>
                                    </ul>
                                    
                                </div>
                                <span class="tenthSlide7103 style2a calibriFont">
                                    <span class="style2c">{{getSingleTextLabel('7103')}}</span>
                                    <br/>
                                    {{getSingleTextLabel('7191')}}
                                </span>
                                <span class="tenthSlide7197 calibriFont"><span style="color: #234b91;">{{getSingleTextLabel('46')}}:</span>{{getSingleTextLabel('7197')}}</span>
                                <span class="tenthSlide7198 calibriFont"><span style="color: #234b91;">{{getSingleTextLabel('155')}}:</span> {{getSingleTextLabel('7198')}}</span>

                                
                            </div>
                        </div>
                    </div>
                    <!--fin vista 10-->
    
                    <div class="carousel-item" *ngIf="(currentMonth>=3) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption eleventhSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 11</span> -->
                                <span class="eleventhSlideTitle calibriFont">{{getSingleTextLabel('7005') | uppercase}}</span>
                                <span class="eleventhSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="eleventhSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="eleventhSlide15 calibriFont">
                                    <span>{{getSingleTextLabel('15')}}</span>
                                </div>
                                <div class="eleventhSlide96 calibriFont">
                                    <span>{{getSingleTextLabel('96')}}</span>
                                </div>
                                <div class="eleventhSlide45 calibriFont">
                                    <span>{{getSingleTextLabel('45')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 11-->
    
                    <div class="carousel-item" *ngIf="(currentMonth>=3) || blnDemoMode">
                        <div class="row justify-content-center">
                            <div class="caption twelfthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 12</span> -->
                                <div style="height: 75px;">
                                    <span class="twelfthSlideTitle calibriFont">{{getSingleTextLabel('7006') | uppercase}}</span>

                                </div>                                <span class="twelfthSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="twelfthSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                
                                <span class="twelfthSlide15 calibriFont">{{getSingleTextLabel('15')}}</span>
                                <span class="twelfthSlide96 calibriFont">{{getSingleTextLabel('96')}}</span>
                                <span class="twelfthSlide45 calibriFont">{{getSingleTextLabel('45')}}</span>
    
                                <div class="twelfthSlide7010 calibriFont">
                                    <span><span class="twelfthSlide7013">{{getSingleTextLabel('7013')}}</span><br>{{getSingleTextLabel('7010')}} = {{getSingleTextLabel('96')}} / {{getSingleTextLabel('15')}} {{getSingleTextLabel('7194')}}</span>
                                </div>
                                <div class="twelfthSlide7011 calibriFont">
                                    <span><span class="twelfthSlide7014">{{getSingleTextLabel('7014')}}</span><br>{{getSingleTextLabel('7011')}} = {{getSingleTextLabel('15')}} / {{getSingleTextLabel('45')}}</span>
                                </div>
                                <div  class="twelfthSlide7012 calibriFont">
                                    <span><span class="twelfthSlide7015">{{getSingleTextLabel('7015')}}</span><br>{{getSingleTextLabel('7012')}} = {{getSingleTextLabel('96')}} / {{getSingleTextLabel('45')}} {{getSingleTextLabel('7194')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 12-->
    
                </div>
    
    
                <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
    </ng-template>
    <ng-template #otherCase>
        
        <div id="carouselExampleCaptions" class="carousel carousel-dark slide" style="height: 100%;">
            <div id="carouselExampleControls" class="carousel slide">
                <div class="carousel-inner" style="height: 100%;">
                    
                    <div class="carousel-item active">
                        <div class="row justify-content-center">
                            <div class="caption firstSlide" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 1</span> -->
                                <span class="firstSlideTitle font-weight-bold calibriFont">{{getSingleTextLabel('7007') | uppercase}}</span>
                                <span class="firstSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="firstSlideISDesc style2a calibriFont">{{getSingleTextLabel('7017')}}</span>
                                <span class="firstSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <span class="firstSlideBSDesc style2a calibriFont">{{getSingleTextLabel('7018')}}</span>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 1-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption secondSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 2</span> -->
                                <!--<span class="secondSlideDescription calibriFont">{{getSingleTextLabel('3018')}}</span>-->
                                <span class="secondSlideDescription calibriFont">
                                    <span class="font-weight-bold">{{ getSingleTextLabel('3018') }}</span>
                                    <br><span class="textAfterColon">{{ getSingleTextLabel('3018.1') }}</span>
                                </span>
                                <span class="secondSlideText2 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <span class="secondSlideText1 style2a calibriFont">{{getSingleTextLabel('7018')}}</span>
                                <span class="secondSlide3026 calibriFont">{{getSingleTextLabel('3026')}}:</span>
                                <span class="secondSlide79 calibriFont">{{getSingleTextLabel('79')}}</span>
                                <span class="secondSlide66 calibriFont">{{getSingleTextLabel('66')}}</span>
                                <span class="secondSlide3025 calibriFont">{{getSingleTextLabel('3025')}}:</span>
                                <span class="secondSlide45 calibriFont">{{getSingleTextLabel('45')}}</span>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 2-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption thirdSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 3</span> -->
                                <span class="thirdSlideTitle calibriFont">
                                    <span class="font-weight-bold">{{getSingleTextLabel('7139') | uppercase}}:
                                    </span>{{getSingleTextLabel('96')}}, {{getSingleTextLabel('7136')}}, {{getSingleTextLabel('7137')}},
                                    {{getSingleTextLabel('7138')}}
                                </span>
                                <div class="thirdSlide96 calibriFont">
                                    <span>{{getSingleTextLabel('96')}}</span>
                                </div>
                                <div class="thirdSlide7136 calibriFont">
                                    <span>{{getSingleTextLabel('7136')}}</span>
                                </div>
                                <div class="thirdSlide7137 calibriFont">
                                    <span>{{getSingleTextLabel('7137')}}</span>
                                </div>
                                <div class="thirdSlide7138 calibriFont">
                                    <span>{{getSingleTextLabel('7138')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 3-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption fourthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 4</span> -->
                                <!--<span class="fourthSlideTitle calibriFont">{{getSingleTextLabel('3002')}}</span>-->
                                <span class="fourthSlideTitle calibriFont">
                                    <span class="font-weight-bold">{{ getSingleTextLabel('3002') }}</span>
                                    <br><span class="textAfterColon">{{ getSingleTextLabel('3002.1') }}</span>
                                </span>
                                <span class="fourthSlideSales calibriFont">{{getSingleTextLabel('15')}}</span>
                                <span class="fourthSlideExpenses calibriFont">{{getSingleTextLabel('95')}}</span>
                                <span class="fourthSlideProfit calibriFont">{{getSingleTextLabel('96')}}</span>
                                <span class="fourthIncomeStatement style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="fourthIncomeStatementDesc style2a calibriFont">{{getSingleTextLabel('7017')}}</span>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 4-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption fifthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 5</span> -->
                                <span class="fifthSlideTitle calibriFont">{{getSingleTextLabel('7140')}}</span>
                                <span class="fifthSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="fifthSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="fithMiniBox">
                                    <span class="fifthSlide7141 style2a calibriFont"><span class="style2c">{{getSingleTextLabel('96')}}</span> {{getSingleTextLabel('7141')}}. {{getSingleTextLabel('7142')}}</span>
                                    <span class="fifthSlide7143 style2a calibriFont"><span class="style2c">{{getSingleTextLabel('7002')}}</span> {{getSingleTextLabel('7143')}}. {{getSingleTextLabel('7144')}}</span>
                                </div>
                                 <div class="fifthSlide96 calibriFont">
                                    <span>{{getSingleTextLabel('96')}}</span>
                                </div>
                                <div class="fifthSlide7002 calibriFont">
                                    <span>{{getSingleTextLabel('7002')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 5-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption sixthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 6</span> -->
                                <span class="sixthSlideTitle calibriFont">{{getSingleTextLabel('7145')}} {{getSingleTextLabel('13')}}</span>
                                <span class="sixthSlide13 style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="sixthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="sixMiniBox1">
                                    <span class="sixthSlide7146 style2c calibriFont">{{getSingleTextLabel('7146')}}:</span>
                                    <span class="sixthSlide7147 style2a calibriFont">{{getSingleTextLabel('7147')}}</span>
                                    <ul style="margin-bottom: 0;line-height: 15px;">
                                        <li class="sixthSlide7148 style2a calibriFont">{{getSingleTextLabel('7148')}}</li>
                                        <li class="sixthSlide7149 style2a calibriFont">{{getSingleTextLabel('7149')}}</li>
                                        <li class="sixthSlide7150 style2a calibriFont">{{getSingleTextLabel('7150')}}</li>
                                    </ul>
                                    <span class="sixthSlide71472 style2a calibriFont">{{getSingleTextLabel('7147.2')}}</span>
                                    <ul style="margin-bottom: 0;line-height: 15px;">
                                        <li class="sixthSlide714821 style2a calibriFont">{{getSingleTextLabel('7174.2.1')}}</li>
                                    </ul>
                                </div>
                                
                                <div class="sixMiniBox2"> 
                                    <span class="sixthSlide7151 style2c calibriFont">{{getSingleTextLabel('7151')}}:</span>
                                    <span class="sixthSlide7152 style2a calibriFont">{{getSingleTextLabel('7152')}}</span>
                                    <ul style="margin-bottom: 0;line-height: 13px;">
                                        <li class="sixthSlide7154 style2a calibriFont">{{getSingleTextLabel('7154')}}</li>
                                        <li class="sixthSlide7155 style2a calibriFont">{{getSingleTextLabel('7155')}}</li>
                                        <li class="sixthSlide7153 style2a calibriFont">{{getSingleTextLabel('7153')}}</li>
                                    </ul>
                                    <span class="sixthSlide71521 style2a calibriFont">{{getSingleTextLabel('7152.1')}}</span>
                                    <ul style="margin-bottom: 0;line-height:13px;">
                                        <li class="sixthSlide715212 style2a calibriFont">{{getSingleTextLabel('7152.2')}}</li> 
                                        <li class="sixthSlide7157 style2a calibriFont">{{getSingleTextLabel('7157')}}</li> 
                                        <li class="sixthSlide7156 style2a calibriFont">{{getSingleTextLabel('7156')}}</li> 
                                        <li class="sixthSlide7193 style2a calibriFont">{{getSingleTextLabel('7193')}}</li> 
                                     
                                    </ul>
                                    <span class="sixthSlide71931 style2a calibriFont">{{getSingleTextLabel('7193.1')}}</span>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                    <!--fin vista 6-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption seventhSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 7</span> -->
                                <span class="seventhSlide7145 calibriFont">{{getSingleTextLabel('7145') | uppercase}} {{getSingleTextLabel('44') | uppercase}}</span>
                                <span class="seventhSlide13 style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="seventhSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <span class="seventhSlide7002 style2c calibriFont">{{getSingleTextLabel('7002')}}:</span>
                                <ul class="style2a calibriFont seventhSlideList1">
                                    <li>{{getSingleTextLabel('7159')}}</li>
                                    <li>{{getSingleTextLabel('7158')}}</li>
                                    <li>{{getSingleTextLabel('7160')}}</li>
                                </ul>
                                <span class="seventhSlide7161 style2c calibriFont">{{getSingleTextLabel('7161')}}:</span>
                                <ul class="style2a calibriFont seventhSlideList2">
                                    <li>{{getSingleTextLabel('7162')}}</li>
                                    <li>{{getSingleTextLabel('7163')}}</li>
                                    <li>{{getSingleTextLabel('7164')}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 7-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption eighthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 8</span> -->
                                <span class="eighthSlideTitle calibriFont">{{getSingleTextLabel('7166')}}</span>
                                <span class="eighthSlide13 style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="eighthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div style="top: 157px; left: 315px; position: absolute; max-width: 374px;">
                                    <div class="eighthSlide7167 style2a calibriFont">{{getSingleTextLabel('7167')}}</div>
                                    <span class="eighthSlide7168 style2c calibriFont">{{getSingleTextLabel('7168')}}</span>
                                    <ul class="eighthSlideList1 style2a calibriFont">
                                        <li>{{getSingleTextLabel('7169')}}</li>
                                        <li>{{getSingleTextLabel('7170')}}</li>
                                    </ul>
                                    <span class="eighthSlide7171 style2c calibriFont">{{getSingleTextLabel('7171')}}</span>
                                    <ul class="eighthSlideList2 style2a calibriFont">
                                        <li>{{getSingleTextLabel('25')}}</li>
                                        <li>{{getSingleTextLabel('7108')}}</li>
                                        <li>{{getSingleTextLabel('7172')}}</li>
                                        <li>{{getSingleTextLabel('30')}}</li>
                                        <li>{{getSingleTextLabel('33')}}</li>
                                        <li>{{getSingleTextLabel('7173')}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 8-->
    
                    <div class="carousel-item" >
                        <div class="row justify-content-center">
                            <div class="caption ninthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 9</span> -->
                                <span class="ninthSlideTitle calibriFont">{{getSingleTextLabel('7174')}}</span>
                                <span class="ninthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="ninthBox">
                                    <div class="ninthSlide7195 style2a calibriFont">{{getSingleTextLabel('7195')}}</div>
                                    <div>
                                        <span class="ninthSlide7175 style2c calibriFont">{{getSingleTextLabel('7175')}}</span>
                                        <ul class="ninthSlideList1 style2a calibriFont">
                                            <li>{{getSingleTextLabel('7176')}}</li>
                                            <li>{{getSingleTextLabel('7177')}}</li>
                                            <li>{{getSingleTextLabel('7178')}}</li>
                                            <li>{{getSingleTextLabel('7179')}}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <span class="ninthSlide7171 style2c calibriFont">{{getSingleTextLabel('7171')}}</span>
                                        <ul class="ninthSlideList2 style2a calibriFont" >
                                            <li>{{getSingleTextLabel('7180')}}</li>
                                            <li>{{getSingleTextLabel('7181')}}</li>
                                            <li>{{getSingleTextLabel('7182')}}</li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 9-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption tenthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 10</span> -->
                                <span class="calibriFont tenthSlideTitle">{{getSingleTextLabel('7187')}}</span>
                                <span class="calibriFont tenthSlideSubTitle">{{getSingleTextLabel('7196')}}</span>
                                <span class="tenthSlide44 style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="tenBox">
                                    <span class="calibriFont tenthSlide7183">
                                        <span class="style2c">{{getSingleTextLabel('7183')}}</span><br/><span class="style2a" >{{getSingleTextLabel('7184')}}</span>
                                    </span>
                                    <ul class="tenthSlideList1 style2a calibriFont">
                                        <li>{{getSingleTextLabel('7185')}}</li>
                                        <li>{{getSingleTextLabel('7186')}}</li>
                                    </ul>
                                    <span class="style2a calibriFont tenthSlide7178">{{getSingleTextLabel('7183')}} {{getSingleTextLabel('7188')}}</span>
                                    <span class="tenthSlide7171 style2c calibriFont">{{getSingleTextLabel('7171')}}</span>
                                    <ul class="tenthSlideList2 style2a calibriFont">
                                        <li>{{getSingleTextLabel('47')}}</li>
                                        <li>{{getSingleTextLabel('7189')}}</li>
                                    </ul>
                                    
                                </div>
                                <span class="tenthSlide7103 style2a calibriFont">
                                    <span class="style2c">{{getSingleTextLabel('7103')}}</span>
                                    <br/>
                                    {{getSingleTextLabel('7191')}}
                                </span>
                                <span class="tenthSlide7197 calibriFont"><span style="color: #234b91;">{{getSingleTextLabel('46')}}:</span>{{getSingleTextLabel('7197')}}</span>
                                <span class="tenthSlide7198 calibriFont"><span style="color: #234b91;">{{getSingleTextLabel('155')}}:</span> {{getSingleTextLabel('7198')}}</span>

                                
                            </div>
                        </div>
                    </div>
                    <!--fin vista 10-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption eleventhSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 11</span> -->
                                <span class="eleventhSlideTitle calibriFont">{{getSingleTextLabel('7005') | uppercase}}</span>
                                <span class="eleventhSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="eleventhSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                <div class="eleventhSlide15 calibriFont">
                                    <span>{{getSingleTextLabel('15')}}</span>
                                </div>
                                <div class="eleventhSlide96 calibriFont">
                                    <span>{{getSingleTextLabel('96')}}</span>
                                </div>
                                <div class="eleventhSlide45 calibriFont">
                                    <span>{{getSingleTextLabel('45')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 11-->
    
                    <div class="carousel-item">
                        <div class="row justify-content-center">
                            <div class="caption twelfthSlide">
                                <!-- <span class="labelSlide calibriFont">{{getSingleTextLabel('7192') | uppercase}} 12</span> -->
                                <div style="height: 75px;">
                                    <span class="twelfthSlideTitle calibriFont">{{getSingleTextLabel('7006') | uppercase}}</span>

                                </div>                                <span class="twelfthSlideIS style2c calibriFont">{{getSingleTextLabel('13')}}</span>
                                <span class="twelfthSlideBS style2c calibriFont">{{getSingleTextLabel('44')}}</span>
                                
                                <span class="twelfthSlide15 calibriFont">{{getSingleTextLabel('15')}}</span>
                                <span class="twelfthSlide96 calibriFont">{{getSingleTextLabel('96')}}</span>
                                <span class="twelfthSlide45 calibriFont">{{getSingleTextLabel('45')}}</span>
    
                                <div class="twelfthSlide7010 calibriFont">
                                    <span><span class="twelfthSlide7013">{{getSingleTextLabel('7013')}}</span><br>{{getSingleTextLabel('7010')}} = {{getSingleTextLabel('96')}} / {{getSingleTextLabel('15')}} {{getSingleTextLabel('7194')}}</span>
                                </div>
                                <div class="twelfthSlide7011 calibriFont">
                                    <span><span class="twelfthSlide7014">{{getSingleTextLabel('7014')}}</span><br>{{getSingleTextLabel('7011')}} = {{getSingleTextLabel('15')}} / {{getSingleTextLabel('45')}}</span>
                                </div>
                                <div  class="twelfthSlide7012 calibriFont">
                                    <span><span class="twelfthSlide7015">{{getSingleTextLabel('7015')}}</span><br>{{getSingleTextLabel('7012')}} = {{getSingleTextLabel('96')}} / {{getSingleTextLabel('45')}} {{getSingleTextLabel('7194')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--fin vista 12-->
    
                </div>
    
    
                <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </ng-template>

    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
        integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
        crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
        integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1"
        crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
        integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
        crossorigin="anonymous"></script>
</body>

</html>