import { Component, OnInit, Inject } from '@angular/core';
import { RulesService } from 'src/app/services/rules/rules.services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-rules-game',
  templateUrl: './rules-game.component.html',
  styleUrls: ['./rules-game.component.scss']
})
export class RulesGameComponent implements OnInit {

  jsonRules = [];
  constructor(private rulesService: RulesService,
              public languageService:LanguageService,
               public matDialogRef: MatDialogRef<RulesGameComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {languageId: any, arrTextLabels: any, boardType: string}) { }

  ngOnInit(): void {
    this.fnGetRules();
  }

  fnGetRules(){
    this.rulesService.getRulesByLanguageId(this.data.languageId).subscribe((response) => {
      this.jsonRules = response['data'];
      console.log("REGLAS: ",this.data.boardType );
    });
}

  fnObtenerLabel(id){
    const tmp = this.data.arrTextLabels.find(element => element.LabelNumber === id);
    return tmp ? tmp.Label : '';
    /*let valor = this.jsonRules.filter(x=>x.LabelNumber === id)
    let label = valor[0].Label;
    return label;*/
  }
}
