
<!-- confirm-dialog.component.html -->
<div class="all">
<h1 [ngClass]="{'rtl': languageService.getRtl()}" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="calibriFont">
  {{getSingleTextLabel('3028')}}
</h1>

<div mat-dialog-content class="mat-content">
  <div class="message style2b calibriFont">
    <p [ngClass]="{'rtl': languageService.getRtl()}" >{{getSingleTextLabel('3029')}}</p>
  </div>
  
  <div class="row main">
    <div [ngClass]="{'rtl': languageService.getRtl()}" class="col-5 title style3 calibriFont">{{getSingleTextLabel('3025')}} = {{getSingleTextLabel('45')}}</div>
    <div [ngClass]="{'rtl': languageService.getRtl()}"class="col-7" style="text-align: center;"><span class="style2aBold calibriFont"> {{getSingleTextLabel('105')}} </span>
      <div class="wrapper">
        <div class="w columnHeader style4 calibriFont">1</div>
        <div class="w columnHeader style4 calibriFont">2</div>
        <div class="w columnHeader style4 calibriFont">3</div>
        <div class="w columnHeader style4 calibriFont">4</div>
        <div class="w columnHeader style4 calibriFont">5</div>
      </div>
    </div>
  </div>



  <div class="row">
    <div [ngClass]="{'rtl': languageService.getRtl()}" class="col-5 calibriFont">{{getSingleTextLabel('47')}}</div>
    <div class="col-7" style="text-align: center;">
      <div class="wrapper">

        <div class="w style5a calibriFont">
          <div *ngIf="step == 0 && canEdit && enableOk"  class="margin"><input id="0"  type="number" (change)="fnChangeInput(data11, 0, 'data11')" (keyup)="TotalColumn11()" matInput class="w style5a calibriFont" (keypress)="validateInputNumber($event)"
              [(ngModel)]="data11" (keyup.enter)="validateKey('5')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 0" class="margin"><input matInput class="w style5a calibriFont" 
              [(ngModel)]="data11" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 1 && canEdit && enableOk" class="margin"><input id="1" matInput type="number"  (change)="fnChangeInput(data12, 1, 'data12')"  class="w style5a calibriFont" (keyup)="TotalColumn12()" (click)="TotalColumn12()" (keypress)="validateInputNumber($event)"
              [(ngModel)]="data12" (keyup.enter)="validateKey('6')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 1" class="margin"><input matInput class="w style5a calibriFont"
              [(ngModel)]="data12" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 2 && canEdit && enableOk" class="margin "><input id="2" matInput type="number"  (change)="fnChangeInput(data13, 2, 'data13')" class="w style5a calibriFont" (keyup)="TotalColumn13()"  (click)="TotalColumn13()" (keypress)="validateInputNumber($event)"
             [(ngModel)]="data13" (keyup.enter)="validateKey('7')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 2" class="margin"><input matInput class="w style5a calibriFont"
              [(ngModel)]="data13" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 3 && canEdit && enableOk" class="margin" ><input id="3" matInput type="number"  (change)="fnChangeInput(data14, 3, 'data14')" class="w style5a calibriFont" (keyup)="TotalColumn14()"  (click)="TotalColumn14()" (keypress)="validateInputNumber($event)"
              [(ngModel)]="data14" (keyup.enter)="validateKey('8')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 3" class="margin"><input matInput class="w style5a calibriFont"
              [(ngModel)]="data14" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 4 && canEdit && enableOk" class="margin"><input id="4" matInput type="number"  (change)="fnChangeInput(data15, 4, 'data15')" class="w style5a calibriFont" (keyup)="TotalColumn15()"  (click)="TotalColumn15()" (keypress)="validateInputNumber($event)"
              [(ngModel)]="data15" (keyup.enter)="validateKey('9')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 4" class="margin"><input matInput class="w style5a calibriFont"
              [(ngModel)]="data15" readonly="readonly" tabindex="-1"></div>
        </div>

      </div>
    </div>
  </div>


  <div class="row">
    <div [ngClass]="{'rtl': languageService.getRtl()}" class="col-5 calibriFont">{{getSingleTextLabel('53')}}</div>
    <div class="col-7" style="text-align: center;">
      <div class="wrapper">

        <div class="w style5a calibriFont">
          <div *ngIf="step == 0 && canEdit && enableOk" class="margin"><input id="5" matInput  type="number" (change)="fnChangeInput(data21, 5, 'data21')" class="w style5a calibriFont" (keyup)="TotalColumn11()" (click)="TotalColumn11()" (keypress)="validateInputNumber($event)"
              [(ngModel)]="data21" (keyup.enter)="validateKey('10')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 0" class="margin"><input matInput class="w style5a calibriFont"
              [(ngModel)]="data21" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 1 && canEdit && enableOk" class="margin"><input matInput id="6"  type="number" (change)="fnChangeInput(data22, 6, 'data22')" class="w style5a calibriFont" (keyup)="TotalColumn12()" (click)="TotalColumn12()" (keypress)="validateInputNumber($event)"
              [(ngModel)]="data22" (keyup.enter)="validateKey('11')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 1" class="margin"><input matInput class="w style5a calibriFont"
              [(ngModel)]="data22" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 2 && canEdit && enableOk" class="margin"><input id="7" matInput type="number"  (change)="fnChangeInput(data23, 7, 'data23')" class="w style5a calibriFont" (keyup)="TotalColumn13()" (click)="TotalColumn13()" (keypress)="validateInputNumber($event)"
              [(ngModel)]="data23" (keyup.enter)="validateKey('12')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 2" class="margin"><input matInput class="w style5a calibriFont"
              [(ngModel)]="data23" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 3 && canEdit && enableOk" class="margin"><input id="8" matInput type="number"  (change)="fnChangeInput(data24, 8, 'data24')" class="w style5a calibriFont" (keyup)="TotalColumn14()" (click)="TotalColumn14()" (keypress)="validateInputNumber($event)"
              [(ngModel)]="data24" (keyup.enter)="validateKey('13')" onkeypress="return event.charCode >= 48" min="0" ></div>
          <div *ngIf="step != 3" class="margin"><input matInput class="w style5a calibriFont"
              [(ngModel)]="data24" readonly="readonly" tabindex="-1"></div>

        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 4 && canEdit && enableOk" class="margin"><input id="9" matInput type="number"  (change)="fnChangeInput(data25, 9, 'data25')" class="w style5a calibriFont" (keyup)="TotalColumn15()" (click)="TotalColumn15()" (keypress)="validateInputNumber($event)" 
              [(ngModel)]="data25" (keyup.enter)="validateKey('14')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 4" class="margin"><input matInput class="w style5a calibriFont"
              [(ngModel)]="data25" readonly="readonly" tabindex="-1"></div>
        </div>

      </div>
    </div>
  </div>

  
  <div class="row">
    <div [ngClass]="{'rtl': languageService.getRtl()}" class="col-5 calibriFont">{{getSingleTextLabel('57')}}</div>
    <div class="col-7" style="text-align: center;">
      <div class="wrapper">

        <div class="w style5a calibriFont">
          <div *ngIf="step == 0 && canEdit && enableOk" class="margin"><input id="10" matInput type="number"  (change)="fnChangeInput(data31, 10, 'data31')" class="w style5a calibriFont" (keyup)="TotalColumn11()" (click)="TotalColumn11()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data31" (keyup.enter)="validateKey('15')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 0" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data31" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 1 && canEdit && enableOk" class="margin"><input id="11" matInput type="number"  (change)="fnChangeInput(data32, 11, 'data32')" class="w style5a calibriFont" (keyup)="TotalColumn12()" (click)="TotalColumn12()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data32" (keyup.enter)="validateKey('16')" onkeypress="return event.charCode >= 48" min="0" ></div>
          <div *ngIf="step != 1" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data32" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 2 && canEdit && enableOk" class="margin"><input id="12" matInput type="number"  (change)="fnChangeInput(data33, 12, 'data33')" class="w style5a calibriFont" (keyup)="TotalColumn13()" (click)="TotalColumn13()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data33" (keyup.enter)="validateKey('17')" onkeypress="return event.charCode >= 48" min="0" ></div>
          <div *ngIf="step != 2" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data33" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 3 && canEdit && enableOk" class="margin"><input id="13" matInput type="number"  (change)="fnChangeInput(data34, 13, 'data34')" class="w style5a calibriFont" (keyup)="TotalColumn14()" (click)="TotalColumn14()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data34" (keyup.enter)="validateKey('18')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 3" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data34" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 4 && canEdit && enableOk" class="margin"><input id="14" matInput type="number"  (change)="fnChangeInput(data35, 14, 'data35')" class="w style5a calibriFont" (keyup)="TotalColumn15()" (click)="TotalColumn15()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data35" (keyup.enter)="validateKey('19')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 4" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data35" readonly="readonly" tabindex="-1"></div>
        </div>

      </div>
    </div>
  </div>

  
  <div class="row">
    <div [ngClass]="{'rtl': languageService.getRtl()}" class="col-5 calibriFont">{{getSingleTextLabel('23.1')}}</div>
    <div class="col-7" style="text-align: center;">
      <div class="wrapper">

        <div class="w style5a calibriFont">
          <div *ngIf="step == 0 && canEdit && enableOk" class="margin"><input id="15" matInput type="number"  (change)="fnChangeInput(data41,15, 'data41')" class="w style5a calibriFont" (keyup)="TotalColumn11()" (click)="TotalColumn11()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data41" (keyup.enter)="validateKey('20')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 0" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data41" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 1 && canEdit && enableOk" class="margin"><input id="16" matInput type="number"  (change)="fnChangeInput(data42, 16, 'data42')" class="w style5a calibriFont" (keyup)="TotalColumn12()" (click)="TotalColumn12()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data42" (keyup.enter)="validateKey('21')" onkeypress="return event.charCode >= 48" min="0" ></div>
          <div *ngIf="step != 1" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data42" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 2 && canEdit && enableOk" class="margin"><input id="17" matInput type="number"  (change)="fnChangeInput(data43, 17, 'data43')" class="w style5a calibriFont" (keyup)="TotalColumn13()" (click)="TotalColumn13()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data43" (keyup.enter)="validateKey('22')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 2" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data43" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 3 && canEdit && enableOk" class="margin"><input id="18" matInput type="number"  (change)="fnChangeInput(data44, 18, 'data44')" class="w style5a calibriFont" (keyup)="TotalColumn14()" (click)="TotalColumn14()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data44" (keyup.enter)="validateKey('23')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 3" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data44" readonly="readonly" tabindex="-1"></div>

        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 4 && canEdit && enableOk" class="margin"><input id="19" matInput type="number"  (change)="fnChangeInput(data45, 19, 'data45')" class="w style5a calibriFont" (keyup)="TotalColumn15()" (click)="TotalColumn15()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data45" (keyup.enter)="validateKey('24')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 4" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data45" readonly="readonly" tabindex="-1"></div>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div [ngClass]="{'rtl': languageService.getRtl()}" class="col-5 calibriFont">{{getSingleTextLabel('61')}}</div>
    <div class="col-7" style="text-align: center;">
      <div class="wrapper">

        <div class="w style5a calibriFont">
          <div *ngIf="step == 0 && canEdit && enableOk" class="margin"><input id="20" matInput type="number"  (change)="fnChangeInput(data51, 20, 'data51')" class="w style5a calibriFont" (keyup)="TotalColumn11()" (click)="TotalColumn11()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data51" (keyup.enter)="validateKey('25')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 0" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data51" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 1 && canEdit && enableOk" class="margin"><input id="21" matInput type="number"  (change)="fnChangeInput(data52, 21, 'data52')" class="w style5a calibriFont" (keyup)="TotalColumn12()" (click)="TotalColumn12()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data52" (keyup.enter)="validateKey('26')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 1" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data52" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 2 && canEdit && enableOk" class="margin"><input id="22" matInput type="number"  (change)="fnChangeInput(data53, 22, 'data53')" class="w style5a calibriFont" (keyup)="TotalColumn13()" (click)="TotalColumn13()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data53" (keyup.enter)="validateKey('27')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 2" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data53" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 3 && canEdit && enableOk" class="margin"><input id="23" matInput type="number"  (change)="fnChangeInput(data54, 23, 'data54' )" class="w style5a calibriFont" (keyup)="TotalColumn14()" (click)="TotalColumn14()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data54" (keyup.enter)="validateKey('28')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 3" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data54" readonly="readonly" tabindex="-1"></div>

        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 4 && canEdit && enableOk" class="margin"><input id="24" matInput type="number"  (change)="fnChangeInput(data55, 24, 'data55')" class="w style5a calibriFont" (keyup)="TotalColumn15()" (click)="TotalColumn15()" (keypress)="validateInputNumber($event)"
            [(ngModel)]="data55" (keyup.enter)="validateKey('29')" onkeypress="return event.charCode >= 48" min="0"></div>
          <div *ngIf="step != 4" class="margin"><input matInput class="w style5a calibriFont"
            [(ngModel)]="data55" readonly="readonly" tabindex="-1"></div>
        </div>

      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-5 totalLabel">
      <strong style="display: inline-flex;">
        <div [ngClass]="{'rtl': languageService.getRtl()}" class="calibriFont" style="width: 90%;">{{getSingleTextLabel('104')}} </div>
        <span class="equal" (click)="fnResultado()" style="text-align: right; height: 1px; font-size: 20px; cursor: pointer; width: 10%;">&#61;</span>
      </strong>
    </div>
    <div class="col-7" style="text-align: center;">
      <div class="wrapper">

        <div class="w style5a calibriFont">
          <div *ngIf="step == 0" class="margin"><div *ngIf="bolFlecha == true" style="z-index: 10000; position: absolute"> <mat-icon>arrow_right_alt</mat-icon></div><input id="25" matInput type="number"  (change)="fnChangeInput(tot11, 25, 'tot11')" class="w totalInput style7a calibriFont" [(ngModel)]="tot11" (keyup)="TotalColumn11()" (click)="clean()" style="position: relative;" (keyup.enter)="validateKey('30')" (keypress)="validateInputNumber($event)"></div>
          <div  *ngIf="step != 0" class="margin"><input matInput type="number"  (change)="fnChangeInput(data11, 0, 'tot11')" class="w totalInput style7a calibriFont" [(ngModel)]="tot11" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 1" class="margin"><div *ngIf="bolFlecha == true" style="z-index: 10000; position: absolute"> <mat-icon>arrow_right_alt</mat-icon></div><input id="26" matInput type="number"  (change)="fnChangeInput(tot12, 26, 'tot12')" class="w totalInput style7a calibriFont" [(ngModel)]="tot12" (keyup)="TotalColumn12()"  (click)="clean()" style="position: relative;" (keyup.enter)="validateKey('31')" (keypress)="validateInputNumber($event)"></div>
          <div *ngIf="step != 1" class="margin"><input matInput type="number"  (change)="fnChangeInput(data11, 0, 'tot12')" class="w totalInput style7a calibriFont" [(ngModel)]="tot12" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 2" class="margin"><div *ngIf="bolFlecha == true" style="z-index: 10000; position: absolute"> <mat-icon>arrow_right_alt</mat-icon></div><input id="27" matInput type="number"  (change)="fnChangeInput(tot13, 27, 'tot13')" class="w totalInput style7a calibriFont" [(ngModel)]="tot13" (keyup)="TotalColumn13()"  (click)="clean()" style="position: relative;" (keyup.enter)="validateKey('32')" (keypress)="validateInputNumber($event)"></div>
          <div *ngIf="step != 2" class="margin"><input matInput type="number"  (change)="fnChangeInput(data11, 0, 'tot13')" class="w totalInput style7a calibriFont" [(ngModel)]="tot13" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 3" class="margin"><div *ngIf="bolFlecha == true" style="z-index: 10000; position: absolute"> <mat-icon>arrow_right_alt</mat-icon></div><input id="28" matInput type="number"  (change)="fnChangeInput(tot14, 28, 'tot14')" class="w totalInput style7a calibriFont" [(ngModel)]="tot14" (keyup)="TotalColumn14()"  (click)="clean()" style="position: relative;" (keyup.enter)="validateKey('33')" (keypress)="validateInputNumber($event)"></div>
          <div *ngIf="step != 3" class="margin"><input matInput type="number"  (change)="fnChangeInput(data11, 0, 'tot14')" class="w totalInput style7a calibriFont" [(ngModel)]="tot14" readonly="readonly" tabindex="-1"></div>
        </div>

        <div class="w style5a calibriFont">
          <div *ngIf="step == 4" class="margin"><div *ngIf="bolFlecha == true" style="z-index: 10000; position: absolute"> <mat-icon>arrow_right_alt</mat-icon></div><input id="29" matInput type="number"  (change)="fnChangeInput(tot15, 29, 'tot15')" class="w totalInput style7a calibriFont" [(ngModel)]="tot15" (keyup)="TotalColumn15()"  (click)="clean()" style="position: relative;" (keyup.enter)="validateKey('34')" (keypress)="validateInputNumber($event)"></div>
          <div *ngIf="step != 4" class="margin"><input matInput type="number"  (change)="fnChangeInput(data11, 0, 'tot15')" class="w totalInput style7a calibriFont" [(ngModel)]="tot15" readonly="readonly" tabindex="-1"></div>
        </div>
      </div>
    </div>
    <div class="alert"  *ngIf="bolFlecha == true && step >= 0 && step < 4 && allowAnim == true" >
      <div><span [ngClass]="{'rtl': languageService.getRtl()}" style="font-size: 12px;" [ngStyle]="{'color':isProd ? 'white' : 'gray'}">1097: </span>{{getSingleTextLabel('3032')}}</div>
    </div>
    <div class="alert" *ngIf="bolFlecha == true && step == 4 && canEdit && enableOk && allowAnim == true">
      <div><span [ngClass]="{'rtl': languageService.getRtl()}" style="font-size: 12px;" [ngStyle]="{'color':isProd ? 'white' : 'gray'}">1098: </span>{{getSingleTextLabel('3032')}}</div>
    </div>
</div>


<div class="row" style="margin-top: 10px; padding-right: 10px;">
  <div class="divider col-12"></div>
</div>
<div class="row" style="padding-right: 10px;">
  <div class="divider col-12"></div>
</div>

<!--SECOND PART-->
<div class="row main">
  <div [ngClass]="{'rtl': languageService.getRtl()}" class="col-6 title"><span class="style3 calibriFont">{{getSingleTextLabel('3026')}} = {{getSingleTextLabel('3027')}}</span></div>
</div>


<div class="row">
  <div [ngClass]="{'rtl': languageService.getRtl()}" class="col-5 calibriFont">{{getSingleTextLabel('69')}}</div>
  <div class="col-7" style="text-align: center;">
    <div class="wrapper">

      <div class="w style5a calibriFont">
        <div *ngIf="step == 0 && canEdit && enableOk" class="margin"><input id="30" matInput type="number"  (change)="fnChangeInput(data71, 30, 'data71')" class="w style5a calibriFont" (keyup)="TotalColumn11()" (click)="TotalColumn11()" (keypress)="validateInputNumber($event)"
          [(ngModel)]="data71" (keyup.enter)="validateKey('35')" onkeypress="return event.charCode >= 48" min="0"></div>
        <div *ngIf="step != 0" class="margin"><input matInput class="w style5a calibriFont"
          [(ngModel)]="data71" readonly="readonly" tabindex="-1"></div>
      </div>

      <div class="w style5a calibriFont">
        <div *ngIf="step == 1 && canEdit && enableOk" class="margin"><input id="31" matInput type="number"  (change)="fnChangeInput(data72, 31, 'data72')" class="w style5a calibriFont" (keyup)="TotalColumn12()" (click)="TotalColumn12()" (keypress)="validateInputNumber($event)"
          [(ngModel)]="data72" (keyup.enter)="validateKey('36')" onkeypress="return event.charCode >= 48" min="0"></div>
        <div *ngIf="step != 1" class="margin"><input matInput class="w style5a calibriFont"
          [(ngModel)]="data72" readonly="readonly" tabindex="-1"></div>
      </div>

      <div class="w style5a calibriFont"> 
        <div *ngIf="step == 2 && canEdit && enableOk" class="margin"><input id="32" matInput type="number"  (change)="fnChangeInput(data73, 32, 'data73')" class="w style5a calibriFont" (keyup)="TotalColumn13()" (click)="TotalColumn13()" (keypress)="validateInputNumber($event)"
          [(ngModel)]="data73" (keyup.enter)="validateKey('37')" onkeypress="return event.charCode >= 48" min="0"></div>
        <div *ngIf="step != 2" class="margin"><input matInput class="w style5a calibriFont"
          [(ngModel)]="data73" readonly="readonly" tabindex="-1"></div>
      </div>

      <div class="w style5a calibriFont">
        <div *ngIf="step == 3 && canEdit && enableOk" class="margin"><input id="33"  matInput type="number"  (change)="fnChangeInput(data74, 33, 'data74')" class="w style5a calibriFont" (keyup)="TotalColumn14()" (click)="TotalColumn14()" (keypress)="validateInputNumber($event)"
          [(ngModel)]="data74" (keyup.enter)="validateKey('38')" onkeypress="return event.charCode >= 48" min="0"></div>
        <div *ngIf="step != 3" class="margin"><input matInput class="w style5a calibriFont"
          [(ngModel)]="data74" readonly="readonly" tabindex="-1"></div>

      </div>

      <div class="w style5a calibriFont">
        <div *ngIf="step == 4 && canEdit && enableOk" class="margin"><input id="34" matInput type="number"  (change)="fnChangeInput(data75, 34, 'data75')" class="w style5a calibriFont" (keyup)="TotalColumn15()" (click)="TotalColumn15()" (keypress)="validateInputNumber($event)"
          [(ngModel)]="data75" (keyup.enter)="validateKey('39')" onkeypress="return event.charCode >= 48" min="0"></div>
        <div *ngIf="step != 4" class="margin"><input matInput class="w style5a calibriFont"
          [(ngModel)]="data75" readonly="readonly" tabindex="-1"></div>
      </div>

    </div>
  </div>
</div>


<div class="row">
  <div [ngClass]="{'rtl': languageService.getRtl()}" class="col-5 calibriFont">{{getSingleTextLabel('73')}}</div>
  <div class="col-7" style="text-align: center;">
    <div class="wrapper">

      <div class="w style5a calibriFont">
        <div *ngIf="step == 0 && canEdit && enableOk" class="margin"><input id="35" matInput type="number"  (change)="fnChangeInput(data81, 35, 'data81')" class="w style5a calibriFont" (keyup)="TotalColumn11()" (click)="TotalColumn11()" (keypress)="validateInputNumber($event)"
          [(ngModel)]="data81" (keyup.enter)="validateKey('40')" onkeypress="return event.charCode >= 48" min="0"></div>
        <div *ngIf="step != 0" class="margin"><input matInput class="w style5a calibriFont"
          [(ngModel)]="data81" readonly="readonly" tabindex="-1"></div>
      </div>

      <div class="w style5a calibriFont">
        <div *ngIf="step == 1 && canEdit && enableOk" class="margin"><input id="36" matInput type="number"  (change)="fnChangeInput(data82, 36, 'data82')" class="w style5a calibriFont" (keyup)="TotalColumn12()" (click)="TotalColumn12()" (keypress)="validateInputNumber($event)"
          [(ngModel)]="data82" (keyup.enter)="validateKey('41')" onkeypress="return event.charCode >= 48" min="0"></div>
        <div *ngIf="step != 1" class="margin"><input matInput class="w style5a calibriFont"
          [(ngModel)]="data82" readonly="readonly" tabindex="-1"></div>
      </div>

      <div class="w style5a calibriFont">
        <div *ngIf="step == 2 && canEdit && enableOk" class="margin"><input id="37" matInput type="number"  (change)="fnChangeInput(data83, 37, 'data83')" class="w style5a calibriFont" (keyup)="TotalColumn13()" (click)="TotalColumn13()" (keypress)="validateInputNumber($event)"
          [(ngModel)]="data83" (keyup.enter)="validateKey('42')" onkeypress="return event.charCode >= 48" min="0"></div>
        <div *ngIf="step != 2" class="margin"><input matInput class="w style5a calibriFont"
          [(ngModel)]="data83" readonly="readonly" tabindex="-1"></div>
      </div>

      <div class="w style5a calibriFont"> 
        <div *ngIf="step == 3 && canEdit && enableOk " class="margin"><input id="38" matInput type="number"  (change)="fnChangeInput(data84, 38, 'data84')" class="w style5a calibriFont" (keyup)="TotalColumn14()" (click)="TotalColumn14()" (keypress)="validateInputNumber($event)"
          [(ngModel)]="data84" (keyup.enter)="validateKey('43')" onkeypress="return event.charCode >= 48" min="0"></div>
        <div *ngIf="step != 3" class="margin"><input matInput class="w style5a calibriFont"
          [(ngModel)]="data84" readonly="readonly" tabindex="-1"></div>

      </div>

      <div class="w style5a calibriFont">
        <div *ngIf="step == 4 && canEdit && enableOk " class="margin"><input id="39" matInput type="number"  (change)="fnChangeInput(data85, 39, 'data85')" class="w style5a calibriFont" (keyup)="TotalColumn15()" (click)="TotalColumn15()" (keypress)="validateInputNumber($event)"
          [(ngModel)]="data85" (keyup.enter)="validateKey('44')" onkeypress="return event.charCode >= 48" min="0"></div>
        <div *ngIf="step != 4" class="margin"><input matInput class="w style5a calibriFont"
          [(ngModel)]="data85" readonly="readonly" tabindex="-1"></div>
      </div>

    </div>
  </div>
</div> 

<div class="row">
  <div class="col-5 totalLabel">
    <strong style="display: inline-flex;">
      <div [ngClass]="{'rtl': languageService.getRtl()}" class="calibriFont" style="width: 90%;">{{getSingleTextLabel('104')}} </div>
      <span class="equal" (click)="fnSegundoResultado()" style="text-align: right; height: 1px; font-size: 20px; cursor: pointer; width: 10%;">&#61;</span>
    </strong>
  </div>
  <div class="col-7" style="text-align: center;">
    <div class="wrapper">

      <div class="w style5a calibriFont">
        <div *ngIf="step == 0" class="margin"><div *ngIf="bolFlecha == true" style="z-index: 10000; position: absolute"> <mat-icon>arrow_right_alt</mat-icon></div><input id="40" matInput type="number"  (change)="fnChangeInput(tot21, 40, 'tot21')" class="w totalInput style7a calibriFont" [(ngModel)]="tot21" (keyup)="TotalColumn11()" (click)="clean2()" (keypress)="validateInputNumber($event)" style="position: relative;"></div>
        <div  *ngIf="step != 0" class="margin"><input matInput type="number" class="w totalInput style7a calibriFont" [(ngModel)]="tot21" readonly="readonly" tabindex="-1"></div>
      </div>

      <div class="w style5a calibriFont">
        <div *ngIf="step == 1" class="margin"><div *ngIf="bolFlecha == true" style="z-index: 10000; position: absolute"> <mat-icon>arrow_right_alt</mat-icon></div><input id="41" matInput type="number"  (change)="fnChangeInput(tot22, 41, 'tot22')" class="w totalInput style7a calibriFont" [(ngModel)]="tot22" (keyup)="TotalColumn12()" (click)="clean2()" (keypress)="validateInputNumber($event)" style="position: relative;"></div>
        <div *ngIf="step != 1" class="margin"><input matInput type="number" class="w totalInput style7a calibriFont" [(ngModel)]="tot22" readonly="readonly" tabindex="-1"></div>
      </div>

      <div class="w style5a calibriFont">
        <div *ngIf="step == 2" class="margin"><div *ngIf="bolFlecha == true" style="z-index: 10000; position: absolute"> <mat-icon>arrow_right_alt</mat-icon></div><input id="42" matInput type="number"  (change)="fnChangeInput(tot23, 42, 'tot23')" class="w totalInput style7a calibriFont" [(ngModel)]="tot23" (keyup)="TotalColumn13()" (click)="clean2()" (keypress)="validateInputNumber($event)" style="position: relative;"></div>
        <div *ngIf="step != 2" class="margin"><input matInput type="number" class="w totalInput style7a calibriFont" [(ngModel)]="tot23" readonly="readonly" tabindex="-1"></div>
      </div>

      <div class="w style5a calibriFont">
        <div *ngIf="step == 3" class="margin"><div *ngIf="bolFlecha == true" style="z-index: 10000; position: absolute"> <mat-icon>arrow_right_alt</mat-icon></div><input id="43" matInput type="number"  (change)="fnChangeInput(tot24, 43, 'tot24')" class="w totalInput style7a calibriFont" [(ngModel)]="tot24" (keyup)="TotalColumn14()" (click)="clean2()" (keypress)="validateInputNumber($event)" style="position: relative;"></div>
        <div *ngIf="step != 3" class="margin"><input matInput type="number" class="w totalInput style7a calibriFont" [(ngModel)]="tot24" readonly="readonly" tabindex="-1"></div>
      </div>

      <div class="w style5a calibriFont">
        <div *ngIf="step == 4" class="margin"><div *ngIf="bolFlecha == true" style="z-index: 10000; position: absolute"> <mat-icon>arrow_right_alt</mat-icon></div><input id="44" matInput type="number"   (change)="fnChangeInput(tot25, 44, 'tot25')" class="w totalInput style7a calibriFont" [(ngModel)]="tot25" (keyup)="TotalColumn15()" (click)="clean2()"  (keypress)="validateInputNumber($event)" style="position: relative;"></div>
        <div *ngIf="step != 4" class="margin"><input matInput type="number" class="w totalInput style7a calibriFont" [(ngModel)]="tot25" readonly="readonly" tabindex="-1"></div>
      </div>
    </div>
  </div>
</div>
</div>

<div mat-dialog-actions style="text-align: center;">
  <button *ngIf="enableOk && canEdit" class="btn btn-primary mx-3 styleButtonOK calibriFont" type="submit" (click)="fnSave()">&nbsp;&nbsp;&nbsp;&nbsp;{{getSingleTextLabel('136')}}&nbsp;&nbsp;&nbsp;&nbsp;</button>
  <button class="btn btn-secondary mx-3 FontMontserratTextInputsCancel calibriFont" [mat-dialog-close]>&nbsp;{{getSingleTextLabel('123')}}&nbsp;</button>
</div>
</div>

<!-- rest of the dialog -->